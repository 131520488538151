/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Summary from './Summary';
import PanelAccess from './PanelAccess';
import ConnectionStats from './ConnectionsStats';
import { useParams } from 'react-router';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { fetchInstallerEmailAlerts, fetchInstallerEmailAlertsArray, fetchPanelARCSettings, fetchSpecificPanelAndEstate, fetchSpecificPanelAndEstateAllDetails, fetchUserEmailAlerts } from '../../redux/actions/specificPanelActions';
import SiteDetails from './SiteDetails';
import { useMediaQuery, useTheme } from '@mui/material'
import { useSelector } from 'react-redux';
import { getRemoteServicing } from '../../redux/actions/remoteServicingActions';
import SpeicificInstallerDetails from './SpeicificInstallerDetails';
import secureLocalStorage  from  "react-secure-storage";
import UserEmailAlert from './UserEmailAlert';
import RemoteServicing from './RemoteServicing/RemoteServicing';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  
}));


export function Panel(): JSX.Element {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useTypedDispatch()
  const { currentUser } = useSelector((state: any) => state.userReducer);
  //const currentUser = JSON.parse(localStorage.getItem('currentUser') || '')
  const { specificPanel } = useSelector((state: any) => state.specificPanelAndEstateReducer);
  const {enabled } = useSelector((state: any) => state.remoteServicingReducer)
  const { id } = useParams()
  const role = secureLocalStorage.getItem('role')

  
  React.useEffect(() => {
    //dispatch(fetchSpecificPanelAndEstate(Number(id)))
    const getPanelData = async () => {
      await dispatch(fetchSpecificPanelAndEstate(Number(id)));
      // await dispatch(fetchInstallerEmailAlerts(specificPanel?.panelId))
      // await dispatch(fetchInstallerEmailAlertsArray(specificPanel?.panelId))
      // await dispatch(fetchUserEmailAlerts(specificPanel?.panelId))
      // await dispatch(fetchPanelARCSettings(Number(id)));
      await dispatch(fetchSpecificPanelAndEstateAllDetails(Number(id)));
      await dispatch(getRemoteServicing(specificPanel?.panelId)) 
    }
    if (id !== undefined)
      getPanelData();
    },[enabled?.enabled])

  return (
    <div style={md ? {} : { maxHeight: '100vh', overflow: 'auto' }}>
      <Box sx={md ? { flexGrow: 1, marginLeft: -1.5, marginRight: -1.5, marginTop: -1.4, pb: 40, maxHeight: '100vh', overflow: 'auto' } : { flexGrow: 1, marginLeft: 8, marginRight: 8, marginTop: 4, pb: 40 }}>
        <Grid container spacing={md ? 2 : 4}>
          <Grid item xs={12}>
            <Item>
              <Summary />
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item>
              <PanelAccess />
            </Item>
          </Grid>
          <Grid item xs={12}>
            {enabled?.enabled === true && (
              <RemoteServicing/>
            )}
          </Grid>
          <Grid item xs={12}>
              <Item>
                  <UserEmailAlert/>
              </Item>
          </Grid>
          <Grid item xs={12}>
            <Item><ConnectionStats /></Item>
          </Grid>
          <Grid item xs={12}>
            {currentUser?.userType !== 'END_USER' && <Item><SiteDetails /></Item>}
          </Grid>
          <Grid item xs={12}>
            {role === 'ADMIN' && <Item><SpeicificInstallerDetails /></Item>}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
