/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { useEffect } from 'react'
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Divider, MobileStepper, Paper, useMediaQuery, useTheme, TextField, IconButton, Snackbar } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '../../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { InstallersProps } from '../InstallersTypes';
import { EditInstallerModalProps } from '../EditInstallerTypes';
import { fetchEditInstallerDetails } from '../../../redux/actions/installersActions';
import secureLocalStorage from 'react-secure-storage';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  backgroundColor: 'background.paper',
  boxShadow: 20,
  p: 0,
};

export default function EditInstallerModal({ count, setCount, data, setData, open, setOpen, spokenPwd, setSpokenPwd }: EditInstallerModalProps): JSX.Element {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const [activeStep, setActiveStep] = React.useState(0);
  const dispatch = useTypedDispatch()
  const { editInstallerStatus,installerDetails } = useSelector((state: any) => state.installerReducer);
  const installer: any = secureLocalStorage.getItem('installer') || {};
  const fname = installerDetails !== undefined && installerDetails?.firstName
  const lname = installerDetails !== undefined && installerDetails?.lastName
  const cID = installerDetails !== undefined && installerDetails?.installerInfo?.cloudId
  const Email = installerDetails !== undefined && installerDetails?.installerInfo?.email
  const CEmail = installerDetails !== undefined && installerDetails?.installerInfo?.contactEmail
  const Status = installerDetails !== undefined && installerDetails?.installerInfo?.installerState
  const Company = installerDetails !== undefined && installerDetails?.installerInfo?.installerCompanyName
  const FirstPhoneNumber = installerDetails !== undefined && installerDetails?.installerInfo?.firstPhoneNumber?.number
  const CompanyAdd = installerDetails !== undefined && installerDetails?.installerInfo?.address?.address
  const Country = installerDetails !== undefined && installerDetails?.installerInfo?.address?.country
  const Postcode = installerDetails !== undefined && installerDetails?.installerInfo?.address?.postCode
  const [firstName, setFirstName] = React.useState(fname)
  const [lastName, setLastName] = React.useState(lname)
  const [userStatus, setUserStatus] = React.useState(Status)
  const [cloudId, setCloudId] = React.useState(cID)
  const [email, setEmail] = React.useState(Email)
  const [contactEmail, setContactEmail] = React.useState(CEmail)
  const [spokenPassword, setSpokenPassword] = React.useState('')
  const [firstPhoneNumber, setFirstPhoneNumber] = React.useState(FirstPhoneNumber)
  const [company, setCompany] = React.useState(Company)
  const [address, setAddress] = React.useState(CompanyAdd)
  const [country, setCountry] = React.useState(Country)
  const [postCode, setPostCode] = React.useState(Postcode)
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [message, setMessage] = React.useState<string>('');  
  const [firstNameError, setFirstNameError] = React.useState<string>('');
  const [lastNameError, setLastNameError] = React.useState<string>('');   
  const [helperPhoneText, setHelperPhoneText] = React.useState('')
  const [helperCompanyText, setHelperCompanyText] = React.useState('')
  const [addressErrorText,setAddressErrorText]=React.useState<string>("")
  const [CountryErrorText,setCountryErrorText]=React.useState<string>("")
  const [postCodeErrorText,setPostCodeErrorText]=React.useState<string>("")
  const [status, setStatus] = React.useState(false);
  const { id } = useParams()
  const { t } = useTranslation();

  useEffect(() => {
    if (status === true) {
      if (editInstallerStatus === 200) {
          setCount(count + 1)
          setMessage(`${t('msg_Installer_details_edit')}`);
      } else if (editInstallerStatus === 500) {
          setMessage(`${t('msg_internal_error')}`);
      } else if (editInstallerStatus === 400) {
          setMessage(`${t('lbl_unauthorizedUser')}`);
      } else {
          setMessage(`${t('lbl_someError')}`);
      }
      setSnackOpen(true);
      setStatus(false);
  }

  }, [status])

  const handleClose = () => {
    setOpen(false);
    setFirstName(fname)
    setLastName(lname)
    setFirstPhoneNumber(FirstPhoneNumber)
    setCompany(Company)
    setAddress(CompanyAdd)
    setCountry(Country)
    setPostCode(Postcode)
    setFirstNameError("")
    setLastNameError("")
    setHelperPhoneText("")
    setHelperCompanyText("")
    setAddressErrorText("")
    setCountryErrorText("")
    setPostCodeErrorText("")    
  }

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFirstName(value);
    if (value.length > 24) {
        setFirstNameError(`${t('error_msg_24_character')}`);
    } else {
        setFirstNameError('');
    }
};

const handleLastNameChange=(e: React.ChangeEvent<HTMLInputElement>)=>{
    const value = e.target.value;
    setLastName(value);
    if (value.length > 24) {
        setLastNameError(`${t('error_msg_24_character')}`);
    }else{
        setLastNameError('');
    }
};

const isValidPhoneNumber = (number:string) => {
  const isValid = /^\d+$/.test(number)
  return isValid
}

const handleFirstPhoneNumber=(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
  const value=e.target.value
  setFirstPhoneNumber(value)
  if(!isValidPhoneNumber(value)){
    setHelperPhoneText(`${t('msg_prohibited_chars')}`)
  }
  else if(value.length>30){
    setHelperPhoneText(`${t('msg_inputLength')}`)
  }else{
    setHelperPhoneText('')
  }
}

const handlePostCard=(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
  const value=e.target.value
  setPostCode(value)
  if(value.length>20){
    setPostCodeErrorText(`${t('error_msg_20_character')}`)
  }else{
    setPostCodeErrorText("")
  }
}

const handleCompanyName=(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
  const value=e.target.value
  setCompany(value)
  if(value.length>100){
    setHelperCompanyText(`${t('error_msg_100_character')}`)
  }else{
    setHelperCompanyText("")
  }
}

const handleAddressChange=(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
  const value=e.target.value
  setAddress(value)
  if(value.length>200){
    setAddressErrorText(`${t('error_msg_200_character')}`)
  }else{
    setAddressErrorText("")
  }
}

const handleCountryName=(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
  const value=e.target.value
  setCountry(value)
  if(value.length>100){
    setCountryErrorText(`${t('error_msg_100_character')}`)
  }else{
    setCountryErrorText("")
  }
}

  const handleSaveEditSiteDetails = async() => {
    const body = {
      ID: data.ID,
      creationTimeInMilliSeconds: data.creationTimeInMilliSeconds,     
      firstName: firstName,
      groupId: data.groupId,
      installerInfo: {
        ID: data.installerInfo.ID,
        email: data.installerInfo.email,
        contactEmail: data.installerInfo.contactEmail,
        cloudId: data.installerInfo.cloudId,
        address: {
          address: address,
          country: country,
          postCode: postCode
        },
        firstPhoneNumber: {
          number: firstPhoneNumber,
          type: data.installerInfo.firstPhoneNumber.type
        },
        installerCompanyName: company,
        installerPrimaryContact: data.installerInfo.installerPrimaryContact,
        installerState: data.installerInfo.installerState,
        secondPhoneNumber: {
          number: data.installerInfo.secondPhoneNumber,
          type: data.installerInfo.secondPhoneNumber
        },
        thirdPhoneNumber: {
          number: data.installerInfo.thirdPhoneNumber,
          type: data.installerInfo.thirdPhoneNumber
        },
      },     
      lastName: lastName,
      locale: data.locale,
      login: data.login,
      userType: data.userType,
    };

     await dispatch(fetchEditInstallerDetails(body))
     setStatus(true)
    setOpen(false);
  }

  const handleBackClick = (): any => {
    setOpen(false);
  }

  const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color="primary" size="small" onClick={handleSnackClose}>
        {t('lbl_close')}
      </Button>
    </React.Fragment>
  );


  return (
    <div>
      {md ? <>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            height: '100%',
            backgroundColor: 'background.paper',
            boxShadow: 20,
            p: 0,
          }} >
            <Paper elevation={0} sx={{ pb: 0 }}>
              <Paper
                elevation={4}
                sx={{
                  paddingTop: 2,
                  paddingBottom: 1,
                  paddingLeft: 2,
                  paddingRight: 2,
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <IconButton onClick={handleClose}>
                  <ArrowBackIcon />
                </IconButton>
                &ensp;&ensp;
                <Typography sx={{ pt: 0.5 }} variant="h6">
                  {t('lbl_editDetails')}
                </Typography>
              </Paper>
            </Paper>
            <Divider />
            <div style={{ maxHeight: '80vh', overflow: 'auto' }} >
              <Box sx={{ flexGrow: 1, paddingTop: 3, paddingBottom: 10, paddingLeft: 3, paddingRight: 3 }}>
                <Grid container spacing={3}>
                <Grid item xs={12}>
                      <TextField fullWidth id="filled-basic" value={firstName} onChange={handleFirstNameChange} label={t('lbl_firstName')} variant="filled" error={firstNameError.length > 0} helperText={firstNameError} />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField fullWidth id="filled-basic" value={lastName} onChange={handleLastNameChange} label={t('lbl_lastName')} variant="filled" error={lastNameError?.length>0} helperText={lastNameError}/>
                    </Grid>                  
                    <Grid item xs={12}>
                      <TextField fullWidth id="filled-basic"
                        label={t('lbl_firstPhone')} variant="filled" value={firstPhoneNumber} onChange={handleFirstPhoneNumber} error={helperPhoneText.length>0} helperText={helperPhoneText}/>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField fullWidth id="filled-basic"
                        label={t('lbl_company')} variant="filled" value={company} onChange={handleCompanyName} error={helperCompanyText.length>0} helperText={helperCompanyText} />
                    </Grid>
                    <Grid item xs={12}>
                    <TextField fullWidth id="filled-basic"
                      label={t('lbl_cmpnyAddress')} variant="filled" value={`${address}`} error={addressErrorText?.length>0} helperText={addressErrorText}
                      onChange={(e) => {
                        handleAddressChange(e)
                      }} />
                    </Grid>
                    <Grid item xs={12}>
                    <TextField fullWidth id="filled-basic"
                      label={t('lbl_country')} variant="filled" value={`${country}`} error={CountryErrorText.length>0} helperText={CountryErrorText}
                      onChange={(e) => {
                        handleCountryName(e)
                      }} />
                    </Grid>
                    <Grid item xs={12}>
                    <TextField fullWidth id="filled-basic"
                      label={t('lbl_postcode')} variant="filled" value={`${postCode}`} 
                      onChange={handlePostCard} error={postCodeErrorText.length>0} helperText={postCodeErrorText} />
                    </Grid>
                </Grid>
              </Box>
            </div>
            <Paper sx={{
              position: 'absolute' as 'absolute',
              top: '95vh',
              zIndex: 1000,
              transform: 'translateY(-100%)',
              width: '100%',
            }}>
              <MobileStepper
                variant="dots"
                steps={0}
                position="static"
                activeStep={activeStep}
                sx={{
                  maxWidth: '100%',
                  flexGrow: 1,
                  paddingTop: 2,
                  paddingBottom: 4,
                  paddingLeft: 3,
                  paddingRight: 3,
                  backgroundColor: 'inherit',
                }}
                nextButton={
                  <Button
                    size="medium"
                    variant="contained"
                    onClick={handleSaveEditSiteDetails}
                    disabled={firstNameError.length>0 || lastNameError.length>0 || helperPhoneText.length>0 || helperCompanyText.length>0 || addressErrorText.length>0 || CountryErrorText.length>0 || postCodeErrorText.length>0}
                  >
                    {t('btn_save')}
                  </Button>

                }
                backButton={
                  <Button size="small" variant="outlined" onClick={handleClose}>
                    {t('btn_cancel')}
                  </Button>
                }
              />
            </Paper>
          </Box>
        </Modal>
      </>
        :
        <>
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} >
              <Paper elevation={0} sx={{ paddingTop: 2, paddingBottom: 3, paddingLeft: 3, paddingRight: 2 }}>
                <Typography variant='h6'> {t('lbl_editDetails')}</Typography>
              </Paper>
              <Divider />
              <div>
                <Box sx={{ flexGrow: 1, paddingTop: 3, paddingBottom: 5, paddingLeft: 3, paddingRight: 3,maxHeight:'60vh',overflow:'auto' }}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <TextField fullWidth id="filled-basic" value={firstName} onChange={handleFirstNameChange} label={t('lbl_firstName')} variant="filled" error={firstNameError.length > 0} helperText={firstNameError} />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField  fullWidth id="filled-basic" value={lastName} onChange={handleLastNameChange} label={t('lbl_lastName')} variant="filled" error={lastNameError?.length>0} helperText={lastNameError}/>
                    </Grid>                    
                    <Grid item xs={12}>
                      <TextField fullWidth id="filled-basic"
                        label={t('lbl_firstPhone')} variant="filled" value={firstPhoneNumber} onChange={handleFirstPhoneNumber} error={helperPhoneText.length>0} helperText={helperPhoneText}/>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField fullWidth id="filled-basic"
                        label={t('lbl_company')} variant="filled" value={company} onChange={handleCompanyName} error={helperCompanyText.length>0} helperText={helperCompanyText} />
                    </Grid>
                    <Grid item xs={12}>
                    <TextField fullWidth id="filled-basic"
                      label={t('lbl_cmpnyAddress')} variant="filled" value={`${address}`} error={addressErrorText?.length>0} helperText={addressErrorText}
                      onChange={(e) => {
                        handleAddressChange(e)
                      }} />
                    </Grid>
                    <Grid item xs={12}>
                    <TextField fullWidth id="filled-basic"
                      label={t('lbl_country')} variant="filled" value={`${country}`} error={CountryErrorText.length>0} helperText={CountryErrorText}
                      onChange={(e) => {
                        handleCountryName(e)
                      }} />
                    </Grid>
                    <Grid item xs={12}>
                    <TextField fullWidth id="filled-basic"
                      label={t('lbl_postcode')} variant="filled" value={`${postCode}`} 
                      onChange={handlePostCard} error={postCodeErrorText.length>0} helperText={postCodeErrorText} 
                       />
                    </Grid>
                  </Grid>
                </Box>
              </div>
              <Divider />
              <Paper >
                <MobileStepper
                  variant="dots"
                  steps={0}
                  position="static"
                  activeStep={activeStep}
                  sx={{
                    flexGrow: 1,
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 3,
                    paddingRight: 3, backgroundColor: 'inherit'
                  }}
                  nextButton={
                    <Button size="medium" disableElevation variant='contained' onClick={handleSaveEditSiteDetails}
                    disabled={firstNameError.length>0 || lastNameError.length>0 || helperPhoneText.length>0 || helperCompanyText.length>0 || addressErrorText.length>0 || CountryErrorText.length>0 || postCodeErrorText.length>0}
                    >
                      {t('btn_save')}
                    </Button>
                  }
                  backButton={
                    <Button size="small" variant='outlined' onClick={handleClose}>
                      {t('btn_cancel')}
                    </Button>
                  }
                />
              </Paper>
            </Box>
          </Modal>
        </>}
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        message={message}
        action={action}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      />
    </div>
  );
}
