/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { MenuItem, TextField, useMediaQuery, useTheme } from '@mui/material';
import { CompanyInformationEditFormProps } from '../SettingsPropTypes';
import { useTranslation, Trans } from 'react-i18next';


export default function CompanyInformationEditForm({ firstName, setFirstName, lastName, setLastName, companyName, setCompanyName, firstPhoneNumber, setfirstPhoneNumber, secondPhoneNumber, setSecondPhoneNumber, thirdPhoneNumber, setThirdPhoneNumber, address, setAddress, postCode, setpostCode, country, setCountry, firstPhoneType, setfirstPhoneType, secondPhoneType, setSecondPhoneType, thirdPhoneType, setThirdPhoneType, countryList,
    companyErrorText, setCompanyErrorText, addressErrorText, setAddressErrorText, postCodeErrorText,setPostCodeErrorText,firstPhoneNumberError,setFirstPhoneNumberError,secoundPhoneNumberError,setSecondPhoneNumberError,thirdPhoneNumberError,setThirdPhoneNumberError}: CompanyInformationEditFormProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const { t, i18n } = useTranslation();

    const handleCompanyName=(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
        const value=e.target.value;
        setCompanyName(value)
        if(value.length>30){
            setCompanyErrorText(`${t('msg_inputLength')}`)
        }else{
            setCompanyErrorText("")
        }
    }
    const handleAddressChange=(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
        const value=e.target.value
        setAddress(value)
        if(value.length>255){
            setAddressErrorText(`${t('error_msg_255_character')}`)
        }else{
            setAddressErrorText("")
        }
    }

    const handlePostCard=(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
        const value=e.target.value
        setpostCode(value)
        if(value.length>20){
            setPostCodeErrorText(`${t('error_msg_20_character')}`)
        }else{
            setPostCodeErrorText("")
        }
    }

    const isValidPhoneNumber = (number:string) => {
        const isValid = /^\d+$/.test(number)
        return isValid
    }

    const handleFirstPhoneNumber=(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
        const value=e.target.value
        setfirstPhoneNumber(value)
        if(!isValidPhoneNumber(value)){
            setFirstPhoneNumberError(`${t('msg_prohibited_chars')}`)
        }
        else if(value.length>30){
            setFirstPhoneNumberError(`${t('msg_inputLength')}`)
        }else{
            setFirstPhoneNumberError('')
        }
    }

    const handleSecondPhoneNumber=(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
        const value=e.target.value
        setSecondPhoneNumber(value)
        if(!isValidPhoneNumber(value)){
            setSecondPhoneNumberError(`${t('msg_prohibited_chars')}`)
        }
        else if(value.length>30){
            setSecondPhoneNumberError(`${t('msg_inputLength')}`)
        }else{
            setSecondPhoneNumberError('')
        }
    }

    const handleThirdPhoneNumber=(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
        const value=e.target.value
        setThirdPhoneNumber(value)
        if(!isValidPhoneNumber(value)){
            setThirdPhoneNumberError(`${t('msg_prohibited_chars')}`)
        }
        else if(value.length>30){
            setThirdPhoneNumberError(`${t('msg_inputLength')}`)
        }else{
            setThirdPhoneNumberError('')
        }
    }

    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: 3,
                paddingBottom: 5,
                paddingLeft: 3,
                paddingRight: 3,
                maxHeight: md ? '80vh' : 430,
                overflow: 'auto',
            }}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField fullWidth id="filled-basic-first" label={t('lbl_compnyName')} value={companyName} onChange={handleCompanyName} variant="filled" error={companyErrorText.length>0} helperText={companyErrorText}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth id="filled-basic-last" label={t('lbl_address')} value={address} onChange={handleAddressChange} error={addressErrorText.length>0} helperText={addressErrorText} variant="filled" />
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth type={'email'} id="filled-basic-mail" label={t('lbl_postcode')} variant="filled" value={postCode} onChange={handlePostCard} error={postCodeErrorText.length>0} helperText={postCodeErrorText} />
                </Grid>
                <Grid item xs={12}>
                    <TextField value={country} select fullWidth id="filled-basic-language" label={t('lbl_country')} variant="filled" onChange={(e) => setCountry(e.target.value)}>
                        {countryList.length !== 0 &&
                            countryList.map((countries: any) => (
                                <MenuItem key={countries.code} value={countries.name}>
                                    {countries.name}
                                </MenuItem>
                            ))
                        }
                    </TextField>
                </Grid>
                <Grid item xs={md ? 12 : 6}>
                    <TextField fullWidth id="filled-basic-repeatPassword" label={t('lbl_firstPhone')} value={firstPhoneNumber} onChange={(e) => handleFirstPhoneNumber(e)} variant="filled" error={firstPhoneNumberError.length>0} helperText={firstPhoneNumberError} />
                </Grid>
                <Grid item xs={md ? 12 : 6}>
                    <TextField fullWidth select value={firstPhoneType} id="filled-basic-spokenPassword" label={t('lbl_firstPhType')} onChange={(e) => setfirstPhoneType(e.target.value)} variant="filled" >
                        <MenuItem value="HOME">
                        {t('lbl_home')}
                        </MenuItem>
                        <MenuItem value="MOBILE">
                        {t('lbl_mobile')}
                        </MenuItem>
                        <MenuItem value="OFFICE">
                        {t('lbl_office')}
                        </MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={md ? 12 : 6}>
                    <TextField fullWidth id="filled-basic-repeatPassword" label={t('lbl_secondPhone')} value={secondPhoneNumber} onChange={(e) => handleSecondPhoneNumber(e)} error={secoundPhoneNumberError.length>0} helperText={secoundPhoneNumberError}variant="filled" />
                </Grid>
                <Grid item xs={md ? 12 : 6}>
                    <TextField fullWidth select value={secondPhoneType} id="filled-basic-spokenPassword" label={t('lbl_secondPhType')} onChange={(e) => setSecondPhoneType(e.target.value)} variant="filled" >
                        <MenuItem value="HOME">
                        {t('lbl_home')}
                        </MenuItem>
                        <MenuItem value="MOBILE">
                        {t('lbl_mobile')}
                        </MenuItem>
                        <MenuItem value="OFFICE">
                        {t('lbl_office')}
                        </MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={md ? 12 : 6}>
                    <TextField fullWidth id="filled-basic-repeatPassword" label={t('lbl_thirdPhone')} value={thirdPhoneNumber} onChange={(e) => handleThirdPhoneNumber(e)} error={thirdPhoneNumberError.length>0} helperText={thirdPhoneNumberError} variant="filled" />
                </Grid>
                <Grid item xs={md ? 12 : 6}>
                    <TextField fullWidth select value={thirdPhoneType} id="filled-basic-spokenPassword" label={t('lbl_thirdPhType')} onChange={(e) => setThirdPhoneType(e.target.value)} variant="filled" >
                        <MenuItem value="HOME">
                        {t('lbl_home')}
                        </MenuItem>
                        <MenuItem value="MOBILE">
                        {t('lbl_mobile')}
                        </MenuItem>
                        <MenuItem value="OFFICE">
                        {t('lbl_office')}
                        </MenuItem>
                    </TextField>
                </Grid>
            </Grid>
        </Box>
    );
}
