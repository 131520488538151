/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Divider, IconButton, MobileStepper, Paper, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import Snackbar from '@mui/material/Snackbar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useTypedDispatch } from '../../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { useSelector } from 'react-redux';
import { addUser, fetchUserGroups } from '../../../redux/actions/accountUsersActions';
import { useTranslation, Trans } from 'react-i18next';
import { AdministratorsProps } from '../AdministratorsTypes';
import EditAdminForm from './EditAdminForm';
import { fetchEditAdministrator } from '../../../redux/actions/administratorsActions';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    backgroundColor: 'background.paper',
    boxShadow: 20,
    p: 0,
};

type AddAdminModalProps = {
    data: AdministratorsProps
    count: number
    setCount: (count: number) => void
    open: boolean
    setOpen: (open: boolean) => void
    setOpenAdminModal: (open: boolean) => void
}

export default function EditAdminModal({ data, count, setCount, open, setOpen, setOpenAdminModal }: AddAdminModalProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useTypedDispatch();
    const { administrators, editAdminStatus } = useSelector((state: any) => state.administratorReducer);
    const [activeStep, setActiveStep] = React.useState(0);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [firstName, setFirstName] = React.useState<string>('');
    const [lastName, setLastName] = React.useState<string>('');
    const [login, setLogin] = React.useState<string>('');
    const [language, setLanguage] = React.useState('')
    const [userType, setUserType] = React.useState<string>('');
    const [message, setMessage] = React.useState<string>('');
    const [status, setStatus] = React.useState(false);
    const [firstNameError, setFirstNameError] = React.useState<string>('');
    const [lastNameError, setLastNameError] = React.useState<string>('');    
    const [helperLoginText, setHelperLoginText] = React.useState('')
    const { t } = useTranslation();

    const isValidEmail = (email: string) => {
        const isValid = /\S+@\S+\.\S+/.test(email);
        return isValid
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const steps = [
        {
            component: (
                <EditAdminForm
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    login={login}
                    setLogin={setLogin}
                    userType={userType}
                    setUserType={setUserType}
                    language={language}
                    setLanguage={setLanguage}
                    firstNameError={firstNameError}
                    setFirstNameError={setFirstNameError}
                    lastNameError={lastNameError} 
                    setLastNameError={setLastNameError}
                    helperLoginText={helperLoginText}
                    setHelperLoginText={setHelperLoginText}
                />
            ),
        },
    ];

    React.useEffect(() => {
        if (status === true) {
            if (editAdminStatus === 200) {
                setCount(count + 1);
                setMessage(`${t('msg_adminAdded')}`);
            } else if (editAdminStatus === 500) {
                setMessage(`${t('msg_internal_error')}`);
            } else if (editAdminStatus === 400) {
                setMessage(`${t('lbl_unauthorizedUser')}`);
            } else {
                setMessage(`${t('lbl_someError')}`);
            }
            setSnackOpen(true);
            setStatus(false);
        }

        setFirstName(data.firstName);
        setLastName(data.lastName);
        setLogin(data.login);
        setUserType(data.userType);
        setLanguage(data.locale);

    }, [data, status]);

    const handleClose = () => {
        setOpen(false);
        setActiveStep(0);
        setFirstNameError("")
        setLastNameError("")
        setHelperLoginText("")
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setLogin(data.login);
        setUserType(data.userType);
        setLanguage(data.locale);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSave = async () => {
        const body = {
            ID: data.ID,
            creationTimeInMilliSeconds: data.creationTimeInMilliSeconds,
            firstName: firstName,
            groupId: data.groupId,
            lastName: lastName,
            locale: language,
            login: login,
            userType: userType,
        };
        await dispatch(fetchEditAdministrator(body, data.ID));
        setStatus(true);
        setFirstName('');
        setLastName('')
        setLogin('')
        setUserType('');
        setLanguage('');
        setActiveStep(0);
        setOpen(false);
        setOpenAdminModal(false)
    };

    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleSnackClose}>
                {t('lbl_close')}
            </Button>
        </React.Fragment>
    );

    return (
        <div>
            {md ? (
                <>
                    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box
                            sx={{
                                position: 'absolute' as 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'background.paper',
                                boxShadow: 20,
                                p: 0,
                            }}
                        >
                            <Paper elevation={0} sx={{ pb: 0 }}>
                                <Paper
                                    elevation={4}
                                    sx={{
                                        paddingTop: 2,
                                        paddingBottom: 1,
                                        paddingLeft: 2,
                                        paddingRight: 2,
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <IconButton onClick={handleClose}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                    &ensp;&ensp;
                                    <Typography sx={{ pt: 0.5 }} variant="h6">
                                        {t('lbl_editDetails')}
                                    </Typography>
                                </Paper>
                                <Divider />
                                {steps[activeStep].component}
                            </Paper>
                            <Divider />
                            <Paper sx={{
                                position: 'absolute' as 'absolute',
                                top: '93vh',
                                zIndex: 1000,
                                transform: 'translateY(-100%)',
                                width: '100%',
                            }}>
                                <MobileStepper
                                    variant="dots"
                                    steps={0}
                                    position="static"
                                    activeStep={activeStep}
                                    sx={{
                                        maxWidth: '100%',
                                        flexGrow: 1,
                                        paddingTop: 2,
                                        paddingBottom: 4,
                                        paddingLeft: 3,
                                        paddingRight: 3,
                                        backgroundColor: 'inherit',
                                    }}
                                    nextButton={
                                        <Button
                                            size="medium"
                                            variant="contained"
                                            disabled={
                                                firstName === '' ||
                                                lastName === '' ||
                                                login === '' ||
                                                userType === '' ||
                                                firstNameError.length>0 || 
                                                lastNameError.length>0 || 
                                                helperLoginText.length>0 ||
                                                (!isValidEmail(login))
                                            }
                                            onClick={handleSave}
                                        >
                                            {t('btn_save')}
                                        </Button>

                                    }
                                    backButton={
                                        <Button size="small" variant="outlined" onClick={handleClose}>
                                            {t('btn_cancel')}
                                        </Button>
                                    }
                                />
                            </Paper>
                        </Box>
                    </Modal>
                    <Snackbar
                        open={snackOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackClose}
                        message={message}
                        action={action}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    />
                </>
            ) : (
                <>
                    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box sx={style}>
                            <Paper
                                elevation={0}
                                sx={{ paddingTop: 2, paddingBottom: 3, paddingLeft: 3, paddingRight: 2 }}
                            >
                                <Typography variant="h6">{t('lbl_editDetails')}</Typography>
                            </Paper>
                            <Divider />
                            <Paper elevation={0} sx={{}}>
                                {steps[activeStep].component}
                            </Paper>
                            <Divider />
                            <MobileStepper
                                variant="dots"
                                steps={0}
                                position="static"
                                activeStep={activeStep}
                                sx={{
                                    maxWidth: 500,
                                    flexGrow: 1,
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    paddingLeft: 3,
                                    paddingRight: 3,
                                    backgroundColor: 'inherit',
                                }}
                                nextButton={

                                    <Button
                                        size="medium"
                                        variant="contained"
                                        disabled={
                                            firstName === '' ||
                                            lastName === '' ||
                                            login === '' ||
                                            userType === '' ||
                                            firstNameError.length>0 || 
                                            lastNameError.length>0 || 
                                            helperLoginText.length>0 ||
                                            (!isValidEmail(login))
                                        }
                                        onClick={handleSave}
                                    >
                                        {t('btn_save')}
                                    </Button>

                                }
                                backButton={
                                    <Button size="small" variant="outlined" onClick={handleClose}>
                                        {t('btn_cancel')}
                                    </Button>
                                }
                            />
                        </Box>
                    </Modal>
                    <Snackbar
                        open={snackOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackClose}
                        message={message}
                        action={action}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    />
                </>
            )}
        </div>
    );
}
