/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/restrict-plus-operands */

import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {
    Button,
    Divider,
    Grid,
    IconButton,
    MobileStepper,
    Paper,
    TextField,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AccountHolderEditModalProps } from '../SettingsPropTypes';
import AccountHolderEditForm from './AccountHolderEditForm';
import { editAccountHolderSettings, setDefaultStatusForCurrentUserSettings } from '../../../redux/actions/settingsActions';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '../../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { LANGUAGES } from '../../../constants/index';
import { useTranslation, Trans } from 'react-i18next';
import secureLocalStorage  from  "react-secure-storage";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    backgroundColor: 'background.paper',
    boxShadow: 20,
    p: 0,
};

export default function AccountHolderEditModal({ open, setOpen, count, setCount, sectionName, setSectionName }: AccountHolderEditModalProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const { currentUserSettings } = useSelector((state: any) => state.settingsReducer);
    const [activeStep, setActiveStep] = React.useState(0);
    const [firstName, setFirstName] = React.useState<string>(currentUserSettings?.firstName);
    const [lastName, setLastName] = React.useState<string>(currentUserSettings?.lastName);
    const [contactEmail, setContactEmail] = React.useState<string>(currentUserSettings?.installerInfo?.email);
    const [language, setLanguage] = React.useState<string>('');
    const [locale, setLocale] = React.useState<string>(currentUserSettings?.locale);
    const [password, setPassword] = React.useState<string>('');
    const [newPassword, setNewPassword] = React.useState<string>('');
    const [repeatPassword, setRepeatPassword] = React.useState<string>('');
    const [spokenPassword, setSpokenPassword] = React.useState<string>('');
    const [firstNameError, setFirstNameError] = React.useState<string>('');
    const [lastNameError, setLastNameError] = React.useState<string>('');    
    const [helperLoginText, setHelperLoginText] = React.useState('')
    const [spokenPasswordError,setSpokenPasswordError]=React.useState('')
    const [passwordError, setPasswordError] = React.useState<string>('');
    const [isError, setIsError] = React.useState<boolean>(false);
    const dispatch = useTypedDispatch()
    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        setSectionName('Account holder');
        setFirstName(currentUserSettings?.firstName)
        setLastName(currentUserSettings?.lastName)
        setContactEmail(currentUserSettings?.installerInfo?.email)
        setLanguage(currentUserSettings?.locale)
        //const spokenPasswordValue = currentUserSettings?.installerInfo?.spokenPassword.substring(1, currentUserSettings?.installerInfo?.spokenPassword.length - 1);
        setSpokenPassword(currentUserSettings?.installerInfo?.spokenPassword)
        setPassword(password)
        setNewPassword(newPassword)
        if (currentUserSettings !== undefined) {
            const languageObj = LANGUAGES.arrOfLanguages.filter((languageSettings) => languageSettings.locale === locale);
            setLanguage(languageObj[0].language);
        }
    }, []);

    const handleSave = async () => {
        i18n.changeLanguage(locale.slice(0, -3));
        //*** Locale should be appliued to login page also ***//
        localStorage.setItem('locale', locale.slice(0, -3));
        const body = {
            firstName: firstName,
            lastName: lastName,
            installerInfo: {
            ID: currentUserSettings?.installerInfo?.ID,
            cloudId: currentUserSettings?.installerInfo?.cloudId,
            installerCompanyName: currentUserSettings?.installerInfo?.installerCompanyName,
            address: {
                address: currentUserSettings?.installerInfo?.address?.address,
                postCode: currentUserSettings?.installerInfo?.address?.postCode,
                country: currentUserSettings?.installerInfo?.address?.country,
            },
            firstPhoneNumber: {
                number: currentUserSettings?.installerInfo?.firstPhoneNumber?.number,
                type: currentUserSettings?.installerInfo?.firstPhoneNumber?.type,
            },
            secondPhoneNumber: {
                number: currentUserSettings?.installerInfo?.secondPhoneNumber?.number,
                type: currentUserSettings?.installerInfo?.secondPhoneNumber?.type,
            },
            thirdPhoneNumber: {
                number: currentUserSettings?.installerInfo?.thirdPhoneNumber?.number,
                type: currentUserSettings?.installerInfo?.thirdPhoneNumber?.type,
            },
            installerPrimaryContact: "firstPhoneNumber",
            installerState: "ACTIVE",
            email: contactEmail
        },
            login: currentUserSettings?.login,
            userType: currentUserSettings?.userType,
            spokenPassword: spokenPassword,
            locale: locale,
        };

        const changePwdbody = {
            firstName: firstName,
            lastName: lastName,
            newPassword: newPassword,
            password: password
        };

        await dispatch(editAccountHolderSettings(body, currentUserSettings.ID, spokenPassword, password, changePwdbody))

        /*** Name should change in usermenu also ****/
        secureLocalStorage.setItem('firstName', firstName)
        secureLocalStorage.setItem('lastName', lastName)
        secureLocalStorage.setItem('email', currentUserSettings?.login)

        setCount(count + 1)
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
        setActiveStep(0);
    };

    return (
        <div>
            {md ? (
                <>
                    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box
                            sx={{
                                position: 'absolute' as 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'background.paper',
                                boxShadow: 20,
                                p: 0,
                            }}
                        >
                            <Paper elevation={0} sx={{ pb: 0 }}>
                                <Paper
                                    elevation={4}
                                    sx={{
                                        paddingTop: 2,
                                        paddingBottom: 1,
                                        paddingLeft: 2,
                                        paddingRight: 2,
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <IconButton onClick={handleClose}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                    &ensp;&ensp;
                                    <Typography sx={{ pt: 0.5 }} variant="h6">
                                    {t('lbl_accnt_holder')}
                                    </Typography>
                                </Paper>
                                <Divider />
                                <AccountHolderEditForm isError={isError} setIsError={setIsError} firstName={firstName} setFirstName={setFirstName} lastName={lastName} setLastName={setLastName} contactEmail={contactEmail} setContactEmail={setContactEmail} password={password} setPassword={setPassword} newPassword={newPassword} setNewPassword={setNewPassword} repeatPassword={repeatPassword} setRepeatPassword={setRepeatPassword} spokenPassword={spokenPassword} setSpokenPassword={setSpokenPassword} language={language} setLanguage={setLanguage} locale={locale} setLocale={setLocale}
                                firstNameError={firstNameError} setFirstNameError={setFirstNameError} lastNameError={lastNameError} passwordError={passwordError} setPasswordError={setPasswordError}
                                setLastNameError={setLastNameError} helperLoginText={helperLoginText} setHelperLoginText={setHelperLoginText} spokenPasswordError={spokenPasswordError} setSpokenPasswordError={setSpokenPasswordError}
                                />
                            </Paper>
                            <Paper
                                sx={{
                                    position: 'absolute' as 'absolute',
                                    top: '100vh',
                                    transform: 'translateY(-100%)',
                                    width: '100%',
                                    zIndex: 1000
                                }}
                                elevation={1}
                            >
                                <Divider />
                                <Box sx={{ flexGrow: 1, p: 2 }}>
                                    <Grid container spacing={2} >
                                        <Grid item xs={12}>
                                        <Button fullWidth size="medium" variant="contained" onClick={handleSave} 
                                            disabled={
                                                firstName === '' ||
                                                lastName === '' ||
                                                contactEmail === '' ||
                                                spokenPassword === '' ||
                                                language === '' ||
                                                password===''||
                                                firstNameError.length>0 ||    
                                                passwordError.length>0||
                                                lastNameError.length>0||
                                                helperLoginText.length>0||
                                                spokenPasswordError.length>0||
                                                (isError)
                                            }>
                                               {t('btn_save')}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button fullWidth size="small" variant="outlined" onClick={handleClose}>
                                            {t('btn_cancel')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Box>
                    </Modal>
                </>
            ) : (
                <>
                    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box sx={style}>
                            <Paper
                                elevation={0}
                                sx={{ paddingTop: 2, paddingBottom: 3, paddingLeft: 3, paddingRight: 2 }}
                            >
                                <Typography variant="h6">{t('lbl_accnt_holder')}</Typography>
                            </Paper>
                            <Divider />
                            <Paper elevation={0} sx={{}}>
                                <AccountHolderEditForm isError={isError} setIsError={setIsError} firstName={firstName} setFirstName={setFirstName} lastName={lastName} setLastName={setLastName} contactEmail={contactEmail} setContactEmail={setContactEmail} password={password} setPassword={setPassword} newPassword={newPassword} setNewPassword={setNewPassword} repeatPassword={repeatPassword} setRepeatPassword={setRepeatPassword} spokenPassword={spokenPassword} setSpokenPassword={setSpokenPassword} language={language} setLanguage={setLanguage} locale={locale} setLocale={setLocale} firstNameError={firstNameError} setFirstNameError={setFirstNameError} lastNameError={lastNameError} 
                                setLastNameError={setLastNameError} helperLoginText={helperLoginText} setHelperLoginText={setHelperLoginText} spokenPasswordError={spokenPasswordError} setSpokenPasswordError={setSpokenPasswordError} passwordError={passwordError} setPasswordError={setPasswordError}/>
                            </Paper>
                            <Divider />
                            <MobileStepper
                                variant="dots"
                                steps={0}
                                position="static"
                                activeStep={activeStep}
                                sx={{
                                    maxWidth: 500,
                                    flexGrow: 1,
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    paddingLeft: 3,
                                    paddingRight: 3,
                                    backgroundColor: 'inherit',
                                }}
                                nextButton={
                                    <Button size="medium" variant="contained" onClick={handleSave}
                                        disabled={
                                            firstName === '' ||
                                            lastName === '' ||
                                            contactEmail === '' ||
                                            password===''||
                                            spokenPassword === '' ||
                                            firstNameError.length>0 ||    
                                            passwordError.length>0||
                                            lastNameError.length>0||
                                            helperLoginText.length>0||
                                            spokenPasswordError.length>0||
                                            (isError)
                                        }>
                                        {t('btn_save')}
                                    </Button>
                                }
                                    backButton={
                                        <Button size="small" variant="outlined" onClick={handleClose}>
                                            {t('btn_cancel')}
                                        </Button>
                                    }
                                />
                        </Box>
                    </Modal>
                </>
            )}
        </div>
    );
}
function useState<T>(arg0: string): [any, any] {
    throw new Error('Function not implemented.');
}

