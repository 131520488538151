/* eslint-disable @typescript-eslint/await-thenable */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
import { EstateArrayProps, EstateProps, PanelArrayProps } from './../../components/ListAndGridView/panelEstateTypes';
import { Dispatch } from 'react';
import { DispatchTypesProps } from '../dispatchActionTypes/dispatchAndThunkActionTypes';
import { ManagePanelsAndEstatesActionTypes } from './../constants/managePanelsAndEstatesActionTypes';
import { setUnassignedPanels } from './panelEstateActions';
import { REACT_APP_BASE_URL } from '../../entities/Endpoints';
/* eslint-disable arrow-body-style */

export const setPanelsInEstate = (panelsInEstate : any):any =>{
    return {
        type : ManagePanelsAndEstatesActionTypes.PANELS_IN_ESTATE ,
        payload : panelsInEstate ,
    };
};
export const resetPanelsInEstate = (panelsInEstates :any):any =>{
    return {
        type : ManagePanelsAndEstatesActionTypes.RESET_PANELS_IN_ESTATE ,
        payload : panelsInEstates ,
    };
};

export const setEstates = (estates : EstateArrayProps):any =>{
    return {
        type : ManagePanelsAndEstatesActionTypes.SET_ESTATES ,
        payload : estates ,
    };
};
export const setPanels = (panels : PanelArrayProps):any =>{
    return {
        type : ManagePanelsAndEstatesActionTypes.SET_PANELS ,
        payload : panels ,
    };
};
export const setManageEstateStatus = (status : number):any =>{
    return {
        type : ManagePanelsAndEstatesActionTypes.SET_MANAGE_ESTATE_AND_PANELS_STATUS ,
        payload : status ,
    };
};

export const setSpecificEstateID=(ID:number):any=>{
    return {
        type : ManagePanelsAndEstatesActionTypes.SET_SPECIFIC_ESTATE_ID,
        payload : ID ,
    }
}

export const setUnassignedPanelID=(flag:any):any=>{
    return {
        type:ManagePanelsAndEstatesActionTypes.SET_UNASSIGNED_PANELS,
        payload:flag
    }
}
export const setRadioButtonChange=(value:any):any=>{
    return {
        type:ManagePanelsAndEstatesActionTypes.SET_RADIO_BUTTON_DETAILS,
        payload:value
    }
}

export function fetchPanelsInEstate(estates: EstateArrayProps){
    return function (dispatch:Dispatch<DispatchTypesProps>):any {
        //const list: any[] = []
        estates.map(async(estate) => 
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/group/devices/${estate.ID}?status=`,{
            method: 'GET',
            credentials: 'include',
        })
        .then(async(res) => {
            if(res.status === 200){
                const data = await res.json()
                dispatch(setPanelsInEstate([estate,data]))
            }
        })
        //.then((data) => dispatch(setPanelsInEstate([estate,data])))
        )
    };
}

export function fetchEstatesAndPanels(currentUser:any){
    return async function (dispatch:Dispatch<DispatchTypesProps>):Promise<any> {
        if(currentUser?.type === 'ESTATE'){
            await  fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/group/${currentUser.ID}/children`,{
                method: 'GET',
                credentials: 'include',
            })
            .then((res) => res.json())
            .then((data) => dispatch(setEstates(data)) ) 

            await  fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/device/recursiveDevice/from/${currentUser.ID}`,{
                method: 'GET',
                credentials: 'include',
            })
            .then((res) => res.json())
            .then((data) => dispatch(setPanels(data)))
        }
        else{
        if (
            currentUser.userType === 'ENGINEER' ||
            currentUser.userType === 'OFFICE_STAFF' ||
            currentUser.userType === 'MANAGER'
        ) {
            await fetch(
                `${REACT_APP_BASE_URL}/m2m-web-admin/rest/group/grantedby/${currentUser.ID}`,
                {
                    method: 'GET',
                    credentials: 'include',
                }
            )
                .then((res) => res.json())
                .then(async (estates) => {
                    if (
                        estates.length !== 0 &&
                        estates !== undefined &&
                        estates.some((estate: EstateProps) => estate.ID === currentUser.groupId)
                    ) {
                        await fetch(
                            `${REACT_APP_BASE_URL}/m2m-web-admin/rest/group/${currentUser.groupId}/children`,
                            {
                                method: 'GET',
                                credentials: 'include',
                            }
                        )
                            .then((res) => res.json())
                            .then((estatess) => {
                                if (estatess.length >= 0) {
                                    dispatch(setEstates(estatess));
                                }

                            });
                            if(currentUser.userType === 'MANAGER'){
                                await fetch(`${REACT_APP_BASE_URL}/v1/panelsMiddleware/${currentUser.groupId}?status=`,
                                    {
                                        method: 'GET',
                                        credentials: 'include',
                                    }
                                    )
                                    .then((res) => res.json())
                                    .then((unassignedPanels) => {
                                         dispatch(setUnassignedPanels(unassignedPanels))
                                         });
                                }
                                else{
                                    dispatch(setUnassignedPanels([]))
                                }
                        }
                        else{
                            dispatch(setEstates(estates))
                            dispatch(setUnassignedPanels([]))
                        }
                    })
                }
                
         else
            {                   
            await  fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/group/${currentUser.groupId}/children`,{
                method: 'GET',
                credentials: 'include',
            })
            .then((res) => res.json())
            .then((data) => dispatch(setEstates(data)) ) 
            
            } 
            await  fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/device/recursiveDevice/from/${currentUser.groupId}`,{
                method: 'GET',
                credentials: 'include',
            })
            .then((res) => res.json())
            .then((data) => dispatch(setPanels(data)))
        }
    };
};
export function assignPanelsToEstate(selectedEstateId:number,panelIdArray:any){
    return async function (dispatch:Dispatch<DispatchTypesProps>):Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/device/assign/${selectedEstateId}`, {
                        method: 'PUT',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(panelIdArray)
                    }).then( async(res) => {
                         await dispatch((setManageEstateStatus(res.status)))
                    })
    };
};

export function setManageEstateStatusToDefault(){
    return async function(dispatch:Dispatch<DispatchTypesProps>):Promise<any>{
        await dispatch(setManageEstateStatus(0))
    }
}

