/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Divider, IconButton, MobileStepper, Paper, Radio, Snackbar, Table, TableBody, TableCell, TableRow, useMediaQuery } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { ManagePanelsProps } from './managePanelsTypes';
import ListOfPanelsWithEstates from './ListOfPanelsWithEstates';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch, useSelector } from 'react-redux';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import {
    assignPanelsToEstate,
    fetchPanelsInEstate,
    resetPanelsInEstate,
    setManageEstateStatusToDefault,
    setRadioButtonChange,
    setSpecificEstateID,
    setUnassignedPanelID,
} from '../../redux/actions/managePanelsAndEstatesActions';
import { fetchPanelsAndEstates, fetchPanelsAndEstatesOfInstaller } from '../../redux/actions/panelEstateActions';
import { EstateProps } from '../ListAndGridView/panelEstateTypes';
import { fetchEstates, fetchPanels, fetchUnAssignedPanels } from '../../redux/actions/esateActions';
import { useTranslation } from 'react-i18next';

const style = {
    position: 'absolute' as 'absolute',
    top: '15vh',
    left: '80vb',
    width: 400,
    backgroundColor: 'background.paper',
    boxShadow: 20,
    p: 0,
};

export default function ManagePanelsModal({
    path,
    estatePage,
    movePanel,
    setCount,
    count,
    panelsInEstates,
    setPanelsInEstates,
    open,
    setOpen,
    panelId,
    panelName
}: ManagePanelsProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useTypedDispatch();
    const dispatch2 = useDispatch();
    const { installerDetails } = useSelector((state: any) => state.installerReducer);
    const { specificEstate } = useSelector((state: any) => state.estateReducer);
    const { currentUser, unassignedPanels } = useSelector((state: any) => state.userReducer);
    //const currentUser = JSON.parse(localStorage.getItem('currentUser') || '')
    const { panelsInEstate, status,estates,specificEstateID ,specificUnassignPanelID,selectedValue} = useSelector((state: any) => state.managePanelsAndEstatesReducer);    
    //const [panelsInEstates,setPanelsInEstates] = React.useState([unassignedPanels].concat(panelsInEstate));
    const [assignedEstate, setAssignedEstate] = React.useState<number>();
    const [draggedPanels, setDraggedPanels] = React.useState<any[]>();
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [save, setSave] = React.useState(false);
    const { t } = useTranslation();
    const dispatchselectedValue=useDispatch();
    
    const handleClose = () => {
        setOpen(false);
        setAssignedEstate(0);
        setDraggedPanels([]);
        setPanelsInEstates([
            [
                {
                    ID: 0,
                    address: {
                        address: '',
                        country: '',
                        postCode: '',
                    },
                    childPresented: true,
                    comments: '',
                    contactName: '',
                    devicesAssigned: true,
                    lastAlert: false,
                    level: 0,
                    name: 'Estateless panels',
                    number: '',
                    path: '',
                    phoneType: '',
                    type: '',
                },
                unassignedPanels,
            ],
            ...panelsInEstate,
        ]);
        dispatch2(resetPanelsInEstate([]));      
        dispatchselectedValue(setRadioButtonChange(null)); 
    };
    const handleSave = async () => {
        if(estatePage){
            if(selectedValue!==null){
                if(selectedValue===0){
                    await dispatch(assignPanelsToEstate(specificEstate?.ID, [panelId]));
                }else{
                    await dispatch(assignPanelsToEstate(selectedValue, [panelId]));
                }
            }
        }else{
        if(selectedValue!==null){
            if(selectedValue===0){
                if(path==='panels'){
                    await dispatch(assignPanelsToEstate(currentUser?.groupId, [panelId]));
                }else{
                    await dispatch(assignPanelsToEstate(installerDetails?.groupId, [panelId]));
                }
            }else{
                await dispatch(assignPanelsToEstate(selectedValue, [panelId]));
            }
        }
        }
        await dispatch2(resetPanelsInEstate([]));        
        if(estatePage){
            await dispatch(fetchEstates(specificEstate?.ID))
            await dispatch(fetchPanels(specificEstate?.ID))
            await dispatch(fetchUnAssignedPanels(specificEstate?.ID))
        }
        else{
            if(path === 'panels')
            await dispatch(fetchPanelsAndEstates());
            else 
            await dispatch(fetchPanelsAndEstatesOfInstaller(installerDetails?.groupId));
        }
        setSave(true)
        setOpen(false);
        setCount(count + 1);
        setAssignedEstate(0);
        dispatchselectedValue(setRadioButtonChange(null)); 
    };

    useEffect(() => {
        
            setPanelsInEstates([
                [
                    {
                        ID: 0,
                        address: {
                            address: '',
                            country: '',
                            postCode: '',
                        },
                        childPresented: true,
                        comments: '',
                        contactName: '',
                        devicesAssigned: true,
                        lastAlert: false,
                        level: 0,
                        name: 'Estateless panels',
                        number: '',
                        path: '',
                        phoneType: '',
                        type: '',
                    },
                    unassignedPanels,
                ],
                ...panelsInEstate,
            ].sort((a: any, b: any):any => {
              if (a[0].ID !== undefined && b[0].ID !== undefined) {
                  return a[0].ID - b[0].ID;
              }
          }));
            

        if (save === true && status !== 0) {
                      
            if (status === 204) {
                setCount(count + 1);
                setMessage(`${t('msg_panels_moved')}`);
                setDraggedPanels([]);
            } else if (status === 500) {
                setMessage(`${t('msg_internal_error')}`);
                setDraggedPanels([]);
            } else if (status === 400) {
                setMessage(`${t('lbl_unauthorizedUser')}`);
                setDraggedPanels([]);
            } else {
                setMessage(`${t('lbl_someError')}`);
                setDraggedPanels([]);
            }
        dispatch(setManageEstateStatusToDefault())
        setSnackOpen(true);
        setSave(false);

        }
    }, [open, save, status]);

    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleSnackClose}>
            {t('lbl_close')}
            </Button>
        </React.Fragment>
    );

    return (
        <div>
            {md ? (
                <>
                    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box
                            sx={{
                                position: 'absolute' as 'absolute',
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'background.paper',
                                boxShadow: 20,
                                p: 0,
                            }}
                        >
                            <Paper
                                elevation={0}
                                sx={{
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    paddingLeft: 3,
                                    paddingRight: 2,
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <IconButton onClick={handleClose}>
                                    <ArrowBackIcon />
                                </IconButton>
                                &ensp;&ensp;
                                <Typography sx={{ pt: 0.5 }} variant="h6">
                                {t('lbl_move_panel')} "{panelName}" {t('lbl_to..')}                              
                                </Typography>
                            </Paper>
                            <Divider />
                            <Paper elevation={0} sx={{ maxHeight: '70%', overflow: 'auto' }}>
                                <ListOfPanelsWithEstates
                                    path={path}
                                    setAssignedEstate={setAssignedEstate}
                                    setDraggedPanels={setDraggedPanels}
                                    draggedPanels={draggedPanels}
                                    panelsInEstates={panelsInEstates}
                                    setPanelsInEstates={setPanelsInEstates}
                                    openModal={open}
                                />
                            </Paper>
                            <Divider />
                            <Paper
                                sx={{
                                    position: 'absolute' as 'absolute',
                                    top: '100%',
                                    transform: 'translateY(-100%)',
                                    width: '100%',
                                }}
                            >
                                <MobileStepper
                                    variant="dots"
                                    steps={0}
                                    position="static"
                                    activeStep={0}
                                    sx={{
                                        maxWidth: md?'100%':400,
                                        flexGrow: 1,
                                        paddingTop: 2,
                                        paddingBottom: 2,
                                        paddingLeft: 3,
                                        paddingRight: 3,
                                        backgroundColor: 'inherit',
                                    }}
                                    nextButton={
                                        <Button
                                            size="medium"
                                            variant="contained"                                            
                                            disabled={selectedValue===null}                                       
                                            onClick={handleSave}
                                        >
                                            {t('btn_save')}
                                        </Button>
                                    }
                                    backButton={
                                        <Button size="small" variant="outlined" onClick={handleClose}>
                                            {t('btn_cancel')}
                                        </Button>
                                    }
                                />
                            </Paper>
                        </Box>
                    </Modal>
                </>
            ) : (
                <>
                    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box
                            sx={
                                movePanel
                                    ? {
                                          position: 'absolute' as 'absolute',
                                          top: '15vh',
                                          left: '80vb',
                                          width: 400,
                                          backgroundColor: 'background.paper',
                                          boxShadow: 20,
                                          p: 0,
                                      }
                                    : style
                            }
                        >
                            <Paper
                                elevation={0}
                                sx={{ paddingTop: 2, paddingBottom: 3, paddingLeft: 3, paddingRight: 2 }}
                            >
                                <Typography variant="h6">
                                {t('lbl_move_panel')} "{panelName}" {t('lbl_to..')} 
                                    </Typography>
                            </Paper>
                            <Divider />
                            <Paper elevation={0} sx={{ maxHeight: 350, overflow: 'auto' }}>
                               
                                    <ListOfPanelsWithEstates
                                    setAssignedEstate={setAssignedEstate}
                                    setDraggedPanels={setDraggedPanels}
                                    draggedPanels={draggedPanels}
                                    panelsInEstates={panelsInEstates}
                                    setPanelsInEstates={setPanelsInEstates}
                                    openModal={open}
                                />
                            </Paper>
                            <Divider />
                            <MobileStepper
                                variant="dots"
                                steps={0}
                                position="static"
                                activeStep={0}
                                sx={{
                                    maxWidth: 400,
                                    flexGrow: 1,
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    paddingLeft: 3,
                                    paddingRight: 3,
                                    backgroundColor: 'inherit',
                                }}
                                nextButton={
                                    <Button                                         
                                        disabled={selectedValue===null}     
                                        size="medium" variant="contained" onClick={handleSave}>
                                        {t('btn_save')}
                                    </Button>
                                }
                                backButton={
                                    <Button size="small" variant="outlined" onClick={handleClose}>
                                        {t('btn_cancel')}
                                    </Button>
                                }
                            />
                        </Box>
                    </Modal>
                </>
            )}
            <Snackbar
                open={snackOpen}
                autoHideDuration={6000}
                onClose={handleSnackClose}
                message={message}
                action={action}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
        </div>
    );
}
