/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */

import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Paper, Typography, useMediaQuery, useTheme, styled, TextField, InputAdornment, Divider } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PendingAndFailedServices from '../../components/ServiceReports/PendingAndFailedServices';
import CompletedServicesReports from '../../components/ServiceReports/CompletedServicesReports';
import './background.css'
import Appbar from '../../components/Appbar/Appbar';
import { useTranslation } from 'react-i18next';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { fetchCompletedReportsForAllPanels } from '../../redux/actions/serviceReportsActions';
import Loader from '../../components/CircularLoader/Loader';
import { useSelector } from 'react-redux';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),
}));
export function ServiceReports(): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation();
    const dispatch = useTypedDispatch()
    const [dataLoaded, setDataLoaded] = React.useState(false);
    const { currentUser } = useSelector((state:any) => state.userReducer)

    useEffect(() => {
        const fetching = async ():Promise<any> => {
        setDataLoaded(false)
       await dispatch(fetchCompletedReportsForAllPanels())
       setDataLoaded(true)
        }
        fetching()

    },[])

    return (
        <div className='main-div'>
            <div style={{maxHeight:'100vh',overflow:'auto'}}>
            <Appbar toolbarMenu={false} title={'Service Reports'} />
            {!dataLoaded && (
                            <Loader />
                        )}
            <Box sx={{ flexGrow: 1, marginLeft:md?0: 5, marginRight:md?0: 5, marginTop:md?0: 2, pb:15 }}>
            <Grid container spacing={md?2:4}>
                <Grid item xs={md?12:3}>
                    <Item sx={{p:md?2:0}} elevation={0}>
                        {md && 
                            <TextField
                            sx={md?{pt:2}:{pl:1,pr:1}}
                            fullWidth
                            variant="outlined"
                            placeholder={t('lbl_search')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                        }
                    </Item>
                </Grid>
                {md?<></>:<Grid item xs={9}></Grid>}
                <Grid item xs={12}>
                    <Item elevation={0}>
                        <Box sx={{ flexGrow: 1, marginLeft: md?0:2, marginRight:md?0: 2, marginTop: 2, pb:2  }}>
                            <Grid container spacing={2}>
                                {md?<></>:<Grid item xs={12}>
                                    <Item elevation={0}>
                                        <TextField
                                            sx={{pl:1,pr:1}}
                                            fullWidth
                                            variant="outlined"
                                            placeholder={t('lbl_search')}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Item>
                                </Grid>}
                                <Grid item xs={12}>
                                    <Item  elevation={0}>    
                                    </Item>
                                </Grid>
                                {/* <Grid item xs={12}>
                                <Item  elevation={md?0:1}>
                                        <Typography sx={{p:2}} variant='subtitle2' color='primary'>
                                        {t('lbl_failedSvc')}
                                        </Typography>
                                        {md && <Divider />}
                                    <PendingAndFailedServices />
                                    </Item>
                                </Grid> */}
                                <Grid item xs={12}>
                                <Item  elevation={1}>
                                        <Typography sx={{p:2}} variant='subtitle2' color='primary'>
                                        {t('lbl_cmpltd_svcReports')}
                                        </Typography>
                                    <CompletedServicesReports />
                                    </Item>
                                </Grid>
                            </Grid>
                        </Box>
                    </Item>
                </Grid>
            </Grid>
        </Box>
        </div>
        </div>
    );
}
