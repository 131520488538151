/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Button, MenuItem, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

type RemoteServiceFormProps = {
    intervalInMonths:number | null
    setIntervalInMonths: (intervalInMonths:number | null) => void
    serviceDate:string
    setServiceDate: (serviceDate:string) => void
}

export default function RemoteServicingEditForm({intervalInMonths,setIntervalInMonths,serviceDate,setServiceDate}:RemoteServiceFormProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const [count, setCount] = React.useState(0);
    const { t } = useTranslation();

    React.useEffect(() => { }, [count]);

    const frequencies = [
        {code:1,value:'Monthly'}, 
        {code:3,value:'3 Monthly'}, 
        {code:6,value:'6 Monthly'},
        {code:12,value:'12 Monthly'},
    ];
    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: 3,
                paddingBottom: 5,
                paddingLeft: 3,
                paddingRight: 3,
                maxHeight: 450,
                overflow: 'auto',
            }}
        >
            <Grid container spacing={md ? 6 : 3}>
                <Grid item xs={12}>
                    <TextField
                        id="date"
                        label={t('lbl_dateOfService')}
                        type="date"
                        fullWidth
                        defaultValue={serviceDate}
                        inputProps={{
                            min: `${new Date().getFullYear().toString()}-${(0 + (new Date().getMonth() + 1).toString())
                                .toString()
                                .slice(-2)}-${(0 + new Date().getDate().toString()).toString().slice(-2)}`,
                        }}
                        onChange={(e) => setServiceDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        select
                        value={intervalInMonths}
                        onChange={(e) => setIntervalInMonths(Number(e.target.value))}
                        fullWidth
                        id="service-frequency"
                        label={t('lbl_serviceFrquency')}
                        variant="filled"
                    >
                        {frequencies.map((freq, key) => (
                            <MenuItem key={key} value={freq.code}>
                                {freq.value}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </Grid>
        </Box>
    );
}
