/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-misused-promises */
import * as React from 'react';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {
    Button,
    Theme,
    InputAdornment,
    TextField,
    useMediaQuery,
    useTheme,
    IconButton,
    Fab,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    TableBody,
    Typography,
    TablePagination,
    Snackbar,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import { EmptyState, InfoListItem, ThreeLiner } from '@brightlayer-ui/react-components';
import { useSelector } from 'react-redux';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AddIcon from '@mui/icons-material/Add';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { fetchInstallerUsers, getUserAvailableGroup, getUserGroupList, getUserInstallerLength, getUserInstallerSearchListLength, setDeleteStatusToDefault } from '../../../redux/actions/installersActions';
import { useTypedDispatch } from '../../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { useParams } from 'react-router';
import Switch from '@mui/material/Switch';
import { AccountUsersProps } from '../../AccountUsers/AccountUsersTypes';
import { fetchUserGroups } from '../../../redux/actions/accountUsersActions';
import AddUserModal from './AddUserModal';
import { InstallersProps } from '../InstallersTypes';
import DeleteUserModal from './DeleteUserModal';
import EditUserModal from './EditUserModal';
import { ROLES } from '../../../entities/Constants';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    })
);

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),
}));

export function Users(): JSX.Element {
    const theme = useTheme();
    const classes = useStyles(theme);
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useTypedDispatch();
    const { t } = useTranslation();
    let { installerUsersList, userInstallerLength } = useSelector((state: any) => state.installerReducer);
    const [addUserModalOpen, setAddUserModalOpen] = React.useState(false)
    const [query, setQuery] = React.useState('');
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [count, setCount] = React.useState(0);
    const [counter, setCounter] = React.useState(0)
    const [message, setMessage] = React.useState("")
    const { addUserStatusForInstaller, userInstallerSearchList, userInstallerSearchListLength } = useSelector((state: any) => state.installerReducer)
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [installerObj, setInstallerObj] = React.useState<InstallersProps>({
        ID: 0,
        firstName: '',
        groupId: 0,
        lastName: '',
        installerInfo: {
            cloudId: '',
            installerState: '',
            installerCompanyName: '',
            contactEmail: '',
            address: {
                address: '',
                country: '',
                postCode: ''
            },
            firstPhoneNumber: {
                number: '',
                type: ''
            },
            spokenPassword: ''
        },
        login: '',
    })
    const [page, setPage] = React.useState(0);
    const [fetchLen, setFetchLen] = React.useState(true);
    const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
    const [openEditModal, setEditModal] = React.useState(false)
    const [editUserDetails, setEditUserDetails] = React.useState<any>({})
    const [deleteUserId, setDeleteUserID] = React.useState(0)
    const [deleteUserName, setDeleteUserName] = React.useState("")
    const [isMobile, setIsMobile] = React.useState<boolean>(false)
    const { userDeleteStatus } = useSelector((state: any) => state.installerReducer)
    const [deleteCounter, setDeleteCounter] = React.useState(0)
    const [deleteSnackOpen,setDeleteSnackOpen]=React.useState(false)
    const [deleteMessage,setDeleteMessage]=React.useState("")    
    const { id } = useParams()  
       
    const fetching = async () => {
        if (query === '') {
            await dispatch(fetchInstallerUsers(id, page * 10, ''));
            if (fetchLen) {
                setFetchLen(false);
                dispatch(getUserInstallerLength(id));
            }
        } else {
            if (query.length >= 3) {
                await dispatch(fetchInstallerUsers(id, 0,  query));
                await dispatch(getUserInstallerSearchListLength(id, query))
            }
        }
    };

    React.useEffect(() => {
        fetching();
        let installerDetails = localStorage.getItem('installer') || ""
        setInstallerObj(JSON.parse(installerDetails))
        if (count !== 0) {
            setSnackOpen(true);
            setCount(0);
            if (addUserStatusForInstaller === 200) {
                setFetchLen(true);
                setMessage(`${t('msg_usr_added')}`);
            } else if (addUserStatusForInstaller === 500) {
                setMessage(`${t('msg_internal_error')}`);
            } else if (addUserStatusForInstaller === 400) {
                setMessage(`${t('lbl_unauthorizedUser')}`);
            } else {
                setMessage(`${t('lbl_someError')}`);
            }
        }

        if (userDeleteStatus !== 0) {            
                if (isMobile) {
                    setEditModal(false)
                }
                setDeleteSnackOpen(true)
                if(userDeleteStatus===204){
                    setFetchLen(true);
                    // fetching();
                    setDeleteMessage(`${t('msg_user_deleted')}`)
                }
                else{
                    setDeleteMessage(`${t('lbl_somethingWrong')}`)
                }
                dispatch(setDeleteStatusToDefault())            
        }
    }, [id, page, count, query === '', counter,deleteCounter,fetchLen]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleAddUserPopUp = () => {
        setAddUserModalOpen(true)
        dispatch(getUserGroupList(installerObj?.groupId!))
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = e.target.value;
        setQuery(newValue)
        if (newValue.length >= 3) {
            setPage(0);
            dispatch(fetchInstallerUsers(id, 0, newValue))
            dispatch(getUserInstallerSearchListLength(id, newValue));
        }
    }
    try {
        if (query !== '' && query.length >= 3) {
            if(installerUsersList.length > 0){
            const filteredList = installerUsersList?.filter(
                (notification: AccountUsersProps) => (
                    notification.firstName?.toLowerCase().includes(query.toLowerCase()) ||
                    notification.lastName?.toLowerCase().includes(query.toLowerCase()) ||
                    notification.login?.toLowerCase().includes(query.toLowerCase()) ||
                    notification.userType?.toLowerCase().includes(query.toLowerCase())
                )
            );
            installerUsersList = query.length > 0 ? filteredList : installerUsersList;
            userInstallerLength = userInstallerSearchListLength

        } else if(userInstallerSearchList === 0) {
            installerUsersList = [];
            userInstallerLength = userInstallerSearchListLength;
        }
    }
    } catch (exception) {
        console.log(`Exception in user filtering: ${JSON.stringify(exception)}`);
    }

    const handleInstallerUserSearchCancelClick = (): any => {
        setQuery('');
        setPage(0);
        dispatch(fetchInstallerUsers(id, 0, ''));
    };

    const handleEditUser = async (accountUser: any) => {
        await dispatch(getUserGroupList(installerObj?.groupId!))
        await dispatch(getUserAvailableGroup(accountUser?.ID))
        setEditUserDetails(accountUser)              
        setEditModal(true)
    }

    const handleDeleteUser = (accountUser: any) => {
        setDeleteUserID(accountUser?.ID)
        const name = `${accountUser?.firstName} ${accountUser?.lastName}`
        setDeleteUserName(name)
        setOpenDeleteModal(true)
    }
    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleSnackClose}>
                {t('lbl_close')}
            </Button>
        </React.Fragment>
    );

    const handleDeleteSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setDeleteSnackOpen(false);
    };


    const deleteDction = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleDeleteSnackClose}>
                {t('lbl_close')}
            </Button>
        </React.Fragment>
    );

    return (
        <div style={md ? {} : { maxHeight: '100vh', overflow: 'auto' }}>
            <Box
                sx={
                    md
                        ? {
                            flexGrow: 1,
                            marginLeft: '-6%',
                            marginRight: '-7.5%',
                            marginTop: '-7.6%',
                            pb: 40,
                            maxHeight: '100vh',
                            overflow: 'auto',
                        }
                        : { flexGrow: 1, marginLeft: '4%', marginRight: '4%', marginTop: 4, pb: 40 }
                }
            >
                <Grid container spacing={2}>
                    {!md && (
                        <Grid item xs={1.5}>
                            <Item elevation={0}>
                                <Button fullWidth variant="outlined" sx={{ fontSize: 13 }} onClick={handleAddUserPopUp}>
                                    <AddIcon sx={{ fontSize: 15 }} />
                                    &nbsp;&nbsp;{t('btn_addUsr')}
                                </Button>
                                {addUserModalOpen &&
                                    <AddUserModal open={addUserModalOpen} setOpen={setAddUserModalOpen} setCount={setCount} count={count} />}
                            </Item>
                        </Grid>
                    )}
                    {!md && <Grid item xs={10.5}></Grid>}
                    <Grid item xs={12}>
                        <Item elevation={1}>
                            <Box
                                sx={{ flexGrow: 1, marginLeft: md ? 0.2 : 3, marginRight: md ? 0.5 : 3, pt: 3, pb: 3 }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Item elevation={0}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                placeholder={t('lbl_search')}
                                                value={query}
                                                onChange={(e) => handleChange(e)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {query.length !== 0 && (
                                                                <IconButton
                                                                    onClick={(event) => handleInstallerUserSearchCancelClick()}
                                                                >
                                                                    <CancelIcon />
                                                                </IconButton>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Item elevation={1}>
                                            {md ? <>
                                                <Table>
                                                    <TableBody>
                                                        {installerUsersList !== undefined &&
                                                            installerUsersList?.length > 0 &&
                                                            installerUsersList?.map(
                                                                (accountUser: AccountUsersProps, key: number) => (
                                                                    <TableRow key={key} sx={{ backgroundColor: 'white' }}>
                                                                        <TableCell align="left" padding="normal">
                                                                            <div
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    flexDirection: 'column',
                                                                                }}
                                                                            >
                                                                              <input
                                                                                readOnly                                                                               
                                                                                value={accountUser?.firstName}
                                                                                  style={{
                                                                                    border: 'none',
                                                                                    outline: 'none',
                                                                                    fontSize: 14,
                                                                                    fontFamily: 'Open Sans',
                                                                                    fontStyle: 'normal',
                                                                                    fontWeight: 500,
                                                                                    lineHeight: 1,
                                                                                    width: '50vw',
                                                                                    textAlign: 'left',
                                                                                    backgroundColor: '#FBFBFB',
                                                                                }}
                                                                                />                  
                                                                                <input
                                                                                readOnly                                                                              
                                                                                value={accountUser?.lastName}
                                                                                  style={{
                                                                                    border: 'none',
                                                                                    outline: 'none',
                                                                                    fontSize: 14,
                                                                                    fontFamily: 'Open Sans',
                                                                                    fontStyle: 'normal',
                                                                                    fontWeight: 500,
                                                                                    lineHeight: 1,
                                                                                    width: '50vw',
                                                                                    textAlign: 'left',
                                                                                    backgroundColor: '#FBFBFB',
                                                                                }}
                                                                                />                                                                                    
                                                                                
                                                                                <Typography variant="body2">
                                                                                    {accountUser?.userType === ROLES?.ENGINEER
                                                                                        ? 'Engineer'
                                                                                        : accountUser?.userType === ROLES?.MANAGER
                                                                                            ? 'Manager'
                                                                                            : accountUser?.userType ===
                                                                                              ROLES?.OFFICESTAFF
                                                                                                ? 'Office Staff'
                                                                                                : 'Installer'}
                                                                                </Typography>
                                                                               <input
                                                                                readOnly
                                                                                value={accountUser?.login}
                                                                                  style={{
                                                                                    border: 'none',
                                                                                    outline: 'none',
                                                                                    fontSize: 14,
                                                                                    fontFamily: 'Open Sans',
                                                                                    fontStyle: 'normal',
                                                                                    fontWeight: 500,
                                                                                    lineHeight: 1,
                                                                                    width: '50vw',
                                                                                    textAlign: 'left',
                                                                                    backgroundColor: '#FBFBFB',
                                                                                }}
                                                                                />
                                                                            </div>
                                                                        </TableCell>
                                                                        <TableCell
                                                                            align={'right'}
                                                                            padding="normal"
                                                                        >
                                                                            <Switch
                                                                                checked={
                                                                                    accountUser?.notificationEnabledUserArray
                                                                                }
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell
                                                                            align={'right'}
                                                                            padding="normal"
                                                                        >
                                                                            <IconButton onClick={() => handleEditUser(accountUser)}>
                                                                                <EditIcon />
                                                                            </IconButton>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            )}
                                                    </TableBody>                                                    
                                                    <EditUserModal
                                                        open={openEditModal}
                                                        setOpen={setEditModal}
                                                        editUserDetails={editUserDetails}
                                                        counter={counter}
                                                        setCounter={setCounter}
                                                        isMobile={isMobile}
                                                        setIsMobile={setIsMobile}
                                                        deleteCounter={deleteCounter}
                                                        setDeleteCounter={setDeleteCounter}        
                                                        installerObj={installerObj}                                                
                                                    />
                                                    
                                                </Table>
                                            </> : <>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell
                                                                width={'20%'}
                                                                variant="head">
                                                                {t('lbl_User_Info')}&ensp;
                                                                <Checkbox
                                                                    icon={
                                                                        <ArrowDownwardIcon
                                                                            fontSize="small"
                                                                            color="disabled"
                                                                        />
                                                                    }
                                                                    checkedIcon={
                                                                        <ArrowUpwardIcon
                                                                            fontSize="small"
                                                                            color="disabled"
                                                                        />
                                                                    }
                                                                />
                                                            </TableCell>
                                                            <TableCell
                                                                width={'80%'}
                                                                align={'right'}
                                                                variant="head"
                                                            >
                                                                {t('lbl_email_notification')}
                                                            </TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {installerUsersList !== undefined &&
                                                            installerUsersList?.length > 0 &&
                                                            installerUsersList?.map(
                                                                (accountUser: AccountUsersProps, key: number) => (
                                                                    <TableRow key={key} sx={{ backgroundColor: 'white' }}>
                                                                        <TableCell align="left" padding="normal">
                                                                            <div
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    flexDirection: 'column',
                                                                                }}
                                                                            >
                                                                                <Typography variant="subtitle1">
                                                                                    {accountUser?.firstName}{' '}
                                                                                    {accountUser?.lastName}
                                                                                </Typography>
                                                                                <Typography variant="body2">
                                                                                    {accountUser?.userType === ROLES?.ENGINEER
                                                                                        ? 'Engineer'
                                                                                        : accountUser?.userType === ROLES?.MANAGER
                                                                                            ? 'Manager'
                                                                                            : accountUser?.userType === ROLES?.OFFICESTAFF
                                                                                                ? 'Office Staff'
                                                                                                : 'Installer'}
                                                                                </Typography>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontWeight: 300,
                                                                                        fontSize: '14px',
                                                                                    }}
                                                                                >
                                                                                    {accountUser?.login}
                                                                                </Typography>
                                                                            </div>
                                                                        </TableCell>
                                                                        <TableCell
                                                                            align={'right'}
                                                                            padding="normal"
                                                                        >
                                                                            <Switch
                                                                                checked={
                                                                                    accountUser?.notificationEnabledUserArray
                                                                                }
                                                                            />
                                                                        </TableCell>                                                                      
                                                                        {accountUser?.userType===ROLES?.INSTALLER ? <>
                                                                            <TableCell
                                                                            align={'right'}
                                                                            padding="normal"
                                                                        >
                                                                            <IconButton onClick={() => handleEditUser(accountUser)}>
                                                                                <EditIcon />
                                                                            </IconButton>
                                                                        </TableCell>    
                                                                        </> : <>
                                                                        <TableCell
                                                                            align={'right'}
                                                                            padding="normal"
                                                                        >
                                                                            <IconButton onClick={() => handleDeleteUser(accountUser)}>
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </TableCell>
                                                                        </>}
                                                                        {accountUser?.userType===ROLES?.INSTALLER ? <>
                                                                            <TableCell></TableCell>
                                                                        </> :<>
                                                                        <TableCell
                                                                            align={'right'}
                                                                            padding="normal"
                                                                        >
                                                                            <IconButton onClick={() => handleEditUser(accountUser)}>
                                                                                <EditIcon />
                                                                            </IconButton>
                                                                        </TableCell>  
                                                                        </>}
                                                                       
                                                                    </TableRow>
                                                                )
                                                            )}
                                                   
                                                    </TableBody>
                                                    <EditUserModal
                                                        open={openEditModal}
                                                        setOpen={setEditModal}
                                                        editUserDetails={editUserDetails}
                                                        counter={counter}
                                                        setCounter={setCounter}
                                                        isMobile={isMobile}
                                                        setIsMobile={setIsMobile}
                                                        deleteCounter={deleteCounter}
                                                        setDeleteCounter={setDeleteCounter}      
                                                        installerObj={installerObj}                                                 
                                                    />
                                                    <DeleteUserModal 
                                                    open={openDeleteModal} 
                                                    setOpen={setOpenDeleteModal} 
                                                    deleteUserId={deleteUserId}
                                                     deleteUserName={deleteUserName} 
                                                     deleteCounter={deleteCounter}
                                                     setDeleteCounter={setDeleteCounter}
                                                     />
                                                </Table>
                                            </>}                                           
                                        </Item>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Item elevation={0}>
                                            <TablePagination
                                                component="div"
                                                count={userInstallerLength}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                rowsPerPage={rowsPerPage}
                                                rowsPerPageOptions={[10]}
                                                showFirstButton
                                                showLastButton
                                            />
                                        </Item>
                                    </Grid>
                                    {(query !== '' && installerUsersList?.length === 0) && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                height: '100%',
                                                width: '100%',
                                            }}
                                        >
                                            <div style={{ flex: '1 1 0px' }}>
                                                <EmptyState nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                    icon={<ManageSearchIcon fontSize={'inherit'} />}
                                                    title={`${t('msg_noSearch_results')} ${query}...`}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </Grid>

                                {md && (
                                    <Paper
                                        elevation={0}
                                        sx={{
                                            position: 'absolute' as 'absolute',
                                            top: '95vh',
                                            zIndex: 1000,
                                            transform: 'translateY(-100%)',
                                            width: '100%',
                                            p: 2,
                                        }}
                                    >
                                        <Button fullWidth variant="outlined" sx={{ fontSize: 13 }} onClick={handleAddUserPopUp}>
                                            <AddIcon sx={{ fontSize: 15 }} />
                                            &nbsp;&nbsp;{t('btn_addUsr')}
                                        </Button>
                                        {addUserModalOpen &&
                                            <AddUserModal open={addUserModalOpen} setOpen={setAddUserModalOpen} setCount={setCount} count={count} />}
                                    </Paper>
                                )}
                            </Box>

                        </Item>

                    </Grid>
                </Grid>
                <Snackbar
                    open={snackOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackClose}
                    message={message}
                    action={action}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                />

                <Snackbar
                open={deleteSnackOpen}
                autoHideDuration={6000}
                onClose={handleDeleteSnackClose}
                message={deleteMessage}
                action={deleteDction}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                />
            </Box>

        </div>
    );
}