/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Divider, MobileStepper, Paper, useMediaQuery, useTheme, TextField, IconButton } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { EditEndUserInfoProps } from './EditEndUserInfoTypes';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { editEndUserInfo, postInstallerLogo } from '../../redux/actions/endUserInfoActions';
import { blobObj } from './constant';
import { useTranslation } from 'react-i18next';
//import { putSiteDetails } from '../../redux/actions/specificPanelActions';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'background.paper',
    boxShadow: 20,
    p: 0,
};

export default function EditEndUserInfoModal({files,setCount,count, open, setOpen }: EditEndUserInfoProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const [activeStep, setActiveStep] = React.useState(0);
    const dispatch = useTypedDispatch();
    const { endUserInfo, status } = useSelector((state: any) => state.endUserInfoReducer);
    const [companyName, setCompanyName] = React.useState('');
    const [officeNumber, setOfficeNumber] = React.useState('');
    const [outOfOfficeNumber, setOutOfOfficeNumber] = React.useState('');
    const [contactEmail, setContactEmail] = React.useState('');
    const [helperLoginText, setHelperLoginText] = React.useState('')
    const [helperPhoneText, setHelperPhoneText] = React.useState('')
    const [helperCompanyText, setHelperCompanyText] = React.useState('')
    const [helperOutOfficePhoneText, setHelperOutOfficePhoneText] = React.useState('')
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [message, setMessage] = React.useState('')
    const [save, setSave] = React.useState(false);
    const { t } = useTranslation();

    useEffect(() => {
      if (save === true) {
        if (status === 200) {
            setCount(count+1)
            setMessage(`${t('msg_endUsrInfo_edited')}`);
        } else if (status === 500) {
            setMessage(`${t('msg_internal_error')}`);
        } else if (status === 400) {
            setMessage(`${t('lbl_unauthorizedUser')}`);
        } else {
            setMessage(`${t('lbl_someError')}`);
        }
        setSnackOpen(true);
        setSave(false);
    }
      setCompanyName(endUserInfo.sharingCompanyName)
      setOfficeNumber(endUserInfo.officePhoneNumber)
      setOutOfOfficeNumber(endUserInfo.outOfHoursPhoneNumber)
      setContactEmail(endUserInfo.contactEmail)
    }, [open,save]);

    const handleClose = () => {
        setHelperLoginText('')
        setHelperOutOfficePhoneText('')
        setHelperPhoneText('')
        setHelperCompanyText('')
        setOpen(false);
    };

    const handleEditEndUserInfo = async () => {
        const body = {
            contactEmail: contactEmail,
            officePhoneNumber: officeNumber,
            outOfHoursPhoneNumber: outOfOfficeNumber,
            sharingCompanyName: companyName,
        };
        await dispatch(editEndUserInfo(body));
        //await dispatch(postInstallerLogo(files[0].preview))
        setSave(true)
        setOpen(false);
    };

    const handleBackClick = (): any => {
        setHelperLoginText('')
        setHelperOutOfficePhoneText('')
        setHelperPhoneText('')
        setHelperCompanyText('')
        setOpen(false);
    };

    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const isValidEmail = (email:string) => {
      const isValid = /\S+@\S+\.\S+/.test(email);
      return isValid
    }

    const isValidPhoneNumber = (number:string) => {
        const isValid = /^\d+$/.test(number)
        return isValid
    }

    const handleCompanyName=(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
        setCompanyName(e.target.value)
        if(e.target.value.length>30){
           setHelperCompanyText(`${t('msg_inputLength')}`)
        }else{
            setHelperCompanyText('')
        }
    }
  
  const handleLogin = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setContactEmail(e.target.value)
     if(!isValidEmail(e.target.value)){
      setHelperLoginText(`${t('msg_email_invalid')}`)
     }else if(e.target.value.length>48){
        setHelperLoginText(`${t('error_msg_48_character')}`)
     }
     else{
      setHelperLoginText('')
     } 
  }
  const handleOfficeNumber = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setOfficeNumber(e.target.value)
     if(!isValidPhoneNumber(e.target.value)){
      setHelperPhoneText(`${t('msg_prohibited_chars')}`)
     }
     else if(e.target.value.length > 30){
        setHelperPhoneText(`${t('msg_inputLength')}`)
     }
     else{
        setHelperPhoneText('')
     }
  }
  const handleOutOfOfficeNumber = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setOutOfOfficeNumber(e.target.value)
     if(!isValidPhoneNumber(e.target.value)){
      setHelperOutOfficePhoneText(`${t('msg_prohibited_chars')}`)
     }
     else if(e.target.value.length > 30){
        setHelperOutOfficePhoneText(`${t('msg_inputLength')}`)
     }
     else{
        setHelperOutOfficePhoneText('')
     }
  }

    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleSnackClose}>
            {t('lbl_close')}
            </Button>
        </React.Fragment>
    );

    return (
        <div>
            <>
                <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    <Box
                        sx={
                            md
                                ? {
                                      position: 'absolute' as 'absolute',
                                      top: '50%',
                                      left: '50%',
                                      transform: 'translate(-50%, -50%)',
                                      width: '100vw',
                                      height: '100vh',
                                      backgroundColor: 'background.paper',
                                      boxShadow: 20,
                                      p: 0,
                                  }
                                : {
                                      position: 'absolute' as 'absolute',
                                      top: '50%',
                                      left: '50%',
                                      transform: 'translate(-50%, -50%)',
                                      width: 400,
                                      backgroundColor: 'background.paper',
                                      boxShadow: 20,
                                      p: 0,
                                  }
                        }
                    >
                        <Paper
                            elevation={md ? 4 : 0}
                            sx={
                                md
                                    ? {
                                          paddingTop: 2,
                                          paddingBottom: 1,
                                          paddingLeft: 2,
                                          paddingRight: 2,
                                          display: 'flex',
                                          flexDirection: 'row',
                                      }
                                    : { paddingTop: 2, paddingBottom: 3, paddingLeft: 3, paddingRight: 2 }
                            }
                        >
                            {md && (
                                <IconButton
                                    color={'default'}
                                    onClick={handleBackClick}
                                    edge={'start'}
                                    style={{ marginRight: theme.spacing(3) }}
                                    size="large"
                                >
                                    <ArrowBackIcon />
                                </IconButton>
                            )}
                            <Typography sx={md ? { pt: 0.5 } : {}} variant="h6">
                            {t('menu_endUserInfo')}
                            </Typography>
                        </Paper>
                        <Divider />
                        <div>
                            <Box sx={{ flexGrow: 1, paddingTop: 3, paddingBottom: 5, paddingLeft: 3, paddingRight: 3 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TextField 
                                            value={companyName} fullWidth id="filled-basic" label={t('lbl_compnyName')} variant="filled" 
                                            error={helperCompanyText.length>0}
                                            helperText={helperCompanyText}
                                            onChange={(e) => handleCompanyName(e)} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            value={officeNumber}
                                            label={t('lbl_officeNo')}
                                            variant="filled"
                                            error={helperPhoneText.length > 0} 
                                            helperText={helperPhoneText}
                                            onChange={(e) => handleOfficeNumber(e)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            value={outOfOfficeNumber}
                                            id="filled-basic"
                                            label={t('lbl_outOfHrs')}
                                            variant="filled"
                                            error={helperOutOfficePhoneText.length > 0} 
                                            helperText={helperOutOfficePhoneText}
                                            onChange={(e) => handleOutOfOfficeNumber(e)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            value={contactEmail}
                                            id="filled-basic"
                                            label={t('lbl_contactEmail')}
                                            variant="filled"
                                            error={helperLoginText.length > 0} 
                                            helperText={helperLoginText} 
                                            onChange={(e) => handleLogin(e)}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                        <Paper
                            sx={
                                md
                                    ? {
                                          position: 'absolute' as 'absolute',
                                          top: '100vh',
                                          transform: 'translateY(-100%)',
                                          width: '100%',
                                          zIndex: 1000
                                      }
                                    : {}
                            }
                        >
                            <MobileStepper
                                variant="dots"
                                steps={0}
                                position="static"
                                activeStep={activeStep}
                                sx={
                                    md
                                        ? {
                                              flexGrow: 1,
                                              paddingTop: 2,
                                              paddingBottom: 2,
                                              paddingLeft: 3,
                                              paddingRight: 3,
                                              backgroundColor: 'inherit',
                                          }
                                        : {
                                              maxWidth: 400,
                                              flexGrow: 1,
                                              paddingTop: 2,
                                              paddingBottom: 2,
                                              paddingLeft: 3,
                                              paddingRight: 3,
                                              backgroundColor: 'inherit',
                                          }
                                }
                                nextButton={
                                    <Button
                                        size="medium"
                                        disableElevation
                                        variant="contained"
                                        disabled={helperLoginText.length > 0 || helperPhoneText.length > 0 || helperOutOfficePhoneText.length > 0 || helperCompanyText.length>0}
                                        onClick={handleEditEndUserInfo}
                                    >
                                        {t('btn_save')}
                                    </Button>
                                }
                                backButton={
                                    <Button size="small" variant="outlined" onClick={handleClose}>
                                        {t('btn_cancel')}
                                    </Button>
                                }
                            />
                        </Paper>
                    </Box>
                </Modal>
                <Snackbar
                    open={snackOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackClose}
                    message={message}
                    action={action}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                />
            </>
        </div>
    );
}
