import { CurrentUserProps, EstateArrayProps, PanelArrayProps, SummaryDetailsProps } from '../../components/ListAndGridView/panelEstateTypes';
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ActionTypes } from '../constants/panelEstateActionTypes';
const initialState ={
    currentUser : <CurrentUserProps>{},
    panels : <PanelArrayProps>[],
    assignedPanels : <PanelArrayProps>[],
    searchedPanels : <PanelArrayProps>[],
    estates : <EstateArrayProps>[],
    unassignedPanels : <PanelArrayProps>[],
    installerEmailStatuses:<any>[],
    userEmailAlerts:<any>[],
    allPanels: <PanelArrayProps>[],
    summaryDetails: <SummaryDetailsProps>{},
    allConnectedPanels:<PanelArrayProps>[],
    allDisconnectedPanels:<PanelArrayProps>[],
    connectedPanelFlag:<boolean>false,
    disConnectedFlag:<boolean>false,
    searchedPanelsForInstaller : <PanelArrayProps>[],
}

export const userReducer = (state:any = initialState  , action: { type: any; payload:any }):any => {
    switch(action.type){
        case ActionTypes.SET_CURRENT_USER :
            return {...state, currentUser: action.payload}
            break;
        case ActionTypes.SET_PANELS :
            return {...state, panels: action.payload}
            break;
        case ActionTypes.SET_ADMIN_PANELS :
            return {...state, assignedPanels: action.payload}
            break;
        case ActionTypes.SET_SEARCHED_PANELS :
            return {...state, searchedPanels: action.payload}
            break;
            case ActionTypes.SET_SUMMARY_DETAILS :
            return {...state, summaryDetails: action.payload}
            break;
        case ActionTypes.SET_ESTATES:
            return {...state, estates: action.payload}
            break;
        case ActionTypes.UNASSIGNED_PANELS:
            return {...state, unassignedPanels:action.payload}
            break;
        case ActionTypes.SET_PANELS_UNFILTERED:
            return {...state, allPanels:action.payload}
            break;
        case ActionTypes.SET_INSTALLER_EMAIL_ALERTS_FOR_ALL_PANELS:
            return {...state, installerEmailStatuses:[...state.installerEmailStatuses,action.payload]}
            break;
        case ActionTypes.SET_USER_EMAIL_ALERTS_FOR_ALL_PANELS:
            return {...state, userEmailAlerts:[...state.userEmailAlerts,action.payload]}
            break;
        case ActionTypes.SET_USER_EMAIL_ALERTS_FOR_ALL_PANELS_TO_EMPTY:
            return {...state, userEmailAlerts:action.payload}
            break;
        case ActionTypes?.SET_CONNECTED_PANEL:
            return{...state,allConnectedPanels:action.payload}
            break;
        case ActionTypes?.SET_DISSCONNECTED_PANEL:
            return{...state,allDisconnectedPanels:action.payload}
            break;
        case ActionTypes?.SET_CONNECTED_PANELS_TRUE:
            return{...state,connectedPanelFlag:action.payload}
            break;
        case ActionTypes?.SET_DISCONNECTED_PANELS_TRUE:
            return{...state,disConnectedFlag:action.payload}
            break;
        case ActionTypes?.SET_SEARCHED_PANELS_FOR_INSTALLER:
            return{...state,searchedPanelsForInstaller:action.payload}
            break;
        default: 
        return {...state}
    }
}