/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Box, Divider, Grid, Paper, Radio, Stack, stepButtonClasses, Table, TableBody, TableCell, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material';
import { EstateProps, PanelArrayProps, PanelProps } from '../ListAndGridView/panelEstateTypes';
import { useSelector,useDispatch } from 'react-redux';
import { ListOfPanelsWithEstatesProps } from './managePanelsTypes';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    DroppableProvided,
    DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import './draggable.css';
import secureLocalStorage  from  "react-secure-storage";
import { InfoListItem } from '@brightlayer-ui/react-components';
import { setRadioButtonChange, setSpecificEstateID, setUnassignedPanelID } from '../../redux/actions/managePanelsAndEstatesActions';
import { useTranslation } from 'react-i18next';
import { setAllConnectedPanels } from '../../redux/actions/panelEstateActions';

export default function ListOfPanelsWithEstates({
    path,
    setAssignedEstate,
    setDraggedPanels,
    draggedPanels,
    panelsInEstates,
    setPanelsInEstates,
    openModal,
}: ListOfPanelsWithEstatesProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const typeOfDomain: any = secureLocalStorage.getItem('estate') || {};
    const role = secureLocalStorage.getItem('role');
    const [openList, setOpenList] = React.useState<Array<boolean>>([]);
    const { unassignedPanels } = useSelector((state: any) => state.userReducer);
    const dispatch = useTypedDispatch();
    const { panelsInEstate, estates, panels,specificEstateID,specificUnassignPanelID, selectedValue } = useSelector((state: any) => state.managePanelsAndEstatesReducer);
    let [panelsInEstatesUpdated, setPanelsInEstatesUpdated] = React.useState<any>();
    const dispatchselectedValue=useDispatch();
    const {t}=useTranslation();   
    const { estateList} = useSelector((state: any) => state.specificPanelAndEstateReducer);

    

    let newEstates: any[] = []
    try{
     newEstates = [
        {
            ID: 0,
            address: {
                address: '',
                country: '',
                postCode: '',
            },
            childPresented: true,
            comments: '',
            contactName: '',
            devicesAssigned: true,
            lastAlert: false,
            level: 0,
            name: (role === 'ADMIN' && (path==='panels')) ? (typeOfDomain?.type === 'INSTALLER' ? 'Estateless panels' : typeOfDomain?.name)
                                    :
                                    typeOfDomain?.type === 'ESTATE' ? typeOfDomain?.name : 'Estateless panels',
            number: '',
            path: '',
            phoneType: '',
            type: '',
        },
    ].concat(
        estates.sort((a: EstateProps, b: EstateProps) => {
            if (a.ID !== undefined && b.ID !== undefined) {
                return a.ID - b.ID;
            }
        })
    );
    }catch(e){console.log(e)}
    openList.length = newEstates.length;
    
   

    const handleRadioChange = (value: number) => {
        dispatchselectedValue(setRadioButtonChange(value)); 
    };

   
    const handleEstateClick = (key: number) => {
        //slicing openList Array
        const openListOne = openList.slice(0, key);
        const openListTwo = openList.slice(key + 1, openList.length);
        const openListThree = !openList[key];
        setOpenList([...openListOne, openListThree, ...openListTwo]);
    };

    React.useEffect(() => {
       
        panelsInEstatesUpdated = panelsInEstates;
            for (let i = 0; i < openList.length; i++) {
                if (i === 0) openList[i] = false;
                else openList[i] = false;
            }
        
    }, [openModal]);

    const onDragEnd = (result: any) => {
        

        const { destination, source } = result;
        if (!destination) return;

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }
        if(destination.droppableId === source.droppableId) return;

        const start = source.droppableId;
        const finish = destination.droppableId;

        const startEstate = newEstates.filter((estate: EstateProps) => estate.name === start);
        const finishEstate = newEstates.filter((estate: EstateProps) => estate.name === finish);
        const startEstateIndex = newEstates.findIndex((estate: EstateProps) => estate.ID === startEstate[0].ID);
        const finishEstateIndex = newEstates.findIndex((estate: EstateProps) => estate.ID === finishEstate[0].ID);

        const draggedPanel:PanelProps[] = panels.filter((panel: PanelProps) => panel.cloudReferenceName === result.draggableId);

        //moved panel filtering from one array to appending to another array
        const fromEstate = panelsInEstates[startEstateIndex][1].filter(
            (panel: PanelProps) => panel.cloudReferenceName !== result.draggableId
        );

        const toEstate = panelsInEstates[finishEstateIndex][1].concat(draggedPanel);

        
        setAssignedEstate(finishEstate[0].ID);
        //reading estate ID and Panel ID's
        if (draggedPanels !== undefined) setDraggedPanels([...draggedPanels, [finishEstate[0].ID, draggedPanel[0].ID]]);
        else setDraggedPanels([[finishEstate[0].ID,draggedPanel[0].ID]]);

        //list auto updating
        if (startEstateIndex < finishEstateIndex) {
            const panelsInEstatesOne = panelsInEstates.slice(0, startEstateIndex);
            const panelsInEstatesTwo = [startEstate[0], fromEstate];
            const panelsInEstatesThree = panelsInEstates.slice(startEstateIndex + 1, finishEstateIndex);
            const panelsInEstatesFour = [finishEstate[0], toEstate];
            const panelsInEstatesFive = panelsInEstates.slice(finishEstateIndex + 1, panelsInEstates.length);
            setPanelsInEstates([
                ...panelsInEstatesOne,
                panelsInEstatesTwo,
                ...panelsInEstatesThree,
                panelsInEstatesFour,
                ...panelsInEstatesFive,
            ]);
        } else {
            const panelsInEstatesOne = panelsInEstates.slice(0, finishEstateIndex);
            const panelsInEstatesTwo = [finishEstate[0], toEstate];
            const panelsInEstatesThree = panelsInEstates.slice(finishEstateIndex + 1, startEstateIndex);
            const panelsInEstatesFour = [startEstate[0], fromEstate];
            const panelsInEstatesFive = panelsInEstates.slice(startEstateIndex + 1, panelsInEstates.length);
            setPanelsInEstates([
                ...panelsInEstatesOne,
                panelsInEstatesTwo,
                ...panelsInEstatesThree,
                panelsInEstatesFour,
                ...panelsInEstatesFive,
            ]);
        }
    };

    return (
        <>
           
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                            title={`${t('lbl_Unassigned_Panels')}`}
                            backgroundColor={'#ffffff'}
                            style={{paddingLeft:23}}
                            dense={true}
                            fontColor={'#424e54'}
                            hidePadding={true}
                            iconAlign={'center'}
                            iconColor={'#424e54'}
                            divider='full'
                            rightComponent={
                                <Radio                        
                                checked={selectedValue === 0}
                                onChange={() => handleRadioChange(0)}
                        />
                      }/>   

                        {estateList?.length>0 && estateList!==undefined && estateList?.map((estate:any)=>(
                            <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                key={estate.ID}
                                title={estate.name}
                                backgroundColor={'#ffffff'}
                                dense={true}
                                fontColor={'#424e54'}
                                hidePadding={true}
                                iconAlign={'center'}
                                iconColor={'#424e54'}
                                divider='full'
                                style={{paddingLeft:23}}
                                rightComponent={
                                  <Radio
                                 checked={selectedValue === estate.ID}
                            onChange={() => handleRadioChange(estate.ID)}                              
                                  />
                                }
                            />
                        ))}
                    </Grid>
                </Grid>
            </Box>

              
        </>
    );
}
