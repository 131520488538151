/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation, Trans } from 'react-i18next';
import { setConnectedPanelsTrue, setDisconnectedPanelsTrue } from '../../redux/actions/panelEstateActions';
import { useDispatch } from 'react-redux';

type MenuProps = {
    setFilterArray:(filterArray: any[]) => void
    filterArray: any[]
    anchorE1 : null | HTMLElement
    open: boolean
    setAnchorE1 : (anchorEl:null | HTMLElement) => void
}

export default function PanelFilterMenu({setFilterArray,filterArray,setAnchorE1,open,anchorE1}:MenuProps):JSX.Element {
  const { t } = useTranslation();
  const dispatchConnectedFlag=useDispatch()
  const dispatchDisConnectedFlag=useDispatch()

  const handleClose = () => {
    setAnchorE1(null);
  };
  const handleAll = () => {
    setFilterArray([])
    setAnchorE1(null);
    dispatchConnectedFlag(setConnectedPanelsTrue(false))
    dispatchDisConnectedFlag(setDisconnectedPanelsTrue(false))
  };
  const handleDisconnected = () => {
    if(filterArray.length !== 0){
      if(filterArray.some((f) => f === `${t('lbl_disconnected')}`)){
        setFilterArray(filterArray)
      }
      else{
        setFilterArray([...filterArray,`${t('lbl_disconnected')}`])
      }
    }
    else{
      setFilterArray([`${t('lbl_disconnected')}`])
    }
    setAnchorE1(null);
  };
  const handleConnected = () => {
    if(filterArray.length !== 0){
      if(filterArray.some((f) => f === `${t('lbl_connected')}`)){
        setFilterArray(filterArray)
      }
      else{
        setFilterArray([...filterArray,`${t('lbl_connected')}`])
      }
    }
    else{
      setFilterArray([`${t('lbl_connected')}`])
    }
    setAnchorE1(null);
  };

  const handleFavorite = () => {
    setAnchorE1(null);
  };

  const handleARCEnabled = () => {
    if(filterArray.length !== 0){
      if(filterArray.some((f) => f === `${t('lbl_ARC_enabled')}`)){
        setFilterArray(filterArray)
      }
      else{
        setFilterArray([...filterArray,`${t('lbl_ARC_enabled')}`])
      }
    }
    else{
      setFilterArray([`${t('lbl_ARC_enabled')}`])
    }
    setAnchorE1(null);
  };

  const handleUserEmailEnabled = () => {
    if(filterArray.length !== 0){
      if(filterArray.some((f) => f === `${t('lbl_end_usrEmail_enabled')}`)){
        setFilterArray(filterArray)
      }
      else{
        setFilterArray([...filterArray,`${t('lbl_end_usrEmail_enabled')}`])
      }
    }
    else{
      setFilterArray([`${t('lbl_end_usrEmail_enabled')}`])
    }
    setAnchorE1(null);
  };

  const handleUserAccessDisabled = () => {
    if(filterArray.length !== 0){
      if(filterArray.some((f) => f === `${t('lbl_end_usrAccess_disabled')}`)){
        setFilterArray(filterArray)
      }
      else{
        setFilterArray([...filterArray,`${t('lbl_end_usrAccess_disabled')}`])
      }
    }
    else{
      setFilterArray([`${t('lbl_end_usrAccess_disabled')}`])
    }
    setAnchorE1(null);
  };

  const handleInstallerEmailAlertsEnabled = () => {
    if(filterArray.length !== 0){
      if(filterArray.some((f) => f === `${t('lbl_installer_emailAlerts_enabled')}`)){
        setFilterArray(filterArray)
      }
      else{
        setFilterArray([...filterArray,`${t('lbl_installer_emailAlerts_enabled')}`])
      }
    }
    else{
      setFilterArray([`${t('lbl_installer_emailAlerts_enabled')}`])
    }
    setAnchorE1(null);
  };

  const handleRemoteServicingEnabled = () => {
    if(filterArray.length !== 0){
      if(filterArray.some((f) => f === `remoteServicingEnabled`)){
        setFilterArray(filterArray)
      }
      else{
        setFilterArray([...filterArray,`remoteServicingEnabled`])
      }
    }
    else{
      setFilterArray([`remoteServicingEnabled`])
    }
    setAnchorE1(null);
  };

  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={anchorE1}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleAll}>{t('lbl_allPanel')}</MenuItem>
        <MenuItem onClick={handleDisconnected}>{t('lbl_disconnected')}</MenuItem>
        <MenuItem onClick={handleConnected}>{t('lbl_connected')}</MenuItem>
        {/* <MenuItem disabled onClick={handleFavorite}>{t('lbl_favorites')}</MenuItem>
        <MenuItem onClick={handleARCEnabled}>{t('lbl_ARC_enabled')}</MenuItem>
        <MenuItem onClick={handleUserEmailEnabled}>{t('lbl_end_usrEmail_enabled')}</MenuItem>
        <MenuItem onClick={handleUserAccessDisabled}>{t('lbl_end_usrAccess_disabled')}</MenuItem>
        <MenuItem onClick={handleInstallerEmailAlertsEnabled}>{t('lbl_installer_emailAlerts_enabled')}</MenuItem>
        <MenuItem onClick={handleRemoteServicingEnabled}>{t('lbl_remoteServicing_enabled')}</MenuItem> */}
      </Menu>
    </div>
  );
}