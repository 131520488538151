/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/require-await */
import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { Button, Divider, MobileStepper, Paper, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import Snackbar from '@mui/material/Snackbar';
import { UserAccessProps } from './UserAccessModalTypes';
import { useSelector } from 'react-redux';
import { fetchSpecificPanelAndEstate, postUserAccess } from '../../redux/actions/specificPanelActions';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: 'background.paper',
  boxShadow: 20,
  p: 0,
};


const BootstrapButton = styled(Button)({
  '&:hover': {
    backgroundColor: '#CA3C3D',
  }, 
});

export default function UserAccessModal({ open, setOpen, panelId, status, setStatus, modalCancelClick, setModalCancelClick }: UserAccessProps): JSX.Element {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const { userAccessStatus } = useSelector((state: any) => state.specificPanelAndEstateReducer);
  const dispatch = useTypedDispatch();
  const { t } = useTranslation();

  React.useEffect(() => {
    if (userAccessStatus === 10) {
      setMessage(`${t('msg_usrAccess_enabled')}`);
    } else if (userAccessStatus === 100) {
      setMessage(`${t('msg_usrAccess_disabled')}`);
    } else {
      setMessage(`${t('msg_usrAccess_error')}`);
    }
    dispatch(fetchSpecificPanelAndEstate(Number(panelId)));
  }, [userAccessStatus]);

  const handleClose = () => {
    setOpen(false);
    setActiveStep(0);
    setModalCancelClick(modalCancelClick + 1);
  }

  const handleUserAccess = async () => {
    await dispatch(postUserAccess(panelId, status));
    setOpen(false);
    setSnackOpen(true);
  }

  const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color="primary" size="small" onClick={handleSnackClose}>
      {t('lbl_close')}
      </Button>
    </React.Fragment>
  );

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} >
          <Paper elevation={0} sx={{ pb: 1 }}>
            <Typography sx={{ pt: 2, pl: 2, pb: 2 }} variant='h6'>{status === true ? `${t('lbl_enable_usrAccess')}` : `${t('lbl_disable_usrAccess')}`}</Typography>
            <Typography sx={{ pl: 2, pb: 4, pt: 0 }} >{t('msg_confirmation')} {status === true ? `${t('msg_enable')}` : `${t('msg_disable')}`} {t('tooltip_usrAccess')}?</Typography>
          </Paper>
          <Divider />
          <Paper >
            <MobileStepper
              variant="dots"
              steps={0}
              position="static"
              activeStep={activeStep}
              sx={{ maxWidth: 400, flexGrow: 1, paddingTop: 2, paddingBottom: 2, paddingLeft: 3, paddingRight: 3, backgroundColor: 'inherit' }}
              nextButton={
                <BootstrapButton size="medium"  disableElevation variant='contained' onClick={handleUserAccess}
                  sx={{
                    backgroundColor: status === true ? '#4CAF50' : '#CA3C3D',
                  }}
                  >
                  {status === true ? `${t('btn_enable')}` : `${t('btn_disable')}`}
                </BootstrapButton>
              }
              backButton={
                <Button size="small" variant='outlined' onClick={handleClose}  >
                  {t('btn_cancel')}
                </Button>
              }
            />
          </Paper>
        </Box>
      </Modal>
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        message={message}
        action={action}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      />
    </div>
  );
}
