/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { BottomNavigation, Button, Divider, IconButton, MobileStepper, Paper, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import PanelList from './PanelList';
import Snackbar from '@mui/material/Snackbar';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { postNewEstate } from '../../redux/actions/newEstateActions';
import ContactNameAndAddressForm from './ContactNameAndAddressForm';
import NameDescriptionForm from './NameDescriptionForm';
import { AddressProps, NewEstateProps } from './newEstateTypes';
import { fetchPanelsAndEstates } from '../../redux/actions/panelEstateActions';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { fetchEstates, fetchPanels, fetchUnAssignedPanels } from '../../redux/actions/esateActions';
import { useTranslation } from 'react-i18next';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: 'background.paper',
  boxShadow: 20,
  p: 0,
};

export default function NewSubEstateModal({open,setOpen}:NewEstateProps):JSX.Element {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const [activeStep, setActiveStep] = React.useState(0);
  const [filled, setFilled] = React.useState<boolean>(true)
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [address, setAddress] = React.useState<AddressProps>({country:'',address:'',postCode:''})
  const [contactName, setContactName] = React.useState('')
  const [number, setNumber] = React.useState('')
  const [deviceList, setDeviceList] = React.useState([])
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const { t } = useTranslation();
  const { specificEstate } = useSelector((state:any) => state.estateReducer)
  const { unassignedPanels } = useSelector((state:any) => state.userReducer)
  const { newEstateID } = useSelector((state: any) => state.newEstateReducer);
  const [estateNameError,setEstateNameError]=React.useState<string>("")
  const [descriptionError,setDescriptionError]=React.useState<string>("")

  const dispatch = useTypedDispatch()

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const steps = [
    { component: <NameDescriptionForm name={name} description={description} setName={setName} setDescription={setDescription} setFilled={setFilled} estateNameError={estateNameError} setEstateNameError={setEstateNameError} descriptionError={descriptionError} setDescriptionError={setDescriptionError} /> }   
  ];

  const fetching = async() => {
    await dispatch(fetchEstates(specificEstate?.ID))
    await dispatch(fetchPanels(specificEstate?.ID))
    await dispatch(fetchUnAssignedPanels(specificEstate?.ID))
}

  React.useEffect(() => {
    if (newEstateID !== undefined) {
      setMessage(`${t('msg_newEstate_created')}`);
      fetching()
    } else {
      setMessage(`${t('msg_estateExists')}`);
    }
  }, [newEstateID]);
  
  const handleClose = () => {
    setName('')
    setDescription('')
    setAddress({country:'',address:'',postCode:''})
    setOpen(false);
    setEstateNameError("")
    setDescriptionError("")
    setActiveStep(0);
    setDeviceList([]);
  }

  const handleSave = async () => {
    const newEstateBody = {
      metadata: { isDomain: true, isEstate: false },
      name: name,
      comments: description,
      contactName: contactName,
      number: number,
      address: { country: address.country, address: address.address, postCode: address.postCode },
      phoneType: "MOBILE",
      type: "ESTATE"
    }
    await dispatch(postNewEstate(specificEstate?.ID, newEstateBody))
    setActiveStep(0)
    setOpen(false);
    setName('')
    setDescription('')
    setSnackOpen(true);
  }

  const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackOpen(false);
  };


  const action = (
    <React.Fragment>
      <Button color="primary" size="small" onClick={handleSnackClose}>
      {t('lbl_close')}
      </Button>
    </React.Fragment>
  );

  return (
    <div>
      {md? <>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            height: '50%',
            backgroundColor: 'background.paper',
            boxShadow: 20,
            p: 0,
          }} >
            <Paper elevation={0} >
              <Paper elevation={4} sx={{
                paddingTop: 2, paddingBottom: 1, paddingLeft: 2, paddingRight: 2,
                display: 'flex', flexDirection: 'row'
              }}>
                <IconButton onClick={handleClose}><ArrowBackIcon /></IconButton>&ensp;&ensp;
                <Typography sx={{ pt: 0.5 }} variant='h6'>{t('lbl_newEstate')}</Typography></Paper>
              <Divider />
              {steps[activeStep].component}
            </Paper>
            <Divider />
            <Paper >
              <MobileStepper
                variant="dots"
                steps={0}
                position="static"
                activeStep={activeStep}
                sx={{ maxWidth: 400, flexGrow: 1, paddingTop: 2, paddingBottom: 2, paddingLeft: 3, paddingRight: 3, backgroundColor: 'inherit' }}
                nextButton={
                      <Button size="medium" variant='contained' onClick={handleSave} disabled={filled || estateNameError.length>0 || descriptionError.length>0}>
                        {t('btn_save')}
                      </Button>
                }
                backButton={
                  <Button size="small" variant='outlined' onClick={handleClose}  >
                    {t('btn_cancel')}
                  </Button>
                }
              />
            </Paper>
          </Box>
        </Modal>
        <Snackbar
          open={snackOpen}
          autoHideDuration={6000}
          onClose={handleSnackClose}
          message={t('msg_newEstate_created')}
          action={action}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        />
      </>
        :
        <>
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} >
              <Paper elevation={0} sx={{ paddingTop: 2, paddingBottom: 3, paddingLeft: 3, paddingRight: 2 }}>
                <Typography variant='h6'>{t('lbl_newEstate')}</Typography></Paper>
              <Divider />
              <Paper elevation={0} sx={{}}>
                {steps[activeStep].component}
              </Paper>
              <Divider />
              <MobileStepper
                variant="dots"
                steps={0}
                position="static"
                activeStep={activeStep}
                sx={{ maxWidth: 400, flexGrow: 1, paddingTop: 2, paddingBottom: 2, paddingLeft: 3, paddingRight: 3, backgroundColor: 'inherit' }}
                nextButton={
                  <Button size="medium" variant='contained' onClick={handleSave} disabled={filled || estateNameError.length>0 || descriptionError.length>0}>
                    {t('btn_save')}
                  </Button>
                }
                backButton={
                  <Button size="small" variant='outlined' onClick={handleClose}  >
                    {t('btn_cancel')}
                  </Button>
                }
              />
            </Box>
          </Modal>
          <Snackbar
            open={snackOpen}
            autoHideDuration={6000}
            onClose={handleSnackClose}
            message={message}
            action={action}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          />
        </>}

    </div>
  );
}
