/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useEffect } from 'react'
import {
    Button,
    Checkbox,
    IconButton,
    Paper,
    SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    useMediaQuery,
    useTheme,
    Snackbar,
    Chip,
    Grid,
    Box,
    Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTypedDispatch } from '../../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { useParams } from 'react-router-dom';
import { ZoneMonitoringProps } from '../../RemoteServicingActivityMonitoring/ZonesMoniteringListTypes';
import { useSelector } from 'react-redux';
import { ListItemTag, Spacer } from '@brightlayer-ui/react-components';
import EditIcon from '@mui/icons-material/Edit';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import RemoteServicingMonitoringModal from '../../RemoteServicingActivityMonitoring/RemoteServicingActivityMonitoringModal';
import { fetchInstallerEmailAlerts, fetchSpecificPanelAndEstate, fetchUserEmailAlerts, fetchZoneMonitoringList } from '../../../redux/actions/specificPanelActions';
import { fetchARDscheduler, fetchRemoteServicingEmailDestinations, getRemoteServicing } from '../../../redux/actions/remoteServicingActions';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';
import RemoteServicingEmailDestinationsModal from '../../RemoteServicingEmailDestinationsModal/RemoteServicingEmailDestinationModal';
import EmailAddressVerifyModal from '../../RemoteServicingEmailDestinationsModal/EmailAddressVerificationModal';
import DeleteEmailDestinationsModal from '../../RemoteServicingEmailDestinationsModal/DeleteEmailDestinationsModal';
import { Delete } from '@mui/icons-material';
import { getCurrentUserDetails } from '../../../redux/actions/accountUsersActions';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),
}));


const RemoteServicing = (): JSX.Element => {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const ms = useMediaQuery(theme.breakpoints.up('md'));
    const dispatch = useTypedDispatch();
    const { id } = useParams();
    const { t } = useTranslation();
    const [typeOfDestinationsModal, setTypeOfDestinationsModal] = React.useState('')
    const [openRemoteEmailModal, setOpenRemoteEmailModal] = React.useState(false)
    const [emailUser, setEmailUser] = React.useState<any>({})
    const [openEmailVerification, setOpenEmailVerification] = React.useState(false)
    const [openDeleteEmailDestination, setOpenDeleteEmailDestination] = React.useState(false)
    const { performRemoteServicingStatus, enabled, schedulerStatus, emailDestinations } = useSelector((state: any) => state.remoteServicingReducer)
    const { specificPanel, panelARCSettings, panelARCCountryCodes, addUserEmailAlertsStatus, installerEmailAlertsStatusValue, installerEmailAlertsDisableStatus, userEmailAlerts, zonesList } = useSelector(
        (state: any) => state.specificPanelAndEstateReducer
    );
    const [openRemoteMonitoringModal, setOpenRemoteMonitoringModal] = React.useState(false)

    const handleRemoteServicingActivityMonitoringModal = () => {
        setOpenRemoteMonitoringModal(true)
    }

    useEffect(() => {
        const getPanelData = async () => {
            // await dispatch(getCurrentUserDetails());
            await dispatch(fetchSpecificPanelAndEstate(Number(id)));
            //await dispatch(fetchPanelARCSettings(Number(id)));
            // await dispatch(fetchInstallerEmailAlerts(specificPanel?.panelId))
            await dispatch(getRemoteServicing(specificPanel?.panelId))
            // await dispatch(fetchUserEmailAlerts(specificPanel?.panelId))
            //await dispatch(performRemoteServicing(specificPanel?.panelId,{enabled:true}))
        };
        getPanelData();
        const fetchArd = async () => {
            await dispatch(fetchZoneMonitoringList(specificPanel?.panelId))
            await dispatch(fetchRemoteServicingEmailDestinations(specificPanel?.panelId))
            await dispatch(fetchARDscheduler(specificPanel?.panelId))
        }
        fetchArd();
    }, [enabled?.enabled])

    const handleEditRemoteServicingemaildestinations = (users: any) => {
        setTypeOfDestinationsModal('edit')
        setEmailUser(users)
        if (users.verified === '0') {
            setOpenEmailVerification(true)
        }
        else {
            setOpenRemoteEmailModal(true)
        }
    }

    const handleDeleteRemoteServicingemaildestinations = (users: any) => {
        setOpenDeleteEmailDestination(true)
        setEmailUser(users)
    }

    const handleRemoteServicingemaildestinations = () => {
        setTypeOfDestinationsModal('add')
        setOpenRemoteEmailModal(true)
    }
    return (
        <>
            <Item elevation={1}>
                <Grid item xs={12}>
                    <Item elevation={0}>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            width={md ? '50%' : '25%'}
                                            variant="head"
                                            style={{ color: '#1397D9' }}
                                        >
                                            Remote Servicing - Activity monitoring
                                        </TableCell>
                                        <TableCell width={md ? '50%' : '75%'}></TableCell>
                                    </TableRow>
                                    {
                                        <TableRow>
                                            <TableCell align="left">
                                                {
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <input
                                                            readOnly
                                                            value={'Excluded zones'}
                                                            style={{
                                                                border: 'none',
                                                                outline: 'none',
                                                                fontSize: 16,
                                                                fontFamily: 'Open Sans',
                                                                fontStyle: 'normal',
                                                                fontWeight: 600,
                                                                lineHeight: 1,
                                                                width: md ? '70%' : '50%',
                                                                textAlign: 'left',
                                                            }}
                                                        />
                                                        &emsp;
                                                        {md ? (
                                                            <>&emsp;&ensp;</>
                                                        ) : (
                                                            <>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</>
                                                        )}
                                                    </div>
                                                }
                                            </TableCell>
                                            <TableCell align="left">
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <Typography sx={{ mt: 0.5 }} variant="body2">
                                                        <Box sx={{ flexGrow: 1, width: '100%', maxHeight: 200, overflow: 'auto' }}>
                                                            <Paper square elevation={0}>

                                                                {zonesList?.filter(
                                                                    (item: ZoneMonitoringProps) =>
                                                                        item.activityMonitorEnabled ===
                                                                        false
                                                                ).length === 0 ? (
                                                                    <Typography
                                                                        variant="body2"
                                                                        sx={{ mt: 0.5 }}
                                                                    >
                                                                        No Excluded zones
                                                                    </Typography>
                                                                )
                                                                    :
                                                                    zonesList?.filter(
                                                                        (item: ZoneMonitoringProps) =>
                                                                            item.activityMonitorEnabled ===
                                                                            false
                                                                    ).length > 6 ?
                                                                        (
                                                                            <Grid container spacing={2}>{
                                                                                zonesList?.filter(
                                                                                    (item: ZoneMonitoringProps) =>
                                                                                        item.activityMonitorEnabled ===
                                                                                        false
                                                                                )
                                                                                    .map(
                                                                                        (
                                                                                            item: ZoneMonitoringProps,
                                                                                            key: number
                                                                                        ) => (
                                                                                            <Grid item key={key} xs={md ? 12 : 2}>
                                                                                                <Chip

                                                                                                    label={item.name}
                                                                                                />
                                                                                            </Grid>
                                                                                        )
                                                                                    )
                                                                            }</Grid>
                                                                        )
                                                                        :
                                                                        (
                                                                            <Stack direction={md ? 'column' : 'row'} spacing={3}>
                                                                                {
                                                                                    zonesList?.filter(
                                                                                        (item: ZoneMonitoringProps) =>
                                                                                            item.activityMonitorEnabled ===
                                                                                            false
                                                                                    )
                                                                                        .map(
                                                                                            (
                                                                                                item: ZoneMonitoringProps,
                                                                                                key: number
                                                                                            ) => (

                                                                                                <Chip key={key}
                                                                                                    label={item.name}
                                                                                                />

                                                                                            )
                                                                                        )
                                                                                }</Stack>
                                                                        )
                                                                }

                                                            </Paper>
                                                        </Box>
                                                    </Typography>
                                                    <Spacer nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                                                    <IconButton
                                                        onClick={
                                                            handleRemoteServicingActivityMonitoringModal
                                                        }
                                                        size="small"
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Item>
                </Grid>

                <RemoteServicingMonitoringModal
                    open={openRemoteMonitoringModal}
                    setOpen={setOpenRemoteMonitoringModal}
                />

            </Item>
            {/* second Modal */}

            <Item elevation={1} sx={{ mt: 4 }}>
                <Grid item xs={12}>
                    <Item elevation={0}>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            width={md ? '50%' : '25%'}
                                            variant="head"
                                            style={{ color: '#1397D9' }}
                                        >
                                            {t('lbl_remove_destination')}
                                        </TableCell>
                                        <TableCell align="right" padding="normal" width={md ? '25%' : '5%'}>
                                            {md ? (
                                                <Button
                                                    onClick={handleRemoteServicingemaildestinations}
                                                    variant="text"
                                                    sx={{ paddingLeft: 10 }}
                                                >
                                                    <AddIcon sx={{ fontSize: 20 }} />
                                                </Button>
                                            ) : (
                                                <Button
                                                    onClick={handleRemoteServicingemaildestinations}
                                                    variant="outlined"
                                                    sx={{ fontSize: 13 }}
                                                    fullWidth
                                                >
                                                    <AddIcon sx={{ fontSize: 15 }} />
                                                    &nbsp;&nbsp;{t('btn_addEmail')}
                                                </Button>
                                            )}
                                            <RemoteServicingEmailDestinationsModal
                                                type={typeOfDestinationsModal}
                                                user={emailUser}
                                                open={openRemoteEmailModal}
                                                setOpen={setOpenRemoteEmailModal}
                                            />
                                            <EmailAddressVerifyModal
                                                open={openEmailVerification}
                                                setOpen={setOpenEmailVerification}
                                                user={emailUser}
                                            />
                                            <DeleteEmailDestinationsModal
                                                open={openDeleteEmailDestination}
                                                setOpen={setOpenDeleteEmailDestination}
                                                user={emailUser}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    {emailDestinations?.message?.length > 0 && emailDestinations?.message !== undefined &&
                                        emailDestinations?.message?.map((users: any, key: number) => (
                                            <TableRow key={key}>
                                                <TableCell colSpan={2} width={'100%'} align="left">
                                                    {md ? (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                            }}
                                                        >
                                                            <Typography variant="subtitle1">
                                                                {users.emailAddress}
                                                            </Typography>
                                                            &emsp;
                                                            {md ? (
                                                                <></>
                                                            ) : (
                                                                <>
                                                                    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                                                                </>
                                                            )}
                                                            <Spacer nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                                                            {users?.verified === '0' && (
                                                                <IconButton disabled>
                                                                    <InfoIcon color="error" />
                                                                </IconButton>
                                                            )}
                                                            <IconButton
                                                                size="small"
                                                                onClick={() =>
                                                                    handleEditRemoteServicingemaildestinations(
                                                                        users
                                                                    )
                                                                }
                                                            >
                                                                <ChevronRightIcon color="action" />
                                                            </IconButton>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                            }}
                                                        >
                                                            <input
                                                                readOnly
                                                                value={users.emailAddress}
                                                                style={{
                                                                    border: 'none',
                                                                    outline: 'none',
                                                                    fontSize: 16,
                                                                    fontFamily: 'Open Sans',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: 600,
                                                                    lineHeight: 1,
                                                                    width: md ? '30%' : '20%',
                                                                    textAlign: 'left',
                                                                }}
                                                            />
                                                            &emsp;
                                                            {md ? (
                                                                <></>
                                                            ) : (
                                                                <>
                                                                    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                                                                </>
                                                            )}
                                                            <Typography sx={{ mt: 0.5 }} variant="body2">
                                                                <Stack direction={'row'} spacing={1}>
                                                                    {users.verified === 1 ? (
                                                                        [
                                                                            users.sendGreen === 1
                                                                                ? 'GREEN'
                                                                                : null,
                                                                            users.sendAmber === 1
                                                                                ? 'AMBER'
                                                                                : null,
                                                                            users.sendRed === 1
                                                                                ? 'RED'
                                                                                : null,
                                                                            users.sendFailed === 1
                                                                                ? 'FAILED'
                                                                                : null,
                                                                        ]
                                                                            .filter((item) => item !== null)
                                                                            .map(
                                                                                (
                                                                                    item: any,
                                                                                    key: number
                                                                                ) => (
                                                                                    <Chip
                                                                                        key={key}
                                                                                        label={item}
                                                                                    />
                                                                                )
                                                                            )
                                                                    ) : (
                                                                        <ListItemTag nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                                            label={
                                                                                'EMAIL ADDRESS PENDING VERIFICATION'
                                                                            }
                                                                            backgroundColor="#CA3C3D"
                                                                        />
                                                                    )}
                                                                </Stack>
                                                            </Typography>
                                                            <Spacer nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                                                            <IconButton
                                                                size="small"
                                                                onClick={() =>
                                                                    handleEditRemoteServicingemaildestinations(
                                                                        users
                                                                    )
                                                                }
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() =>
                                                                    handleDeleteRemoteServicingemaildestinations(
                                                                        users
                                                                    )
                                                                }
                                                            >
                                                                <Delete />
                                                            </IconButton>
                                                        </div>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    <TableRow>
                                        <TableCell align="center" colSpan={2}>
                                            <Typography variant="body2">
                                                {t('lbl_emailDestinationAdd')}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Item>
                </Grid>
            </Item>

        </>
    )
}

export default RemoteServicing
