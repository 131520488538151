/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { MenuItem, Switch, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { AddUserFormProps } from './addUserModalTypes';
import { useTranslation, Trans } from 'react-i18next';

export default function AddUserForm({
    firstName,
    lastName,
    login,
    password,
    repeatPassword,
    userType,
    setFirstName,
    setLastName,
    setLogin,
    setPassword,
    setRepeatPassword,
    setUserType,
    notification,
    setNotification,
    setPasswordError
}: AddUserFormProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const [helperText, setHelperText] = React.useState('')
    const [helperLoginText, setHelperLoginText] = React.useState('')
    const [firstNameError, setFirstNameError] = React.useState<string>('');
    const [lastNameError, setLastNameError] = React.useState<string>('');    
    const { t } = useTranslation();
    const [error, setError] = React.useState('');

    React.useEffect(() => {
       
    }, [])

    const validatePassword = (pwd: any) => {
        const errors = [];
        if (!/[A-Z]/.test(pwd)) {
            errors.push(`${t('error_pwd_info_capital')}`);
        }
        if (!/[a-z]/.test(pwd)) {
            errors.push(`${t('error_pwd_small')}`);
        }
        if (!/\d/.test(pwd)) {
            errors.push(`${t('error_pwd_number')}`);
        }
        if (!/[!@#$%^&*(),.?"':{}|<>]/.test(pwd)) {
            errors.push(`${t('error_pwd_special')}`);
        }
        if (pwd.length < 6) {
            errors.push(`${t('error_pwd_length')}`);
        }
        return errors.join(' ');
    };

    const handlePassword = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const pwd = e.target.value;
        setPassword(pwd);
        const errorMessage = validatePassword(pwd);
        setError(errorMessage);
        setPasswordError(errorMessage)
    } 

    const handleRepeatPassword = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRepeatPassword(e.target.value)
        if(e.target.value === password){
            setHelperText('')
        }
        else{
            setHelperText(`${t('msg_unmatchedPwd')}`)
        }
    }
    const isValidEmail = (email:string) => {
        const isValid = /\S+@\S+\.\S+/.test(email);
        return isValid
      }
    
      const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setFirstName(value);
        if (value.length > 24) {
            setFirstNameError(`${t('error_msg_24_character')}`);
        } else {
            setFirstNameError('');
        }
    };

    const handleLastNameChange=(e: React.ChangeEvent<HTMLInputElement>)=>{
        const value = e.target.value;
        setLastName(value);
        if (value.length > 24) {
            setLastNameError(`${t('error_msg_24_character')}`);
        }else{
            setLastNameError('');
        }
    };

    const handleLogin = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
       const value=e.target.value;
        setLogin(value)
       if(!isValidEmail(value)){
        setHelperLoginText(`${t('msg_email_invalid')}`)
       }else if(value.length>48){
        setHelperLoginText(`${t('error_msg_48_character')}`)
       }
       else{
        setHelperLoginText('')
       }
       
    }


    const userTypes = [
        {
            code: 'ENGINEER',
            value: 'Engineer',
        },
        {
            code: 'MANAGER',
            value: 'Manager',
        },
        {
            code: 'OFFICE_STAFF',
            value: 'Office Staff',
        },
    ];

    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: 3,
                paddingBottom: 5,
                paddingLeft: 3,
                paddingRight: 3,
                maxHeight:md?'75vh': 450,
                overflow: 'auto',
            }}
        >
            <Grid container spacing={3}>
                <Grid item xs={md?12:6}>
                    <TextField  value={firstName} fullWidth id="filled-basic" label={t('lbl_firstName')} variant="filled"  error={firstNameError.length > 0} helperText={firstNameError} onChange={handleFirstNameChange} />
                </Grid>
                <Grid item xs={md?12:6}>
                    <TextField  value={lastName} fullWidth id="filled-basic" label={t('lbl_lastName')} variant="filled" error={lastNameError?.length>0} helperText={lastNameError} onChange={handleLastNameChange} />
                </Grid>
                <Grid item xs={12}>
                    <TextField value={login} fullWidth type={'email'} id="filled-basic" label={t('lbl_login')} variant="filled" error={helperLoginText.length > 0} helperText={helperLoginText} onChange={(e) => handleLogin(e)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField value={password} type={'password'} fullWidth id="filled-basic" label={t('lbl_pwd')} variant="filled" onChange={(e) => handlePassword(e)} error={!!error} helperText={error} />
                </Grid>
                <Grid item xs={12}>
                    <TextField  value={repeatPassword}type={'password'} fullWidth id="filled-basic" label={t('lbl_repeat_pwd')} variant="filled" error={helperText.length > 0 && password.length > 0} helperText={helperText} onChange={(e) => handleRepeatPassword(e)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField select value={userType} fullWidth id="filled-basic" label={t('lbl_typeUsr')} variant="filled" onChange={(e) => setUserType(e.target.value)} >
                        {userTypes.map((user) => (
                            <MenuItem key={user.code} value={user.code}>
                                {user.value}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={6}>
                    <Typography variant="subtitle2">{t('lbl_emailNotifications')}</Typography>
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={2}>
                    <Switch checked={notification} onChange={(e) => setNotification(e.target.checked)} sx={{ mt: -1 }} inputProps={{ 'aria-label': 'controlled' }} />
                </Grid>
            </Grid>
        </Box>
    );
}
