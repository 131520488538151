import { AccountUsersArrayProps } from '../../components/AccountUsers/AccountUsersTypes';
import { InstallerSwitchGroupArrayType, InstallerUserGroupListArray, InstallersArrayProps } from '../../components/Installers/InstallersTypes';
import { PanelArrayProps } from '../../components/ListAndGridView/panelEstateTypes';
import { InstallerActionTypes } from '../constants/installersActionTypes';
const initialState = {
        installersList: <InstallersArrayProps>[],
        installerDetails: <any>{},
        totalPanels: <PanelArrayProps>[],
        installerUsersList: <AccountUsersArrayProps>[],
        activateOrDeactivateInstallerStatus: <number>0,
        removeInstallerStatus: <number>0,
        removeInstallerState: <boolean>false,
        installerLength: <number>0,
        searchedInstallerLength: <number> 0,
        editInstallerStatus: <number>0,
        addUserStatusForInstaller: <any>{},
        installerSwitchGroup: <InstallerSwitchGroupArrayType>[],
        userGroupList: <InstallerUserGroupListArray>[],
        installerSearchList: <InstallerUserGroupListArray>[],
        userInstallerSearchListLength: <number>0,
        userInstallerLength: <number>0,
        userAvailableGroup:<any>[],
        editUserStatus:<number>0,
        userDeleteStatus:<number>0
}

export const installerReducer = (state: any = initialState, action: { type: any; payload: any }): any => {
        switch (action.type) {
                case InstallerActionTypes.SET_INSTALLERS:
                        return { ...state, installersList: action.payload }
                        break;
                case InstallerActionTypes.SET_INSTALLER_DETAILS:
                        return { ...state, installerDetails: action.payload }
                        break;
                case InstallerActionTypes.SET_INSTALLER_PANELS:
                        return { ...state, totalPanels: action.payload }
                        break;
                case InstallerActionTypes.SET_INSTALLER_USERS:
                        return { ...state, installerUsersList: action.payload }
                        break;
                case InstallerActionTypes.SET_ACTIVATE_DEACTIVATE_INSTALLER:
                        return { ...state, activateOrDeactivateInstallerStatus: action.payload }
                        break;
                case InstallerActionTypes.SET_INSTALLERS_LENGTH:
                        return { ...state, installerLength: action.payload }
                        break;
                case InstallerActionTypes.SET_SEARCHED_INSTALLERS_LENGTH:
                        return { ...state, searchedInstallerLength: action.payload }
                        break;
                case InstallerActionTypes.SET_REMOVE_INSTALLER:
                        return { ...state, removeInstallerStatus: action.payload }
                        break;
                case InstallerActionTypes.SET_REMOVE_INSTALLER_STATE:
                        return { ...state, removeInstallerState: action.payload }
                        break;
                case InstallerActionTypes.SET_EDIT_INSTALLER_STATUS:
                        return { ...state, editInstallerStatus: action.payload }
                        break;
                case InstallerActionTypes?.SET_ADD_USER_FOR_INSTALLER:
                        return { ...state, addUserStatusForInstaller: action.payload }
                        break;

                case InstallerActionTypes?.SET_INSTALLER_SWITCH_GROUP:
                        return { ...state, installerSwitchGroup: action.payload }
                        break;
                case InstallerActionTypes?.SET_USER_GROUP_LIST_FOR_INSTALLER:
                        return { ...state, userGroupList: action.payload }
                        break;
                case InstallerActionTypes?.SET_INTALLER_SEARCH_LIST:
                        return { ...state, installerSearchList: action.payload }
                        break;
                case InstallerActionTypes?.SET_INSTALLER_USER_SEARCH_LIST_LENGTH:
                        return { ...state, userInstallerSearchListLength: action.payload }
                        break;
                case InstallerActionTypes?.SET_INSTALLER_USER_LENGTH:
                        return { ...state, userInstallerLength: action.payload }
                        break;
                case InstallerActionTypes?.SET_USER_AVAILABLE_GROUP:
                        return { ...state, userAvailableGroup: action.payload }
                        break;
                case InstallerActionTypes?.SET_EDIT_USER_DETAILS:
                        return { ...state, editUserStatus: action.payload }
                        break;
                case InstallerActionTypes?.SET_USER_DELETE_STATUS:
                        return { ...state, userDeleteStatus: action.payload }
                        break
                default:
                        return state
                        break;

        }
}