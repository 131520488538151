/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { MenuItem, TextField, useMediaQuery, useTheme } from '@mui/material';
import { AccountHolderEditFormProps } from "../SettingsPropTypes";
import { LANGUAGES } from '../../../constants/index';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import secureLocalStorage  from  "react-secure-storage";

export default function AccountHolderEditForm({ isError, setIsError, firstName, setFirstName, lastName, setLastName, contactEmail, setContactEmail, password, setPassword, newPassword, setNewPassword, repeatPassword, setRepeatPassword, spokenPassword, setSpokenPassword, language, setLanguage, locale, setLocale,firstNameError,setFirstNameError,lastNameError,setLastNameError,helperLoginText,setHelperLoginText,spokenPasswordError ,setSpokenPasswordError,passwordError,setPasswordError, }: AccountHolderEditFormProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const role = secureLocalStorage.getItem('role')
    const [helperRepeatPwdText, setHelperRepeatPwdText] = React.useState('')
    const [helperEmailText, setHelperEmailText] = React.useState('')
    const [helperOldPwdText, setHelperOldPwdText] = React.useState('')
    const [helperNewPwdText, setHelperNewPwdText] = React.useState('')
    const { currentUser } = useSelector((state: any) => state.userReducer)
    const { t, i18n } = useTranslation();

    const setLanguageValue = (event: any) => {
        const languageObj = LANGUAGES.arrOfLanguages.filter((languageSettings) => languageSettings.language === event.target.value);
        setLocale(languageObj[0].locale);
        setLanguage(event.target.value)
    }

    const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setFirstName(value);
        if (value.length > 30) {
            setFirstNameError(`${t('msg_inputLength')}`);
        } else {
            setFirstNameError('');
        }
    };

    const handleLastNameChange=(e: React.ChangeEvent<HTMLInputElement>)=>{
        const value = e.target.value;
        setLastName(value);
        if (value.length > 30) {
            setLastNameError(`${t('msg_inputLength')}`);
        }else{
            setLastNameError('');
        }
    };

    const validatePassword = (pwd: any) => {
        const errors = [];
        if (!/[A-Z]/.test(pwd)) {
            errors.push(`${t('error_pwd_info_capital')}`);
        }
        if (!/[a-z]/.test(pwd)) {
            errors.push(`${t('error_pwd_small')}`);
        }
        if (!/\d/.test(pwd)) {
            errors.push(`${t('error_pwd_number')}`);
        }
        if (!/[!@#$%^&*(),.?"':{}|<>]/.test(pwd)) {
            errors.push(`${t('error_pwd_special')}`);
        }
        if (pwd.length < 6) {
            errors.push(`${t('error_pwd_length')}`);
        }
        return errors.join(' ');
    };

    const handleOldPassword = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPassword(e.target.value)
        if (password === '') {
            setIsError(true)
            setHelperOldPwdText(`${t('msg_field_required')}`)
        }else{
            setIsError(false)
            setHelperOldPwdText("")
        }
    }

    const handleSpokenPassword = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSpokenPassword(e.target.value)
        if(e.target.value.length>30){
            setSpokenPasswordError(`${t('msg_inputLength')}`)
        }else{
            setSpokenPasswordError('')
        }
    }

    const handleRepeatPassword = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (password === '') {
            setIsError(true)
            setHelperOldPwdText(`${t('msg_field_required')}`)
        } else {
            setIsError(false)
            setHelperOldPwdText('')
        }
        if (newPassword === '') {
            setIsError(true)
            setHelperNewPwdText(`${t('msg_field_required')}`)
        } else {
            setIsError(false)
            setHelperNewPwdText('')
        }

        if (e.target.value === newPassword) {
            setIsError(false)
            setRepeatPassword(e.target.value)
            setHelperRepeatPwdText('')
        }
        else {
            setIsError(true)
            setHelperRepeatPwdText(`${t('msg_unmatchedPwd')}`)
        }
    }

    const handleNewPassword = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setNewPassword(e.target.value)
        const errorMessage = validatePassword(e.target.value);
        setPasswordError(errorMessage)
        if (e.target.value && password === '') {
            setIsError(true)
            setHelperOldPwdText(`${t('msg_field_required')}`)
        } else if (e.target.value && repeatPassword === '') {
            setIsError(true)
        } else if (e.target.value === '' && (password !== '' && newPassword !== '')) {
            setIsError(true)
        } else {
            setIsError(false)
            setNewPassword(e.target.value)
            setHelperRepeatPwdText('')
            setHelperOldPwdText('')
            
        }
    }

    function isValidEmail(email: string) {
        const isValid = /\S+@\S+\.\S+/.test(email);
        return isValid;
    }

    const handleEmail = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setContactEmail(e.target.value)
        if (e.target.value === '') {
            setIsError(true)
        } else if (!isValidEmail(e.target.value)) {
            setIsError(true)
            setHelperEmailText(`${t('msg_email_invalid')}`)
        }else if(e.target.value.length>48){
            setHelperLoginText(`${t('error_msg_48_character')}`)
        }
        else {
            setIsError(false)
            setHelperEmailText('')
        }
    }

  

    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: 3,
                paddingBottom: 5,
                paddingLeft: 3,
                paddingRight: 3,
                maxHeight: md ? 500 : 430,
                overflow: 'auto',
            }}
        >
            <Grid container spacing={3}>
                <Grid item xs={md ? 12 : 6}>
                    <TextField fullWidth id="filled-basic-first" label={t('lbl_firstName')} value={firstName} onChange={handleFirstNameChange} variant="filled"  error={firstNameError.length > 0}  helperText={firstNameError}/>
                </Grid>
                <Grid item xs={md ? 12 : 6}>
                    <TextField fullWidth id="filled-basic-last" label={t('lbl_lastName')} value={lastName} onChange={handleLastNameChange} variant="filled" error={lastNameError.length>0} helperText={lastNameError}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth type={'email'} id="filled-basic-mail" label={t('lbl_contactEmail')} value={contactEmail} variant="filled" error={helperEmailText.length > 0 || helperLoginText.length>0} helperText={helperEmailText || helperLoginText} onChange={(e) => handleEmail(e)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth type={'password'} id="filled-basic-old-password" label={t('lbl_oldPwd')} value={password} error={helperOldPwdText.length > 0} helperText={helperOldPwdText} onChange={(e) => handleOldPassword(e)} variant="filled" />
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth type={'password'} id="filled-basic-password" label={t('lbl_newPwd')} value={newPassword} variant="filled" error={helperNewPwdText.length > 0  ||passwordError.length>0} helperText={helperNewPwdText || passwordError} onChange={(e) => handleNewPassword(e)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField type={'password'} fullWidth id="filled-basic-repeat-password" label={t('lbl_repeatPwd')} variant="filled" error={helperRepeatPwdText.length > 0} helperText={helperRepeatPwdText} onChange={(e) => handleRepeatPassword(e)} />
                </Grid>
                {role === 'ADMIN' || role === 'INSTALLER' &&
                <Grid item xs={12}>
                    <TextField fullWidth id="filled-basic-spokenPassword" label={t('lbl_spoken_pwd')} value={spokenPassword} onChange={(e) => handleSpokenPassword(e)} variant="filled" error={spokenPasswordError.length>0} helperText={spokenPasswordError}/>
                </Grid>
                }
                <Grid item xs={12}>
                    <TextField value={language} select fullWidth id="filled-basic-language" label={t('lbl_language')} variant="filled" onChange={setLanguageValue}>
                        {LANGUAGES.arrOfLanguages.map((languageObj) => (
                            <MenuItem key={languageObj.locale} value={languageObj.language}>
                                {languageObj.language}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

            </Grid>
        </Box>
    );
}
