/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { InfoListItem } from '@brightlayer-ui/react-components';
import { Box, Grid, Switch, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { InstallersProps } from '../../InstallersTypes';

type addUserPropType = {
    setAvailableGroups: (availableGroups: number[]) => void;
}

function EditUserGroup({ setAvailableGroups }: addUserPropType): JSX.Element {
    const { t } = useTranslation();
    const { installerSwitchGroup, userAvailableGroup } = useSelector((state: any) => state.installerReducer)
    const [checkedList, setCheckedList] = React.useState<boolean[]>([]);
    const [userGroupsId, setUserGroupsId] = React.useState<number[]>([]);
    const [groupSet, setGroupSet] = React.useState(true)
    const [installerObj, setInstallerObj] = React.useState<InstallersProps>({
        ID: 0,
        firstName: '',
        groupId: 0,
        lastName: '',
        installerInfo: {
            cloudId: '',
            installerState: '',
            installerCompanyName: '',
            contactEmail: '',
            address: {
                address: '',
                country: '',
                postCode: ''
            },
            firstPhoneNumber: {
                number: '',
                type: ''
            },
            spokenPassword: ''
        },
        login: '',
    })

    useEffect(() => {
        let installerDetails = localStorage.getItem('installer') || ""
        setInstallerObj(JSON.parse(installerDetails))
        checkedList.length = installerSwitchGroup !== undefined ? installerSwitchGroup?.length : 0;
        if (groupSet === true) {
            for (let i = 0; i < checkedList.length; i++) {
                checkedList[i] = userAvailableGroup?.some((group: any) => group.ID === installerSwitchGroup[i].ID)
            }
            setAvailableGroups([JSON.parse(installerDetails).groupId])
            setGroupSet(false)
        }
        for (let i = 0; i < checkedList.length; i++) {
            if (checkedList[i] === true) {
                userGroupsId[i] = installerSwitchGroup[i]?.ID;
            } else {
                userGroupsId[i] = 0;
            }
        }
    }, [...checkedList])

    const handleClick = (key: number) => {
        if (key === 0) {
            const li = checkedList.map((c: boolean) => !checkedList[key])
            setCheckedList(li)
            setAvailableGroups([installerObj?.groupId!])
        }
    }

    return (
        <>
            <Box sx={{
                flexGrow: 1,
                paddingTop: 3,
                paddingBottom: 5,
                paddingLeft: 3,
                paddingRight: 3,
                maxHeight: 450,
                overflow: 'auto',
            }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="body2">{t('lbl_userPemissions')}:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {installerSwitchGroup !== undefined &&
                            installerSwitchGroup?.map((value: any, index: number) => (
                                <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                    key={index}
                                    title={value.ID === installerObj?.groupId ? `${t('lbl_installerGrp')}` : value.name}
                                    backgroundColor={'#ffffff'}
                                    dense={true}
                                    fontColor={'#424e54'}
                                    hidePadding={true}
                                    iconAlign={'center'}
                                    iconColor={'#424e54'}
                                    divider='full'
                                    rightComponent={<>
                                        <label className="toggle-switch">
                                            <input type="checkbox"
                                                onClick={(): any => handleClick(index)}
                                                checked={checkedList[index]} />
                                            <span className="switch" />
                                        </label>
                                    </>
                                    }
                                />
                            ))}
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default EditUserGroup
