export const ActionTypes = {
    SET_PANELS : 'SET_PANELS',
    SET_ADMIN_PANELS:'SET_ADMIN_PANELS',
    SET_SEARCHED_PANELS:'SET_SEARCHED_PANELS',
    SET_SUMMARY_DETAILS:'SET_SUMMARY_DETAILS',
    SET_ESTATES : 'SET_ESTATES',
    SET_CURRENT_USER : 'SET_CURRENT_USER',
    UNASSIGNED_PANELS : 'UNASSIGNED_PANELS',
    SET_INSTALLER_EMAIL_ALERTS_FOR_ALL_PANELS:'SET_INSTALLER_EMAIL_ALERTS_FOR_ALL_PANELS',
    SET_USER_EMAIL_ALERTS_FOR_ALL_PANELS:'SET_USER_EMAIL_ALERTS_FOR_ALL_PANELS',
    SET_PANELS_UNFILTERED:'SET_PANELS_UNFILTERED',
    SET_USER_EMAIL_ALERTS_FOR_ALL_PANELS_TO_EMPTY:'SET_USER_EMAIL_ALERTS_FOR_ALL_PANELS_TO_EMPTY',
    SET_CONNECTED_PANEL:'SET_CONNECTED_PANEL',
    SET_DISSCONNECTED_PANEL:'SET_DISSCONNECTED_PANEL',
    SET_CONNECTED_PANELS_TRUE:'SET_CONNECTED_PANELS_TRUE',
    SET_DISCONNECTED_PANELS_TRUE:'SET_DISCONNECTED_PANELS_TRUE',
    SET_SEARCHED_PANELS_FOR_INSTALLER:'SET_SEARCHED_PANELS_FOR_INSTALLER',
}