/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';
import { NameDescriptionFormProps } from './newEstateTypes';
import { useTranslation } from 'react-i18next';

export default function NameDescriptionForm({setFilled,name,description,setName,setDescription,estateNameError,setEstateNameError,descriptionError,setDescriptionError}:NameDescriptionFormProps):JSX.Element {
  const { t } = useTranslation();

  if (name !== '') {
    setFilled(false)
  }
  else {
    setFilled(true)
  }

  const handleName=(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
    const value=e.target.value;
    setName(value)
    if(value.length>24){
      setEstateNameError(`${t('error_msg_24_character')}`)
    }else{
      setEstateNameError("")
    }
  }

  const handleDescription=(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
    const value=e.target.value;
    setDescription(value)
    if(value.length>255){
      setDescriptionError(`${t('error_msg_255_character')}`)
    }else{
      setDescriptionError("")
    }
}
  return (
    <div>
      <Box sx={{ flexGrow: 1, paddingTop: 3, paddingBottom: 5, paddingLeft: 3, paddingRight: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField  fullWidth id="filled-basic"
              label={t('lbl_name')} variant="filled"
              value={name} onChange={(e) => handleName(e)} error={estateNameError.length>0} helperText={estateNameError}/>
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth id="filled-basic"
              label={t('lbl_siteComments')} variant="filled"
              multiline
              error={descriptionError.length>0}
              helperText={descriptionError}
              rows={4}
              value={description} onChange={(e) => handleDescription(e)} />

          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
