/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint no-unused-expressions: ["error", { "allowTernary": true }] */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { Box, Button, Divider, Grid, MobileStepper, Modal, Paper, Snackbar, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEstates, fetchPanels, fetchUnAssignedPanels, setEstate } from '../../redux/actions/esateActions';
import { postEditEstateDetails } from '../../redux/actions/newEstateActions';


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'background.paper',
    boxShadow: 20,
    p: 0,
};

type props = {
    open: boolean
    setOpen: (open: boolean) => void
    estateDetailsName: object
    setEstateDetailsName: (name: string) => void
    esatesDetailsCmnts: object
    setEstateDetailsCmnts: (cmnt: string) => void
}

function EditEstateDetails({ open, setOpen, estateDetailsName, setEstateDetailsName, esatesDetailsCmnts, setEstateDetailsCmnts }: props): JSX.Element {
    const dispatchEstate = useDispatch()
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation();
    const [activeStep, setActiveStep] = React.useState(0);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [count, setCount] = React.useState(0);
    const dispatch = useTypedDispatch();
    const { specificEstate } = useSelector((state: any) => state.estateReducer)
    const { editEstatesDetails } = useSelector((state: any) => state.newEstateReducer)
    const [estateNameError,setEstateNameError]=React.useState<string>("")
    const [descriptionError,setDescriptionError]=React.useState<string>("")

    const updatedData = {
        ...specificEstate,
        name: estateDetailsName,
        comments: esatesDetailsCmnts
    };

    const handleClose = () => {
        setOpen(false);
        setEstateNameError("")
        setDescriptionError("")
    }

    const fetching = async () => {
        await dispatch(fetchEstates(specificEstate?.ID));
        await dispatch(fetchPanels(specificEstate?.ID))
    }

    const handleName=(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
        const value=e.target.value;
        setEstateDetailsName(value)
        if(value.length>24){
          setEstateNameError(`${t('error_msg_24_character')}`)
        }else{
          setEstateNameError("")
        }
      }

      
    const handleDescription=(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
        const value=e.target.value;
        setEstateDetailsCmnts(value)
        if(value.length>255){
          setDescriptionError(`${t('error_msg_255_character')}`)
        }else{
          setDescriptionError("")
        }
    }

    React.useEffect(() => {
        if (editEstatesDetails !== 0) {
            if (editEstatesDetails === 200) {
                setMessage(`${t('msg_edit_estatesDetails')}`);
                dispatchEstate(setEstate(updatedData));
                fetching();
            } else if (editEstatesDetails === 500) {
                setMessage(`${t('msg_internal_error')}`);
            }
            else {
                setMessage(`${t('lbl_somethingWrong')}`);
            }
        }
    }, [count]);


    const handleSave = async () => {
        const body = {
            ID: specificEstate?.ID,
            address: {
                address: specificEstate?.address?.address,
                country: specificEstate?.address?.country,
                postCode: specificEstate?.address?.postCode,
            },
            childPresented: specificEstate?.childPresented,
            comments: esatesDetailsCmnts,
            contactName: specificEstate?.contactName,
            devicesAssigned: specificEstate?.devicesAssigned,
            lastAlert: specificEstate?.lastAlert,
            level: specificEstate?.level,
            metadata: { isDomain: true, isEstate: false },
            name: estateDetailsName,
            number: specificEstate?.number,
            path: specificEstate?.path,
            phoneType: specificEstate?.phoneType,
            type: specificEstate?.type
        }
        await dispatch(postEditEstateDetails(specificEstate?.ID, body))        
        setSnackOpen(true)
        setCount((prev) => prev + 1)
        setOpen(false);
    }

    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleSnackClose}>
                {t('lbl_close')}
            </Button>
        </React.Fragment>
    );

    return (
        <>
            {md ? <>
                <Modal
                    open={open}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        height: '45%',
                        backgroundColor: 'background.paper',
                        boxShadow: 20,
                        p: 0,
                    }} >
                        <Paper elevation={0} >
                            <Paper elevation={4} sx={{
                                paddingTop: 2, paddingBottom: 1, paddingLeft: 2, paddingRight: 2,
                                display: 'flex', flexDirection: 'row'
                            }}>
                                <Typography sx={{ pt: 0.5 }} variant='h6'>{t('lbl_edit_estate')}</Typography>
                            </Paper>
                            <Divider />
                            <Box sx={{ flexGrow: 1, paddingTop: 3, paddingLeft: 3, paddingBottom: 5, paddingRight: 3 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TextField  fullWidth id="filled-basic"
                                            label={t('lbl_name')} variant="filled"
                                            value={estateDetailsName}
                                            error={estateNameError.length>0} helperText={estateNameError}
                                            onChange={(e) => handleName(e)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField fullWidth id="filled-basic"
                                            multiline
                                            error={descriptionError.length>0}
                                            helperText={descriptionError}    
                                            rows={4}
                                            label={t('lbl_siteComments')} variant="filled"
                                            value={esatesDetailsCmnts}
                                            onChange={(e) => handleDescription(e)}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper >
                            <MobileStepper
                                variant="dots"
                                steps={0}
                                position="static"
                                activeStep={activeStep}
                                sx={{ maxWidth: 400, flexGrow: 1, paddingTop: 2, paddingBottom: 2, paddingLeft: 3, paddingRight: 3, backgroundColor: 'inherit' }}
                                nextButton={
                                    <Button size="medium" variant='contained' onClick={handleSave} disabled={estateNameError.length>0 || descriptionError.length>0}>
                                        {t('btn_save')}
                                    </Button>
                                }
                                backButton={
                                    <Button size="small" variant='outlined' onClick={handleClose}  >
                                        {t('btn_cancel')}
                                    </Button>
                                }
                            />
                        </Paper>
                    </Box>
                </Modal>
                <Snackbar
                    open={snackOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackClose}
                    message={message}
                    action={action}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                />
            </> : <>
                <Modal
                    open={open}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} >
                        <Paper elevation={0} sx={{ paddingTop: 2, paddingBottom: 3, paddingLeft: 3, paddingRight: 2 }}>
                            <Typography variant='h6'>
                                {t('lbl_edit_estate')}
                            </Typography>
                        </Paper>
                        <Divider />
                        <Paper elevation={0} sx={{}}>
                            <Box sx={{ flexGrow: 1, paddingTop: 3, paddingBottom: 5, paddingLeft: 3, paddingRight: 3 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TextField fullWidth id="filled-basic"
                                            label={t('lbl_name')} variant="filled"
                                            value={estateDetailsName}
                                            error={estateNameError.length>0} helperText={estateNameError}
                                            onChange={(e) => handleName(e)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField fullWidth id="filled-basic"
                                            multiline
                                            error={descriptionError.length>0}
                                            helperText={descriptionError}    
                                            rows={4}
                                            label={t('lbl_siteComments')} variant="filled"
                                            value={esatesDetailsCmnts}
                                            onChange={(e) => handleDescription(e)}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                        <Divider />
                        <MobileStepper
                            variant="dots"
                            steps={0}
                            position="static"
                            activeStep={activeStep}
                            sx={{ maxWidth: 400, flexGrow: 1, paddingTop: 2, paddingBottom: 2, paddingLeft: 3, paddingRight: 3, backgroundColor: 'inherit' }}
                            nextButton={
                                <Button size="medium" variant='contained' onClick={handleSave} disabled={estateNameError.length>0 || descriptionError.length>0}>
                                    {t('btn_save')}
                                </Button>
                            }
                            backButton={
                                <Button size="small" variant='outlined' onClick={handleClose}  >
                                    {t('btn_cancel')}
                                </Button>
                            }
                        />
                    </Box>
                </Modal>
                <Snackbar
                    open={snackOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackClose}
                    message={message}
                    action={action}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                />
            </>}
        </>
    )
}

export default EditEstateDetails




