/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { MenuItem, Switch, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';

export default function AddAdminForm({
    firstName,
    lastName,
    login,
    password,
    repeatPassword,
    language,
    userType,
    setFirstName,
    setLastName,
    setLogin,
    setPassword,
    setRepeatPassword,
    setLanguage,
    setUserType,
    setPasswordError
}: any): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const [helperText, setHelperText] = React.useState('')
    const [helperLoginText, setHelperLoginText] = React.useState('')
    const [firstNameError, setFirstNameError] = React.useState<string>('');
    const [lastNameError, setLastNameError] = React.useState<string>('');    
    const { t } = useTranslation();
    const [error, setError] = React.useState('');

    React.useEffect(() => {
       
    }, [])

    const validatePassword = (pwd: any) => {
        const errors = [];
        if (!/[A-Z]/.test(pwd)) {
            errors.push(`${t('error_pwd_info_capital')}`);
        }
        if (!/[a-z]/.test(pwd)) {
            errors.push(`${t('error_pwd_small')}`);
        }
        if (!/\d/.test(pwd)) {
            errors.push(`${t('error_pwd_number')}`);
        }
        if (!/[!@#$%^&*(),.?"':{}|<>]/.test(pwd)) {
            errors.push(`${t('error_pwd_special')}`);
        }
        if (pwd.length < 6) {
            errors.push(`${t('error_pwd_length')}`);
        }
        return errors.join(' ');
    };

    const handlePassword = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const pwd = e.target.value;
        setPassword(pwd);
        const errorMessage = validatePassword(pwd);
        setError(errorMessage);
        setPasswordError(errorMessage)
    }


    const handleRepeatPassword = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRepeatPassword(e.target.value)
        if(e.target.value === password){
            setHelperText('')
        }
        else{
            setHelperText(`${t('msg_unmatchedPwd')}`)
        }
    }
    const isValidEmail = (email:string) => {
        const isValid = /\S+@\S+\.\S+/.test(email);
        return isValid
      }
    
    const handleLogin = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setLogin(e.target.value)
       if(!isValidEmail(e.target.value)){
        setHelperLoginText(`${t('msg_email_invalid')}`)
       }else if(e.target.value.length>24){
        setHelperLoginText(`${t('error_msg_24_character')}`)
       }
       else{
        setHelperLoginText('')
       }
       
    }

    const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setFirstName(value);
        if (value.length > 24) {
            setFirstNameError(`${t('error_msg_24_character')}`);
        } else {
            setFirstNameError('');
        }
    };

    const handleLastNameChange=(e: React.ChangeEvent<HTMLInputElement>)=>{
        const value = e.target.value;
        setLastName(value);
        if (value.length > 24) {
            setLastNameError(`${t('error_msg_24_character')}`);
        }else{
            setLastNameError('');
        }
    };

    const userTypes = [
        {
            code: 'ADMIN',
            value: 'Administrator',
        },
        {
            code: 'NORMAL_ADMIN',
            value: 'Normal Administrator',
        },
    ];

    const languages = [
        {
            code : 'en_US',
            value: 'English',
        },
        {
            code:'de_DE',
            value: 'Deutsch',
        },
        {
            code:'nl_NL',
            value: 'Nederlands',
        },
        {
            code:'fr_FR',
            value: 'Francais',
        },
        {
            code:'el_GR',
            value: 'Ἑλληνική',
        },
        {
            code:'it_IT',
            value: 'Italiano',
        },
        {
            code:'pt_PT',
            value: 'Portugues',
        },
    ];

    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: 3,
                paddingBottom: 5,
                paddingLeft: 3,
                paddingRight: 3,
                maxHeight:md?'75vh': 450,
                overflow: 'auto',
            }}
        >
            <Grid container spacing={3}>
                <Grid item xs={md?12:6}>
                    <TextField value={firstName} fullWidth id="filled-firstName" label={t('lbl_firstName')} variant="filled" onChange={handleFirstNameChange} error={firstNameError.length > 0} helperText={firstNameError}/>
                </Grid>
                <Grid item xs={md?12:6}>
                    <TextField value={lastName} fullWidth id="filled-lastName" label={t('lbl_lastName')} variant="filled" onChange={handleLastNameChange} error={lastNameError?.length>0} helperText={lastNameError}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField value={login} fullWidth type={'email'} id="filled-email" label={t('lbl_login')} variant="filled" error={helperLoginText.length > 0} helperText={helperLoginText} onChange={(e) => handleLogin(e)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField value={password} type={'password'} fullWidth id="filled-pwd" label={t('lbl_pwd')} variant="filled" onChange={(e) => handlePassword(e)} error={!!error} helperText={error}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField  value={repeatPassword}type={'password'} fullWidth id="filled-repeatPwd" label={t('lbl_repeat_pwd')} variant="filled" error={helperText.length > 0 && password.length > 0} helperText={helperText} onChange={(e) => handleRepeatPassword(e)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField select value={language} fullWidth id="filled-language" label={'Select Language'} variant="filled" onChange={(e) => setLanguage(e.target.value)} >
                        {languages.map((user,i) => (
                            <MenuItem key={user.code} value={user.code}>
                                {user.value}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField select value={userType} fullWidth id="filled-user" label={t('lbl_typeUsr')} variant="filled" onChange={(e) => setUserType(e.target.value)} >
                        {userTypes.map((user) => (
                            <MenuItem key={user.code} value={user.code}>
                                {user.value}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12}></Grid>
            </Grid>
        </Box>
    );
}
