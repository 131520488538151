/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-shadow */
import {
    Button,
    Checkbox,
    IconButton,
    Paper,
    SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    useMediaQuery,
    useTheme,
    Snackbar,
    Chip,
    Stack,
} from '@mui/material'
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import UserEmailAlertsModal from '../UserEmailAlertsModal/UserEmailAlertsModal';
import { useSelector } from 'react-redux';
import { SiaEmailSettingsProps } from '../UserEmailAlertsModal/UserEmailAlertsTypes';
import { Spacer } from '@brightlayer-ui/react-components';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Delete } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import EditUserEmailAlertsModal from '../EditUserEmailAlerts/EditUserEmailAlertsModal';
import DeleteUserEmailAlertsModal from '../DeleteUserEmailAlerts/DeleteUserEmailAlertsModal';

const UserEmailAlert = (): JSX.Element => {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation();
    const { userEmailAlerts, addUserEmailAlertsStatus } = useSelector(
        (state: any) => state.specificPanelAndEstateReducer
    );
    const [EmailAlertsModalOpen, setEmailAlertsModalOpen] = React.useState(false);
    const [alertsmessage, setAlertsMessage] = React.useState('');
    const [alertsSnackOpen, setAlertsSnackOpen] = React.useState(false);
    const [editUserIndex, setEditUserIndex] = React.useState<number>();
    const [editUserId, setEditUserId] = React.useState<number>(0);
    const [siaEmailSetting, setSiaEmailSetting] = React.useState<SiaEmailSettingsProps>()
    const [editUserEmailAlertsModalOpen, setEditUserEmailAlertsModalOpen] = React.useState(false);
    const [deleteUserId, setDeleteUserId] = React.useState<number>(0);
    const [deleteUserEmailAlertsModalOpen, setDeleteUserEmailAlertsModalOpen] = React.useState(false);
    const [counter, setCounter] = React.useState<number>(0)
    const [isMobile, setIsMobile] = React.useState<boolean>(false)


    const handleUserEmailAlertsModal = () => {
        setEmailAlertsModalOpen(true);
    };

    const handleEditUserAlerts = (id: number, key: number, users: SiaEmailSettingsProps) => {
        setEditUserIndex(key)
        setEditUserId(id)
        setSiaEmailSetting(users)
        setEditUserEmailAlertsModalOpen(true)
    }
    const handleDeleteUserAlerts = (id: number) => {
        setDeleteUserId(id)
        setDeleteUserEmailAlertsModalOpen(true)
    }

    useEffect(() => {
        if (counter !== 0) {
            setAlertsSnackOpen(true)
            if (addUserEmailAlertsStatus === 204) {
                setAlertsMessage(`${t('msg_usr_emailDeleted')}`)
            }
            else {
                setAlertsMessage(`${t('lbl_someError')}`)
            }
            setCounter(0)
        }
    }, [counter])

    const handleDisableInstallerEmailAlertSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertsMessage('')
        setAlertsSnackOpen(false);
    };
    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleDisableInstallerEmailAlertSnackClose}>
                {t('lbl_close')}
            </Button>
        </React.Fragment>
    );
    return (
        <>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <TableCell
                                width={md ? '50%' : '25%'}
                                variant="head"
                                style={{ color: '#1397D9' }}
                            >
                                {t('tooltip_usrEmail_alerts')}
                            </TableCell>
                            <TableCell align="right" padding="normal" width={md ? '25%' : '2%'}>
                                {md ? (
                                    <Button
                                        onClick={handleUserEmailAlertsModal}
                                        variant="text"
                                        sx={{ paddingLeft: 10 }}
                                    >
                                        <AddIcon sx={{ fontSize: 20 }} />
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={handleUserEmailAlertsModal}
                                        variant="outlined"
                                        sx={{ fontSize: 13 }}
                                        fullWidth
                                    >
                                        <AddIcon sx={{ fontSize: 15 }} />
                                        &nbsp;&nbsp;{t('btn_addUsr')}
                                    </Button>
                                )}
                                <UserEmailAlertsModal
                                    open={EmailAlertsModalOpen}
                                    setOpen={setEmailAlertsModalOpen}
                                />
                            </TableCell>
                        </TableRow>
                        {userEmailAlerts?.siaEmailSettings?.map(
                            (users: SiaEmailSettingsProps, key: number) => (
                                <TableRow key={key}>
                                    <TableCell colSpan={2} width={'100%'} align="left">
                                        {md ? (
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <Typography variant="subtitle1">
                                                    {users.email}
                                                </Typography>
                                                &emsp;
                                                {md ? (
                                                    <></>
                                                ) : (
                                                    <>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</>
                                                )}
                                                <Spacer nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                                                <IconButton
                                                    size="small"
                                                    onClick={() =>
                                                        handleEditUserAlerts(users.ID, key, users)
                                                    }
                                                >
                                                    <ChevronRightIcon color="action" />
                                                </IconButton>
                                            </div>
                                        ) : (
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <input
                                                    readOnly
                                                    value={users.email}
                                                    style={{
                                                        border: 'none',
                                                        outline: 'none',
                                                        fontSize: 16,
                                                        fontFamily: 'Open Sans',
                                                        fontStyle: 'normal',
                                                        fontWeight: 600,
                                                        lineHeight: 1,
                                                        width: md ? '30%' : '20%',
                                                        textAlign: 'left',
                                                    }}
                                                />
                                                &emsp;
                                                {md ? (
                                                    <></>
                                                ) : (
                                                    <>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</>
                                                )}
                                                <Typography sx={{ mt: 0.5 }} variant="body2">
                                                    <Stack direction={'row'} spacing={1}>
                                                        {[
                                                            users.setEnabled ? 'Sets' : null,
                                                            users.systemEnabled ? 'System' : null,
                                                            users.alarmsEnabled ? 'Alarms' : null,
                                                            users.tampersEnabled ? 'Tampers' : null,
                                                        ]
                                                            .filter((item) => item !== null)
                                                            .map((item: any, key: number) => (
                                                                <Chip key={key} label={item} />
                                                            ))}
                                                    </Stack>
                                                </Typography>
                                                <Spacer nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                                                <IconButton
                                                    size="small"
                                                    onClick={() =>
                                                        handleEditUserAlerts(users.ID, key, users)
                                                    }
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => handleDeleteUserAlerts(users.ID)}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </div>
                                        )}
                                    </TableCell>
                                </TableRow>
                            )
                        )}
                        <EditUserEmailAlertsModal
                            counter={counter}
                            setCounter={setCounter}
                            siaEmailSetting={siaEmailSetting}
                            index={editUserIndex}
                            id={editUserId}
                            open={editUserEmailAlertsModalOpen}
                            setOpen={setEditUserEmailAlertsModalOpen}
                        />
                        <DeleteUserEmailAlertsModal
                            counter={counter}
                            setCounter={setCounter}
                            open={editUserEmailAlertsModalOpen}
                            setOpen={setEditUserEmailAlertsModalOpen}
                            isMobile={isMobile}
                            setIsMobile={setIsMobile}
                            id={deleteUserId}
                            openDeleteModal={deleteUserEmailAlertsModalOpen}
                            setOpenDeleteModal={setDeleteUserEmailAlertsModalOpen}
                        />
                        <TableRow>
                            <TableCell align="center" colSpan={2}>
                                <Typography variant="body2">{t('lbl_addUsr')}</Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                    <Snackbar
                        open={alertsSnackOpen}
                        autoHideDuration={6000}
                        onClose={handleDisableInstallerEmailAlertSnackClose}
                        message={alertsmessage}
                        action={action}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    />
                </Table>
            </TableContainer>
        </>
    )
}

export default UserEmailAlert
