/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from 'react'
import { MenuItem, Switch, TextField, Typography, useMediaQuery, useTheme, Box, Grid, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EditInstallerUserProps } from './EditUserTypes';
import { NotificationLog } from '@brightlayer-ui/icons-mui';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';

function EditUserForm({
    firstName,
    lastName,
    login,
    userType,
    setFirstName,
    setLastName,
    setLogin,
    setUserType,
    notification,
    setNotification,
    firstNameError,setFirstNameError,lastNameError,setLastNameError,helperLoginText,setHelperLoginText
 }: EditInstallerUserProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation();

    const USER_TYPE_ENGINEER = 'ENGINEER';
    const USER_TYPE_MANAGER = 'MANAGER';
    const USER_TYPE_OFFICE_STAFF = 'OFFICE_STAFF';

    const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setFirstName(value);
        if (value.length > 24) {
            setFirstNameError(`${t('error_msg_24_character')}`);
        } else {
            setFirstNameError('');
        }
    };

    const handleLastNameChange=(e: React.ChangeEvent<HTMLInputElement>)=>{
        const value = e.target.value;
        setLastName(value);
        if (value.length > 24) {
            setLastNameError(`${t('error_msg_24_character')}`);
        }else{
            setLastNameError('');
        }
    };

    function isValidEmail(email: string) {
        const isValid = /\S+@\S+\.\S+/.test(email);
        return isValid;
    }

    const handleEmail = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setLogin(e.target.value)
        if (!isValidEmail(e.target.value)) {            
            setHelperLoginText(`${t('msg_email_invalid')}`)
        }else if(e.target.value.length>48){
            setHelperLoginText(`${t('error_msg_48_character')}`)
        }
        else {
            setHelperLoginText('')
        }

    }

    return (
        <>
            <Box
                sx={{
                    flexGrow: 1,
                    paddingTop: 3,
                    paddingBottom: 5,
                    paddingLeft: 3,
                    paddingRight: 3,
                    maxHeight: md ? '75vh' : 450,
                    overflow: 'auto',
                }}
            >
                <Grid container spacing={3}>
                    <Grid item xs={md ? 12 : 6}>
                        <TextField value={firstName} fullWidth id="filled-basic-firstname" label={t('lbl_firstName')} variant="filled" onChange={handleFirstNameChange} error={firstNameError.length > 0}  helperText={firstNameError}/>
                    </Grid>
                    <Grid item xs={md ? 12 : 6}>
                        <TextField value={lastName} fullWidth id="filled-basic-lastname" label={t('lbl_lastName')} variant="filled" onChange={handleLastNameChange} error={lastNameError.length>0} helperText={lastNameError} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField value={login} fullWidth id="filled-basic-login" label={t('lbl_login')} variant="filled" onChange={handleEmail} error={helperLoginText.length>0} helperText={helperLoginText}/>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField inputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <ArrowDropDownIcon />
                                </InputAdornment>
                            )
                        }}
                            value={userType === USER_TYPE_ENGINEER ? `${t('lbl_engineer')}` : userType === USER_TYPE_MANAGER ? `${t('lbl_manager')}` : userType === USER_TYPE_OFFICE_STAFF ? `${t('lbl_officeStaff')}` : `${t('lbl_installer')}`}
                            disabled={true} fullWidth id="filled-basic" label={t('lbl_typeUsr')} variant="filled"
                            onChange={(e) => setUserType(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2">{t('lbl_emailNotifications')}</Typography>
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={2}>
                        <Switch checked={notification} onChange={(e) => setNotification(e.target.checked)} sx={{ mt: -1 }} inputProps={{ 'aria-label': 'controlled' }} />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default EditUserForm
