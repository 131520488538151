/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Divider, IconButton, MobileStepper, Paper, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import Snackbar from '@mui/material/Snackbar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { EditUserModalProps } from './editUserModalTypes';
import EditUserForm from './EditUserForm';
import AddUserGroups from './EditUserGroups';
import { useTypedDispatch } from '../../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { useSelector } from 'react-redux';
import { editUser } from '../../../redux/actions/accountUsersActions';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteUserModal from '../DeleteUserModal/DeleteUserModal';
import { Spacer } from '@brightlayer-ui/react-components';
import { useTranslation, Trans } from 'react-i18next';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    backgroundColor: 'background.paper',
    boxShadow: 20,
    p: 0,
};

export default function EditUserModal({
    userEditDetails,
    count,
    setCount,
    open,
    setOpen,
    counter,
    setCounter,
    isMobile,
    setIsMobile
}: EditUserModalProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useTypedDispatch();
    const { currentUser } = useSelector((state: any) => state.userReducer);
    //const currentUser = JSON.parse(localStorage.getItem('currentUser') || '')
    const { editUserStatus } = useSelector((state: any) => state.accountUsersReducer);
    const [activeStep, setActiveStep] = React.useState(0);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [availableGroups, setAvailableGroups] = React.useState<number[]>([]);
    const [firstName, setFirstName] = React.useState<string>(userEditDetails?.firstName);
    const [lastName, setLastName] = React.useState<string>(userEditDetails?.lastName);
    const [login, setLogin] = React.useState<string>(userEditDetails?.login);
    const [userType, setUserType] = React.useState<string | undefined>(userEditDetails?.userType);
    const [notification, setNotification] = React.useState<boolean>(userEditDetails?.notificationEnabledUserArray);
    const [message, setMessage] = React.useState<string>('');
    const [status, setStatus] = React.useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);
    const [firstNameError, setFirstNameError] = React.useState<string>('');
    const [lastNameError, setLastNameError] = React.useState<string>('');    
    const [helperLoginText, setHelperLoginText] = React.useState('')
    const { t } = useTranslation();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const userTypes = [
        {
            code: 'ENGINEER',
            value: 'Engineer',
        },
        {
            code: 'MANAGER',
            value: 'Manager',
        },
        {
            code: 'OFFICE_STAFF',
            value: 'Office Staff',
        },
    ];

    const steps = [
        {
            component: (
                <EditUserForm
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    login={login}
                    setLogin={setLogin}
                    userType={userType}
                    setUserType={setUserType}
                    notification={notification}
                    setNotification={setNotification}
                    firstNameError={firstNameError}
                    setFirstNameError={setFirstNameError}
                    lastNameError={lastNameError} 
                    setLastNameError={setLastNameError}
                    helperLoginText={helperLoginText}
                    setHelperLoginText={setHelperLoginText}
                />
            ),
        },
        { component: <AddUserGroups setAvailableGroups={setAvailableGroups} /> },
    ];

    React.useEffect(() => {
        if (status === true) {
            if (editUserStatus === 200) {
                setCount(count + 1);
                setMessage(`${t('msg_userEdited')}`);
            } else if (editUserStatus === 500) {
                setMessage(`${t('msg_internal_error')}`);
            } else if (editUserStatus === 400) {
                setMessage(`${t('lbl_unauthorizedUser')}`);
            } else {
                setMessage(`${t('lbl_someError')}`);
            }
            setSnackOpen(true);
            setStatus(false);
        }

        setFirstName(userEditDetails.firstName);
        setLastName(userEditDetails.lastName);
        setLogin(userEditDetails.login);
        setUserType(userEditDetails.userType);
        setNotification(userEditDetails.notificationEnabledUserArray);
    }, [status, userEditDetails]);

    const handleClose = () => {
        setOpen(false);
        setActiveStep(0);
        setFirstNameError("")
        setLastNameError("")
        setHelperLoginText("")
        setFirstName(userEditDetails.firstName);
        setLastName(userEditDetails.lastName);
        setLogin(userEditDetails.login);
        setNotification(userEditDetails.notificationEnabledUserArray);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSave = async () => {
        const UserType = userTypes.find((type) => type.code === userType);
        const body = {
            ID: userEditDetails.ID,
            alertNotificationEnabled: notification,
            creationTimeInMilliSeconds: userEditDetails.creationTimeInMilliSeconds,
            firstName: firstName,
            groupId: userEditDetails.groupId,
            installerInfo: userEditDetails.installerInfo,
            lastName: lastName,
            locale: userEditDetails.locale,
            login: login,
            userType: userType,
            userTypeTitle: UserType?.value,
        };
        await dispatch(editUser(body, availableGroups, notification, userEditDetails.ID, currentUser));
        setStatus(true);
        setActiveStep(0);
        setOpen(false);
    };

    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const handleDeleteUser = (accountUser: any) => {
        setDeleteModalOpen(true);
        setIsMobile(true)
    };

    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleSnackClose}>
            {t('lbl_close')}
            </Button>
        </React.Fragment>
    );

    return (
        <div>
            {md ? (
                <>
                    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box
                            sx={{
                                position: 'absolute' as 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'background.paper',
                                boxShadow: 20,
                                p: 0,
                            }}
                        >
                            <Paper elevation={0} sx={{ pb: 0 }}>
                                <Paper
                                    elevation={4}
                                    sx={{
                                        paddingTop: 2,
                                        paddingBottom: 1,
                                        paddingLeft: 2,
                                        paddingRight: 2,
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <IconButton onClick={handleClose}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                    &ensp;&ensp;
                                    <Typography sx={{ pt: 0.5 }} variant="h6">
                                       {t('lbl_editUsr')}
                                    </Typography>
                                    <Spacer nonce={undefined} onResize={undefined} onResizeCapture={undefined}/>
                                    <IconButton
                                        onClick={() => handleDeleteUser(userEditDetails.ID)}
                                        sx={{ flexDirection: 'row', textAlign: 'right' }}
                                        size="small">
                                        <DeleteIcon />
                                    </IconButton>
                                    <DeleteUserModal counter={counter} setCounter={setCounter} open={deleteModalOpen} setOpen={setDeleteModalOpen} selectedUserId={userEditDetails.ID} ></DeleteUserModal>
                                </Paper>
                                <Divider />
                                {steps[activeStep].component}
                            </Paper>
                            <Divider />
                            <Paper
                                sx={{
                                    position: 'absolute' as 'absolute',
                                    top: '100vh',
                                    transform: 'translateY(-100%)',
                                    width: '100%',
                                    zIndex: 1000
                                }}
                            >
                                <MobileStepper
                                    variant="dots"
                                    steps={2}
                                    position="static"
                                    activeStep={activeStep}
                                    sx={{
                                        maxWidth: '100%',
                                        flexGrow: 1,
                                        paddingTop: 2,
                                        paddingBottom: 4,
                                        paddingLeft: 3,
                                        paddingRight: 3,
                                        backgroundColor: 'inherit',
                                    }}
                                    nextButton={
                                        activeStep < 1 ? (
                                            <Button size="medium" variant="contained" onClick={handleNext} disabled={firstNameError.length>0 || lastNameError.length>0 || helperLoginText.length>0 }>
                                                {t('btn_next')}
                                            </Button>
                                        ) : (
                                            <Button size="medium" variant="contained" onClick={handleSave}>
                                               {t('btn_save')}
                                            </Button>
                                        )
                                    }
                                    backButton={
                                        activeStep < 1 ? (
                                            <Button size="small" variant="outlined" onClick={handleClose}>
                                               {t('btn_cancel')}
                                            </Button>
                                        ) : (
                                            <Button size="small" variant="outlined" onClick={handleBack}>
                                                {t('btn_back')}
                                            </Button>
                                        )
                                    }
                                />
                            </Paper>
                        </Box>
                    </Modal>
                    <Snackbar
                        open={snackOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackClose}
                        message={message}
                        action={action}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    />
                </>
            ) : (
                <>
                    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box sx={style}>
                            <Paper
                                elevation={0}
                                sx={{ paddingTop: 2, paddingBottom: 3, paddingLeft: 3, paddingRight: 2 }}
                            >
                                <Typography variant="h6">{t('lbl_editUsr')}</Typography>
                            </Paper>
                            <Divider />
                            <Paper elevation={0} sx={{}}>
                                {steps[activeStep].component}
                            </Paper>
                            <Divider />
                            <MobileStepper
                                variant="dots"
                                steps={2}
                                position="static"
                                activeStep={activeStep}
                                sx={{
                                    maxWidth: 500,
                                    flexGrow: 1,
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    paddingLeft: 3,
                                    paddingRight: 3,
                                    backgroundColor: 'inherit',
                                }}
                                nextButton={
                                    activeStep < 1 ? (
                                        <Button size="medium" variant="contained" onClick={handleNext} disabled={firstNameError.length>0 || lastNameError.length>0 || helperLoginText.length>0}>
                                            {t('btn_next')}
                                        </Button>
                                    ) : (
                                        <Button size="medium" variant="contained" onClick={handleSave}>
                                             {t('btn_save')}
                                        </Button>
                                    )
                                }
                                backButton={
                                    activeStep < 1 ? (
                                        <Button size="small" variant="outlined" onClick={handleClose}>
                                             {t('btn_cancel')}
                                        </Button>
                                    ) : (
                                        <Button size="small" variant="outlined" onClick={handleBack}>
                                            {t('btn_back')}
                                        </Button>
                                    )
                                }
                            />
                        </Box>
                    </Modal>
                    <Snackbar
                        open={snackOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackClose}
                        message={message}
                        action={action}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    />
                </>
            )}
        </div>
    );
}
