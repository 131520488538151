/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from 'react-redux';
import { Box, Button, Checkbox, Divider, IconButton, Snackbar, Stack, Switch, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { fetchInstallerEmailAlerts, fetchInstallerEmailAlertsArray, fetchPanelARCSettings, fetchSpecificPanelAndEstate, fetchUserEmailAlerts } from '../../redux/actions/specificPanelActions';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { Maintenance } from '@brightlayer-ui/icons-mui';
import { Report } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { fetchARDscheduler, getRemoteServicing, setRemoteServicingEnabled } from '../../redux/actions/remoteServicingActions';
import PeopleIcon from '@mui/icons-material/People';
import { InfoListItem } from '@brightlayer-ui/react-components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { getCurrentUserDetails } from '../../redux/actions/accountUsersActions';
import RemoteServiceModal from '../RemoteServicingModal/RemoteServicingModal';
import ARCAccessDisableModal from '../ARCAccessModal/ARCAccessDisableModal';
import ARCAccessModal from '../ARCAccessModal/ARCEditModal';
import AlertsDisableModal from '../AlertsAccessModal/AlertsDisableModal';
import AlertsAccessModal from '../AlertsAccessModal/AlertsAccessModal';
import UserAccessModal from '../UserAccessModal/UserAccessModal';


const PanelAccess = (): JSX.Element => {
    const { specificPanel, panelARCSettings, installerEmailAlertsStatusValue, userEmailAlerts, installerEmailAlertsDisableStatus } = useSelector((state: any) => state.specificPanelAndEstateReducer);
    const { enabled, performRemoteServicingStatus, schedulerStatus } = useSelector((state: any) => state.remoteServicingReducer)
    const navigate = useNavigate();
    const dispatch = useTypedDispatch();
    const { id } = useParams();
    const { t } = useTranslation();
    const { currentUserDetails } = useSelector((state: any) => state.accountUsersReducer)
    const { userAccessStatus } = useSelector((state: any) => state.specificPanelAndEstateReducer);
    const [modalCancelClick, setModalCancelClick] = React.useState(0);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [snackOpenForEmail, setSnackOpenForEmail] = React.useState(false);
    const [remoteServicingOpen, setRemoteServicingOpen] = React.useState<boolean>(false)
    const [remoteServicingModalOpen, setRemoteServicingModalOpen] = React.useState(false);
    const [typeOfARDModal, setTypeOfARDModal] = React.useState('')
    const [enableMsg, setEnableMsg] = React.useState(false)
    const [count, setCount] = React.useState<number>(0)
    const [alertsSnackOpen, setAlertsSnackOpen] = React.useState(false);
    const [alertsmessage, setAlertsMessage] = React.useState('');
    const [openModal, setOpenModal] = React.useState(false);
    const [isARCEdit, setIsARCEdit] = React.useState<boolean>(false)
    const [refreshArcModal, setRefreshArcModal] = React.useState<boolean>(false)
    const [openArcDisableModal, setOpenArcDisableModal] = React.useState(false);
    const [arcModalEnableTrigger, setArcModalEnableTrigger] = React.useState(false);
    const [installerEmailAlertsStatus, setInstallerEmailAlertsStatus] = React.useState(false);
    const [alertsModalOpen, setAlertsModalOpen] = React.useState(false);
    const [isInstallerEmailAlertsEdit, setIsInstallerEmailAlertsEdit] = React.useState<boolean>(false)
    const [alertsDisableModalOpen, setAlertsDisableModalOpen] = React.useState(false);
    const [checked, setChecked] = React.useState(false);
    const [flagForInstallerEmailAlertsDisable, setFlagForInstallerEmailAlertsDisable] = React.useState(false);
    const [status, setStatus] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [openRemoteMsg, setOpenRemoteMsg] = React.useState<boolean>(false)


    const fetching = async () => {
        await dispatch(getCurrentUserDetails())
        await dispatch(fetchSpecificPanelAndEstate(Number(id)));
        // await dispatch(fetchUserEmailAlerts(specificPanel?.panelId))
        await dispatch(fetchInstallerEmailAlerts(specificPanel?.panelId))
        await dispatch(getRemoteServicing(specificPanel?.panelId))
    }
    useEffect(() => {

        fetching();
        setRemoteServicingOpen(enabled?.enabled)
        setChecked(specificPanel?.userAccessEnabled);
        setArcModalEnableTrigger(false);
        setInstallerEmailAlertsStatus(installerEmailAlertsStatusValue);

        if (installerEmailAlertsDisableStatus !== 0 && flagForInstallerEmailAlertsDisable) {
            setAlertsSnackOpen(true)
            if (installerEmailAlertsDisableStatus === 200) {
                setAlertsMessage(`${t('msg_emailAlert_disabled')}`)
            } else if (installerEmailAlertsDisableStatus === 404) {
                setAlertsMessage(`${t('lbl_somethingWrong')}`)
            }
            setFlagForInstallerEmailAlertsDisable(false)
        }

        if (count !== 0) {
            setAlertsSnackOpen(true)
            if (typeOfARDModal === 'enable') {
                if (performRemoteServicingStatus === 200) {
                    if (enableMsg) {
                        setAlertsMessage(`Remote servicing enabled`)
                    }
                    else {
                        setAlertsMessage(`Remote servicing disabled`)
                    }
                }
                else if (performRemoteServicingStatus === 400 || performRemoteServicingStatus === 401) {
                    setAlertsMessage(`Unauthorized user`)
                }
                else if (performRemoteServicingStatus === 404) {
                    setAlertsMessage(`Panel software does not support remote servicing`)
                }
                else {
                    setAlertsMessage(`Something went wrong`)
                }
            }

            else {
                if (schedulerStatus === 200) {
                    setAlertsMessage(`Scheduled successfully`);
                } else if (schedulerStatus === 500) {
                    setAlertsMessage(`${t('msg_internal_error')}`);
                } else if (schedulerStatus === 400 || schedulerStatus === 404) {
                    setAlertsMessage(`${t('lbl_unauthorizedUser')}`);
                } else {
                    setAlertsMessage(`${t('lbl_someError')}`);
                }
            }
            setCount(0)
        }


    }, [count, modalCancelClick, installerEmailAlertsStatusValue, installerEmailAlertsDisableStatus, flagForInstallerEmailAlertsDisable, openModal === false, enabled?.enabled]);

    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
        setSnackOpenForEmail(false)
    };

    const handleRemoteServicing = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setRemoteServicingModalOpen(true)
            setTypeOfARDModal('enable')
            setEnableMsg(true)
        }
        else {
            await dispatch(setRemoteServicingEnabled(specificPanel?.panelId, { enabled: false }))
            setTypeOfARDModal('enable')
            setEnableMsg(false)
            setCount((prev) => prev + 1)
        }
    }

    const handleRemoteServicingEdit = async () => {
        setRemoteServicingModalOpen(true)
        await dispatch(fetchARDscheduler(specificPanel?.panelId))
        setTypeOfARDModal('edit')
    }

    const handleDisableInstallerEmailAlertSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertsMessage('')
        setAlertsSnackOpen(false);
    };

    const handleOpen = (): void => {
        setOpenModal(true);
        setIsARCEdit(true)
    };

    const handleArcAccessChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setOpenModal(true)
            setIsARCEdit(false)
            setRefreshArcModal(true)
        }
        else {
            setOpenArcDisableModal(true)
        }
    };


    const handleAlertsDisableModal = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setAlertsModalOpen(true);
            setIsInstallerEmailAlertsEdit(false);
        }
        else
            setAlertsDisableModalOpen(true);
    };

    const handleAlertModal = () => {
        setAlertsModalOpen(true);
        setIsInstallerEmailAlertsEdit(true);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        setStatus(event.target.checked);
        setOpenRemoteMsg(false)
        setOpen(true);
    };

    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleDisableInstallerEmailAlertSnackClose}>
                {t('lbl_close')}
            </Button>
        </React.Fragment>
    );

    return (
        <>

            <TableContainer>
                <Table>
                    <TableBody>
                        {(currentUserDetails?.userType === 'INSTALLER' ||
                            currentUserDetails?.userType === 'MANAGER' ||
                            currentUserDetails?.userType === 'ENGINEER' ||
                            currentUserDetails?.userType === 'ADMIN') && (
                                <>
                                    <TableRow>
                                        <TableCell variant="head" style={{ color: '#1397D9' }}>
                                            {t('lbl_panelAccess')}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <Box>
                                            <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}

                                                title={<strong style={{ marginLeft: 8 }}>{t('tooltip_ARC_Access')}</strong>}
                                                icon={<Report color="action" />}
                                                hidePadding
                                                subtitle={[<Typography key={0} sx={{ pl: 1 }}>
                                                    {specificPanel?.arcAccessEnabled === true ? (
                                                        <>
                                                            <Stack alignItems="center" direction="row" gap={2} sx={{ mt: 1 }}>
                                                                <CheckCircleIcon fontSize="small" sx={{ color: "#178E0B" }} />
                                                                <Typography variant="body1">{t('lbl_enabled')}</Typography>
                                                            </Stack>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Stack alignItems="center" direction="row" gap={2} sx={{ mt: 1 }}>
                                                                <CancelIcon fontSize="small" sx={{ color: "#CA3C3D" }} />
                                                                <Typography variant="body1">{t('lbl_disabled')}</Typography>
                                                            </Stack>
                                                        </>

                                                    )}
                                                </Typography>]}
                                                rightComponent={
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            gap: '10px'
                                                        }}
                                                    >

                                                        <IconButton style={{ paddingTop: 9 }}
                                                            disabled={
                                                                (specificPanel?.status === 'CONNECTED'
                                                                    ? false
                                                                    : true) ||
                                                                (specificPanel?.arcAccessEnabled === true
                                                                    ? false
                                                                    : true)
                                                            }
                                                            onClick={handleOpen}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>

                                                        <Switch
                                                            disabled={specificPanel.status === 'DISCONNECTED'}
                                                            onChange={handleArcAccessChange}
                                                            checked={specificPanel?.arcAccessEnabled}
                                                        />

                                                    </div>
                                                }
                                            />
                                            <ARCAccessDisableModal
                                                open={openArcDisableModal}
                                                setOpen={setOpenArcDisableModal}
                                            />
                                            <ARCAccessModal
                                                refreshArcModal={refreshArcModal}
                                                setRefreshArcModal={setRefreshArcModal}
                                                open={openModal}
                                                setOpen={setOpenModal}
                                                setArcModalEnableTrigger={setArcModalEnableTrigger}
                                                isARCEdit={isARCEdit}
                                                setIsARCEdit={setIsARCEdit}
                                            />

                                        </Box>
                                    </TableRow>
                                </>
                            )}

                        <Divider />
                        <TableRow>
                            <Box>
                                <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                    title={<strong style={{ marginLeft: 8 }}>{t('tooltip_remoteServicing')}</strong>}
                                    icon={<Maintenance color="action" />}
                                    hidePadding
                                    subtitle={[<Typography key={0} sx={{ pl: 1 }}>
                                        {enabled?.enabled !== undefined && enabled?.enabled === true ? (
                                            <>
                                                <Stack alignItems="center" direction="row" gap={2} sx={{ mt: 1 }}>
                                                    <CheckCircleIcon fontSize="small" sx={{ color: "#178E0B" }} />
                                                    <Typography variant="body1">{t('lbl_enabled')}</Typography>
                                                </Stack>
                                            </>
                                        ) : (
                                            <>
                                                <Stack alignItems="center" direction="row" gap={2} sx={{ mt: 1 }}>
                                                    <CancelIcon fontSize="small" sx={{ color: "#CA3C3D" }} />
                                                    <Typography variant="body1">{t('lbl_disabled')}</Typography>
                                                </Stack>
                                            </>

                                        )}
                                    </Typography>]}
                                    rightComponent={
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                gap: '10px'
                                            }}
                                        >

                                            <IconButton style={{ paddingTop: 9 }}
                                                onClick={handleRemoteServicingEdit}
                                                disabled={enabled.enabled === false || specificPanel?.status === 'DISCONNECTED'}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <Switch
                                                disabled={specificPanel?.status === 'DISCONNECTED'}
                                                onChange={handleRemoteServicing}
                                                checked={remoteServicingOpen}
                                            />
                                        </div>
                                    }
                                />
                                <RemoteServiceModal
                                    type={typeOfARDModal}
                                    counter={count}
                                    setCounter={setCount}
                                    open={remoteServicingModalOpen}
                                    setOpen={setRemoteServicingModalOpen}
                                />
                            </Box>
                        </TableRow>
                        <Divider />
                        <TableRow>
                            {(currentUserDetails?.userType === 'INSTALLER' ||
                                currentUserDetails?.userType === 'MANAGER' ||
                                currentUserDetails?.userType === 'ADMIN') && (
                                    <Box>
                                        <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                            title={<strong style={{ marginLeft: 8 }}>{t('tooltip_installerEmail_alerts')}</strong>}
                                            icon={<NotificationsActiveIcon color="action" />}
                                            hidePadding
                                            subtitle={[<Typography key={0} sx={{ pl: 1 }}>
                                                {installerEmailAlertsStatus ? (
                                                    <>
                                                        <Stack alignItems="center" direction="row" gap={2} sx={{ mt: 1 }}>
                                                            <CheckCircleIcon fontSize="small" sx={{ color: "#178E0B" }} />
                                                            <Typography variant="body1">{t('lbl_enabled')}</Typography>
                                                        </Stack>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Stack alignItems="center" direction="row" gap={2} sx={{ mt: 1 }}>
                                                            <CancelIcon fontSize="small" sx={{ color: "#CA3C3D" }} />
                                                            <Typography variant="body1">{t('lbl_disabled')}</Typography>
                                                        </Stack>
                                                    </>

                                                )}
                                            </Typography>]}
                                            rightComponent={
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        gap: '10px'
                                                    }}
                                                >

                                                    <IconButton style={{ paddingTop: 9 }}
                                                        disabled={!installerEmailAlertsStatus}
                                                        onClick={handleAlertModal}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <Switch
                                                        checked={installerEmailAlertsStatus}
                                                        onChange={handleAlertsDisableModal}
                                                    />
                                                </div>
                                            }
                                        />
                                        <AlertsAccessModal
                                            setInstallerEmailAlertsStatus={setInstallerEmailAlertsStatus}
                                            installerEmailAlertsStatus={installerEmailAlertsStatus}
                                            panelId={specificPanel?.panelId}
                                            open={alertsModalOpen}
                                            setOpen={setAlertsModalOpen}
                                            isEdit={isInstallerEmailAlertsEdit}
                                            setIsEdit={setIsInstallerEmailAlertsEdit}
                                        />
                                        {alertsDisableModalOpen && (
                                            <AlertsDisableModal
                                                flagForInstallerEmailAlertsDisable={flagForInstallerEmailAlertsDisable}
                                                setFlagForInstallerEmailAlertsDisable={setFlagForInstallerEmailAlertsDisable}
                                                installerEmailAlertsStatus={installerEmailAlertsStatus}
                                                setInstallerEmailAlertsStatus={setInstallerEmailAlertsStatus}
                                                panelId={specificPanel?.panelId}
                                                open={alertsDisableModalOpen}
                                                setOpen={setAlertsDisableModalOpen}
                                            />
                                        )}
                                    </Box>
                                )}

                        </TableRow>
                        <Divider />


                        <TableRow>
                            {(currentUserDetails?.userType === 'INSTALLER' ||
                                currentUserDetails?.userType === 'MANAGER' ||
                                currentUserDetails?.userType === 'ENGINEER' ||
                                currentUserDetails?.userType === 'ADMIN') && (<Box>
                                    <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                        title={<strong style={{ marginLeft: 8 }}>{t('tooltip_usrAccess')}</strong>}
                                        icon={<PeopleIcon color="action" />}

                                        subtitle={[<Typography key={0} sx={{ pl: 1 }}>
                                            {specificPanel?.userAccessEnabled === true ? (
                                                <>
                                                    <Stack alignItems="center" direction="row" gap={2} sx={{ mt: 1 }}>
                                                        <CheckCircleIcon fontSize="small" sx={{ color: "#178E0B" }} />
                                                        <Typography variant="body1">{t('lbl_enabled')}</Typography>
                                                    </Stack>
                                                </>
                                            ) : (
                                                <>
                                                    <Stack alignItems="center" direction="row" gap={2} sx={{ mt: 1 }}>
                                                        <CancelIcon fontSize="small" sx={{ color: "#CA3C3D" }} />
                                                        <Typography variant="body1">{t('lbl_disabled')}</Typography>
                                                    </Stack>
                                                </>

                                            )}
                                        </Typography>]}
                                        rightComponent={
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                    }}>
                                                    <Switch
                                                        checked={checked}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    />
                                    <UserAccessModal
                                        modalCancelClick={modalCancelClick}
                                        setModalCancelClick={setModalCancelClick}
                                        open={open}
                                        setOpen={setOpen}
                                        status={status}
                                        setStatus={setStatus}
                                        panelId={id}
                                    />
                                </Box>
                                )}

                        </TableRow>
                        <Snackbar
                            open={alertsSnackOpen}
                            autoHideDuration={6000}
                            onClose={handleDisableInstallerEmailAlertSnackClose}
                            message={alertsmessage}
                            action={action}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        />
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default PanelAccess;
