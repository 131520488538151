/* eslint-disable @typescript-eslint/naming-convention */
const resources = {
    "menu_oveview": "Overzicht",
    "menu_endUserInfo": "Eindgebruiker Info",
    "menu_accntUsrs": "Accountgebruikers",
    "menu_serviceReports": "Servicerapporten",
    "menu_settings": "Instellingen",
    "menu_help": "Informatie",
    "lbl_cloudId": "Cloud-ID",
    "lbl_logOut": "Uitloggen",
    "lbl_contactSupport": "CONTACT OPNEMEN MET ONDERSTEUNING",
    "lbl_estates": "Landgoederen",
    "lbl_ESTATES": "VASTGOED",
    "lbl_panels": "Panelen",
    "lbl_PANELS": "PANELEN",
    "lbl_connected": "Verbonden",
    "lbl_disconnected": "Losgekoppeld",
    "lbl_manageEstates": "Landgoederen beheren",
    "lbl_newEstate": "Nieuw Landgoed",
    "lbl_estate": "Landgoed",
    "lbl_search": "Zoekopdracht…",
    "lbl_manage_estateAndpanels": "Landgoederen en panelen beheren",
    "btn_cancel": "Annuleren",
    "btn_save": "Redden",
    "msg_panels_moved": "Panelen zijn met succes verplaatst",
    "lbl_name": "Naam",
    "lbl_description": "Beschrijving",
    "btn_next": "Volgende",
    "lbl_contact_name": "contactnaam",
    "lbl_contact_phNo": "Contact telefoonnummer",
    "lbl_country": "Land",
    "lbl_address": "Adres",
    "lbl_postcode": "Postcode",
    "lbl_selectedPanels": "geselecteerde panelen",
    "lbl_close": "Dichtbij",
    "msg_newEstate_created": "Nieuw landgoed succesvol aangemaakt",
    "msg_estateExists": "Landgoed met deze naam bestaat al",
    "tooltip_ARC_Access": "ARC-toegang",
    "tooltip_usrEmail_alerts": "E-mailwaarschuwingen van gebruikers",
    "tooltip_usrAccess": "Gebruikerstoegang",
    "tooltip_installerEmail_alerts": "E-mailwaarschuwingen voor installateurs",
    "tooltip_remoteServicing": "Onderhoud op afstand",
    "lbl_allPanel": "Alle panelen",
    "lbl_favorites": "Favorieten",
    "lbl_ARC_enabled": "ARC ingeschakeld",
    "lbl_end_usrEmail_enabled": "E-mailadres van eindgebruiker ingeschakeld",
    "lbl_end_usrAccess_disabled": "Toegang voor eindgebruikers uitgeschakeld",
    "lbl_installer_emailAlerts_enabled": "E-mailwaarschuwingen voor installateurs ingeschakeld",
    "lbl_remoteServicing_enabled": "Onderhoud op afstand ingeschakeld",
    "msg_no_panels_found": "Geen panelen beschikbaar voor bovenstaande filters",
    "lbl_move_panel": "Paneel verplaatsen",
    "lbl_delete_panel": "Paneel verwijderen",
    "lbl_delete": "Verwijderen",
    "lbl_delete_estate": "Landgoed verwijderen",
    "msg_delete_estate": "Weet u zeker dat u de nalatenschap wilt verwijderen",
    "msg_no_action_revert": "Deze actie kan niet ongedaan worden gemaakt.",
    "msg_estate_deleted": "Landgoed succesvol verwijderd",
    "msg_deleteEstate_error": "Fout bij het verwijderen van nalatenschap",
    "msg_noSearch_results": "Geen overeenkomende resultaten",
    "msg_deletePanel": "Weet u zeker dat u het paneel wilt verwijderen",
    "msg_deletePanel_error": "Fout bij het verwijderen van paneel",
    "msg_panelDeleted": "Paneel succesvol verwijderd",
    "lbl_viewEstate": "Landgoed bekijken",
    "lbl_goToPanel": "Ga naar paneel",
    "lbl_subEstate": "Ondergrond",
    "msg_noEstatesConfigured": "Geen landgoederen geconfigureerd",
    "msg_noPanelsConnected": "Geen panelen aangesloten",
    "tab_notifications": "PANEELGESCHIEDENIS",
    "tab_overview": "OVERZICHT",
    "tab_panelLog": "PANEEL LOGBOEK",
    "tab_ARC_reporting": "ARC-RAPPORTAGE",
    "tab_connect": "AANSLUITEN",
    "tab_keypad": "TOETSENBORD",
    "tab_remoteServicing": "ONDERHOUD OP AFSTAND",
    "tab_mobileApp_usrs": "GEBRUIKERS VAN MOBIELE APP",
    "lbl_mobAppUsrs": "Gebruikers van mobiele applicaties",
    "lbl_summary": "Samenvatting",
    "lbl_status": "Toestand",
    "lbl_panelId": "Paneel-ID",
    "lbl_macAddress": "Mac adres",
    "lbl_firmware": "Firmware",
    "msg_panelIdCopied": "PanelID gekopieerd naar klembord",
    "lbl_panelAccess": "Paneel Toegang",
    "lbl_enabled": "Ingeschakeld",
    "lbl_disabled": "Gehandicapt",
    "lbl_connectionStats": "Verbindingsstatistieken",
    "lbl_totalTime": "Totale tijd (uren)",
    "lbl_connectedTime": "Verbonden(uren)",
    "lbl_disconnectedTime": "Losgekoppeld (uur)",
    "lbl_connectedPercent": "Verbonden(%)",
    "lbl_siteDetails": "Sitegegevens",
    "lbl_cloudReference": "Cloud-referentie",
    "lbl_siteAddress": "Site-adres",
    "lbl_optional": "Optioneel",
    "lbl_somethingWrong": "Er is iets fout gegaan",
    "msg_siteDetails_edited": "Sitedetails zijn succesvol bewerkt",
    "lbl_ARCProvider": "ARC-aanbieder",
    "lbl_ARCName": "ARC-naam",
    "lbl_accntNo": "Rekeningnummer",
    "lbl_IP_version": "SIA-IP-versie",
    "lbl_primaryIP": "Primair IP",
    "lbl_secondaryIP": "Secundaire IP",
    "lbl_primaryPort": "Primaire poort",
    "lbl_secondaryPort": "Secundaire poort",
    "lbl_encryption": "Crittografia",
    "option_no_encryption": "GEEN ENCRYPTIE",
    "lbl_report_email": "E-mail rapporteren",
    "msg_ARC_edited": "ARC-toegang is succesvol bewerkt",
    "msg_ARC_enabled": "ARC-toegang ingeschakeld",
    "msg_ARC_updated": "ARC-toegang ingeschakeld en bijgewerkt",
    "btn_back": "Indietro",
    "msg_ARC_disabled": "ARC-toegang uitgeschakeld",
    "msg_error": "Fout",
    "lbl_dateOfService": "Datum volgende onderhoudsbeurt",
    "lbl_serviceFrquency": "Service frequentie",
    "option_svc_frqncy_1": "Maandelijks",
    "option_svc_frqncy_2": "6 Maandelijks",
    "msg_remote_srvcng_enable": "Onderhoud op afstand ingeschakeld",
    "msg_remote_srvcng_disabled": "Onderhoud op afstand uitgeschakeld",
    "msg_email_alert_enabled": "E-mailwaarschuwing installatieprogramma succesvol ingeschakeld",
    "msg_email_alert_edited": "E-mailwaarschuwing van installateur succesvol bewerkt",
    "lbl_email_alert_disable": "Schakel e-mailwaarschuwingen van het installatieprogramma uit",
    "msg_email_alert_disable": "Weet u zeker dat u e-mailwaarschuwingen voor installateurs wilt uitschakelen?",
    "btn_disable": "Uitzetten",
    "msg_emailAlert_disabled": "E-mailwaarschuwing installatieprogramma uitgeschakeld",
    "lbl_enable_usrAccess": "Gebruikerstoegang inschakelen",
    "lbl_disable_usrAccess": "Gebruikerstoegang uitschakelen",
    "msg_enable_usrAccess": "Weet u zeker dat u gebruikerstoegang wilt inschakelen?",
    "btn_enable": "Inschakelen",
    "msg_usrAccess_enabled": "Gebruikerstoegang ingeschakeld",
    "msg_usrAccess_disabled": "Gebruikerstoegang uitgeschakeld",
    "msg_usrAccess_error": "Fout bij het in-/uitschakelen van gebruikerstoegang",
    "btn_addUsr": "Voeg gebruiker toe",
    "lbl_addUsr": "voeg gebruikers toe zodat ze op de hoogte worden gehouden van gebeurtenissen voor dit paneel",
    "lbl_events": "Evenementen",
    "lbl_sets": "sets",
    "lbl_alarms": "Alarmen",
    "lbl_tampers": "Sabotage",
    "lbl_system": "Systeem",
    "msg_emailAlert_added": "E-mailwaarschuwingen van gebruikers zijn succesvol toegevoegd.",
    "lbl_edit_emailAlerts": "E-mailwaarschuwingen van gebruikers bewerken",
    "msg_usrEmail_edited": "E-mailadres van gebruiker is succesvol bewerkt",
    "lbl_delete_usrEmail": "E-mailwaarschuwingen van gebruikers verwijderen",
    "msg_delete_usrEmail": "Weet u zeker dat u e-mailwaarschuwingen van gebruikers wilt verwijderen?",
    "msg_usr_emailDeleted": "E-mailwaarschuwing van gebruiker succesvol verwijderd",
    "tootltip_exportNotification": "Meldingen exporteren",
    "lbl_delivered": "AFGELEVERD",
    "lbl_failed": "MISLUKT",
    "lbl_information": "Informatie",
    "lbl_evntDetails": "Evenement Details",
    "lbl_systmName": "Systeem naam",
    "lbl_Id": "ID kaart",
    "lbl_partition": "Partitie",
    "lbl_user": "Utente",
    "lbl_username": "Gebruikersnaam",
    "lbl_text": "Tekst",
    "lbl_verbose": "Uitgebreid",
    "lbl_timestamp": "Tijdstempel",
    "lbl_code": "Code",
    "lbl_group": "Groep",
    "lbl_SIA_IP": "SIA IP DIRECT",
    "lbl_CLS_Dualcom": "CSL DualCom",
    "lbl_webway": "WebWayOne",
    "lbl_noResponse": "GEEN ANTWOORD",
    "lbl_rejected": "AFGEWEZEN",
    "lbl_readyForDelivering": "KLAAR VOOR LEVERING",
    "lbl_sent": "VERSTUURD",
    "lbl_exportLogs": "Logboeken exporteren",
    "lbl_failedSvc": "Mislukte diensten",
    "lbl_ScheduledSvc": "Geplande diensten",
    "lbl_pendingSvc": "Geen geplande of mislukte services",
    "lbl_cmpltd_svcReports": "Voltooide servicerapporten",
    "lbl_report_no": "Rapporteer nummer",
    "lbl_cmpltd_date": "Voltooide datum",
    "lbl_cmpltd_Classification": "Classificatie",
    "lbl_cmpltd_View": "Weergave",
    "lbl_service_report": "Servicerapport",
    "lbl_delete_svcReport": "Onderhoudsrapport verwijderen",
    "msg_delete_svcReport": "Weet u zeker dat u het servicerapport wilt verwijderen?",
    "msg_svcReport_deleted": "Servicerapport succesvol verwijderd",
    "btn_perform_remoteSvc": "Voer service op afstand uit",
    "msg_remoteSvc_initiated": "Service op afstand gestart",
    "msg_remoteSvc_inProgress": "de dienst is bezig",
    "lbl_errorOccurred": "Fout opgetreden",
    "lbl_noCmpltd_svc": "Geen voltooide services",
    "lbl_noCmpltd_svcReports": "Geen voltooide onderhoudsrapporten",
    "lbl_mobApp_usrs": "Gebruikers van mobiele apps",
    "msg_usr_authorized": "Gebruiker niet geautoriseerd",
    "msg_internal_error": "Interne Server Fout",
    "msg_try_later_error": "Er is iets fout gegaan. Probeer het opnieuw.",
    "lbl_info_diplayed": "Informatie weergegeven in mobiele app",
    "lbl_compnyName": "Bedrijfsnaam",
    "lbl_officeNo": "Telefoonnummer kantoor",
    "lbl_outOfHrs": "Telefoonnummer buiten kantooruren",
    "lbl_contactEmail": "Contact email",
    "lbl_logo": "Logo",
    "btn_uploadLogo": "Logo uploaden",
    "btn_dragLogo": "of sleep het logo hierheen",
    "msg_endUsrInfo_edited": "Eindgebruikergegevens succesvol bewerkt",
    "btn_remove": "Verwijderen",
    "lbl_userType": "Gebruikerstype",
    "lbl_login": "Log in",
    "lbl_email_notification": "E-mail notificatie",
    "lbl_delete_accntUsr": "Accountgebruiker verwijderen",
    "msg_deleteUsr": "Weet u zeker dat u de gebruiker wilt verwijderen?",
    "msg_deleteUsr_error": "Fout bij het verwijderen van accountgebruiker",
    "msg_accntUsr_deleted": "Accountgebruiker succesvol verwijderd",
    "lbl_firstName": "Voornaam",
    "lbl_lastName": "Achternaam",
    "lbl_typeUsr": "Typ Gebruiker",
    "lbl_emailNotifications": "E-mail notificaties",
    "lbl_userPemissions": "Gebruikersmachtigingen",
    "msg_userEdited": "Gebruiker succesvol bewerkt",
    "lbl_unauthorizedUser": "Onbevoegde gebruiker",
    "lbl_someError": "Er is een fout opgetreden",
    "lbl_pwd": "Wachtwoord",
    "lbl_repeat_pwd": "Herhaal wachtwoord",
    "lbl_engineer": "Ingenieur",
    "lbl_manager": "Manager",
    "lbl_installer": "Installateur",
    "lbl_officeStaff": "Kantoorpersoneel",
    "msg_noMatch_pwd": "Ongeëvenaarde wachtwoorden!",
    "msg_usr_added": "Gebruiker succesvol toegevoegd",
    "lbl_panel": "Paneel",
    "lbl_accnt_holder": "Rekeninghouder",
    "lbl_spoken_pwd": "Gesproken wachtwoord",
    "lbl_language": "Taal",
    "lbl_cmpnyInfo": "bedrijfsinformatie",
    "lbl_firstPh": "Eerste telefoon",
    "lbl_secondPh": "Tweede telefoon",
    "lbl_thirdPh": "Derde telefoon",
    "lbl_oldPwd": "Oud Wachtwoord",
    "lbl_newPwd": "nieuw paswoord",
    "lbl_repeatPwd": "Herhaal wachtwoord",
    "msg_accntHolder_edited": "Accounthouder succesvol bewerkt",
    "lbl_firstPhType": "Eerste telefoontype",
    "lbl_firstPhone": "Eerste telefoonnummer",
    "lbl_secondPhone": "Tweede telefoonnummer",
    "lbl_thirdPhone": "Derde telefoonnummer",
    "lbl_secondPhType": "Tweede telefoontype",
    "lbl_thirdPhType": "Derde telefoontype",
    "lbl_home": "Thuis",
    "lbl_mobile": "Mobiel",
    "lbl_office": "Kantoor",
    "msg_cmpnyInfo_edited": "Bedrijfsinformatie succesvol bewerkt",
    "lbl_termsConditions": "Voorwaarden",
    "lbl_privacyPolicy": "Privacybeleid",
    "msg_email_invalid": "Email is ongeldig",
    "msg_unmatchedPwd": "Ongeëvenaarde wachtwoorden!",
    "lbl_installerGrp": "Installateur Groep",
    "msg_usrExist": "Gebruiker met login bestaat al",
    "lbl_editUsr": "bewerk gebruiker",
    "msg_field_required": "dit veld is verplicht",
    "lbl_disable_ARC": "ARC-toegang uitschakelen",
    "msg_disable_ARC": "Weet u zeker dat u ARC-toegang wilt uitschakelen?",
    "lbl_SiaEvnts": "Sia-evenementen",
    "lbl_SIAEvnt": "SIA-evenement",
    "msg_prohibited_chars": "invoer bevat verboden tekens",
    "msg_inputLength": "invoerlengte mag niet meer dan 30 tekens zijn",
    "lbl_favPanels": "FAVORIETE PANELEN",
    "lbl_lastConnection": "Laatste verbinding",
    "lbl_androidPh": "Android telefoon",
    "lbl_iPhone": "Mijn iPhone",
    "lbl_alexa": "Alexa",
    "lbl_gHome": "Google huis",
    "lbl_removePairing": "Koppeling verwijderen",
    "msg_removePairing": "Weet je zeker dat je de koppeling voor deze app-gebruiker wilt verwijderen?",
    "lbl_no": "Nee",
    "lbl_yes": "Ja",
    "lbl_device": "Apparaat",
    "lbl_appDetails": "Toepassingsdetails",
    "lbl_myAndroid": "Mijn Android",
    "lbl_myAlexa": "Mijn Alexa",
    "lbl_myiPhone": "Mijn iPhone",
    "lbl_mygHome": "Mijn Google Home",
    "lbl_appID": "Applicatie ID",
    "lbl_mobOS": "Mobiel besturingssysteem",
    "lbl_token": "Token",
    "msg_didNotMovePanl": "Geen enkel paneel verplaatst",
    "lbl_panlsSelected": "Panelen geselecteerd",
    "lbl_panlSelected": "Paneel geselecteerd",
    "lbl_ARCCode": "ARC-code",
    "lbl_response": "Antwoord",
    "lbl_data": "Gegevens",
    "lbl_state": "Staat",
    "lbl_signal_Timeout": "Signaal time-out",
    "lbl_sysID": "Systeem-ID",
    "lbl_date": "Datum",
    "lbl_remove_destination": "E-mailbestemmingen voor onderhoud op afstand",
    "lbl_emailAddress": "E-mailadres",
    "Reports": "rapporten",
    "lbl_green": "GROENTE",
    "lbl_amber": "AMBER",
    "lbl_red": "ROOD",
    "lbl_reportDetails": "Rapporteer details",
    "lbl_dueDate": "Deadline",
    "btn_edit": "bewerking",
    "lbl_nameAZ": "Naam (A-Z)",
    "lbl_nameZA": "Naam (Z-A)",
    "lbl_mostAlarms": "De meeste alarmen",
    "lbl_fewerAlarms": "Minder alarmen",
    "lbl_updatedRecent": "Onlangs bijgewerkt",
    "lbl_none": "Geen",
    "msg_confirmation": "Weet je zeker dat je wil",
    "msg_enable": "inschakelen",
    "msg_disable": "uitzetten",
    "msg_edited": "succesvol bewerkt",
    "btn_addEmail": "E-mailbestemming toevoegen",
    "lbl_emailDestinationAdd": "e-mailbestemming toevoegen zodat servicerapporten op afstand via e-mail kunnen worden verzonden",
    "lbl_termsToUseWebsite": "De voorwaarden waaronder u onze website mag gebruiken",
    "lbl_termsToUseWebsite_1.1": "Deze gebruiksvoorwaarden (samen met de andere documenten waarnaar hieronder wordt verwezen) zetten de voorwaarden uiteen waaronder u onze website mag gebruiken",
    "lbl_termsToUseWebsite_1.1part": "We raden u aan een exemplaar van deze Gebruiksvoorwaarden af ​​te drukken voor toekomstig gebruik.",
    "lbl_termsToUseWebsite_1.2": "U dient deze gebruiksvoorwaarden aandachtig te lezen voordat u onze website gebruikt, aangezien u door onze website te gebruiken bevestigt dat u deze gebruiksvoorwaarden accepteert en dat u zich eraan zult houden.",
    "lbl_termsToUseWebsite_1.3": "Als u deze gebruiksvoorwaarden niet accepteert, dient u onze website onmiddellijk te verlaten",
    "lbl_termsToUseWebsite_1.4": "Het volgende beleid is ook van toepassing op uw gebruik van onze website",
    "lbl_termsToUseWebsite_1.4.1": "Ons privacy- en cookiebeleid",
    "lbl_termsToUseWebsite_1.4.1part": "Door onze website te gebruiken, stemt u ermee in dat wij alle persoonlijke gegevens verwerken die u ons verstrekt of die wij van u verzamelen als gevolg van uw gebruik van onze website. In ons privacybeleid staat wat we met die persoonsgegevens doen",
    "lbl_termsToUseWebsite_1.5": "U bent ervoor verantwoordelijk dat iedereen die onze website bezoekt via uw internetverbinding op de hoogte is van deze Gebruiksvoorwaarden en het hierboven genoemde beleid en dat zij zich hieraan houden.",
    "lbl_chngesToTerms": "Wijzigingen in deze Gebruiksvoorwaarden en ons ander beleid",
    "lbl_chngesToTerms_2.1": "We brengen van tijd tot tijd wijzigingen aan in deze Gebruiksvoorwaarden. U dient daarom deze gebruiksvoorwaarden te controleren telkens wanneer u terugkeert naar onze website om te zien of er wijzigingen zijn aangebracht, aangezien deze bindend voor u zijn.",
    "lbl_chngesToTerms_2.2": "We brengen ook van tijd tot tijd wijzigingen aan in ons privacybeleid en cookiesbeleid, dus u dient deze ook regelmatig te controleren of er wijzigingen zijn aangebracht.",
    "lbl_aboutUs": "Over ons",
    "lbl_aboutUs_3.1": "Wij, Eaton Electrical Products Ltd, beheren deze website op www.eatonsecureconnect.com. Wij zijn een bedrijf geregistreerd in Engeland en Wales onder ondernemingsnummer 01342230 en onze maatschappelijke zetel is gevestigd op 6 Jephson Court, Tancred Close, Queensway, Royal Leamington Spa, Warwickshire, CV31 3RZ.",
    "lbl_aboutUs_3.2": "U kunt contact met ons opnemen",
    "lbl_aboutUs_3.2part": "Per e-mail op",
    "lbl_restrictionsOnUseOfWebsite": "Beperkingen op uw gebruik van onze website",
    "lbl_restrictionsOnUseOfWebsite_4.1": "U mag onze website alleen gebruiken voor wettige doeleinden en in overeenstemming met alle toepasselijke wetten, inclusief maar niet beperkt tot gegevensbeschermings- en privacywetten, wetten met betrekking tot het auteursrecht op inhoud en wetten met betrekking tot ongevraagde commerciële elektronische berichten.",
    "lbl_restrictionsOnUseOfWebsite_4.2": "Bovendien moet je niet",
    "lbl_restrictionsOnUseOfWebsite_4.2.1": "onze website gebruiken om, direct of indirect, ongevraagd of ongeoorloofd reclame- of promotiemateriaal, kettingbrieven of piramidespelen te verzenden of te verzenden;",
    "lbl_restrictionsOnUseOfWebsite_4.2.2": "onze website gebruiken voor mailbombardementen of overstromingen of om opzettelijke pogingen te ondernemen om een ​​systeem te overbelasten;",
    "lbl_restrictionsOnUseOfWebsite_4.2.3": "onze website gebruiken om inhoud te plaatsen of naar inhoud te linken waarvan u weet of had moeten weten dat de eigenaar van die inhoud niet heeft ingestemd met of toestemming heeft gegeven voor dat plaatsen of linken of waar dat plaatsen of linken inbreuk maakt op de rechten van die eigenaar;",
    "lbl_restrictionsOnUseOfWebsite_4.2.4": "bewust of roekeloos onze website introduceren, of onze website gebruiken om virussen, trojaanse paarden, wormen, logische bommen, tijdbommen, keystroke loggers, spyware, malware, adware of andere materialen die kwaadaardig of schadelijk zijn te verzenden;",
    "lbl_restrictionsOnUseOfWebsite_4.2.5": "poging om ongeoorloofde toegang te krijgen tot onze website, de server, apparatuur of het netwerk waarop onze website is opgeslagen, elke server, computer of database die is verbonden met onze website of enige software;",
    "lbl_restrictionsOnUseOfWebsite_4.2.6": "onze website gebruiken om te proberen ongeoorloofde toegang te krijgen tot een andere website, internetaccount, server, computer, apparatuur, systeem, netwerk, gegevens of informatie;",
    "lbl_restrictionsOnUseOfWebsite_4.2.7": "onze website gebruiken om gegevens of verkeer op een netwerk of systeem te controleren;",
    "lbl_restrictionsOnUseOfWebsite_4.2.8": "onze website gebruiken om informatie te verzamelen of te gebruiken, inclusief maar niet beperkt tot e-mailadressen, schermnamen of andere identificatoren, door middel van bedrog (zoals phishing, internetscammen, wachtwoordroof, spidering, scraping en harvesting);",
    "lbl_restrictionsOnUseOfWebsite_4.2.9": "onze website gebruiken om software te verspreiden;",
    "lbl_restrictionsOnUseOfWebsite_4.2.10": "een denial-of-service-aanval of een gedistribueerde denial-of-service-aanval uitvoeren op onze website of onze website gebruiken om een ​​dergelijke aanval uit te voeren op een andere website;",
    "lbl_restrictionsOnUseOfWebsite_4.2.11": "gebruik onze website:",
    "lbl_restrictionsOnUseOfWebsite_4.2.11.1": "voor elk doel dat; of",
    "lbl_restrictionsOnUseOfWebsite_4.2.11.2": "het verzenden, verzenden, publiceren, uploaden, downloaden, gebruiken, opslaan, reproduceren of willens en wetens ontvangen van materiaal dat niet voldoet aan de inhoudsnormen uiteengezet in",
    "lbl_para8": "lid 8",
    "lbl_restrictionsOnUseOfWebsite_4.2.12": "onze website gebruiken om minderjarigen op welke manier dan ook te verzorgen, schade toe te brengen of hiervan te profiteren, of om te proberen dit te doen; of",
    "lbl_restrictionsOnUseOfWebsite_4.2.13": "onze website gebruiken in strijd met exportwetten, controles, voorschriften of sanctiebeleid van de Verenigde Staten of enig ander land.",
    "lbl_restrictionsOnUseOfWebsite_4.3": "Als u enige voorwaarde van deze Gebruiksvoorwaarden schendt, wordt uw recht om onze website te gebruiken onmiddellijk beëindigd. Daarnaast kunnen we dergelijke andere acties ondernemen, inclusief maar niet beperkt tot juridische stappen die we passend achten.",
    "lbl_restrictionsOnUseOfWebsite_4.4": "Als u een voorwaarde van deze Gebruiksvoorwaarden schendt, of als we een verzoek van een wetshandhavingsinstantie ontvangen om dit te doen, kunnen we uw identiteit, details van elke inbreuk en alle andere informatie die wij nodig achten, melden aan relevante wetshandhavingsinstanties.",
    "lbl_pwdNSecRequirement": "Wachtwoorden en veiligheidseisen",
    "lbl_pwdNSecRequirement_5.1": "Als u een account op onze website registreert, moet u nauwkeurige en volledige registratie-informatie verstrekken en deze informatie te allen tijde up-to-date houden.",
    "lbl_pwdNSecRequirement_5.2": "Het gebruik van bepaalde delen van deze website is beveiligd met een wachtwoord. Om het te gebruiken, hebt u een gebruikersidentificatiecode of wachtwoord nodig om toegang te krijgen. Het is uw verantwoordelijkheid om deze informatie geheim en vertrouwelijk te houden en niet aan iemand anders bekend te maken.",
    "lbl_pwdNSecRequirement_5.3": "Als u denkt dat iemand anders uw gebruikersidentificatiecode of wachtwoord kent, moet u inloggen op uw account en deze zo snel mogelijk wijzigen",
    "lbl_pwdNSecRequirement_5.4": "We behouden ons het recht voor om gebruikersidentificatiecodes of wachtwoorden op elk moment uit te schakelen als u naar onze redelijke mening niet voldoet aan een van deze Gebruiksvoorwaarden, als we van mening zijn dat u onze website gebruikt om inhoud te downloaden of inbreuk maakt op enige manier de rechten van een andere persoon of als we van mening zijn dat er mogelijk een veiligheidsrisico bestaat.",
    "lbl_webSiteNContent": "Onze website en de inhoud ervan",
    "lbl_webSiteNContent_6.1": "We garanderen niet dat toegang tot onze website, of de inhoud ervan (inclusief inhoud die u uploadt naar onze website), altijd beschikbaar of ononderbroken zal zijn. Uw recht op toegang tot onze website wordt slechts tijdelijk verleend.",
    "lbl_webSiteNContent_6.2": "We behouden ons het recht voor om onze website en de inhoud erop te wijzigen, inclusief maar niet beperkt tot het toevoegen of verwijderen van inhoud of functionaliteit, op elk moment en zonder kennisgeving.",
    "lbl_webSiteNContent_6.3": "We kunnen niet garanderen dat alle inhoud op onze website juist, volledig of actueel is. We geven daarom geen garanties, verklaringen, toezeggingen of garanties dat de inhoud op onze website juist, volledig of actueel is.",
    "lbl_webSiteNContent_6.4": "De inhoud op onze website is uitsluitend bedoeld voor algemene informatiedoeleinden. Niets op onze website is bedoeld als advies waarop u kunt vertrouwen. U dient altijd passend specialistisch advies in te winnen voordat u al dan niet actie onderneemt op basis van inhoud op onze website.",
    "lbl_webSiteNContent_6.5": "We geven geen garanties, verklaringen, toezeggingen of garanties dat onze website veilig is of vrij is van bugs of virussen. U bent ervoor verantwoordelijk dat het apparaat waarmee u onze website bezoekt over geschikte antivirussoftware beschikt.",
    "lbl_contentUploaded": "Inhoud die door u of door andere gebruikers naar onze website is geüpload",
    "lbl_contentUploaded_7.1": "Als u inhoud uploadt naar onze website, moet u voldoen aan de inhoudsnormen uiteengezet in",
    "lbl_contentUploaded_7.1part": "met betrekking tot die inhoud",
    "lbl_contentUploaded_7.2": "U garandeert ons dat u de eigenaar bent van alle inhoud die u naar onze website uploadt of, als u niet de eigenaar bent, dat u het recht hebt om deze te uploaden.",
    "lbl_contentUploaded_7.3": "U blijft eigenaar van alle inhoud die u naar onze website uploadt, maar zolang die inhoud op onze website blijft staan, verleent u ons een wereldwijde licentie om deze te gebruiken, op te slaan, te kopiëren, te wijzigen, uit te voeren, te distribueren, weer te geven en beschikbaar te stellen. die inhoud en om andere gebruikers van onze website toe te staan ​​deze te gebruiken, downloaden, afdrukken en reproduceren in overeenstemming met onze Gebruiksvoorwaarden.",
    "lbl_contentUploaded_7.4": "U zult ons schadeloos stellen voor alle verliezen, aansprakelijkheden, kosten, uitgaven, claims of procedures die wij lijden of oplopen, of zullen lijden of oplopen, als gevolg van of in verband met een schending door u van uw verplichtingen of garanties in deze",
    "lbl_para7": "lid 7",
    "lbl_contentUploaded_7.5": "Wij zijn niet verantwoordelijk voor, en geven geen garanties, verklaringen, toezeggingen of garanties met betrekking tot enige inhoud op onze website die is geüpload door een gebruiker. Elke mening die door een gebruiker van onze website wordt geuit, is hun mening en niet de onze, noch de mening van onze functionarissen of werknemers.",
    "lbl_contentUploaded_7.6": "Wij zijn niet verantwoordelijk voor de opslag of back-up van inhoud die naar onze website is geüpload, en zijn niet verantwoordelijk voor enig verlies, beschadiging of vernietiging van dergelijke inhoud.",
    "lbl_contentUploaded_7.7": "We raden u aan een back-up te maken van alle inhoud die u naar onze website uploadt.",
    "lbl_contentUploaded_7.8": "We behouden ons het recht voor om op elk moment inhoud te verwijderen die u naar onze website hebt geüpload.",
    "lbl_contentUploaded_7.9": "We behouden ons ook het recht voor om uw identiteit bekend te maken aan elke persoon die beweert dat inhoud die u naar onze website hebt geüpload, inbreuk maakt op hun intellectuele eigendomsrechten of hun recht op privacy of lasterlijk is.",
    "lbl_contentStd": "Inhoudelijke normen",
    "lbl_contentStd_8.1": "Houd er rekening mee dat alle inhoud die u naar onze website uploadt, door andere gebruikers kan worden bekeken, dus denk altijd goed na voordat u inhoud indient.",
    "lbl_contentStd_8.2": "Alle inhoud die u naar onze website uploadt, moet:",
    "lbl_contentStd_8.2.1": "voldoen aan alle toepasselijke wetten;",
    "lbl_contentStd_8.2.2": "nauwkeurig zijn (waar feiten worden vermeld); En",
    "lbl_contentStd_8.2.3": "oprecht worden gehouden (waar het meningen vermeldt).",
    "lbl_contentStd_8.3": "U mag geen inhoud naar onze website uploaden die:",
    "lbl_contentStd_8.3.1": "lasterlijk, obsceen, beledigend, onfatsoenlijk, kwaadaardig, hatelijk of opruiend is;",
    "lbl_contentStd_8.3.2": "pornografisch of seksueel expliciet is",
    "lbl_contentStd_8.3.3": "exploiteert minderjarigen;",
    "lbl_contentStd_8.3.4": "bevordert geweld of terrorisme;",
    "lbl_contentStd_8.3.5": "discriminerend is op basis van ras, geslacht, religie, nationaliteit, handicap, seksuele geaardheid of leeftijd;",
    "lbl_contentStd_8.3.6": "inbreuk maakt op enig auteursrecht, databankrecht, handelsmerk of ander intellectueel eigendomsrecht van een andere persoon;",
    "lbl_contentStd_8.3.7": "om wachtwoorden of persoonlijk identificeerbare informatie vraagt ​​of probeert te vragen aan andere gebruikers voor commerciële of onwettige doeleinden;",
    "lbl_contentStd_8.3.8": "frauduleus is of iemand kan bedriegen;",
    "lbl_contentStd_8.3.9": "is gemaakt in strijd met enige wettelijke verplichting jegens iemand anders, zoals een contractuele verplichting of een vertrouwensplicht;",
    "lbl_contentStd_8.3.10": "een risico vormt voor de gezondheid of veiligheid van een persoon of de volksgezondheid of veiligheid;",
    "lbl_contentStd_8.3.11": "bevordert of vormt illegale of onwettige activiteit;",
    "lbl_contentStd_8.3.12": "brengt de nationale veiligheid in gevaar;",
    "lbl_contentStd_8.3.13": "een onderzoek door een wetshandhavings- of regelgevende instantie verstoort;",
    "lbl_contentStd_8.3.14": "è minaccioso o offensivo o invade la privacy di un'altra persona o causa fastidio, disagio o ansia inutile;",
    "lbl_contentStd_8.3.15": "waarschijnlijk een andere persoon lastigvalt, van streek maakt, in verlegenheid brengt, alarmeert of irriteert;",
    "lbl_contentStd_8.3.16": "impersonates any person or misrepresents your identity or affiliation with any person;",
    "lbl_contentStd_8.3.17": "wekt de indruk dat het van ons komt;",
    "lbl_contentStd_8.3.18": "vormt reclamemateriaal; of",
    "lbl_contentStd_8.3.19": "bepleit, promoot of assisteert onwettige activiteiten, inclusief maar niet beperkt tot inbreuk op auteursrechten of computermisbruik.",
    "lbl_IntellctlProprty": "Intellectuele eigendomsrechten",
    "lbl_IntellctlProprty_9.1": "Wij, of onze licentiegevers, bezitten het auteursrecht en alle andere intellectuele eigendomsrechten op onze website en alle inhoud erop, behalve inhoud die is geüpload door of waarnaar is gelinkt door anderen.",
    "lbl_IntellctlProprty_9.2": "U mag de inhoud die wij bezitten van onze website downloaden en/of een redelijk aantal exemplaren van elke pagina op onze website afdrukken. U mag geen wijzigingen aanbrengen in de inhoud die u van onze website hebt gedownload of afgedrukt. Als u de gedownloade of afgedrukte inhoud reproduceert op welk medium dan ook, moet u ervoor zorgen dat u deze nauwkeurig en volledig reproduceert en een verklaring opnemen waarin u erkent dat deze afkomstig is van onze website",
    "lbl_IntellctlProprty_9.3": "Als u inhoud van onze website gebruikt, downloadt, afdrukt of reproduceert in strijd met deze gebruiksvoorwaarden, wordt uw recht om onze website te gebruiken onmiddellijk beëindigd en moet u alle digitale en papieren kopieën van de inhoud aan ons retourneren of vernietigen (naar onze keuze). die je hebt gemaakt.",
    "lbl_linksToOtherWebsite": "Links van onze website naar andere websites",
    "lbl_linksToOtherWebsite_text": "Onze website kan van tijd tot tijd links naar andere websites bevatten. Deze links worden voor uw gemak verstrekt. We hebben geen controle over, en geen verantwoordelijkheid of aansprakelijkheid jegens u voor, die andere websites of hun inhoud, en elk gebruik van of toegang door u tot dergelijke websites is onderworpen aan de relevante gebruiksvoorwaarden van die websites en niet aan deze voorwaarden bruikbaar.",
    "lbl_linksFromOtherWebsite": "Links van andere websites naar onze website",
    "lbl_linksFromOtherWebsite_11.1": "U mag vanaf een andere website een link naar onze website maken, maar alleen als u aan de volgende voorwaarden voldoet:",
    "lbl_linksFromOtherWebsite_11.1.1": "u moet ons op de hoogte stellen van uw voornemen om een ​​link naar onze website te maken vanaf een andere website en onze schriftelijke toestemming verkrijgen voordat u een dergelijke link maakt;",
    "lbl_linksFromOtherWebsite_11.1.2": "u mag alleen linken naar de startpagina van onze website. U mag geen link maken naar een andere pagina van onze website, noch een pagina van onze website framen;",
    "lbl_linksFromOtherWebsite_11.1.3": "u mag alleen naar onze website linken vanaf een website waarvan u de eigenaar bent en die voldoet aan de normen uiteengezet in",
    "lbl_linksFromOtherWebsite_11.1.4": "het maken van de link zal onze website niet introduceren, of onze website gebruiken om virussen, trojaanse paarden, wormen, logische bommen, tijdbommen, toetsaanslagloggers, spyware, malware, adware of andere materialen die kwaadaardig of schadelijk zijn te verzenden ;",
    "lbl_linksFromOtherWebsite_11.1.5": "u zult voldoen aan de vereisten en/of beleidslijnen waarvan wij u van tijd tot tijd op de hoogte kunnen stellen met betrekking tot een link naar onze website vanaf een andere website; En",
    "lbl_linksFromOtherWebsite_11.1.6": "u mag niet suggereren dat wij een band met u hebben of dat wij uw website of een van uw producten of diensten goedkeuren of onderschrijven.",
    "lbl_linksFromOtherWebsite_11.2": "We behouden ons het recht voor om van u te eisen dat u elke link naar onze website op elk moment en zonder voorafgaande kennisgeving verwijdert",
    "lbl_ourLiability": "Onze aansprakelijkheid jegens u",
    "lbl_ourLiability_12.1": "Niets in deze Gebruiksvoorwaarden beperkt of sluit onze aansprakelijkheid jegens u uit voor:",
    "lbl_ourLiability_12.1.1": "dood of persoonlijk letsel als gevolg van nalatigheid;",
    "lbl_ourLiability_12.1.2": "fraude of frauduleuze verkeerde voorstelling van zaken; of",
    "lbl_ourLiability_12.1.3": "elke andere kwestie waarvoor het ons wettelijk niet is toegestaan ​​onze aansprakelijkheid uit te sluiten of te beperken.",
    "lbl_ourLiability_12.2": "Aangezien deze gebruiksvoorwaarden uw toegang tot en gebruik van onze website regelen, zijn alle garanties, voorwaarden en andere voorwaarden met betrekking tot onze website en/of de inhoud ervan die anders door de wet zouden worden geïmpliceerd (hetzij door de wet, het gewoonterecht of anderszins) uitgesloten.",
    "lbl_ourLiability_12.3": "Wij zijn niet aansprakelijk jegens u voor verlies of schade die voortvloeit uit of verband houdt met uw gebruik van (of onvermogen tot gebruik van) onze website of uw gebruik van of vertrouwen op enige inhoud op onze website, ongeacht of dit contractueel, onrechtmatig (inclusief zonder beperking nalatigheid), verkeerde voorstelling van zaken, restitutie, wettelijk of anderszins.",
    "lbl_ourLiability_13": "Toepasselijk recht",
    "lbl_ourLiability_13.1": "Als u een consument bent, zijn deze Gebruiksvoorwaarden onderworpen aan de wet van Engeland en Wales en hebben de rechtbanken van Engeland en Wales de niet-exclusieve jurisdictie om geschillen te beslechten die daaruit voortvloeien of daarmee verband houden. Als u in Schotland woont, kunt u ook een procedure in Schotland aanhangig maken en als u in Noord-Ierland woont, kunt u ook een procedure in Noord-Ierland beginnen. Inwoners van andere landen waar de website op de markt wordt gebracht, zijn onderworpen aan de wet van Engeland en Wales",
    "lbl_ourLiability_13.2": "Als u een zakelijke gebruiker bent, vallen deze Gebruiksvoorwaarden (en alle niet-contractuele verplichtingen die daaruit voortvloeien of daarmee verband houden) onder de wet van Engeland en Wales en hebben de rechtbanken van Engeland en Wales de exclusieve jurisdictie om eventuele geschillen te beslechten uit of in verband daarmee (inclusief maar niet beperkt tot niet-contractuele verplichtingen). Inwoners van andere landen waar de website op de markt wordt gebracht, zijn onderworpen aan de wet van Engeland en Wales",
    "lbl_privPolicy": "Privacybeleid",
    "lbl_privPolicy_text": "De Eaton SecureConnect-website is een website en portaal beheerd door Eaton Electrical Products Limited, een bedrijf geregistreerd bij Companies House onder registratienummer 01342230, met als geregistreerd adres Jephson Court Tancred Close, Queensway, Royal Leamington Spa, Warwickshire, CV31 3RZ.",
    "lbl_privPolicy_text1": "Eaton Electrical Products Limited",
    "lbl_privPolicy_textWe": "Wij",
    "lbl_privPolicy_textUs": "ons",
    "lbl_privPolicy_textOur": "ons",
    "lbl_privPolicy_text2": "is de verwerkingsverantwoordelijke met betrekking tot persoonlijke informatie die door u aan ons wordt verstrekt via deze website.",
    "lbl_privPolicy_text3": "Deze kennisgeving (samen met onze gebruiksvoorwaarden:",
    "lbl_privPolicy_text4": "en alle andere documenten waarnaar wordt verwezen) vormt de basis waarop alle persoonlijke gegevens die we van u verzamelen of die u ons via deze website verstrekt, door ons worden verwerkt. Lees het volgende aandachtig door om onze praktijken met betrekking tot uw persoonlijke gegevens te begrijpen en hoe we deze zullen behandelen.",
    "lbl_privPolicy_text5": "Als u vragen heeft over deze kennisgeving of het er niet mee eens bent, neem dan contact met ons op via dataprotection@eaton.com voordat u deze website gebruikt.",
    "lbl_privPolicy_text6": "We kunnen deze kennisgeving van tijd tot tijd wijzigen door deze pagina bij te werken. U dient deze pagina van tijd tot tijd te controleren om er zeker van te zijn dat u tevreden bent met eventuele wijzigingen. Deze kennisgeving is van kracht vanaf 20 december 2019.",
    "lbl_infoWeCollect": "Welke informatie verzamelen we?",
    "lbl_infoWeCollect_text": "U krijgt toegang tot de website zonder ons enige informatie te geven. De meeste diensten die via de website worden aangeboden (zoals die beschikbaar zijn via de portal) zijn echter alleen beschikbaar als we bepaalde informatie over u verwerken.",
    "lbl_infoWeCollect_text1": "We kunnen de volgende informatie verzamelen:",
    "lbl_infoWeCollect_text2": "uw naam en contactgegevens (waaronder adres, e-mailadres en telefoonnummers) en bedrijfsgegevens;",
    "lbl_infoWeCollect_text3": "uw accountregistratie-informatie als u een geregistreerde gebruiker van de website wordt (inclusief uw inloggegevens en wachtwoord);",
    "lbl_infoWeCollect_text4": "het land van waaruit u de website bezoekt;",
    "lbl_infoWeCollect_text5": "informatie over uw persoonlijke of professionele interesses, demografische gegevens, ervaringen met onze producten en contactvoorkeuren om u te voorzien van verdere correspondentie over onze producten en diensten;",
    "lbl_infoWeCollect_text6": "informatie over uw klanten (inclusief hun namen, adressen, producten die ze hebben gekocht en systemen die bij hen zijn geïnstalleerd, informatie over hun alarmstatus (zoals of het al dan niet is geactiveerd en/of goed werkt), CCTV-beelden en of ze een gebruiker van de Eaton SecureConnect mobiele applicatie);",
    "lbl_infoWeCollect_text7": "verslagen van elk contact tussen u en ons; En",
    "lbl_infoWeCollect_text8": "alle informatie die u vrijwillig aan ons verstrekt of invoert via de website en/of hulplijn.",
    "lbl_whatWeDowithInfo": "Wat doen we met de informatie die we verzamelen?",
    "lbl_whatWeDowithInfo_text": "We gebruiken deze informatie om u onze producten en diensten te leveren, en met name om de volgende redenen",
    "lbl_whatWeDowithInfo_text1": "om ons in staat te stellen u ondersteuning en technische assistentie te bieden met betrekking tot de producten die u bij ons hebt gekocht;",
    "lbl_whatWeDowithInfo_text2": "om u te informeren over periodes van downtime voor onderhoud met betrekking tot het portaal en andere aan u geleverde diensten;",
    "lbl_whatWeDowithInfo_text3": "interne administratie; En",
    "lbl_whatWeDowithInfo_text4": "om onze producten en diensten te verbeteren.",
    "lbl_whatWeDowithInfo_text5": "We kunnen contact met u opnemen via e-mail, telefoon of post",
    "lbl_whatWeDowithInfo_text6": "Wij verwerken deze persoonsgegevens om aan onze verplichtingen te voldoen en onze rechten uit te oefenen in verband met onze overeenkomst(en) met u en voor onze legitieme belangen, waaronder het leveren van producten, diensten en oplossingen aan onze klanten en door Eaton geaccrediteerde installateurs, het naleven van onze wettelijke, contractuele en morele verplichtingen, het vergroten van de efficiëntie van onze activiteiten en het beschermen van ons bedrijf",
    "lbl_infoShareWith": "Met wie delen we deze informatie?",
    "lbl_infoShareWith_text": "Behalve zoals hieronder uiteengezet, zullen we uw persoonlijke informatie niet overdragen, openbaar maken, verkopen, distribueren of leasen aan andere derden dan onze dochterondernemingen en/of moedermaatschappij, tenzij we uw toestemming hebben of wettelijk verplicht zijn.",
    "lbl_infoShareWith_text1": "Soms (en indien nodig met uw toestemming) delen we uw persoonsgegevens met zorgvuldig geselecteerde derden buiten onze bedrijfsgroep. We kunnen dit doen om de volgende redenen:",
    "lbl_infoShareWith_text2": "om dergelijke derden in staat te stellen diensten voor ons uit te voeren, inclusief onze zorgvuldig geselecteerde website-hostingprovider;",
    "lbl_infoShareWith_text3": "om uw werkgever, opdrachtgever en/of opdrachtnemer in staat te stellen gegevens in het portaal in te zien die betrekking hebben op zijn installateursbedrijf en/of de producten die hij heeft afgenomen;",
    "lbl_infoShareWith_text4": "in reactie op wettige verzoeken van overheidsinstanties, onder meer om te voldoen aan nationale veiligheids- of wetshandhavingsvereisten;",
    "lbl_infoShareWith_text5": "wanneer we van mening zijn dat het nodig is om de wet na te leven of om de rechten, eigendommen of veiligheid van ons of een ander te beschermen; en/of",
    "lbl_infoShareWith_text6": "als er een verandering is (of zal zijn) in eigendom van ons bedrijf of activa, dan willen we mogelijk uw informatie delen zodat de nieuwe (toekomstige) eigenaren ons bedrijf effectief kunnen blijven runnen en diensten aan klanten kunnen blijven leveren. Dit kunnen nieuwe aandeelhouders zijn of organisaties die een opdracht of overdracht aannemen van overeenkomsten die we met onze klanten zijn aangegaan.",
    "lbl_infoShareWith_text7": "Dit proces kan het verzenden van persoonsgegevens naar andere landen binnen de Europese Economische Ruimte omvatten. Als we, om welke reden dan ook, uw persoonlijke gegevens buiten de Europese Economische Ruimte overdragen, zullen we waarborgen implementeren om het juiste beschermingsniveau voor al dergelijke persoonlijke gegevens te waarborgen en zullen we u een kopie van zulke waarborgen.",
    "lbl_howLongStoreData": "Hoe lang bewaren wij uw persoonsgegevens?",
    "lbl_howLongStoreData_text": "Hoe lang we uw informatie bewaren, hangt af van de basis waarop deze is verstrekt, maar in het algemeen:",
    "lbl_howLongStoreData_text1": "we zullen de informatie bewaren die nodig is om ons in staat te stellen u een dienst te bieden die u via of in verband met deze website hebt aangevraagd, zolang als nodig is om die dienst te verlenen;",
    "lbl_howLongStoreData_text2": "over het algemeen bewaren we alle transacties die u met ons aangaat minimaal zes jaar vanaf het einde van de relatie. Dit is zodat we problemen kunnen oplossen en kunnen reageren op eventuele klachten of geschillen die zich in die periode voordoen. Anders bewaren we de informatie waar nodig voor legitieme zakelijke behoeften;",
    "lbl_howLongStoreData_text3": "we zullen andere informatie over u bewaren als dit voor ons noodzakelijk is om te voldoen aan de wet, of indien van toepassing voor legitieme zakelijke behoeften.",
    "lbl_sensativeInfo": "Gevoelige informatie",
    "lbl_sensativeInfo_text": "Informatie over u die volgens de wetgeving inzake gegevensbescherming als gevoelig wordt beschouwd of als een speciale categorie van persoonsgegevens wordt beschouwd, kan informatie bevatten over uw medische of gezondheidstoestand, raciale of etnische afkomst, politieke opvattingen, vakbondslidmaatschap, religieuze of filosofische overtuigingen, genetische gegevens, biometrische gegevens , seksueel leven en seksuele geaardheid, en vermoedelijke of bewezen criminele activiteiten en aanverwante procedures. Als we gevoelige persoonlijke gegevens moeten verwerken, wordt u op de hoogte gesteld van een dergelijke verwerking en wordt u gevraagd om specifiek in te stemmen met het gebruik van dergelijke informatie, indien van toepassing.",
    "lbl_security": "Beveiliging",
    "lbl_security_text": "We doen er alles aan om ervoor te zorgen dat uw informatie veilig is. Om ongeoorloofde toegang of openbaarmaking te voorkomen, hebben we fysieke, elektronische en bestuurlijke procedures ingevoerd om de informatie die we online verzamelen te beschermen en te beveiligen.",
    "lbl_security_text1": "Wij streven ernaar om alle redelijke maatregelen te nemen om uw persoonsgegevens te beschermen. Houd er echter rekening mee dat er inherente veiligheidsrisico's verbonden zijn aan het verstrekken van informatie en het online handelen via internet en daarom kunnen we de veiligheid van persoonlijke gegevens die online openbaar worden gemaakt niet garanderen. We vragen u om ons geen gevoelige persoonlijke gegevens online te verstrekken (zie het gedeelte 'Gevoelige informatie' hierboven), tenzij we hier specifiek om vragen.",
    "lbl_cookies": "Koekjes",
    "lbl_cookies_text": "Zoals veel bedrijven gebruiken we `cookies` en vergelijkbare tools op onze website om de prestaties en uw gebruikerservaring te verbeteren.",
    "lbl_cookies_text1": "Wat zijn cookies?",
    "lbl_cookies_text2": "Een cookie is een klein tekstbestand dat wordt geplaatst op het apparaat waarmee u de website bezoekt (uw",
    "lbl_cookies_text3": "Cookies helpen het webverkeer te analyseren en stellen webapplicaties in staat om op u als individu te reageren. De webapplicatie kan zijn werking afstemmen op uw behoeften, voorkeuren en antipathieën door informatie over uw voorkeuren te verzamelen en te onthouden. Bepaalde cookies kunnen persoonlijke gegevens bevatten - als u bijvoorbeeld klikt om `onthoud mij` te klikken bij het inloggen, kan een cookie uw gebruikersnaam opslaan. De meeste cookies verzamelen geen persoonlijke gegevens die u identificeren, maar verzamelen in plaats daarvan meer algemene informatie, zoals hoe gebruikers onze website bereiken en gebruiken, of de algemene locatie van een gebruiker.",
    "lbl_cookies_text4": "Waarvoor gebruiken we cookies?",
    "lbl_cookies_text5": "We kunnen cookies of soortgelijke bestanden op uw apparaat plaatsen voor veiligheidsdoeleinden, om ons te vertellen of u de website eerder hebt bezocht, om uw taalvoorkeuren te onthouden, of u een nieuwe bezoeker bent of om op een andere manier sitenavigatie te vergemakkelijken, en om uw ervaring tijdens uw bezoek te personaliseren. onze websites. Cookies stellen ons in staat technische en navigatie-informatie te verzamelen, zoals browsertype, tijd doorgebracht op onze websites en bezochte pagina's. Cookies kunnen uw online ervaring verbeteren door uw voorkeuren op te slaan terwijl u een bepaalde website bezoekt. Over het algemeen helpen cookies ons om u een betere website te bieden door ons in staat te stellen te controleren welke pagina's u nuttig vindt en welke niet. Een cookie geeft ons op geen enkele manier toegang tot uw apparaat of enige informatie over u, behalve de gegevens die u ervoor kiest om met ons te delen.",
    "lbl_cookies_text6": "Welke soorten cookies gebruiken we?",
    "lbl_cookies_text7": "Over het algemeen kunnen de cookies die op de website worden gebruikt, worden onderverdeeld in de volgende categorieën:",
    "lbl_cookies_text8": "Sessiecookies:",
    "lbl_cookies_text9": "Deze cookies worden elke keer dat u een website bezoekt 'in-sessie' gebruikt en vervallen wanneer u een website verlaat of kort daarna: ze worden niet permanent op uw apparaat opgeslagen, bevatten geen persoonlijke gegevens en helpen door de noodzaak om persoonlijke gegevens over te dragen tot een minimum te beperken over het internet. Deze cookies kunnen worden verwijderd of u kunt het gebruik ervan weigeren, maar dit kan de prestaties en uw ervaring bij het gebruik van de websites belemmeren. Deze cookies hebben ook tijdstempels die registreren wanneer u een website bezoekt en wanneer u een website verlaat.",
    "lbl_cookies_text10": "Traceercookies:",
    "lbl_cookies_text11": "Deze cookies stellen ons in staat terugkerende bezoekers van onze websites te herkennen. Door een anonieme, willekeurig gegenereerde identificator te matchen, houdt een trackingcookie bij waar een gebruiker van onze websites vandaan kwam, welke zoekmachine ze mogelijk hebben gebruikt, op welke link ze hebben geklikt, welk trefwoord ze hebben gebruikt en waar ze in de wereld waren toen ze hebben een website bezocht. Door deze gegevens te monitoren kunnen wij verbeteringen aanbrengen aan onze websites.",
    "lbl_cookies_text12": "Permanente cookies:",
    "lbl_cookies_text13": "Dit type cookie wordt voor een vaste periode op uw Apparaat opgeslagen (soms voor een paar uur, soms voor een jaar of langer) en wordt niet verwijderd wanneer de browser wordt afgesloten. Permanente cookies worden gebruikt wanneer we moeten onthouden wie u bent voor meer dan één browsersessie. Dit type cookie kan bijvoorbeeld worden gebruikt om uw voorkeuren op te slaan, zodat deze onthouden worden voor een volgend bezoek aan een website.",
    "lbl_cookies_text14": "Prestatie- of analytische cookies:",
    "lbl_cookies_text15": "Prestatiecookies worden gebruikt om te analyseren hoe de websites worden gebruikt en om hun prestaties te monitoren, waardoor we uw ervaring met het gebruik van de websites kunnen verbeteren. Deze cookies helpen ons om de inhoud van de",
    "lbl_cookies_text16": "websites",
    "lbl_cookies_text17": "om weer te geven wat de websitegebruikers het meest interessant vinden en om te identificeren wanneer zich technische problemen met de websites voordoen. We kunnen deze gegevens ook gebruiken om rapporten op te stellen die ons helpen te analyseren hoe websites worden gebruikt, wat de meest voorkomende problemen zijn en hoe we de websites kunnen verbeteren.",
    "lbl_cookies_text18": "Kunnen de cookies worden geblokkeerd?",
    "lbl_cookies_text19": "U kunt ervoor kiezen om cookies te accepteren of te weigeren. De meeste webbrowsers accepteren cookies automatisch, maar u kunt uw browserinstelling meestal wijzigen om cookies te weigeren als u dat wilt. Als u liever geen cookies wilt accepteren, kunt u met de meeste browsers: (i) uw browserinstellingen wijzigen om u op de hoogte te stellen wanneer u een cookie ontvangt, zodat u kunt kiezen of u deze al dan niet accepteert; (ii) om bestaande cookies uit te schakelen; of (iii) om uw browser zo in te stellen dat cookies automatisch worden geweigerd. Houd er echter rekening mee dat als u cookies uitschakelt of weigert, sommige functies en services op onze websites mogelijk niet correct werken omdat we u mogelijk niet kunnen herkennen en koppelen aan uw account(s). Bovendien zijn de aanbiedingen die we doen wanneer u ons bezoekt mogelijk niet zo relevant voor u of afgestemd op uw interesses.",
    "lbl_cookies_text20": "Instructies voor het blokkeren of toestaan ​​van cookies in populaire webbrowsersoftware Internet Explorer 7 en 8 Firefox Google Chrome Apple Safari AboutCookies.org",
    "lbl_linking": "Koppelen",
    "lbl_linking_text": "We kunnen linken naar andere websites waarover wij geen controle hebben. Zodra u onze website heeft verlaten, kunnen wij niet verantwoordelijk worden gehouden voor de bescherming en privacy van de informatie die u verstrekt. Wees voorzichtig en kijk naar de privacyverklaring die van toepassing is op de betreffende website.",
    "lbl_yourRights": "Jou rechten",
    "lbl_yourRights_text": "U kunt een kopie aanvragen van alle persoonlijke informatie die wij over u bewaren. Er kan een kleine vergoeding worden betaald. U kunt dit doen door ons te schrijven op dataprotection@eaton.com",
    "lbl_yourRights_text1": "In sommige omstandigheden kunt u het recht hebben om uw persoonsgegevens te ontvangen in een gestructureerd, algemeen gebruikt en machineleesbaar formaat.",
    "lbl_yourRights_text2": "Als u denkt dat informatie die wij over u hebben onjuist of onvolledig is, schrijf of e-mail ons dan zo snel mogelijk. We zullen alle informatie, indien van toepassing, zo snel mogelijk corrigeren of bijwerken.",
    "lbl_yourRights_text3": "U hebt het recht om van ons te eisen dat wij onze verwerking van uw persoonsgegevens beperken in bepaalde omstandigheden waarin:",
    "lbl_yourRights_text4": "u betwist de juistheid van de persoonsgegevens die wij over u verwerken;",
    "lbl_yourRights_text5": "onze verwerking van uw gegevens is onrechtmatig;",
    "lbl_yourRights_text6": "wij hebben de persoonsgegevens niet meer nodig voor de doeleinden waarvoor wij ze verwerken, maar u heeft de persoonsgegevens nodig voor het instellen, uitoefenen of verdedigen van rechtsvorderingen;",
    "lbl_yourRights_text7": "u betwist of de legitieme gronden van de verwerkingsverantwoordelijke zwaarder wegen dan die van de betrokkene.",
    "lbl_yourRights_text8": "Als u vragen, opmerkingen of suggesties heeft over ons gebruik van uw persoonlijke gegevens, kunt u ons schrijven op dataprotection@eaton.com",
    "lbl_yourRights_text9": "U hebt ook het recht om een ​​klacht in te dienen bij de Information Commissioner's Office als u niet tevreden bent met onze verwerking van uw persoonsgegevens.",
    "lbl_noticeToCalfrniaResidents": "Kennisgeving aan inwoners van Californië",
    "lbl_noticeToCalfrniaResidents_text": "Dit gedeelte is van toepassing op inwoners van Californië.",
    "lbl_noticeToCalfrniaResidents_text1": "Zoals beschreven in 'Welke informatie verzamelen we?', kunnen we persoonlijke informatie verzamelen, waaronder identifiers, commerciële informatie, internet- of andere netwerkactiviteitsinformatie, geolocatie-informatie, professionele of werkgerelateerde informatie en gevolgtrekkingen die worden getrokken uit de hierboven geïdentificeerde informatie om maak een profiel aan over een consument.",
    "lbl_noticeToCalfrniaResidents_text2": "Zoals beschreven in het gedeelte 'Met wie delen we uw informatie?', kunnen persoonlijke gegevens die we van consumenten verzamelen voor zakelijke doeleinden worden gedeeld met derden. Het is mogelijk dat we in de afgelopen 12 maanden alle categorieën van persoonlijke informatie die hierboven zijn vermeld, op basis van de use case, voor zakelijke doeleinden hebben vrijgegeven.",
    "lbl_noticeToCalfrniaResidents_text3": "Wij verkopen uw persoonlijke gegevens niet.",
    "lbl_noticeToCalfrniaResidents_text4": "Als inwoner van Californië hebt u bepaalde rechten, onderhevig aan wettelijke beperkingen en toepasselijke uitzonderingen, met betrekking tot het verzamelen, gebruiken en delen van uw persoonlijke informatie. Die rechten zijn met name:",
    "lbl_noticeToCalfrniaResidents_text5": "Het recht om te weten",
    "lbl_noticeToCalfrniaResidents_text6": "U hebt het recht om informatie op te vragen over de categorieën van persoonlijke informatie die we over u hebben verzameld, de categorieën van bronnen waaruit we de persoonlijke informatie hebben verzameld, de doeleinden voor het verzamelen van de persoonlijke informatie, de categorieën van derden met wie we uw persoonlijke informatie en het doel waarvoor we uw persoonlijke informatie hebben gedeeld (`Categorieënrapport`). U kunt ook informatie opvragen over de specifieke stukjes persoonlijke informatie die we over u hebben verzameld (“Specific Pieces Report”). Geef aan welk verzoek u wilt uitvoeren.",
    "lbl_noticeToCalfrniaResidents_text7": "Het recht om te verwijderen",
    "lbl_noticeToCalfrniaResidents_text8": "U hebt het recht om te verzoeken dat wij persoonlijke informatie die wij van u hebben verzameld, verwijderen.",
    "lbl_noticeToCalfrniaResidents_text9": "Het recht op opt-out",
    "lbl_noticeToCalfrniaResidents_text10": "U hebt het recht om u af te melden voor de verkoop van uw persoonlijke gegevens.",
    "lbl_noticeToCalfrniaResidents_text11": "In overeenstemming met de toepasselijke wetgeving zullen we u niet discrimineren voor het uitoefenen van deze rechten. U kunt een gemachtigde vertegenwoordiger gebruiken om namens u uw rechten uit te oefenen. Als u een van de bovenstaande verzoeken doet via een geautoriseerde agent, zullen we u om schriftelijke toestemming vragen en proberen te verifiëren zoals hierboven beschreven, of we zullen een wettelijke volmacht onder de California Probate Code accepteren aan de geautoriseerde agent.",
    "lbl_noticeToCalfrniaResidents_text12": "U kunt uw recht te allen tijde uitoefenen door contact met ons op te nemen door een e-mail te sturen naar",
    "lbl_noticeToCalfrniaResidents_text13": "door te bellen naar +1-800-386-1911 of door dit te gebruiken",
    "lbl_noticeToCalfrniaResidents_text14": "online formulier",
    "lbl_noticeToCalfrniaResidents_text15": "Om uw rechten uit te oefenen, hebben we informatie nodig om u in onze administratie te lokaliseren of uw identiteit te verifiëren, afhankelijk van de aard van het verzoek. Als u een verzoek indient namens een huishouden, moeten we elk lid van het huishouden verifiëren op de manier die in dit gedeelte wordt beschreven. Daarom kunnen we op basis van het verzoek contact met u opnemen voor meer informatie.",
    "lbl_noticeToCalfrniaResidents_text16": "We zullen binnen 15 dagen reageren op een verzoek tot opt-out. We zullen binnen 45 dagen reageren op Verzoeken om te verwijderen en Verzoeken om te weten, tenzij we meer tijd nodig hebben, in welk geval we u op de hoogte stellen en het in totaal 90 dagen kan duren om op uw verzoek te reageren.",
    "lbl_viewSubEstate": "Ondergrond bekijken",
    "lbl_installers": "Installateurs",
    "lbl_managedPanels": "Onbeheerde panelen",
    "lbl_days": "Dagen",
    "lbl_email": "E-mailen",
    "lbl_company": "Bedrijf",
    "lbl_removeInstaller": "Installatieprogramma verwijderen",
    "lbl_confirm": "Bevestigen",
    "msg_deleteAdminConfirmation": "Weet u zeker dat u wilt verwijderen",
    "lbl_editDetails": "Details bewerken",
    "msg_adminAdded": "Beheerder is succesvol bewerkt",
    "msg_adminDeleted": "Beheerder succesvol verwijderd",
    "msg_errorDeleteAdmin": "Fout bij het verwijderen van beheerder",
    "lbl_addAdmin": "Beheerder toevoegen",
    "lbl_info": "Info",
    "lbl_noOfPanels": "Aantal panelen",
    "lbl_dateRegistered": "Datum geregistreerd",
    "lbl_cmpnyAddress": "bedrijfsadres",
    "lbl_addressDetails": "Adresgegevens",
    "txt_addressDetails": "Om u te registreren voor een Bedrijfsaccount, dient u de adresgegevens van uw bedrijf op te geven.",
    "lbl_contactDetails": "Contact details",
    "txt_contactDetails": "Om u te registreren voor een bedrijfsaccount, dient u de contactgegevens van uw bedrijf op te geven.",
    "lbl_phoneType": "Telefoon type",
    "msg_alreadyAccnt": "Er is al een account voor dit e-mailadres.",
    "txt_invalidEmail": "ongeldig e-mail",
    "lbl_createAccnt": "Account aanmaken",
    "lbl_createAccntDetails": "Om u te registreren voor een bedrijfsaccount, voert u hieronder de vereiste gegevens in.",
    "msg_pwdValidation": "Minimaal 8 tekens, minimaal 1 hoofdletter, 1 kleine letter, 1 cijfer en 1 speciaal teken",
    "lbl_createPwd": "Creëer een wachtwoord",
    "txt_pwdLength": "Het wachtwoord moet minimaal 8 tekens lang zijn en minimaal één hoofdletter, één keer een cijfer en één keer een speciaal teken bevatten.",
    "lbl_licenseAgreememnt": "Licentieovereenkomst",
    "lbl_licenseAgreememntDetails": "Om u te registreren voor een bedrijfsaccount, moet u de Algemene voorwaarden lezen en ermee akkoord gaan.",
    "lbl_accntActivation": "Account activatie",
    "msg_accntActivation": "Gefeliciteerd, u bent geregistreerd met de login van",
    "lbl_accntActivationEmailSent": "We hebben u een activatie-e-mail gestuurd. Klik op de link in dat bericht om uw account te activeren.",
    "lbl_notReceive": "Geen e-mail ontvangen?",
    "lbl_sendAgain": "Opnieuw versturen",
    "lbl_cnfrmPwd": "bevestig wachtwoord",
    "msg_accntExists": "Er is al een account voor dit e-mailadres.",
    "lbl_forgotPwd": "Als de gebruikers-ID bestaat, wordt er een link verzonden om uw wachtwoord opnieuw in te stellen",
    "lbl_RmtSvsMsg": "Kan niet verdergaan, het paneel bevindt zich in de installateurmodus",
    "lbl_planned": "GEPLAND",
    "lbl_noPlannedService": "Geen geplande servicerapporten",
    "lbl_rmtServInitiationMsg": "Er is een handmatige service op afstand aangevraagd. Wacht 5-10 minuten totdat het rapport is voltooid.",
    "lbl_okay": "Oké",
    "lbl_pwdReset": "Wachtwoord reset",
    "lbl_setPwd": "Stel een wachtwoord in",
    "lbl_pwdResetMsg": "Om uw wachtwoord opnieuw in te stellen, voert u hieronder een nieuw wachtwoord in.",
    "lbl_pwdResetSuccess": "Wachtwoord opnieuw instellen gelukt",
    "lbl_pwdResetSuccessMsg": "Uw wachtwoord is succesvol gereset. Log in op de app met uw bijgewerkte wachtwoord.",
    "lbl_pwdResetErrMsg": "Wachtwoordreset al aangevraagd in de afgelopen 15 minuten.",
    "btn_download_notifications": "Paneelgeschiedenis downloaden",
    "btn_download_logs": "Meldingen downloaden",
    "lbl_userAccDelete": "Verwijdering van gebruikersaccount",
    "lbl_userAccDeleteConfirm": "Wilt u de verwijdering van uw account bevestigen?",
    "lbl_userAccDeleteMsg": "Om de verwijdering van een gebruikersaccount aan te vragen, voert u hieronder het e-mailadres van het account in en vervolgens op de knop Verzenden.",
    "lbl_userAccDeleteSubmitMsg": "Uw verzoek voor het verwijderen van een gebruikersaccount is ingediend. Als de gebruikers-ID bestaat, wordt er een link verzonden om uw wachtwoord opnieuw in te stellen",
    "btn_rmvLogo": "Logo verwijderen",
    "btn_cgnLogo": "Wijzig logo",
    "lbl_endUserMsg": "De hier verstrekte informatie wordt weergegeven in zowel de mobiele applicatie als in Remote Servicing-rapporten",
    "lbl_dwnPanelLog": "Paneellogboeken downloaden",
    "lbl_estatelessPanels": "Panelen die niet binnen een Estate liggen",
    "lbl_siteComments": "Opmerkingen",
    "mainHeadingforsystemConfig": "Koptekst",
    "lbl_EatonGCMorFCMurl": "Eaton GCM- of FCM-URL",
    "lbl_EatonapiKey": "Eaton API-sleutel",
    "lbl_eatonApplepassword": "Eaton Apple-wachtwoord",
    "lbl_applePushNotification": "Apple gebruikt productie-pushmeldingen",
    "headingaccountSettings": "Accountinstelling",
    "lbl_account_default_state": "Standaardstatus account",
    "lbl_choose_state_for_new_account": "Kies de staat voor het nieuwe accountinstallatieprogramma",
    "lbl_account_disable_after": "Account uitschakelen na",
    "lbl_the_top_limit_in_days": "De bovenlimiet in dagen, waarna een account wordt uitgeschakeld",
    "lbl_remove_unpaired_account_after": "Verwijder het niet-gekoppelde account daarna",
    "lbl_helper_text": "Hulptekst",
    "lbl_cloud_password": "Cloud-wachtwoord",
    "lbl_user_registration_disable_time": "Uitschakeltijd voor gebruikersregistratie",
    "lbl_invalid_login_attempt": "Ongeldige duur van uitsluiting van inlogpoging",
    "lbl_maximum_sign_in_attempt": "Maximale inlogpogingen",
    "lbl_seconds": "seconden",
    "lbl_times": "keer",
    "headingCertificates": "Certificaten",
    "lbl_eaton_Apple_certificaates": "Eaton Apple-certificaat",
    "lbl_no_file_selected": "Geen bestand geselecteerd",
    "lbl_upload_file": "Upload bestand",
    "lbl_heartbeat_interval": "Hartslaginterval",
    "lbl_miliseconds": "milliseconden",
    "lbl_WS_session_drop_after": "WS-sessie wordt afgebroken daarna",
    "lbl_jwt_time_to_live": "JWT tijd om te leven",
    "lbl_communication_log_size": "Grootte van communicatielogboek",
    "lbl_panel_log_size": "Paneellog downloadgrootte",
    "lbl_nesting_group_level": "Groepsniveau nesten",
    "lbl_ws_sessions_close_qty": "WS Sessies sluiten aantal",
    "lbl_async_context_timeout": "Time-out van asynchrone context",
    "lbl_refer_header": "Verwijzende kop",
    "heading_Liceense_Agrrement": "Licentieovereenkomst",
    "heading_proxy": "Volmacht",
    "lbl_proxy_host": "Proxy host",
    "lbl_proxy_port": "Proxy-poort",
    "lbl_use_proxy": "Proxy gebruiken",
    "lbl_fichet_gcmorfcm": "Fichet GCM- of FCM-url",
    "lbl_fichetApiKey": "API-sleutelbestand",
    "lbl_fichet_applePsword": "Fichet Apple-wachtwoord",
    "lbl_fichet_applepassword": "Fichet Apple-certificaat",
    "lbl_discard": "Weggooien",
    "heading_CSL": "CSL-instellingen",
    "lbl_csl_login": "CSL-login",
    "lbl_csl_password": "CSL-wachtwoord",
    "lbl_csl_primary_ip": "CSL primaire server-IP",
    "lbl_csl_primary_port": "CSL primaire serverpoort",
    "lbl_csl_secondary_IP": "CSL secundaire server-IP",
    "lbl_csl_secondary_port": "CSL secundaire serverpoort",
    "lbl_csl_primary_protocol": "CSL primair serverprotocol",
    "lbl_csl_secondary_portocol": "CSL secundair serverprotocol",
    "lbl_poll_fails": "CSL-peiling mislukt daarna",
    "lbl_minutes": "minuten",
    "button_CSL_ARC": "CSL ARC-codes",
    "button__csl_countries": "CSL-landen",
    "lbl_dual_com_login": "CSL DualCom-aanmelding",
    "lbl_dual_com_password": "CSL DualCom-wachtwoord",
    "lbl_poll_failer_helper": "Stuur een poll mislukt sia-bericht naar CSL als de verbinding van het paneel langer duurt dan deze waarde in minuten",
    "lbl_add_email": "E-mail toevoegen",
    "lbl_csl_support_team_e-mail_address": "E-mailadressen van het CSL-ondersteuningsteam",
    "lbl_Support_email_list_of_CSL_arc_provider": "Ondersteuning e-maillijst van CSL-boogprovider",
    "lbl_support_team_email_address": "E-mailadressen van het ondersteuningsteam",
    "lbl_email_address_of_support_team": "E-mailadressen van het ondersteuningsteam",
    "heading_Permissions": "Rechten",
    "lbl_Enable_auth": "Verificatie inschakelen",
    "lbl_Mail_transport_protocol_authorization": "Autorisatie van het posttransportprotocol",
    "lbl_EnableTLS": "Schakel TLS in",
    "lbl_Mail_transport_protocol_enable": "Mailtransportprotocol ingeschakeld",
    "heading_Info_SMTP": "Informatie-SMTP",
    "lbl_SMTP_host": "SMTP-host",
    "lbl_Mail_trasport_protocol_host": "Host van het mailtransportprotocol",
    "lbl_SMTP_port": "SMTP-poort",
    "lbl_Mail_trasport_protocol_post": "Mail transportprotocol post",
    "lbl_SMTP_user": "SMTP-gebruiker",
    "lbl_Mail_transport_protocol_user": "Gebruiker van het mailtransportprotocol",
    "lbl_SMTP_password": "SMTP-wachtwoord",
    "lbl_Mail_transport_protocol_password": "Wachtwoord voor het mailtransportprotocol",
    "lbl_Protocol": "Protocol",
    "lbl_Mail_transport_protocol": "Protocol voor postvervoer",
    "lbl_Form": "Formulier",
    "lbl_Mail_transport_protocol_source": "Bron van het mailtransportprotocol",
    "heading_Email_Settings": "Email instellingen",
    "lbl_Email_Text_Language": "E-mail teksttaal",
    "lbl_User_registration_e-mail": "E-mail voor gebruikersregistratie",
    "lbl_helper_text_for_user_register_mail": "Deze tekst wordt naar het e-mailadres van de gebruiker verzonden.${user.login} wordt vervangen bij het inloggen van de gebruiker, die e-mail ${user.name} ontvangt - gebruikersnaam, ${user-cloudId} - gebruikerscloud-ID, ${confirm.link} - e-mailbevestigingslink",
    "lbl_State_change_e-mail": "E-mailadres voor staatswijziging",
    "lbl_helpertext_for_state_email_change": "Deze tekst wordt naar het e-mailadres van de gebruiker verzonden.${user.login} wordt vervangen bij het inloggen van de gebruiker, die e-mail ${user.name} ontvangt - gebruikersnaam, ${user.state} gebruikersstatus, ${user-cloudId} - gebruikerscloud-ID",
    "lbl_Forgot_login_e-mail_subject": "Onderwerp van inlog-e-mail vergeten",
    "lbl_Email_subject_for_login_reminder": "E-mailonderwerp voor inlogherinnering",
    "lbl_Forgotlogine-mail": "Inlog-e-mailadres vergeten",
    "lbl_helpertext_for_forget_login_mail": "Wanneer u de beschrijving wijzigt, voert u de variabele ${user.name} en ${user.login} in als u respectievelijk de gebruikersnaam en de gebruikersaanmelding wilt zien",
    "lbl_App_user_creation_e-mail": "E-mail voor het maken van een app-gebruiker",
    "lbl_helper_text_for_app_user_creation": "Deze tekst wordt naar het e-mailadres van de gebruiker verzonden. ${user.login} wordt vervangen bij het inloggen van de gebruiker, die e-mail ontvangt ${user.name} - gebruikersnaam ${confirm.link} - e-mailbevestigingslink",
    "lbl_Forgot_passworde-mail": "Wachtwoord e-mail vergeten",
    "lbl_helper_text_for_forgetpassworde-mail": "Deze tekst wordt naar het e-mailadres van de gebruiker verzonden. ${user.name} - gebruikersnaam ${secretLink} - URL voor het wijzigen van het wachtwoord",
    "lbl_Maintenance_notification_subject": "Onderwerp onderhoudsmelding",
    "lbl_Subject_for_all_user_maintenancenotification": "Onderwerp voor alle gebruikersonderhoudsmeldingen",
    "lbl_Maintenance_notificationtext": "Tekst onderhoudsmelding",
    "lbl_helper_text_for_maintain_notigication": "Deze tekst wordt naar alle gebruikers verzonden wanneer het nodig is om hen op de hoogte te stellen van een gebeurtenis op de cloudserver",
    "lbl_Raised_alert_event_e-mail_text": "E-mailtekst met verhoogde waarschuwingsgebeurtenis",
    "lbl_helper_text_for_raised_alert_event": "Dit wordt naar het ondersteuningsteam van het panel gestuurd. ${site.name} - wordt vervangen door de paneelwolkreferentienaam ${panel.id} - wordt vervangen door de cloudpaneel-ID ${sia.code} - waarschuwingsgebeurtenis SIA-code ${description} - waarschuwingsgebeurtenis SIA-codebeschrijving ${time.stamp} - tijdstempel waarop de waarschuwing werd gegenereerd ${panel.link} - Hyperlink naar cloudpaneelscherm",
    "lbl_Self_test_failure_text": "Tekst over mislukte zelftest",
    "lbl_helper_text_for_test_failure_Text": "Deze tekst wordt verzonden naar het ondersteuningsteam.${event.ipAdd} - IP-adres van het defecte knooppunt ${event.type} - gebeurtenistype ${event.message} - bericht ${event.connected} - verbindingsstatus ${event.date} - tijdstempel van gebeurtenis",
    "lbl_SIA_message_failure_e-mail_subject": "SIA-bericht mislukt e-mailonderwerp",
    "lbl_Subject_to_message_about_fails_in_sending_sia_events": "Onder voorbehoud van bericht over het mislukken van het verzenden van sia-gebeurtenissen",
    "lbl_SIA_message_failuree-mail_text": "E-mailtekst voor SIA-bericht mislukt",
    "lbl_Email_text_about_siaevent_sendingproblems": "E-mailtekst over problemen bij het verzenden van sia-evenementen",
    "lbl_time_in_seconds_for_locking_account": "Tijd in seconden voor het vergrendelen van het account wanneer er te veel mislukte inlogpogingen hebben plaatsgevonden",
    "lbl_max_failed_login": "Maximaal aantal mislukte inlogpogingen voordat het account wordt vergrendeld",
    "lbl_seconds_for_temporarily_disable_new_user_registration": "Tijd in seconden voor het tijdelijk uitschakelen van de registratie van nieuwe gebruikers",
    "lbl_credentials_for_the_cloud_user_into_the_panel": "Geef de inloggegevens van de cloudgebruiker op in het paneel",
    "lbl_Days_to_remove_unpaired_user_accounts": "Dagen om niet-gekoppelde gebruikersaccounts te verwijderen",
    "lbl_google_firebase_push_notification": "URL van de pushmeldingsserver van Google of Firebase",
    "lbl_api_key": "API sleutel",
    "lbl_Apple_push_notification_server_password": "Apple push notificatieserverwachtwoord",
    "lbl_determines_apple_server": "Bepaalt welke Apple-servers moeten worden gebruikt: productie of sandbox",
    "lbl_upload_certificate_for_eaton": "Upload een certificaat voor Eaton Apple Push Notification",
    "lbl_Heartbeat_intervalinmilliseconds": "Hartslaginterval in milliseconden",
    "lbl_Atime_delay_between_closing_session": "Een vertraging tussen de sluitingssessie",
    "lbl_json_web_token": "JSON-webtoken tijd om te leven",
    "lbl_hazelcast_max_comminication_log_size": "Hazelcast maximale communicatieloggrootte",
    "lbl_Max_size_of_log_entries": "Maximale grootte van logboekvermeldingen die vanuit het paneel kunnen worden gedownload",
    "lbl_max_nesting_group_level": "Maximaal nestgroepniveau",
    "lbl_count_of_Web_token_which_will_be_closed": "Een telling van websocketsessies die worden gesloten",
    "lbl_timeout_for_external_async_request": "Time-out voor extern async-verzoek",
    "lbl_pattern_for_refer_header": "Patroon voor zie koptekst",
    "heading_Support_actions": "Ondersteunende acties",
    "lbl_User_e-mail": "E-mailadres van gebruiker",
    "lbl_Action": "Actie",
    "btn_Startaction": " Actie starten",
    "heading_Download_Reports": "Rapporten downloaden",
    "lbl_Installer_report": "Installateursrapport",
    "lbl_Download": "Downloaden",
    "lbl_App_userReport": "App-gebruikersrapport",
    "lbl_Managed_panelsreport": "Beheerde panelen rapporteren",
    "lbl_Unmanaged_panels_report": "Onbeheerde panelen rapporteren",
    "lbl_ARC_report": "ARC-rapport",
    "heading_confirmation": "Bevestiging",
    "msg_for_Pairing": "Hierdoor wordt alle gebruikersinformatie (koppeling, mobiele apparaten) verwijderd en heeft hij er geen toegang meer toe",
    "msg_for_remove_pariring": "Weet u zeker dat u de koppeling tussen",
    "msg_for_disable_registration": "Weet u zeker dat u registraties vanuit de cloud en app tijdelijk wilt uitschakelen?",
    "lbl_User_Info": "gebruikers informatie",
    "error_pwd_info_capital": "Gebruik hoofdletters (X,Y,Z...),",
    "error_pwd_small": "kleine letters (x,y,z...),",
    "error_pwd_number": "getallen (1,2,3,4...),",
    "error_pwd_special": "en speciale tekens (!@#$%^&*) in het wachtwoord in het wachtwoord",
    "error_pwd_length": "De wachtwoordlengte mag niet korter zijn dan 6 tekens.",
    "lbl_installer_Details": "Installateurgegevens",
    "lbl_error_for_Date_Range": "Geen overeenkomend resultaat voor het geselecteerde datumbereik",
    "lbl_panel_and_esate": "Panelen en landgoederen",
    "lbl_new_subEsates": "Nieuw sub-landgoed",
    "btn_rmv_estate": "Estate verwijderen",
    "heading_est_details": "Landgoeddetails",
    "lbl_Searchforpanels": "Zoek naar panelen",
    "lbl_placeholder_for_Search": "Zoekpanelen...",
    "heading_new_subEstates_Details": "Details van het sub-landgoed",
    "lbl_edit_estate": "Landgoed bewerken",
    "msg_edit_estatesDetails": "Landgoedgegevens zijn succesvol bewerkt",
    "msg_NoEstatesorPanelsassigned": "Geen landgoederen of panelen toegewezen",
    "lbl_DeleteUser": "Gebruiker verwijderen",
    "lbl_confirm_delete": "Weet u zeker dat u wilt verwijderen",
    "msg_user_deleted": "Gebruiker succesvol verwijderd",
    "msg_Installer_details_edit": "Installateurgegevens zijn succesvol bewerkt",
    "btn_Deactivate_Installer": "Deactiveer het installatieprogramma",
    "btn_Activate_Installer": "Activeer Installateur",
    "lbl_Unassigned_Panels":"Niet-toegewezen panelen",
    "lbl_to":"naar...",
    "lbl_plz_select":"Selecteer alstublieft",
    "error_msg_24_character":"De invoerlengte mag niet meer dan 24 tekens bedragen",
    "error_msg_48_character":"De invoerlengte mag niet meer dan 48 tekens bedragen",
    "error_msg_255_character":"De invoerlengte mag niet meer dan 255 tekens bedragen",
    "error_msg_20_character":"De invoerlengte mag niet meer dan 20 tekens bedragen",
    "error_msg_100_character":"De invoerlengte mag niet meer dan 100 tekens bedragen",
    "error_msg_200_character":"De invoerlengte mag niet meer dan 200 tekens bedragen"
};
export default resources;