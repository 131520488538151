/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/await-thenable */

import { DispatchTypesProps } from '../dispatchActionTypes/dispatchAndThunkActionTypes';
import { Dispatch } from 'react';
import { InstallerActionTypes } from '../constants/installersActionTypes';
import { InstallersArrayProps, InstallerSwitchGroup, InstallerUserGroupList } from '../../components/Installers/InstallersTypes';
import { PanelArrayProps } from '../../components/ListAndGridView/panelEstateTypes';
import { AccountUsersArrayProps, AccountUsersProps } from '../../components/AccountUsers/AccountUsersTypes';
import secureLocalStorage from "react-secure-storage";
import { REACT_APP_BASE_URL } from '../../entities/Endpoints';

export const setInstallers = (installers: InstallersArrayProps): any => {
    return {
        type: InstallerActionTypes.SET_INSTALLERS,
        payload: installers,
    };
}
export const setSearchedInstallersLength = (searchedInstallersLength: number): any => {
    return {
        type: InstallerActionTypes.SET_SEARCHED_INSTALLERS_LENGTH,
        payload: searchedInstallersLength,
    };
}
export const setInstallerDetails = (installerDetails: any): any => {
    return {
        type: InstallerActionTypes.SET_INSTALLER_DETAILS,
        payload: installerDetails,
    };
}
export const setInstallerPanels = (totalPanels: PanelArrayProps): any => {
    return {
        type: InstallerActionTypes.SET_INSTALLER_PANELS,
        payload: totalPanels,
    };
}
export const setInstallerUsers = (installerUsersList: AccountUsersArrayProps): any => {
    return {
        type: InstallerActionTypes.SET_INSTALLER_USERS,
        payload: installerUsersList,
    };
}
export const setActivateOrDeactivateInstallerStatus = (activateOrDeactivateInstallerStatus: number): any => {
    return {
        type: InstallerActionTypes.SET_ACTIVATE_DEACTIVATE_INSTALLER,
        payload: activateOrDeactivateInstallerStatus,
    };
}
export const setRemoveInstallerStatus = (removeInstallerStatus: number): any => {
    return {
        type: InstallerActionTypes.SET_REMOVE_INSTALLER,
        payload: removeInstallerStatus,
    };
}
export const setRemoveInstallerState = (removeInstallerState: boolean): any => {
    return {
        type: InstallerActionTypes.SET_REMOVE_INSTALLER_STATE,
        payload: removeInstallerState,
    };
}
export const setInstallersLength = (installersLength: number): any => {
    return {
        type: InstallerActionTypes.SET_INSTALLERS_LENGTH,
        payload: installersLength,
    };
}
export const setEditInstallerDetailsStatus = (editInstallerStatus: number): any => {
    return {
        type: InstallerActionTypes.SET_EDIT_INSTALLER_STATUS,
        payload: editInstallerStatus,
    };
}

const setAddUserForInstaller = (addUserStatus: number): any => {
    return {
        type: InstallerActionTypes?.SET_ADD_USER_FOR_INSTALLER,
        payload: addUserStatus,
    }
}

const setInstallerSwitchGroup = (switchGroup: InstallerSwitchGroup): any => {
    return {
        type: InstallerActionTypes?.SET_INSTALLER_SWITCH_GROUP,
        payload: switchGroup
    }
}

const setSearchInstallerList = (InstallerSearchList: InstallerUserGroupList): any => {
    return {
        type: InstallerActionTypes?.SET_INTALLER_SEARCH_LIST,
        payload: InstallerSearchList
    }
}

const setUserInstallerSearchListLength = (userSearchListLength: any): any => {
    return {
        type: InstallerActionTypes?.SET_INSTALLER_USER_SEARCH_LIST_LENGTH,
        payload: userSearchListLength
    }
}

const setUserInstallerLength = (userLength: number): any => {
    return {
        type: InstallerActionTypes.SET_INSTALLER_USER_LENGTH,
        payload: userLength
    }
}

const setUserAvailableGroup=(availableGroup:any):any=>{
    return{
        type:InstallerActionTypes?.SET_USER_AVAILABLE_GROUP,
        payload:availableGroup
    }   
}

const setEditUserDetails=(editStatus:number):any=>{
    return{
        type:InstallerActionTypes?.SET_EDIT_USER_DETAILS,
        payload:editStatus
    }
}

const setDeleteStatus=(deleteStatus:number):any=>{
    return{
        type:InstallerActionTypes?.SET_USER_DELETE_STATUS,
        payload:deleteStatus
    }
}

export function fetchInstallers(start: number, query?: string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(
            `${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/installers?limit=10&search=${query}&start=${start}`,
            {
                method: 'GET',
                credentials: 'include',
            }
        )
            .then((res) => res.json())
            .then((data) => {
                dispatch(setInstallers(data));
            });
    }
}

export function fetchInstallersLength() {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await  fetch(
            `${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/installers?limit=5000&search=&start=0`,
            {
                method: 'GET',
                credentials: 'include',
            }
        )
            .then((res) => res.json())
            .then((data) => {
                dispatch(setInstallersLength(data.length));
               // dispatch(setInstallers(data));
            });
    }
}

export function fetchInstallerDetails(id: any) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/${id}`, {
            method: 'GET',
            credentials: 'include',
        }
        )
            .then((res) => res.json())
            .then((data) => {
                dispatch(setInstallerDetails(data));
            });
    }
}

export function fetchInstallerPanels(id: any) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/${id}`, {
            method: 'GET',
            credentials: 'include',
        }
        )
            .then((res) => res.json())
            .then(async (data) => {
                await fetch(
                    `${REACT_APP_BASE_URL}/m2m-web-admin/rest/device/recursiveDevice/from/${data.groupId}`,
                    {
                        method: 'GET',
                        credentials: 'include',
                    }
                )
                    .then((res) => res.json())
                    .then((panels) => {
                        dispatch(setInstallerPanels(panels));
                    });
            });
    }
}

export function fetchActivateOrDeactivateInstaller(body: any, id: number, activate: string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/${id}/activate?active=${activate}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        }).then((res) => {
            dispatch((setActivateOrDeactivateInstallerStatus(res.status)))
        })
    };
};
export function fetchRemoveInstaller(id: number) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/${id}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => {
            dispatch((setRemoveInstallerStatus(res.status)))
        })
    };
};

export function setRemoveInstaller(state: boolean) {
    return function (dispatch: Dispatch<DispatchTypesProps>): any {
        dispatch(setRemoveInstallerState(state));
    };
};

export function fetchEditInstallerDetails(body: any) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/${body.ID}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        }).then((res) => {
            if (res.status === 200 && res !== undefined) {
                secureLocalStorage.setItem('installer', body)
                dispatch((setEditInstallerDetailsStatus(res.status)))
            }
            else {
                dispatch((setEditInstallerDetailsStatus(res.status)))
            }
        })
    };
};

export function getAddUserForInstaller(body: any, switchGroupList: any) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        }
        )
            .then((res) => res.json())
            .then(async (data) => {
                await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/${data.ID}/groups`, {
                    method: 'PUT',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(switchGroupList),
                }).then((res) => {
                    dispatch(setAddUserForInstaller(res.status));
                });
            });
    }
}

export function getUserGroupList(id: number) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/bygroup/${id}`, {
            method: 'GET',
            credentials: 'include',
        }
        )
            .then((res) => res.json())
            .then(async (data) => {
                const installerObj = data?.filter((value: any) => value.userType === "INSTALLER")[0]
                const id = installerObj.ID
                await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/group/list/${id}`, {
                    method: 'GET',
                    credentials: 'include',
                }
                )
                    .then((res) => res.json())
                    .then((list) => {
                        dispatch(setInstallerSwitchGroup(list));
                    });
            });
    }
}


export function getSearchInstallerListLength(searchString: string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/installers?limit=100&search=${searchString}&start=0`, {
            method: 'GET',
            credentials: 'include',
        }
        )
            .then((res) => res.json())
            .then((data) => {
                dispatch(setSearchedInstallersLength(data.length));
            });
    }
}


export function getUserInstallerSearchListLength(id: any, searchString: string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/${id}`, {
            method: 'GET',
            credentials: 'include',
        }
        )
            .then((res) => res.json())
            .then(async (installer) => {
                await fetch(
                    `${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/bygroup/${installer.groupId}?filter=${searchString}&limit=100&start=0`,
                    {
                        method: 'GET',
                        credentials: 'include',
                    }
                )
                    .then((res) => res.json())
                    .then((data) => {
                        dispatch(setUserInstallerSearchListLength(data.length));
                    });
            });
    };
}

export function fetchInstallerUsers(id: any, start: number, query?: string): any {
    let notificationEnableUsersArray: any;
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/${id}`, {
            method: 'GET',
            credentials: 'include',
        }
        )
            .then((res) => res.json())
            .then(async (installer) => {
                await fetch(
                    `${REACT_APP_BASE_URL}/m2m-web-admin/rest/assignUserToInstaller/${installer.ID}`,
                    {
                        method: 'GET',
                        credentials: 'include',
                    }
                )
                    .then((res) => res.json())
                    .then((data1) => {
                        notificationEnableUsersArray = data1;
                    });

                await fetch(
                    `${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/bygroup/${installer.groupId}?filter=${query}&limit=10&start=${start}`,
                    {
                        method: 'GET',
                        credentials: 'include',
                    }
                )
                    .then((res) => res.json())
                    .then((data) => {
                        data?.forEach((element: AccountUsersProps) => {
                            element.notificationEnabledUserArray = notificationEnableUsersArray?.some(
                                (item: AccountUsersProps) => element.ID === item.ID
                            );
                        });
                        dispatch(setInstallerUsers(data));
                        //dispatch(setUserInstallerLength(data.length));
                    });
            });
    };
}

export function getUserInstallerLength(id: any): any {
    let notificationEnableUsersArray: any;
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/${id}`, {
            method: 'GET',
            credentials: 'include',
        }
        )
            .then((res) => res.json())
            .then(async (installer) => {
                await fetch(
                    `${REACT_APP_BASE_URL}/m2m-web-admin/rest/assignUserToInstaller/${installer.ID}`,
                    {
                        method: 'GET',
                        credentials: 'include',
                    }
                )
                    .then((res) => res.json())
                    .then((data1) => {
                        notificationEnableUsersArray = data1;
                    });

                await fetch(
                    `${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/bygroup/${installer.groupId}?filter=&limit=1000&start=0`,
                    {
                        method: 'GET',
                        credentials: 'include',
                    }
                )
                    .then((res) => res.json())
                    .then((data) => {
                         data?.forEach((element: AccountUsersProps) => {
                            element.notificationEnabledUserArray = notificationEnableUsersArray?.some(
                                (item: AccountUsersProps) => element.ID === item.ID
                            );
                        });
                        dispatch(setUserInstallerLength(data.length));
                    });
            });
    };
}

export function getUserAvailableGroup(id:number){
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/group/user/${id}/available`,{
            method: 'GET',
            credentials: 'include',
        })
        .then((res)=>res.json())
        .then((data)=>dispatch(setUserAvailableGroup(data)))
    }
}

export function PostEditUserDetails(body:any,id:number,availableGroup:any,notification:boolean,installerID:number){
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/${id}`,{
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
        .then((res)=>{
            dispatch(setEditUserDetails(res.status))
        })
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/${id}/groups`,{
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(availableGroup),
        })
        if(notification===true){
            await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/assignUserToInstaller/${id}/to/${installerID}`,{
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        }else{
            await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/assignUserToInstaller/${id}/from/${installerID}`,{
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        }
    }
}

export function setDeleteStatusToDefault() {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
         await dispatch(setDeleteStatus(0));
    };
}

export function getDeleteStatus(id:number){
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/${id}`,{
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((res)=>{
            dispatch(setDeleteStatus(res.status))
        })
    }
}

