export const ManagePanelsAndEstatesActionTypes = {
    PANELS_IN_ESTATE : 'PANELS_IN_ESTATE',
    MANAGE_PANELS_ESTATES: 'MANAGE_PANELS_ESTATES',
    SET_ESTATES:'SET_ESTATES',
    SET_PANELS:'SET_PANELS',
    SET_MANAGE_ESTATE_AND_PANELS_STATUS:'SET_MANAGE_ESTATE_AND_PANELS_STATUS',
    RESET_PANELS_IN_ESTATE:'RESET_PANELS_IN_ESTATE',
    SET_SPECIFIC_ESTATE_ID:'SET_SPECIFIC_ESTATE_ID',
    SET_UNASSIGNED_PANELS:'SET_UNASSIGNED_PANELS',
    SET_RADIO_BUTTON_DETAILS:'SET_RADIO_BUTTON_DETAILS'
}