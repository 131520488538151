/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Divider, MobileStepper, Snackbar, useMediaQuery, useTheme } from '@mui/material';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {
    addUserEmailAlerts,
    fetchPanelARCSettings,
    fetchSpecificPanelAndEstate,
    fetchUserEmailAlerts,
    panelArcAccessDisable,
} from '../../redux/actions/specificPanelActions';
import { SiaEmailSettingsProps } from '../UserEmailAlertsModal/UserEmailAlertsTypes';
import { useTranslation } from 'react-i18next';

type DeleteUserEmailAlertsModalProps = {
    id:number
    open: boolean;
    setOpen: (open: boolean) => void;
    openDeleteModal: boolean;
    setOpenDeleteModal: (openDeleteModal: boolean) => void;
    counter: number;
    setCounter: (counter: number) => void;
    isMobile: boolean;
    setIsMobile: (isMobile: boolean) => void;
};

export default function DeleteUserEmailAlertsModal({id, openDeleteModal, setOpenDeleteModal, isMobile, setIsMobile, open, setOpen, counter, setCounter }: DeleteUserEmailAlertsModalProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const { specificPanel, userEmailAlerts, addUserEmailAlertsStatus } = useSelector((state: any) => state.specificPanelAndEstateReducer);
    const dispatch = useTypedDispatch();
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [status, setStatus] = React.useState(false);
    const [count, setCount] = React.useState(0);
    const [openEditModal, setOpenEditModal] = React.useState(0);
    const { panelId } = useParams();
    const { t } = useTranslation();

    React.useEffect(() => {
       dispatch(fetchUserEmailAlerts(specificPanel?.panelId));
        if(status){
            if(isMobile){
                setOpen(false)
            }
            setCounter(counter + 1)
            setStatus(false)
        }
    }, [count]);

    const handleClose = () => setOpenDeleteModal(false);

    const handleDelete = async () => {
        const body = {
            device: { panelId: specificPanel?.panelId },
            siaEmailSettings: userEmailAlerts?.siaEmailSettings.filter((sia:SiaEmailSettingsProps) => sia.ID !== id)
        };
        await dispatch(addUserEmailAlerts(body));
        setStatus(true)
        setOpenDeleteModal(false);
        setCount((prev) => prev+1)
    };

    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleSnackClose}>
            {t('lbl_close')}
            </Button>
        </React.Fragment>
    );

    return (
        <>
            <Modal
                open={openDeleteModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: md ? 300 : 400,
                        bgcolor: 'background.paper',
                        border: '0px solid #000',
                        boxShadow: 20,
                        p: 0,
                    }}
                >
                    <Typography sx={{ p: 3 }} id="modal-modal-title" variant="h6" component="h2">
                    {t('lbl_delete_usrEmail')}
                    </Typography>
                    <Typography sx={{ pl: 3, pr: 3, pt: 1, pb: 4 }} id="modal-modal-description">
                    {t('msg_delete_usrEmail')}
                    </Typography>
                    <Divider />
                    <MobileStepper
                        variant="dots"
                        steps={0}
                        position="static"
                        activeStep={0}
                        sx={{
                            maxWidth: 400,
                            flexGrow: 1,
                            paddingTop: 2,
                            paddingBottom: 2,
                            paddingLeft: 3,
                            paddingRight: 3,
                            backgroundColor: 'inherit',
                        }}
                        nextButton={
                            <Button
                                size="medium"
                                variant="contained"
                                sx={{ backgroundColor: '#CA3C3D' }}
                                onClick={handleDelete}
                            >
                                {t('lbl_delete')}
                            </Button>
                        }
                        backButton={
                            <Button size="small" variant="outlined" onClick={handleClose}>
                                {t('btn_cancel')}
                            </Button>
                        }
                    />
                </Box>
            </Modal>
            <Snackbar
                open={snackOpen}
                autoHideDuration={6000}
                onClose={handleSnackClose}
                message={message}
                action={action}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
        </>
    );
}
