/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-shadow */

import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Button, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { ListItemTag } from '@brightlayer-ui/react-components';
import { useSelector } from 'react-redux';
import { CurrentUserProps } from '../../ListAndGridView/panelEstateTypes';
import EditInstallerModal from '../EditInstaller/EditInstallerModal';
import { InstallersProps } from '../InstallersTypes';
import { EditInstallerModalProps } from '../EditInstallerTypes'
import RemoveInstallerModal from '../RemoveInstallerModal/RemoveInstallerModal';
import DeactivateInstallerModal from '../DeactivateInstallerModal/DeactivateInstallerModal';
import { useTranslation, Trans } from 'react-i18next';
import secureLocalStorage from 'react-secure-storage';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.secondary,

}));

type Props ={
    count:number
    setCount:(count:number) => void
    data: InstallersProps
    setData : (data:InstallersProps) => void
    open : boolean
    setOpen : (open:boolean) => void
    spokenPwd? : string
    setSpokenPwd? : (spokenPwd:string) => void
}


export function Details({ count, setCount, data, setData, open, setOpen,spokenPwd,setSpokenPwd }: Props): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const { installersList, installerDetails, totalPanels } = useSelector((state: any) => state.installerReducer);
    const installerUser: any = secureLocalStorage.getItem('installer') || {};
    const [installer, setInstaller] = useState<CurrentUserProps>({})    
    const [openRemoveInstaller, setOpenRemoveInstaller] = useState(false)
    const [openDeactivateInstaller, setOpenDeactivateInstaller] = useState(false)
    const { t } = useTranslation();

    const getDate = (timestamp: number): string => {
        const date = new Date(new Date(timestamp).toUTCString()).getUTCDate() > 9 ? (new Date(new Date(timestamp).toUTCString()).getUTCDate().toString()) : (`0${new Date(new Date(timestamp).toUTCString()).getUTCDate().toString()}`);

        return date;
    };
    const getMonth = (timestamp: number): string => {
        const month = new Date(new Date(timestamp).toUTCString()).getUTCMonth() + 1 > 9 ? (new Date(new Date(timestamp).toUTCString()).getUTCMonth() + 1).toString() : `0${(new Date(new Date(timestamp).toUTCString()).getUTCMonth() + 1).toString()}`

        return month;
    };
    const getYear = (timestamp: number): number => {
        const year = new Date(new Date(timestamp).toUTCString()).getUTCFullYear();

        return year;
    };

    const getDateFormat = (timestamp: number): string => {
        const dateFormat = `${getDate(timestamp).toString()}/${getMonth(timestamp).toString()}/${getYear(timestamp).toString()}`

        return dateFormat
    }

    React.useEffect(() => {
        setInstaller(installerUser)
        setData(data)
    }, [count])

    const handleEditClick = (installerDetail: InstallersProps) => {
        setOpen(true)
        setData(installerDetail)        
    }

    const handleRemoveInstaller = () => {
        setOpenRemoveInstaller(true)
    }

    const handleDeactivateInstaller = () => {
        setOpenDeactivateInstaller(true)
    }

    return (
        <div style={md ? {} : { maxHeight: '100vh', overflow: 'auto' }}>
            <Box
                sx={
                    md
                        ? {
                            flexGrow: 1,
                            marginLeft: '-7%',
                            marginRight: '-7%',
                            marginTop: '-7.6%',
                            pb: 40,
                            maxHeight: '100vh',
                            overflow: 'auto',
                        }
                        : { flexGrow: 1, marginLeft: 8, marginRight: 8, marginTop: 4, pb: 40 }
                }
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Item square={md} elevation={md ? 1 : 2}>
                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        {!md && (
                                            <TableRow>
                                                <TableCell variant="head" style={{ color: '#1397D9' }}>
                                                    <Typography variant="h6">{t('lbl_info')}</Typography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <IconButton>
                                                        <EditIcon onClick={() => handleEditClick(installerDetails)} />
                                                    </IconButton>
                                                </TableCell>
                                                <EditInstallerModal count={count} setCount={setCount} data={data} setData={setData} open={open} setOpen={setOpen} spokenPwd={spokenPwd} setSpokenPwd={setSpokenPwd} />
                                            </TableRow>
                                        )}
                                        <TableRow>
                                            <TableCell variant="head">
                                                <Typography variant="subtitle1">{t('lbl_status')}</Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <ListItemTag nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                    label={installerDetails?.installerInfo?.installerState}
                                                    backgroundColor={
                                                        installerDetails?.installerInfo?.installerState === 'ACTIVE'
                                                            ? '#E0F1FD'
                                                            : '#CA3C3D'
                                                    }
                                                    fontColor={
                                                        installerDetails?.installerInfo?.installerState === 'ACTIVE'
                                                            ? '#39B620'
                                                            : '#ffff'
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell variant="head">
                                                <Typography variant="subtitle1">{t('lbl_noOfPanels')}</Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="body1">{totalPanels?.length}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell variant="head">
                                                <Typography variant="subtitle1">{t('lbl_dateRegistered')}</Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="body1">
                                                    {getDateFormat(installerDetails?.creationTimeInMilliSeconds)}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell variant="head">
                                                <Typography variant="subtitle1">{t('lbl_cloudId')}</Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="body1">
                                                    {installerDetails?.installerInfo?.cloudId}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell variant="head">
                                                <Typography variant="subtitle1">{t('lbl_spoken_pwd')}</Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="body1">
                                                    {spokenPwd}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell variant="head">
                                                <Typography variant="subtitle1">{t('lbl_email')}</Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="body1">{installerDetails?.login}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell variant="head">
                                                <Typography variant="subtitle1">{t('lbl_contactEmail')}</Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="body1">
                                                {installerDetails?.installerInfo?.contactEmail}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell variant="head">
                                                <Typography variant="subtitle1">{t('lbl_firstPhone')}</Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="body1">                                                    
                                                    {installerDetails?.installerInfo?.firstPhoneNumber?.type
                                                        .toLowerCase()}
                                                    :{installerDetails?.installerInfo?.firstPhoneNumber?.number}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell variant="head">
                                                <Typography variant="subtitle1">{t('lbl_company')}</Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="body1">
                                                    {installerDetails?.installerInfo?.installerCompanyName}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell variant="head">
                                                <Typography variant="subtitle1">{t('lbl_cmpnyAddress')}</Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="body1">
                                                    {installerDetails?.installerInfo?.address.postCode},
                                                    {installerDetails?.installerInfo?.address.country},
                                                    {installerDetails?.installerInfo?.address.address}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <RemoveInstallerModal data={installerDetails} openInstaller={openRemoveInstaller} setOpenInstaller={setOpenRemoveInstaller} />
                            <DeactivateInstallerModal count={count} setCount={setCount} data={installerDetails} openInstaller={openDeactivateInstaller} setOpenInstaller={setOpenDeactivateInstaller} />
                        </Item>
                    </Grid>
                    {!md && (
                        <Grid item xs={12}>
                            <Stack spacing={2} direction={md ? 'column' : 'row-reverse'}>
                                <Button onClick={handleDeactivateInstaller} variant="outlined">
                                {installerDetails?.installerInfo?.installerState === 'ACTIVE' ? `${t('btn_Deactivate_Installer')}`:`${t('btn_Activate_Installer')}`}
                                </Button>
                                <Button onClick={handleRemoveInstaller} sx={{ borderColor: '#CA3C3D' }} color="error" variant="outlined">
                                {t('lbl_removeInstaller')}
                                </Button>
                            </Stack>
                        </Grid>
                    )}
                </Grid>
                {md && (
                    <Paper elevation={0} sx={{
                        position: 'absolute' as 'absolute',
                        top: '94vh',
                        zIndex: 1000,
                        transform: 'translateY(-100%)',
                        width: '100%',
                        p: 2,
                    }}>
                        <Stack spacing={2} direction={md ? 'column' : 'row-reverse'}>
                            <Button onClick={handleDeactivateInstaller} variant="outlined">
                                {installerDetails?.installerInfo?.installerState === 'ACTIVE' ? `${t('btn_Deactivate_Installer')}`:`${t('btn_Activate_Installer')}`}
                            </Button>
                            <Button onClick={handleRemoveInstaller} sx={{ borderColor: '#CA3C3D' }} color="error" variant="outlined">
                            {t('lbl_removeInstaller')}
                            </Button>
                        </Stack>
                    </Paper>
                )}
            </Box>
        </div>
    );
}
