/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Box, Button, Divider, IconButton, MobileStepper, Modal, Paper, Snackbar, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import { useTypedDispatch } from '../../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Spacer } from '@brightlayer-ui/react-components';
import DeleteIcon from '@mui/icons-material/Delete';
import EditUserForm from './EditUserModal/EditUserForm';
import EditUserGroup from './EditUserModal/EditUserGroup';
import { EditProp } from './EditUserModal/EditUserTypes';
import { PostEditUserDetails } from '../../../redux/actions/installersActions';
import { useSelector } from 'react-redux';
import DeleteUserModal from './DeleteUserModal';
import { useParams } from 'react-router';
import { ROLES } from '../../../entities/Constants';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    backgroundColor: 'background.paper',
    boxShadow: 20,
    p: 0,
};


function EditUserModal({ editUserDetails, open, setOpen, counter, setCounter, isMobile, setIsMobile, deleteCounter, setDeleteCounter, installerObj }: EditProp): JSX.Element {
    const { t } = useTranslation();
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useTypedDispatch();
    const [activeStep, setActiveStep] = React.useState(0);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [message, setMessage] = React.useState<string>('');
    const [firstName, setFirstName] = React.useState<string>(editUserDetails?.firstName);
    const [lastName, setLastName] = React.useState<string>(editUserDetails?.lastName);
    const [login, setLogin] = React.useState<string>(editUserDetails?.login);
    const [userType, setUserType] = React.useState<string | undefined>(editUserDetails?.userType);
    const [notification, setNotification] = React.useState<boolean>(editUserDetails?.notificationEnabledUserArray)
    const [availableGroups, setAvailableGroups] = React.useState<number[]>([]);
    const [status, setStatus] = React.useState(false);
    const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
    const [deleteUserId, setDeleteUserID] = React.useState(0)
    const [deleteUserName, setDeleteUserName] = React.useState("")
    const { editUserStatus } = useSelector((state: any) => state.installerReducer)
    const [firstNameError, setFirstNameError] = React.useState<string>('');
    const [lastNameError, setLastNameError] = React.useState<string>('');    
    const [helperLoginText, setHelperLoginText] = React.useState('')
    const { id } = useParams()
    
    const steps = [
        {
            component: (
                <EditUserForm
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    login={login}
                    setLogin={setLogin}
                    userType={userType}
                    setUserType={setUserType}
                    notification={notification}
                    setNotification={setNotification}
                    firstNameError={firstNameError}
                    setFirstNameError={setFirstNameError}
                    lastNameError={lastNameError} 
                    setLastNameError={setLastNameError}
                    helperLoginText={helperLoginText}
                    setHelperLoginText={setHelperLoginText}
                />
            )
        },
        { component: <EditUserGroup setAvailableGroups={setAvailableGroups} /> },
    ]

    useEffect(() => {
        if (status === true) {
            if (editUserStatus === 200) {
                setCounter(counter + 1)
                setMessage(`${t('msg_userEdited')}`);
            } else if (editUserStatus === 500) {
                setMessage(`${t('msg_internal_error')}`);
            } else if (editUserStatus === 400) {
                setMessage(`${t('lbl_unauthorizedUser')}`);
            } else {
                setMessage(`${t('lbl_someError')}`);
            }
            setSnackOpen(true);
            setStatus(false);
        }
        setFirstName(editUserDetails?.firstName)
        setLastName(editUserDetails?.lastName)
        setLogin(editUserDetails?.login)
        setUserType(editUserDetails?.userType)
        setNotification(editUserDetails?.notificationEnabledUserArray)
    }, [status, editUserDetails])

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleClose = () => {
        setOpen(false);
        setActiveStep(0);
        setFirstName(editUserDetails?.firstName)
        setLastName(editUserDetails?.lastName)
        setLogin(editUserDetails?.login)
        setUserType(editUserDetails?.userType)
        setNotification(editUserDetails?.notificationEnabledUserArray)
        setFirstNameError("")
        setLastNameError("")
        setHelperLoginText("")
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const userTypes = [
        {
            code: 'ENGINEER',
            value: 'Engineer',
        },
        {
            code: 'MANAGER',
            value: 'Manager',
        },
        {
            code: 'OFFICE_STAFF',
            value: 'Office Staff',
        },
    ];


    const handleSave = async () => {
        const UserType = userTypes.find((type) => type.code === userType);
        const body = {
            ID: editUserDetails.ID,
            alertNotificationEnabled: notification,
            creationTimeInMilliSeconds: editUserDetails.creationTimeInMilliSeconds,
            firstName: firstName,
            groupId: editUserDetails.groupId,
            installerInfo: editUserDetails.installerInfo,
            lastName: lastName,
            locale: editUserDetails.locale,
            login: login,
            userType: userType,
            userTypeTitle: UserType?.value,
        }
        await dispatch(PostEditUserDetails(body, editUserDetails?.ID, availableGroups, notification, installerObj?.ID))
        setActiveStep(0);
        setOpen(false);
        setStatus(true);
    }

    const handleDeleteUser = (accountUser: any) => {
        setDeleteUserID(accountUser?.ID)
        const name = `${accountUser?.firstName} ${accountUser?.lastName}`
        setDeleteUserName(name)
        setOpenDeleteModal(true)
        setIsMobile(true)
    }
    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    }

    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleSnackClose}>
                {t('lbl_close')}
            </Button>
        </React.Fragment>
    );
    return (
        <>
            {md ? <>
                <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    <Box
                        sx={{
                            position: 'absolute' as 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'background.paper',
                            boxShadow: 20,
                            p: 0,
                        }}
                    >
                        <Paper elevation={0} sx={{ pb: 0 }}>
                            <Paper
                                elevation={4}
                                sx={{
                                    paddingTop: 2,
                                    paddingBottom: 1,
                                    paddingLeft: 2,
                                    paddingRight: 2,
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <IconButton onClick={handleClose}>
                                    <ArrowBackIcon />
                                </IconButton>
                                &ensp;&ensp;
                                <Typography sx={{ pt: 0.5 }} variant="h6">
                                    {t('lbl_editUsr')}
                                </Typography>
                                <Spacer nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                                {userType!==ROLES.INSTALLER && (
                                <IconButton
                                    onClick={() => handleDeleteUser(editUserDetails)}
                                    sx={{ flexDirection: 'row', textAlign: 'right' }}
                                    size="small">
                                    <DeleteIcon />
                                </IconButton>
                                )}
                                <DeleteUserModal
                                    open={openDeleteModal}
                                    setOpen={setOpenDeleteModal}
                                    deleteUserId={deleteUserId}
                                    deleteUserName={deleteUserName}
                                    deleteCounter={deleteCounter}
                                    setDeleteCounter={setDeleteCounter} />

                            </Paper>
                            <Divider />
                            {steps[activeStep].component}
                        </Paper>
                        <Divider />
                        <Paper
                            sx={{
                                position: 'absolute' as 'absolute',
                                top: '100vh',
                                transform: 'translateY(-100%)',
                                width: '100%',
                                zIndex: 1000
                            }}
                        >
                            <MobileStepper
                                variant="dots"
                                steps={2}
                                position="static"
                                activeStep={activeStep}
                                sx={{
                                    maxWidth: '100%',
                                    flexGrow: 1,
                                    paddingTop: 2,
                                    paddingBottom: 4,
                                    paddingLeft: 3,
                                    paddingRight: 3,
                                    backgroundColor: 'inherit',
                                }}
                                nextButton={
                                    activeStep < 1 ? (
                                        <Button size="medium" variant="contained" onClick={handleNext} disabled={firstNameError.length>0 || lastNameError.length>0 || helperLoginText.length>0 }>
                                            {t('btn_next')}
                                        </Button>
                                    ) : (
                                        <Button size="medium" variant="contained"
                                            onClick={handleSave}
                                        >
                                            {t('btn_save')}
                                        </Button>
                                    )
                                }
                                backButton={
                                    activeStep < 1 ? (
                                        <Button size="small" variant="outlined" onClick={handleClose}>
                                            {t('btn_cancel')}
                                        </Button>
                                    ) : (
                                        <Button size="small" variant="outlined" onClick={handleBack}>
                                            {t('btn_back')}
                                        </Button>
                                    )
                                }
                            />
                        </Paper>
                    </Box>
                </Modal>
                <Snackbar
                    open={snackOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackClose}
                    message={message}
                    action={action}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                />

            </> : <>

                <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    <Box sx={style}>
                        <Paper
                            elevation={0}
                            sx={{ paddingTop: 2, paddingBottom: 3, paddingLeft: 3, paddingRight: 2 }}
                        >
                            <Typography variant="h6">{t('lbl_editUsr')}</Typography>
                        </Paper>
                        <Divider />
                        <Paper elevation={0} sx={{}}>
                            {steps[activeStep].component}
                        </Paper>
                        <Divider />
                        <MobileStepper
                            variant="dots"
                            steps={2}
                            position="static"
                            activeStep={activeStep}
                            sx={{
                                maxWidth: 500,
                                flexGrow: 1,
                                paddingTop: 2,
                                paddingBottom: 2,
                                paddingLeft: 3,
                                paddingRight: 3,
                                backgroundColor: 'inherit',
                            }}
                            nextButton={
                                activeStep < 1 ? (
                                    <Button size="medium" variant="contained" onClick={handleNext} disabled={firstNameError.length>0 || lastNameError.length>0 || helperLoginText.length>0 }>
                                        {t('btn_next')}
                                    </Button>
                                ) : (
                                    <Button size="medium" variant="contained"
                                        onClick={handleSave}
                                    >
                                        {t('btn_save')}
                                    </Button>
                                )
                            }
                            backButton={
                                activeStep < 1 ? (
                                    <Button size="small" variant="outlined" onClick={handleClose}>
                                        {t('btn_cancel')}
                                    </Button>
                                ) : (
                                    <Button size="small" variant="outlined" onClick={handleBack}>
                                        {t('btn_back')}
                                    </Button>
                                )
                            }
                        />
                    </Box>
                </Modal>
                <Snackbar
                    open={snackOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackClose}
                    message={message}
                    action={action}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                />

            </>}
        </>
    )
}

export default EditUserModal
