
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */
/*eslint no-unused-expressions: "error"*/
/* eslint-disable @typescript-eslint/no-shadow */

import { Box, Button, Checkbox, InputAdornment, ListItemIcon, MenuItem, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AddLinkIcon from '@mui/icons-material/AddLink';
import { useTypedDispatch } from '../../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { getEnableRegistration, getGenerateTestNotification, getMaintenanceNotification } from '../../../redux/actions/systemToolsActions';
import { useSelector } from 'react-redux';
import DisableRegistrationPopUp from './DisableRegistrationPopUp';
import RemovePairingPopUp from './RemovePairingPopUp';
import RemoveApplicationUserPopUp from './RemoveApplicationUserPopUp';
import CheckIcon from '@mui/icons-material/Check';

function SupportAction(): JSX.Element {
    const [userEmail, setUserEmail] = useState("")
    const [panelID, setPanelId] = useState("")
    const [actionMenu, setActionMenu] = useState("Please select")
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [disablePopup, setDisablePopup] = useState(false)
    const [removePairingPopUp, setRemovePairingPopUp] = useState(false)
    const [removeApplicationPopUp, setRemoveApplicationPopUp] = useState(false)
    const { enableRegistration, disableRegistration, maintenanceNotification, generateTestNotification, removePairing, removeApplicationUser } = useSelector((state: any) => state.systemToolReducer);
    const { t } = useTranslation()
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useTypedDispatch();
    const [count, setCount] = useState(0)
    const [emailError, setEmailError] = useState(null);
    const [helperEmailText,setHelperEmailText]=useState("")
    const [panelIdError, setPanelIdError] = useState("");

    const actions = [

        {
            id: "1",
            des: "Remove pairing",
            Data: "remove"
        },
        {
            id: "2",
            des: "Remove application user",
            Data: "mobileUser"
        },
        {
            id: "3",
            des: "Generate test notification",
            Data: "testNotification"
        },
        {
            id: "4",
            des: "Send maintenance notification",
            Data: "notificateCloudUsers"
        },
        {
            id: "5",
            des: "Disable registration",
            Data: "disableRegistration"
        },
        {
            id: "6",
            des: "Enable registration",
            Data: "enableRegistration"
        },
    ]

    const handleEmailInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const emailValue = event.target.value;
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(emailValue)) {
            setEmailError(t('Invalid email Id'));
        }else if(emailValue.length>30){
            setHelperEmailText(`${t('msg_inputLength')}`)
        } else {
            setEmailError(null);
            setHelperEmailText("")
        }
        setUserEmail(emailValue);
    }

    const handlePanelID=(event: React.ChangeEvent<HTMLInputElement>)=>{
        const value=event.target.value;
        setPanelId(value)
        if(value.length>30){
            setPanelIdError(`${t('msg_inputLength')}`)
        }else{
            setPanelIdError("")
        }
    }

    const handleStartAction = async () => {

        switch (actionMenu) {
            case "enableRegistration":
                await dispatch(getEnableRegistration(actionMenu))
                setSnackOpen(true);
                break;

            case 'disableRegistration':
                setDisablePopup(true);
                break;

            case 'notificateCloudUsers':
                await dispatch(getMaintenanceNotification(actionMenu));
                setSnackOpen(true);
                break;

            case 'testNotification':
                await dispatch(getGenerateTestNotification(actionMenu, userEmail));
                setSnackOpen(true);
                break;

            case 'remove':
                setRemovePairingPopUp(true);
                break;

            case 'mobileUser':
                setRemoveApplicationPopUp(true);
                break;

            default:
                break;
        }

        setCount(count + 1)
    }

    useEffect(() => {
        if (snackOpen) {
            if (actionMenu === 'enableRegistration') {
                if (enableRegistration === 204) {
                    setMessage(`${t('Registration was enabled')}`);
                }
            }
            else if (actionMenu === 'disableRegistration') {
                if (disableRegistration === 204) {
                    setMessage(`${t('disable was enabled')}`);
                }
            }
            else if (actionMenu === 'notificateCloudUsers') {
                if (maintenanceNotification === 204) {
                    setMessage(`Maintenance notification was successfully sent`);
                }
            }
            else if (actionMenu === 'testNotification') {
                if (generateTestNotification === 204) {
                    setMessage(`Test notification was successfully sent`);
                } else if (generateTestNotification === 404) {
                    setMessage(`Mobile application user with email ${userEmail} does not exist`)
                }
            } else if (actionMenu === 'remove') {
                if (removePairing === 200) {
                    setMessage(`Pairing was successfully removed`);
                }
            } else if (actionMenu === 'mobileUser') {
                if (removeApplicationUser === 200) {
                    setMessage(`Pairing was successfully removed`);
                } else if (removeApplicationUser === 404) {
                    setMessage(`Mobile application user with email ${userEmail} does not exist`)
                }
            }
            else {
                setMessage(`${t('msg_error')}`);
            }
        }
    }, [count])


    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setActionMenu(event.target.value)
    }
    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleSnackClose}>
                {t('lbl_close')}
            </Button>
        </React.Fragment>
    );

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead >
                        <TableRow>
                            <TableCell variant='head' style={{ color: '#1397D9' }}>
                                <Typography sx={{ fontWeight: "600" }}>
                                    {t('heading_Support_actions')}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <TextField variant="filled" id='filled-user-email' fullWidth label={t('lbl_User_e-mail')} value={userEmail} onChange={handleEmailInput} error={emailError !== null || helperEmailText.length>0} helperText={emailError || helperEmailText} />
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>
                                <TextField variant="filled" id='filled-panel-id' fullWidth label={t('lbl_panelId')} value={panelID} onChange={handlePanelID} error={panelIdError.length>0} helperText={panelIdError} />
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>
                                <TextField variant="filled" id='filled-action' fullWidth select label={t('lbl_Action')} value={actionMenu} onChange={handleChange}>
                                    <MenuItem disabled value="Please select">
                                        {t('lbl_plz_select')}
                                    </MenuItem>
                                    {actions.map((state) => (
                                        <MenuItem key={state.id} value={state.Data}>
                                            {state.des}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='right'>
                                {md ? <>
                                    <Button variant="contained" fullWidth
                                        onClick={handleStartAction}
                                        disabled={(userEmail.length === 0) || (panelID.length === 0) || panelIdError.length>0 ||helperEmailText.length>0}>
                                        <AddLinkIcon /> &nbsp; {t('btn_Startaction')}
                                    </Button>
                                </> : <>
                                    <Button variant="contained"
                                        onClick={handleStartAction}
                                        disabled={(userEmail.length === 0) || (panelID.length === 0) || panelIdError.length>0 || helperEmailText.length>0}>
                                        <AddLinkIcon /> &nbsp;   {t('btn_Startaction')}
                                    </Button>
                                </>}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Snackbar
                open={snackOpen}
                autoHideDuration={6000}
                onClose={handleSnackClose}
                message={message}
                action={action}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
            {disablePopup &&
                <DisableRegistrationPopUp open={disablePopup} setOpen={setDisablePopup} snackOpen={snackOpen} setSnackOpen={setSnackOpen} count={count} setCount={setCount} actionMenu={actionMenu === 'disableRegistration' ? "disableRegistration" : ""} />
            }
            {
                removePairingPopUp &&
                <RemovePairingPopUp open={removePairingPopUp} setOpen={setRemovePairingPopUp} snackOpen={snackOpen} setSnackOpen={setSnackOpen} count={count} setCount={setCount} actionMenu={actionMenu === 'remove' ? "remove" : ""} userEmail={userEmail} panelID={panelID} />
            }
            {
                removeApplicationPopUp &&
                <RemoveApplicationUserPopUp open={removeApplicationPopUp} setOpen={setRemoveApplicationPopUp} snackOpen={snackOpen} setSnackOpen={setSnackOpen} count={count} setCount={setCount} actionMenu={actionMenu === 'mobileUser' ? "mobileUser" : ""} userEmail={userEmail} />
            }
        </>
    )
}

export default SupportAction
