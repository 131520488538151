/* eslint-disable @typescript-eslint/naming-convention */
const resources = {
    "menu_oveview": "ΣΦΑΙΡΙΚΗ ΕΙΚΟΝΑ",
    "menu_endUserInfo": "Πληροφορίες τελικού χρήστη",
    "menu_accntUsrs": "Χρήστες λογαριασμού",
    "menu_serviceReports": "Αναφορές υπηρεσιών",
    "menu_settings": "Ρυθμίσεις",
    "menu_help": "Πληροφορίες",
    "lbl_cloudId": "Cloud ID",
    "lbl_logOut": "Αποσύνδεση",
    "lbl_contactSupport": "ΕΠΙΚΟΙΝΩΝΗΣΤΕ ΜΕ ΤΗΝ ΥΠΟΣΤΗΡΙΞΗ",
    "lbl_estates": "Κτήματα",
    "lbl_ESTATES": "ΚΤΗΜΑΤΑ",
    "lbl_panels": "Πάνελ",
    "lbl_PANELS": "ΠΑΝΕΛ",
    "lbl_connected": "Συνδεδεμένος",
    "lbl_disconnected": "Ασύνδετος",
    "lbl_manageEstates": "Διαχειριστείτε κτήματα",
    "lbl_newEstate": "Νέο Κτήμα",
    "lbl_estate": "Περιουσία",
    "lbl_search": "Αναζήτηση…",
    "lbl_manage_estateAndpanels": "Διαχείριση κτημάτων και πάνελ",
    "btn_cancel": "Ματαίωση",
    "btn_save": "Αποθηκεύσετε",
    "msg_panels_moved": "Τα πάνελ μετακινήθηκαν με επιτυχία",
    "lbl_name": "Ονομα",
    "lbl_description": "Περιγραφή",
    "btn_next": "Επόμενο",
    "lbl_contact_name": "όνομα επαφής",
    "lbl_contact_phNo": "Τηλέφωνο επικοινωνίας",
    "lbl_country": "Χώρα",
    "lbl_address": "Διεύθυνση",
    "lbl_postcode": "ΤΑΧΥΔΡΟΜΙΚΟΣ ΚΩΔΙΚΟΣ",
    "lbl_selectedPanels": "Επιλεγμένα πάνελ",
    "lbl_close": "Κλείσε",
    "msg_newEstate_created": "Νέο κτήμα δημιουργήθηκε με επιτυχία",
    "msg_estateExists": "Το κτήμα με αυτό το όνομα υπάρχει ήδη",
    "tooltip_ARC_Access": "Πρόσβαση ARC",
    "tooltip_usrEmail_alerts": "Ειδοποιήσεις μέσω email χρήστη",
    "tooltip_usrAccess": "Πρόσβαση χρήστη",
    "tooltip_installerEmail_alerts": "Ειδοποιήσεις ηλεκτρονικού ταχυδρομείου του προγράμματος εγκατάστασης",
    "tooltip_remoteServicing": "Απομακρυσμένη εξυπηρέτηση",
    "lbl_allPanel": "Όλα τα πάνελ",
    "lbl_favorites": "Αγαπημένα",
    "lbl_ARC_enabled": "Ενεργοποιημένο ARC",
    "lbl_end_usrEmail_enabled": "Το email τελικού χρήστη ενεργοποιήθηκε",
    "lbl_end_usrAccess_disabled": "Η πρόσβαση τελικού χρήστη απενεργοποιήθηκε",
    "lbl_installer_emailAlerts_enabled": "Οι ειδοποιήσεις email προγράμματος εγκατάστασης είναι ενεργοποιημένες",
    "lbl_remoteServicing_enabled": "Η απομακρυσμένη εξυπηρέτηση είναι ενεργοποιημένη",
    "msg_no_panels_found": "Δεν υπάρχουν διαθέσιμα πλαίσια για τα παραπάνω φίλτρα",
    "lbl_move_panel": "Μετακίνηση πίνακα",
    "lbl_delete_panel": "Διαγραφή πίνακα",
    "lbl_delete": "Διαγράφω",
    "lbl_delete_estate": "Διαγραφή περιουσίας",
    "msg_delete_estate": "Είστε βέβαιοι ότι θέλετε να διαγράψετε την περιουσία",
    "msg_no_action_revert": "Δεν είναι δυνατή η επαναφορά αυτής της ενέργειας.",
    "msg_estate_deleted": "Η περιουσία διαγράφηκε με επιτυχία",
    "msg_deleteEstate_error": "Σφάλμα κατά τη διαγραφή της περιουσίας",
    "msg_noSearch_results": "Δεν ταιριάζουν αποτελέσματα",
    "msg_deletePanel": "Είστε βέβαιοι ότι θέλετε να διαγράψετε τον πίνακα",
    "msg_deletePanel_error": "Σφάλμα κατά τη διαγραφή του πίνακα",
    "msg_panelDeleted": "Ο πίνακας διαγράφηκε με επιτυχία",
    "lbl_viewEstate": "Δείτε το Κτήμα",
    "lbl_goToPanel": "Μεταβείτε στον πίνακα",
    "lbl_subEstate": "Υπο-κτήμα",
    "msg_noEstatesConfigured": "Δεν έχουν διαμορφωθεί κτήματα",
    "msg_noPanelsConnected": "Δεν συνδέονται πάνελ",
    "tab_notifications": "ΙΣΤΟΡΙΑ ΠΑΝΕΛ",
    "tab_overview": "ΣΦΑΙΡΙΚΗ ΕΙΚΟΝΑ",
    "tab_panelLog": "ΗΜΕΡΟΛΟΓΙΟ ΠΙΝΑΚΑΣ",
    "tab_ARC_reporting": "ARC REPORTING",
    "tab_connect": "ΣΥΝΔΕΩ-ΣΥΩΔΕΟΜΑΙ",
    "tab_keypad": "ΠΛΗΚΤΡΟΛΟΓΙΟ",
    "tab_remoteServicing": "ΤΗΛΕΠΙΚΟΙΝΩΝΙΑ",
    "tab_mobileApp_usrs": "ΧΡΗΣΤΕΣ ΕΦΑΡΜΟΓΗΣ ΚΙΝΗΤΩΝ",
    "lbl_mobAppUsrs": "Χρήστες εφαρμογών για κινητά",
    "lbl_summary": "Περίληψη",
    "lbl_status": "Status",
    "lbl_panelId": "Ταυτότητα πίνακα",
    "lbl_macAddress": "Διεύθυνση MAC",
    "lbl_firmware": "Υλικολογισμικό",
    "msg_panelIdCopied": "Το PanelID αντιγράφηκε στο πρόχειρο",
    "lbl_panelAccess": "Πρόσβαση πίνακα",
    "lbl_enabled": "Ενεργοποιήθηκε",
    "lbl_disabled": "άτομα με ειδικές ανάγκες",
    "lbl_connectionStats": "Στατιστικά σύνδεσης",
    "lbl_totalTime": "Συνολικός χρόνος (ώρες)",
    "lbl_connectedTime": "Συνδεδεμένοι (ώρες)",
    "lbl_disconnectedTime": "Αποσυνδέθηκε (ώρες)",
    "lbl_connectedPercent": "Συνδεδεμένος(%)",
    "lbl_siteDetails": "Στοιχεία τοποθεσίας",
    "lbl_cloudReference": "Αναφορά στο σύννεφο",
    "lbl_siteAddress": "Διεύθυνση τοποθεσίας",
    "lbl_optional": "Προαιρετικός",
    "lbl_somethingWrong": "Κάτι πήγε στραβά",
    "msg_siteDetails_edited": "Οι λεπτομέρειες του ιστότοπου επεξεργάστηκαν με επιτυχία",
    "lbl_ARCProvider": "Πάροχος ARC",
    "lbl_ARCName": "Όνομα ARC",
    "lbl_accntNo": "Αριθμός λογαριασμού",
    "lbl_IP_version": "Έκδοση SIA-IP    ",
    "lbl_primaryIP": "Κύρια IP",
    "lbl_secondaryIP": "Δευτερεύουσα IP",
    "lbl_primaryPort": "Πρωτοβάθμιο Λιμάνι",
    "lbl_secondaryPort": "Δευτεροβάθμιο Λιμάνι",
    "lbl_encryption": "Κρυπτογράφηση",
    "option_no_encryption": "ΟΧΙ ΚΡΥΠΤΩΣΗ",
    "lbl_report_email": "Αναφορά email",
    "msg_ARC_edited": "Η πρόσβαση ARC επεξεργάστηκε με επιτυχία",
    "msg_ARC_enabled": "Η πρόσβαση ARC ενεργοποιήθηκε με επιτυχία",
    "msg_ARC_updated": "Η πρόσβαση ARC ενεργοποιήθηκε και ενημερώθηκε",
    "btn_back": "Πίσω",
    "msg_ARC_disabled": "Η πρόσβαση ARC απενεργοποιήθηκε",
    "msg_error": "Λάθος",
    "lbl_dateOfService": "Ημερομηνία επόμενης υπηρεσίας",
    "lbl_serviceFrquency": "Συχνότητα σέρβις",
    "option_svc_frqncy_1": "Μηνιαίο",
    "option_svc_frqncy_2": "6 Μηνιαία",
    "msg_remote_srvcng_enable": "Η απομακρυσμένη εξυπηρέτηση ενεργοποιήθηκε με επιτυχία",
    "msg_remote_srvcng_disabled": "Η απομακρυσμένη εξυπηρέτηση απενεργοποιήθηκε με επιτυχία",
    "msg_email_alert_enabled": "Η ειδοποίηση email προγράμματος εγκατάστασης ενεργοποιήθηκε με επιτυχία",
    "msg_email_alert_edited": "Η ειδοποίηση email του εγκαταστάτη επεξεργάστηκε με επιτυχία",
    "lbl_email_alert_disable": "Απενεργοποιήστε τις ειδοποιήσεις email του προγράμματος εγκατάστασης",
    "msg_email_alert_disable": "Είστε βέβαιοι ότι θέλετε να απενεργοποιήσετε τις ειδοποιήσεις ηλεκτρονικού ταχυδρομείου του προγράμματος εγκατάστασης;",
    "btn_disable": "Καθιστώ ανίκανο",
    "msg_emailAlert_disabled": "Η ειδοποίηση email προγράμματος εγκατάστασης απενεργοποιήθηκε με επιτυχία",
    "lbl_enable_usrAccess": "Ενεργοποίηση πρόσβασης χρήστη",
    "lbl_disable_usrAccess": "Απενεργοποιήστε την πρόσβαση χρήστη",
    "msg_enable_usrAccess": "Είστε βέβαιοι ότι θέλετε να ενεργοποιήσετε την πρόσβαση χρήστη;",
    "btn_enable": "Ermöglichen",
    "msg_usrAccess_enabled": "Η πρόσβαση χρήστη ενεργοποιήθηκε με επιτυχία",
    "msg_usrAccess_disabled": "Η πρόσβαση χρήστη απενεργοποιήθηκε με επιτυχία",
    "msg_usrAccess_error": "Σφάλμα κατά την ενεργοποίηση/απενεργοποίηση της πρόσβασης χρήστη",
    "btn_addUsr": "Πρόσθεσε χρήστη",
    "lbl_addUsr": "προσθέστε χρήστες, ώστε να λαμβάνουν ειδοποίηση για συμβάντα για αυτό το πλαίσιο",
    "lbl_events": "Εκδηλώσεις",
    "lbl_sets": "Σκηνικά",
    "lbl_alarms": "Συναγερμός",
    "lbl_tampers": "Παραβιάσεις",
    "lbl_system": "Σύστημα",
    "msg_emailAlert_added": "Οι ειδοποιήσεις email χρήστη προστέθηκαν με επιτυχία.",
    "lbl_edit_emailAlerts": "Επεξεργασία ειδοποιήσεων email χρήστη",
    "msg_usrEmail_edited": "Το email χρήστη επεξεργάστηκε με επιτυχία",
    "lbl_delete_usrEmail": "Διαγραφή ειδοποιήσεων email χρήστη",
    "msg_delete_usrEmail": "Είστε βέβαιοι ότι θέλετε να διαγράψετε τις ειδοποιήσεις ηλεκτρονικού ταχυδρομείου των χρηστών;",
    "msg_usr_emailDeleted": "Η ειδοποίηση email χρήστη διαγράφηκε με επιτυχία",
    "tootltip_exportNotification": "Ειδοποιήσεις εξαγωγής",
    "lbl_delivered": "ΠΑΡΑΔΟΘΗΚΕ",
    "lbl_failed": "ΑΠΕΤΥΧΕ",
    "lbl_information": "Πληροφορίες",
    "lbl_evntDetails": "Λεπτομέρειες εκδήλωσης",
    "lbl_systmName": "Όνομα συστήματος",
    "lbl_Id": "ταυτότητα",
    "lbl_partition": "Χώρισμα",
    "lbl_user": "Χρήστης",
    "lbl_username": "Όνομα χρήστη",
    "lbl_text": "Κείμενο",
    "lbl_verbose": "Πολύλογος",
    "lbl_timestamp": "Χρονική σήμανση",
    "lbl_code": "Κώδικας",
    "lbl_group": "Ομάδα",
    "lbl_SIA_IP": "SIA IP DIRECT",
    "lbl_CLS_Dualcom": "CSL DualCom",
    "lbl_webway": "WebWayOne",
    "lbl_noResponse": "ΚΑΜΙΑ ΑΠΑΝΤΗΣΗ",
    "lbl_rejected": "ΑΠΟΡΡΙΦΘΗΚΕ",
    "lbl_readyForDelivering": "ΕΤΟΙΜΟ ΓΙΑ ΠΑΡΑΔΟΣΗ",
    "lbl_sent": "ΑΠΕΣΤΑΛΜΕΝΑ",
    "lbl_exportLogs": "Εξαγωγή αρχείων καταγραφής",
    "lbl_failedSvc": "Αποτυχημένες υπηρεσίες",
    "lbl_ScheduledSvc": "Προγραμματισμένες υπηρεσίες",
    "lbl_pendingSvc": "Χωρίς προγραμματισμένες ή αποτυχημένες υπηρεσίες",
    "lbl_cmpltd_svcReports": "Ολοκληρωμένες αναφορές σέρβις",
    "lbl_report_no": "Αριθμός αναφοράς",
    "lbl_cmpltd_date": "Ημερομηνία ολοκλήρωσης",
    "lbl_cmpltd_Classification": "Ταξινόμηση",
    "lbl_cmpltd_View": "Θέα",
    "lbl_service_report": "Αναφορά υπηρεσίας",
    "lbl_delete_svcReport": "Servicebericht löschen",
    "msg_delete_svcReport": "Είστε βέβαιοι ότι θέλετε να διαγράψετε την αναφορά υπηρεσίας;",
    "msg_svcReport_deleted": "Η αναφορά υπηρεσίας διαγράφηκε με επιτυχία",
    "btn_perform_remoteSvc": "Εκτελέστε απομακρυσμένη εξυπηρέτηση",
    "msg_remoteSvc_initiated": "Ξεκίνησε η απομακρυσμένη υπηρεσία",
    "msg_remoteSvc_inProgress": "η υπηρεσία είναι σε εξέλιξη",
    "lbl_errorOccurred": "Προέκυψε σφάλμα",
    "lbl_noCmpltd_svc": "Δεν υπάρχουν ολοκληρωμένες υπηρεσίες",
    "lbl_noCmpltd_svcReports": "Δεν υπάρχουν ολοκληρωμένες αναφορές σέρβις",
    "lbl_mobApp_usrs": "Χρήστες εφαρμογών για κινητά",
    "msg_usr_authorized": "Ο χρήστης δεν είναι εξουσιοδοτημένος",
    "msg_internal_error": "Εσωτερικό Σφάλμα Διακομιστή",
    "msg_try_later_error": "Κάτι πήγε στραβά. ΠΑΡΑΚΑΛΩ προσπαθησε ξανα.",
    "lbl_info_diplayed": "Πληροφορίες που εμφανίζονται στην εφαρμογή για κινητά",
    "lbl_compnyName": "Όνομα εταιρείας",
    "lbl_officeNo": "Αριθμός τηλεφώνου γραφείου",
    "lbl_outOfHrs": "Αριθμός τηλεφώνου εκτός ωραρίου",
    "lbl_contactEmail": "E-mail επικοινωνίας",
    "lbl_logo": "Λογότυπο",
    "btn_uploadLogo": "Μεταφόρτωση λογότυπου",
    "btn_dragLogo": "ή σύρετε το λογότυπο εδώ",
    "msg_endUsrInfo_edited": "Τα στοιχεία τελικού χρήστη επεξεργάστηκαν με επιτυχία",
    "btn_remove": "Αφαιρώ",
    "lbl_userType": "Τύπος χρήστη",
    "lbl_login": "Σύνδεση",
    "lbl_email_notification": "Ειδοποίηση ηλεκτρονικού ταχυδρομείου",
    "lbl_delete_accntUsr": "Διαγραφή χρήστη λογαριασμού",
    "msg_deleteUsr": "Είστε βέβαιοι ότι θέλετε να διαγράψετε χρήστη;",
    "msg_deleteUsr_error": "Σφάλμα κατά τη διαγραφή χρήστη λογαριασμού",
    "msg_accntUsr_deleted": "Ο χρήστης λογαριασμού διαγράφηκε με επιτυχία",
    "lbl_firstName": "Ονομα",
    "lbl_lastName": "Επίθετο",
    "lbl_typeUsr": "Πληκτρολογήστε Χρήστης",
    "lbl_emailNotifications": "Ειδοποιήσεις ηλεκτρονικού ταχυδρομείου",
    "lbl_userPemissions": "Δικαιώματα χρήστη",
    "msg_userEdited": "Ο χρήστης επεξεργάστηκε με επιτυχία",
    "lbl_unauthorizedUser": "Μη εξουσιοδοτημένος χρήστης",
    "lbl_someError": "Παρουσιάστηκε κάποιο σφάλμα",
    "lbl_pwd": "Κωδικός πρόσβασης",
    "lbl_repeat_pwd": "Επαναλάβετε τον κωδικό πρόσβασης",
    "lbl_engineer": "Μηχανικός",
    "lbl_manager": "Διευθυντής",
    "lbl_installer": "Εγκαταστάτης",
    "lbl_officeStaff": "Προσωπικό γραφείου",
    "msg_noMatch_pwd": "Αταίριαστοι κωδικοί πρόσβασης!",
    "msg_usr_added": "Ο χρήστης προστέθηκε με επιτυχία",
    "lbl_panel": "Πίνακας",
    "lbl_accnt_holder": "Κάτοχος λογαριασμού",
    "lbl_spoken_pwd": "Προφορικός κωδικός πρόσβασης",
    "lbl_language": "Γλώσσα",
    "lbl_cmpnyInfo": "Στοιχεία της εταιρείας",
    "lbl_firstPh": "Πρώτο τηλέφωνο",
    "lbl_secondPh": "Δεύτερο τηλέφωνο",
    "lbl_thirdPh": "Τρίτο τηλέφωνο",
    "lbl_oldPwd": "ΠΑΛΙΟΣ ΚΩΔΙΚΟΣ",
    "lbl_newPwd": "Νέος Κωδικός",
    "lbl_repeatPwd": "Επαναλάβετε τον κωδικό πρόσβασης",
    "msg_accntHolder_edited": "Ο κάτοχος λογαριασμού επεξεργάστηκε με επιτυχία",
    "lbl_firstPhType": "Πρώτος τύπος τηλεφώνου",
    "lbl_firstPhone": "Πρώτος αριθμός τηλεφώνου",
    "lbl_secondPhone": "Δεύτερος αριθμός τηλεφώνου",
    "lbl_thirdPhone": "Τρίτος αριθμός τηλεφώνου",
    "lbl_secondPhType": "Δεύτερος τύπος τηλεφώνου",
    "lbl_thirdPhType": "Τρίτος τύπος τηλεφώνου",
    "lbl_home": "Σπίτι",
    "lbl_mobile": "Κινητό",
    "lbl_office": "Γραφείο",
    "msg_cmpnyInfo_edited": "Οι πληροφορίες εταιρείας επεξεργάστηκαν με επιτυχία",
    "lbl_termsConditions": "Οροι και Προϋποθέσεις",
    "lbl_privacyPolicy": "Πολιτική Απορρήτου",
    "msg_email_invalid": "Το email δεν είναι έγκυρο",
    "msg_unmatchedPwd": "Αταίριαστοι κωδικοί πρόσβασης!",
    "lbl_installerGrp": "Ομάδα εγκαταστάτη",
    "msg_usrExist": "Ο χρήστης με τη σύνδεση υπάρχει ήδη",
    "lbl_editUsr": "Επεξεργασία χρήστη",
    "msg_field_required": "Αυτό το πεδίο είναι υποχρεωτικό",
    "lbl_disable_ARC": "Απενεργοποιήστε την πρόσβαση ARC",
    "msg_disable_ARC": "Είστε βέβαιοι ότι θέλετε να απενεργοποιήσετε την πρόσβαση ARC;",
    "lbl_SiaEvnts": "Sia Events",
    "lbl_SIAEvnt": "Εκδήλωση SIA",
    "msg_prohibited_chars": "η είσοδος περιέχει απαγορευμένους χαρακτήρες",
    "msg_inputLength": "Το μήκος εισαγωγής δεν πρέπει να υπερβαίνει τους 30 χαρακτήρες",
    "lbl_favPanels": "ΑΓΑΠΗΜΕΝΑ ΠΑΝΕΛ",
    "lbl_lastConnection": "Τελευταία σύνδεση",
    "lbl_androidPh": "Τηλέφωνο Android",
    "lbl_iPhone": "Το iPhone μου",
    "lbl_alexa": "Alexa",
    "lbl_gHome": "Google Home",
    "lbl_removePairing": "Κατάργηση σύζευξης",
    "msg_removePairing": "Είστε βέβαιοι ότι θέλετε να καταργήσετε τη σύζευξη για αυτόν τον χρήστη της εφαρμογής;",
    "lbl_no": "Οχι",
    "lbl_yes": "Ναί",
    "lbl_device": "Συσκευή",
    "lbl_appDetails": "Στοιχεία Εφαρμογής",
    "lbl_myAndroid": "Το Android μου",
    "lbl_myAlexa": "Alexa μου",
    "lbl_myiPhone": "Το iPhone μου",
    "lbl_mygHome": "Το Google Home μου",
    "lbl_appID": "Αναγνωριστικό εφαρμογής",
    "lbl_mobOS": "Mobile OS",
    "lbl_token": "Ενδειξη",
    "msg_didNotMovePanl": "Δεν μετακινήθηκε κανένα πλαίσιο",
    "lbl_panlsSelected": "Επιλεγμένα πάνελ",
    "lbl_panlSelected": "Επιλέχθηκε ο πίνακας",
    "lbl_ARCCode": "Κωδικός ARC",
    "lbl_response": "Απάντηση",
    "lbl_data": "Δεδομένα",
    "lbl_state": "κατάσταση",
    "lbl_signal_Timeout": "Λήξη χρονικού ορίου σήματος",
    "lbl_sysID": "Αναγνωριστικό συστήματος",
    "lbl_date": "Ημερομηνία",
    "lbl_remove_destination": "Προορισμοί email απομακρυσμένης εξυπηρέτησης",
    "lbl_emailAddress": "Διεύθυνση ηλεκτρονικού ταχυδρομείου",
    "Reports": "Αναφορές",
    "lbl_green": "ΠΡΑΣΙΝΟΣ",
    "lbl_amber": "ΚΕΧΡΙΜΠΑΡΙ",
    "lbl_red": "ΤΟ ΚΟΚΚΙΝΟ",
    "lbl_reportDetails": "Λεπτομέρειες αναφοράς",
    "lbl_dueDate": "Ημερομηνία λήξης",
    "btn_edit": "επεξεργασία",
    "lbl_nameAZ": "Όνομα (A-Z)",
    "lbl_nameZA": "Όνομα (Z-A)",
    "lbl_mostAlarms": "Οι περισσότεροι συναγερμοί",
    "lbl_fewerAlarms": "Λιγότεροι συναγερμοί",
    "lbl_updatedRecent": "Ενημερώθηκε πρόσφατα",
    "lbl_none": "Κανένας",
    "msg_confirmation": "Είστε σίγουροι ότι θέλετε να",
    "msg_enable": "επιτρέπω",
    "msg_disable": "καθιστώ ανίκανο",
    "msg_edited": "επεξεργάστηκε με επιτυχία",
    "btn_addEmail": "Προσθήκη προορισμού email",
    "lbl_emailDestinationAdd": "προσθέστε προορισμό email, ώστε οι απομακρυσμένες αναφορές υπηρεσιών να μπορούν να αποστέλλονται μέσω email",
    "lbl_termsToUseWebsite": "Οι όροι με τους οποίους μπορείτε να χρησιμοποιήσετε τον ιστότοπό μας",
    "lbl_termsToUseWebsite_1.1": "Αυτοί οι Όροι Χρήσης (μαζί με τα άλλα έγγραφα που αναφέρονται παρακάτω) καθορίζουν τους όρους βάσει των οποίων μπορείτε να χρησιμοποιήσετε τον ιστότοπό μας",
    "lbl_termsToUseWebsite_1.1part": "Σας συνιστούμε να εκτυπώσετε ένα αντίγραφο αυτών των Όρων Χρήσης για μελλοντική αναφορά.",
    "lbl_termsToUseWebsite_1.2": "Θα πρέπει να διαβάσετε προσεκτικά αυτούς τους Όρους Χρήσης πριν χρησιμοποιήσετε τον ιστότοπό μας, καθώς χρησιμοποιώντας τον ιστότοπό μας επιβεβαιώνετε ότι αποδέχεστε αυτούς τους Όρους Χρήσης και ότι θα συμμορφώνεστε με αυτούς.",
    "lbl_termsToUseWebsite_1.3": "Εάν δεν αποδέχεστε αυτούς τους Όρους Χρήσης, θα πρέπει να εγκαταλείψετε τον ιστότοπό μας αμέσως",
    "lbl_termsToUseWebsite_1.4": "Η ακόλουθη πολιτική ισχύει επίσης για τη χρήση του ιστότοπού μας",
    "lbl_termsToUseWebsite_1.4.1": "Η πολιτική απορρήτου και τα cookies μας",
    "lbl_termsToUseWebsite_1.4.1part": "Χρησιμοποιώντας τον ιστότοπό μας, συναινείτε να επεξεργαζόμαστε οποιαδήποτε προσωπικά δεδομένα που μας παρέχετε ή που συλλέγουμε από εσάς ως αποτέλεσμα της χρήσης του ιστότοπού μας. Η πολιτική απορρήτου μας καθορίζει τι κάνουμε με αυτά τα προσωπικά δεδομένα",
    "lbl_termsToUseWebsite_1.5": "Είστε υπεύθυνοι να διασφαλίσετε ότι οποιοσδήποτε άλλος έχει πρόσβαση στον ιστότοπό μας μέσω της σύνδεσής σας στο Διαδίκτυο γνωρίζει αυτούς τους Όρους Χρήσης και τις πολιτικές που αναφέρονται παραπάνω και ότι συμμορφώνεται με αυτούς.",
    "lbl_chngesToTerms": "Αλλαγές σε αυτούς τους Όρους Χρήσης και τις άλλες πολιτικές μας",
    "lbl_chngesToTerms_2.1": "Κάνουμε αλλαγές στους παρόντες Όρους Χρήσης κατά καιρούς. Επομένως, θα πρέπει να ελέγχετε αυτούς τους Όρους Χρήσης κάθε φορά που επιστρέφετε στον ιστότοπό μας για να δείτε εάν έχουν γίνει αλλαγές, καθώς αυτοί θα είναι δεσμευτικοί για εσάς.",
    "lbl_chngesToTerms_2.2": "Πραγματοποιούμε επίσης αλλαγές στην πολιτική απορρήτου μας και στην πολιτική cookies κατά καιρούς, επομένως θα πρέπει επίσης να τις ελέγχετε τακτικά για να δείτε εάν έχουν γίνει αλλαγές.",
    "lbl_aboutUs": "Σχετικά με εμάς",
    "lbl_aboutUs_3.1": "Εμείς, η Eaton Electrical Products Ltd, διαχειριζόμαστε αυτόν τον ιστότοπο στη διεύθυνση www.eatonsecureconnect.com. Είμαστε μια εταιρεία εγγεγραμμένη στην Αγγλία και την Ουαλία με τον αριθμό εταιρείας 01342230 και η έδρα μας βρίσκεται στη διεύθυνση 6 Jephson Court, Tancred Close, Queensway, Royal Leamington Spa, Warwickshire, CV31 3RZ.",
    "lbl_aboutUs_3.2": "Μπορείτε να επικοινωνήσετε μαζί μας",
    "lbl_aboutUs_3.2part": "Με email στο",
    "lbl_restrictionsOnUseOfWebsite": "Περιορισμοί στη χρήση του ιστότοπού μας",
    "lbl_restrictionsOnUseOfWebsite_4.1": "Μπορείτε να χρησιμοποιήσετε τον ιστότοπό μας μόνο για νόμιμους σκοπούς και σε συμμόρφωση με όλους τους ισχύοντες νόμους, συμπεριλαμβανομένων χωρίς περιορισμό των νόμων περί προστασίας δεδομένων και απορρήτου, νόμων που σχετίζονται με τα πνευματικά δικαιώματα περιεχομένου και νόμων που σχετίζονται με ανεπιθύμητα εμπορικά ηλεκτρονικά μηνύματα.",
    "lbl_restrictionsOnUseOfWebsite_4.2": "Επιπλέον, δεν πρέπει",
    "lbl_restrictionsOnUseOfWebsite_4.2.1": "να χρησιμοποιήσουμε τον ιστότοπό μας για να στείλουμε ή να μεταδώσουμε, άμεσα ή έμμεσα, αυτόκλητο ή μη εξουσιοδοτημένο διαφημιστικό ή προωθητικό υλικό, αλυσιδωτές επιστολές ή συστήματα πώλησης πυραμίδων.",
    "lbl_restrictionsOnUseOfWebsite_4.2.2": "χρησιμοποιήστε τον ιστότοπό μας για βομβαρδισμούς αλληλογραφίας ή πλημμύρες ή για σκόπιμες προσπάθειες υπερφόρτωσης ενός συστήματος.",
    "lbl_restrictionsOnUseOfWebsite_4.2.3": "χρησιμοποιήστε τον ιστότοπό μας για να δημοσιεύσετε περιεχόμενο ή να συνδέσετε περιεχόμενο όπου το γνωρίζετε ή θα έπρεπε να γνωρίζετε ότι ο κάτοχος αυτού του περιεχομένου δεν έχει συναινέσει ή εξουσιοδοτήσει αυτήν τη δημοσίευση ή τη σύνδεση ή όταν αυτή η δημοσίευση ή η σύνδεση παραβιάζει τα δικαιώματα αυτού του κατόχου.",
    "lbl_restrictionsOnUseOfWebsite_4.2.4": "εν γνώσει ή απερίσκεπτα εισάγετε στον ιστότοπό μας ή χρησιμοποιήστε τον ιστότοπό μας για να μεταδώσετε ιούς, δούρειους ίππους, σκουλήκια, λογικές βόμβες, ωρολογιακές βόμβες, καταγραφείς πληκτρολόγησης, spyware, κακόβουλο λογισμικό, adware ή άλλο υλικό που είναι κακόβουλο ή επιβλαβές.",
    "lbl_restrictionsOnUseOfWebsite_4.2.5": "απόπειρα να αποκτήσετε μη εξουσιοδοτημένη πρόσβαση στον ιστότοπό μας, στον διακομιστή, στον εξοπλισμό ή στο δίκτυο στο οποίο είναι αποθηκευμένος ο ιστότοπός μας, σε οποιονδήποτε διακομιστή, υπολογιστή ή βάση δεδομένων που είναι συνδεδεμένος στον ιστότοπό μας ή σε οποιοδήποτε λογισμικό.",
    "lbl_restrictionsOnUseOfWebsite_4.2.6": "χρησιμοποιήστε τον ιστότοπό μας για να προσπαθήσετε να αποκτήσετε μη εξουσιοδοτημένη πρόσβαση σε οποιονδήποτε άλλο ιστότοπο, λογαριασμό Διαδικτύου, διακομιστή, υπολογιστή, εξοπλισμό, σύστημα, δίκτυο, δεδομένα ή πληροφορίες.",
    "lbl_restrictionsOnUseOfWebsite_4.2.7": "χρησιμοποιήστε τον ιστότοπό μας για την παρακολούθηση δεδομένων ή κίνησης σε οποιοδήποτε δίκτυο ή σύστημα.",
    "lbl_restrictionsOnUseOfWebsite_4.2.8": "χρησιμοποιήστε τον ιστότοπό μας για να συλλέξετε ή να χρησιμοποιήσετε πληροφορίες, συμπεριλαμβανομένων χωρίς περιορισμό διευθύνσεων ηλεκτρονικού ταχυδρομείου, ονομάτων οθόνης ή άλλων αναγνωριστικών, με δόλο (όπως phishing, διαδικτυακή απάτη, ληστεία κωδικού πρόσβασης, spidering, απόξεση και συγκομιδή).",
    "lbl_restrictionsOnUseOfWebsite_4.2.9": "χρησιμοποιήστε τον ιστότοπό μας για τη διανομή λογισμικού.",
    "lbl_restrictionsOnUseOfWebsite_4.2.10": "πραγματοποιήσουμε επίθεση άρνησης υπηρεσίας ή κατανεμημένη επίθεση άρνησης υπηρεσίας στον ιστότοπό μας ή χρησιμοποιήστε τον ιστότοπό μας για να πραγματοποιήσετε οποιαδήποτε τέτοια επίθεση σε οποιονδήποτε άλλο ιστότοπο.",
    "lbl_restrictionsOnUseOfWebsite_4.2.11": "χρησιμοποιήστε την ιστοσελίδα μας:",
    "lbl_restrictionsOnUseOfWebsite_4.2.11.1": "για οποιονδήποτε σκοπό που ή",
    "lbl_restrictionsOnUseOfWebsite_4.2.11.2": "για αποστολή, μετάδοση, δημοσίευση, μεταφόρτωση, λήψη, χρήση, αποθήκευση, αναπαραγωγή ή εν γνώσει σας λήψη οποιουδήποτε υλικού που δεν συμμορφώνεται με τα πρότυπα περιεχομένου που ορίζονται στο",
    "lbl_para8": "παράγραφος 8",
    "lbl_restrictionsOnUseOfWebsite_4.2.12": "χρησιμοποιήστε τον ιστότοπό μας για να καλλωπίσετε, να βλάψετε ή να εκμεταλλευτείτε ανηλίκους με οποιονδήποτε τρόπο ή για να επιχειρήσετε να το πράξετε. ή",
    "lbl_restrictionsOnUseOfWebsite_4.2.13": "χρησιμοποιήστε τον ιστότοπό μας κατά παράβαση των νόμων εξαγωγών, των ελέγχων, των κανονισμών ή των πολιτικών κυρώσεων των Ηνωμένων Πολιτειών ή οποιασδήποτε άλλης χώρας.",
    "lbl_restrictionsOnUseOfWebsite_4.3": "Εάν παραβιάσετε οποιονδήποτε όρο των παρόντων Όρων Χρήσης, το δικαίωμά σας να χρησιμοποιείτε τον ιστότοπό μας θα λήξει αμέσως. Επιπλέον, ενδέχεται να προβούμε σε τέτοιες άλλες ενέργειες, συμπεριλαμβανομένων, χωρίς περιορισμό, νομικών ενεργειών, όπως θεωρούμε σκόπιμο.",
    "lbl_restrictionsOnUseOfWebsite_4.4": "Εάν παραβιάσετε οποιονδήποτε όρο αυτών των Όρων Χρήσης ή εάν λάβουμε αίτημα από μια αρχή επιβολής του νόμου να το πράξουμε, ενδέχεται να αναφέρουμε την ταυτότητά σας, τα στοιχεία οποιασδήποτε παραβίασης και οποιαδήποτε άλλη πληροφορία θεωρούμε απαραίτητη στις αρμόδιες αρχές επιβολής του νόμου.",
    "lbl_pwdNSecRequirement": "Κωδικοί πρόσβασης και απαιτήσεις ασφαλείας",
    "lbl_pwdNSecRequirement_5.1": "Εάν εγγραφείτε λογαριασμό στον ιστότοπό μας, πρέπει να παρέχετε ακριβείς και πλήρεις πληροφορίες εγγραφής και να διατηρείτε αυτές τις πληροφορίες ενημερωμένες ανά πάσα στιγμή.",
    "lbl_pwdNSecRequirement_5.2": "Η χρήση ορισμένων τμημάτων αυτού του ιστότοπου προστατεύεται με κωδικό πρόσβασης. Για να το χρησιμοποιήσετε θα χρειαστείτε έναν κωδικό αναγνώρισης χρήστη ή έναν κωδικό πρόσβασης για να έχετε πρόσβαση σε αυτό. Είναι δική σας ευθύνη να διατηρήσετε αυτές τις πληροφορίες μυστικές και εμπιστευτικές και να μην τις αποκαλύψετε σε κανένα άλλο πρόσωπο.",
    "lbl_pwdNSecRequirement_5.3": "Εάν πιστεύετε ότι κάποιος άλλος μπορεί να γνωρίζει τον κωδικό αναγνώρισης χρήστη ή τον κωδικό πρόσβασής σας, πρέπει να συνδεθείτε στον λογαριασμό σας και να τους αλλάξετε το συντομότερο δυνατό",
    "lbl_pwdNSecRequirement_5.4": "Διατηρούμε το δικαίωμα να απενεργοποιήσουμε οποιονδήποτε κωδικό αναγνώρισης χρήστη ή κωδικό πρόσβασης ανά πάσα στιγμή, εάν, κατά την εύλογη γνώμη μας, δεν συμμορφωθείτε με οποιονδήποτε από αυτούς τους Όρους Χρήσης, εάν θεωρούμε ότι χρησιμοποιείτε τον ιστότοπό μας για λήψη περιεχομένου ή παραβίαση οποιουδήποτε τρόπο με τα δικαιώματα άλλου προσώπου ή αν θεωρήσουμε ότι μπορεί να υπάρχει κίνδυνος ασφάλειας.",
    "lbl_webSiteNContent": "Η ιστοσελίδα μας και το περιεχόμενό της",
    "lbl_webSiteNContent_6.1": "Δεν εγγυόμαστε ότι η πρόσβαση στον ιστότοπό μας ή το περιεχόμενο σε αυτόν (συμπεριλαμβανομένου οποιουδήποτε περιεχομένου που ανεβάζετε στον ιστότοπό μας) θα είναι πάντα διαθέσιμη ή αδιάλειπτη. Το δικαίωμά σας πρόσβασης στον ιστότοπό μας παρέχεται μόνο σε προσωρινή βάση.",
    "lbl_webSiteNContent_6.2": "Διατηρούμε το δικαίωμα να αλλάξουμε τον ιστότοπό μας και το περιεχόμενο σε αυτόν, μεταξύ άλλων, χωρίς περιορισμό, προσθέτοντας ή αφαιρώντας περιεχόμενο ή λειτουργικότητα, ανά πάσα στιγμή και χωρίς προειδοποίηση.",
    "lbl_webSiteNContent_6.3": "Δεν μπορούμε να εγγυηθούμε ότι όλο το περιεχόμενο στον ιστότοπό μας είναι ακριβές, πλήρες ή ενημερωμένο. Επομένως, δεν παρέχουμε εγγυήσεις, δηλώσεις, δεσμεύσεις ή εγγυήσεις ότι το περιεχόμενο στον ιστότοπό μας είναι ακριβές, πλήρες ή ενημερωμένο.",
    "lbl_webSiteNContent_6.4": "Το περιεχόμενο στον ιστότοπό μας παρέχεται μόνο για λόγους γενικής πληροφόρησης. Τίποτα στον ιστότοπό μας δεν προορίζεται να είναι συμβουλές στις οποίες θα πρέπει να βασίζεστε. Θα πρέπει πάντα να λαμβάνετε τις κατάλληλες συμβουλές από ειδικούς πριν προβείτε ή δεν προβείτε σε ενέργειες βάσει οποιουδήποτε περιεχομένου στον ιστότοπό μας.",
    "lbl_webSiteNContent_6.5": "Δεν παρέχουμε εγγυήσεις, δηλώσεις, δεσμεύσεις ή εγγυήσεις ότι ο ιστότοπός μας είναι ασφαλής ή απαλλαγμένος από σφάλματα ή ιούς. Είστε υπεύθυνοι για τη διασφάλιση ότι η συσκευή από την οποία αποκτάτε πρόσβαση στον ιστότοπό μας διαθέτει κατάλληλο λογισμικό προστασίας από ιούς.",
    "lbl_contentUploaded": "Περιεχόμενο που ανέβηκε στον ιστότοπό μας από εσάς ή από άλλους χρήστες",
    "lbl_contentUploaded_7.1": "Εάν ανεβάζετε περιεχόμενο στον ιστότοπό μας, πρέπει να συμμορφώνεστε με τα πρότυπα περιεχομένου που ορίζονται στο",
    "lbl_contentUploaded_7.1part": "όσον αφορά το περιεχόμενο αυτό",
    "lbl_contentUploaded_7.2": "Μας εγγυάστε ότι είστε κάτοχος οποιουδήποτε περιεχομένου που ανεβάζετε στον ιστότοπό μας ή, εάν δεν είστε ο κάτοχος, ότι έχετε το δικαίωμα να το ανεβάσετε.",
    "lbl_contentUploaded_7.3": "Θα συνεχίσετε να κατέχετε οποιοδήποτε περιεχόμενο ανεβάζετε στον ιστότοπό μας, αλλά για όσο διάστημα αυτό το περιεχόμενο παραμένει στον ιστότοπό μας, μας εκχωρείτε παγκόσμια άδεια χρήσης, αποθήκευσης, αντιγραφής, τροποποίησης, εκτέλεσης, διανομής, προβολής και διάθεσης αυτό το περιεχόμενο και να επιτρέψουμε σε άλλους χρήστες του ιστότοπού μας να το χρησιμοποιούν, να το κατεβάζουν και να το εκτυπώνουν και να το αναπαράγουν σύμφωνα με τους Όρους Χρήσης μας.",
    "lbl_contentUploaded_7.4": "Θα μας αποζημιώσετε για τυχόν απώλειες, υποχρεώσεις, έξοδα, έξοδα, αξιώσεις ή διαδικασίες που υποστούμε ή υποστούμε, ή θα υποστούμε ή θα υποστούμε, ως αποτέλεσμα ή σε σχέση με οποιαδήποτε παράβαση εκ μέρους σας των υποχρεώσεων ή των εγγυήσεων σας στο παρόν",
    "lbl_para7": "παράγραφος 7",
    "lbl_contentUploaded_7.5": "Δεν είμαστε υπεύθυνοι και δεν παρέχουμε εγγυήσεις, δηλώσεις, δεσμεύσεις ή εγγυήσεις σχετικά με οποιοδήποτε περιεχόμενο στον ιστότοπό μας το οποίο ανεβάζεται από έναν χρήστη. Οποιαδήποτε άποψη εκφράζεται από οποιονδήποτε χρήστη της ιστοσελίδας μας είναι δική του άποψη και όχι δική μας ούτε άποψη των στελεχών ή των υπαλλήλων μας.",
    "lbl_contentUploaded_7.6": "Δεν φέρουμε ευθύνη για την αποθήκευση ή τη δημιουργία αντιγράφων ασφαλείας οποιουδήποτε περιεχομένου που έχει μεταφορτωθεί στον ιστότοπό μας και δεν θα είμαστε υπεύθυνοι για οποιαδήποτε απώλεια, διαφθορά ή καταστροφή τέτοιου περιεχομένου.",
    "lbl_contentUploaded_7.7": "Συνιστούμε να δημιουργήσετε αντίγραφα ασφαλείας οποιουδήποτε περιεχομένου ανεβάζετε στον ιστότοπό μας.",
    "lbl_contentUploaded_7.8": "Διατηρούμε το δικαίωμα να αφαιρέσουμε οποιοδήποτε περιεχόμενο που έχετε ανεβάσει στον ιστότοπό μας ανά πάσα στιγμή.",
    "lbl_contentUploaded_7.9": "Διατηρούμε επίσης το δικαίωμα να αποκαλύψουμε την ταυτότητά σας σε οποιοδήποτε άτομο που ισχυρίζεται ότι οποιοδήποτε περιεχόμενο που έχετε ανεβάσει στον ιστότοπό μας παραβιάζει τα δικαιώματα πνευματικής ιδιοκτησίας ή το δικαίωμά του στην ιδιωτική ζωή ή είναι δυσφημιστικό.",
    "lbl_contentStd": "Πρότυπα περιεχομένου",
    "lbl_contentStd_8.1": "Να θυμάστε ότι οποιοδήποτε περιεχόμενο ανεβάζετε στον ιστότοπό μας μπορεί να προβληθεί από άλλους χρήστες, επομένως πάντα να σκέφτεστε προσεκτικά πριν υποβάλετε οποιοδήποτε περιεχόμενο.",
    "lbl_contentStd_8.2": "Κάθε περιεχόμενο που ανεβάζετε στον ιστότοπό μας πρέπει:",
    "lbl_contentStd_8.2.1": "συμμορφώνονται με όλους τους ισχύοντες νόμους·",
    "lbl_contentStd_8.2.2": "να είναι ακριβής (όπου αναφέρει γεγονότα). και",
    "lbl_contentStd_8.2.3": "να είναι γνήσια (όπου εκφράζει απόψεις).",
    "lbl_contentStd_8.3": "Δεν πρέπει να ανεβάσετε στον ιστότοπό μας περιεχόμενο το οποίο:",
    "lbl_contentStd_8.3.1": "είναι δυσφημιστικό, άσεμνο, προσβλητικό, άσεμνο, κακόβουλο, μίσος ή εμπρηστικό·",
    "lbl_contentStd_8.3.2": "είναι πορνογραφικό ή σεξουαλικά",
    "lbl_contentStd_8.3.3": "εκμεταλλεύεται ανηλίκους·",
    "lbl_contentStd_8.3.4": "προωθεί τη βία ή την τρομοκρατία·",
    "lbl_contentStd_8.3.5": "εισάγει διακρίσεις λόγω φυλής, φύλου, θρησκείας, εθνικότητας, αναπηρίας, σεξουαλικού προσανατολισμού ή ηλικίας·",
    "lbl_contentStd_8.3.6": "infringes any copyright, database right, trade mark or other intellectual property right of any other person;",
    "lbl_contentStd_8.3.7": "ζητά ή προσπαθεί να ζητήσει κωδικούς πρόσβασης ή προσωπικά στοιχεία ταυτοποίησης για εμπορικούς ή παράνομους σκοπούς από άλλους χρήστες·",
    "lbl_contentStd_8.3.8": "είναι δόλια ή πιθανό να εξαπατήσει οποιοδήποτε άτομο·",
    "lbl_contentStd_8.3.9": "γίνεται κατά παράβαση οποιουδήποτε νομικού καθήκοντος που οφείλεται σε οποιονδήποτε άλλον, όπως συμβατικό καθήκον ή καθήκον εμπιστοσύνης·",
    "lbl_contentStd_8.3.10": "δημιουργεί κίνδυνο για την υγεία ή την ασφάλεια ή τη δημόσια υγεία ή ασφάλεια ενός ατόμου·",
    "lbl_contentStd_8.3.11": "προωθεί ή συνιστά παράνομη ή παράνομη δραστηριότητα·",
    "lbl_contentStd_8.3.12": "θέτει σε κίνδυνο την εθνική ασφάλεια·",
    "lbl_contentStd_8.3.13": "παρεμβαίνει σε μια έρευνα από μια αρχή επιβολής του νόμου ή ρυθμιστική αρχή·",
    "lbl_contentStd_8.3.14": "είναι απειλητικό ή καταχρηστικό ή εισβάλλει στην ιδιωτική ζωή άλλου ατόμου ή προκαλεί ενόχληση, ενόχληση ή περιττό άγχος·",
    "lbl_contentStd_8.3.15": "είναι πιθανό να παρενοχλήσει, να αναστατώσει, να φέρει σε αμηχανία, να ανησυχήσει ή να ενοχλήσει οποιοδήποτε άλλο άτομο.",
    "lbl_contentStd_8.3.16": "impersonates any person or misrepresents your identity or affiliation with any person;",
    "lbl_contentStd_8.3.17": "δίνει την εντύπωση ότι προέρχεται από εμάς.",
    "lbl_contentStd_8.3.18": "αποτελεί διαφημιστικό υλικό· ή",
    "lbl_contentStd_8.3.19": "υποστηρίζει, προωθεί ή βοηθά οποιαδήποτε παράνομη δραστηριότητα, συμπεριλαμβανομένων, ενδεικτικά, παραβίασης πνευματικών δικαιωμάτων ή κατάχρησης υπολογιστή.",
    "lbl_IntellctlProprty": "Τα δικαιώματα πνευματικής ιδιοκτησίας",
    "lbl_IntellctlProprty_9.1": "Εμείς, ή οι δικαιοδόχοι μας, κατέχουμε τα πνευματικά δικαιώματα και όλα τα άλλα δικαιώματα πνευματικής ιδιοκτησίας στον ιστότοπό μας και σε όλο το περιεχόμενο σε αυτόν, εκτός από το περιεχόμενο που έχει μεταφορτωθεί ή συνδέεται με άλλους.",
    "lbl_IntellctlProprty_9.2": "Μπορείτε να κάνετε λήψη του περιεχομένου που κατέχουμε από τον ιστότοπό μας ή/και να εκτυπώσετε έναν εύλογο αριθμό αντιγράφων οποιασδήποτε σελίδας στον ιστότοπό μας. Δεν πρέπει να κάνετε αλλαγές σε οποιοδήποτε περιεχόμενο που έχετε κατεβάσει ή εκτυπώσει από τον ιστότοπό μας. Εάν αναπαράγετε το ληφθέν ή εκτυπωμένο περιεχόμενο σε οποιοδήποτε μέσο, ​​πρέπει να βεβαιωθείτε ότι το αναπαράγετε με ακρίβεια και πληρότητα και να συμπεριλάβετε μια δήλωση που να αναγνωρίζει ότι προέρχεται από τον ιστότοπό μας",
    "lbl_IntellctlProprty_9.3": "Εάν χρησιμοποιείτε, κατεβάζετε, εκτυπώνετε ή αναπαράγετε περιεχόμενο από τον ιστότοπό μας κατά παράβαση των παρόντων Όρων Χρήσης, το δικαίωμά σας να χρησιμοποιείτε τον ιστότοπό μας θα λήξει αμέσως και πρέπει να επιστρέψετε σε εμάς ή να καταστρέψετε (κατ' επιλογή μας) όλα τα ψηφιακά και έντυπα αντίγραφα του περιεχομένου που έχετε φτιάξει.",
    "lbl_linksToOtherWebsite": "Σύνδεσμοι από τον ιστότοπό μας σε άλλους ιστότοπους",
    "lbl_linksToOtherWebsite_text": "Ο ιστότοπός μας μπορεί να περιέχει κατά καιρούς συνδέσμους προς άλλους ιστότοπους. Αυτοί οι σύνδεσμοι παρέχονται για τη διευκόλυνσή σας. Δεν έχουμε κανέναν έλεγχο και καμία ευθύνη ή υποχρέωση απέναντί ​​σας για αυτούς τους άλλους ιστότοπους ή το περιεχόμενό τους, και οποιαδήποτε χρήση ή πρόσβαση από εσάς σε τέτοιους ιστότοπους θα υπόκειται στους σχετικούς όρους και προϋποθέσεις χρήσης αυτών των ιστοσελίδων και όχι στους παρόντες Όρους χρήσης.",
    "lbl_linksFromOtherWebsite": "Σύνδεσμοι από άλλους ιστότοπους στον ιστότοπό μας",
    "lbl_linksFromOtherWebsite_11.1": "Μπορείτε να δημιουργήσετε έναν σύνδεσμο προς τον ιστότοπό μας από άλλο ιστότοπο, αλλά μόνο εάν συμμορφώνεστε με τις ακόλουθες προϋποθέσεις:",
    "lbl_linksFromOtherWebsite_11.1.1": "πρέπει να μας ενημερώσετε για την πρόθεσή σας να δημιουργήσετε έναν σύνδεσμο προς τον ιστότοπό μας από άλλο ιστότοπο και να λάβετε τη γραπτή έγκρισή μας πριν δημιουργήσετε έναν τέτοιο σύνδεσμο.",
    "lbl_linksFromOtherWebsite_11.1.2": "μπορείτε να συνδέσετε μόνο την αρχική σελίδα του ιστότοπού μας. Δεν πρέπει να δημιουργήσετε σύνδεσμο προς οποιαδήποτε άλλη σελίδα του ιστότοπού μας, ούτε να πλαισιώσετε οποιαδήποτε σελίδα του ιστότοπού μας.",
    "lbl_linksFromOtherWebsite_11.1.3": "μπορείτε να συνδεθείτε με τον ιστότοπό μας μόνο από έναν ιστότοπο που σας ανήκει και ο οποίος συμμορφώνεται με τα πρότυπα που ορίζονται στο",
    "lbl_linksFromOtherWebsite_11.1.4": "η δημιουργία του συνδέσμου δεν θα εισαγάγει στον ιστότοπό μας ή δεν θα χρησιμοποιήσει τον ιστότοπό μας για τη μετάδοση ιών, δούρειων ίππων, σκουληκιών, λογικών βομβών, ωρολογιακών βομβών, καταγραφών πληκτρολογίου, spyware, κακόβουλου λογισμικού, adware ή άλλου υλικού που είναι κακόβουλο ή επιβλαβές ;",
    "lbl_linksFromOtherWebsite_11.1.5": "θα συμμορφώνεστε με τις απαιτήσεις ή/και τις πολιτικές για τις οποίες ενδέχεται να σας ειδοποιούμε κατά καιρούς σχετικά με οποιονδήποτε σύνδεσμο προς τον ιστότοπό μας από άλλο ιστότοπο. και",
    "lbl_linksFromOtherWebsite_11.1.6": "δεν πρέπει να προτείνετε ότι έχουμε οποιαδήποτε σχέση μαζί σας ή ότι εγκρίνουμε ή εγκρίνουμε τον ιστότοπό σας ή οποιοδήποτε από τα προϊόντα ή τις υπηρεσίες σας.",
    "lbl_linksFromOtherWebsite_11.2": "Διατηρούμε το δικαίωμα να σας ζητήσουμε να αφαιρέσετε οποιονδήποτε σύνδεσμο προς τον ιστότοπό μας ανά πάσα στιγμή και χωρίς προηγούμενη ειδοποίηση",
    "lbl_ourLiability": "Η ευθύνη μας απέναντί ​​σας",
    "lbl_ourLiability_12.1": "Τίποτα σε αυτούς τους Όρους Χρήσης δεν περιορίζει ή αποκλείει την ευθύνη μας απέναντί ​​σας για:",
    "lbl_ourLiability_12.1.1": "θάνατος ή σωματική βλάβη που προκύπτει από αμέλεια·",
    "lbl_ourLiability_12.1.2": "απάτη ή δόλια ψευδή δήλωση· ή",
    "lbl_ourLiability_12.1.3": "οποιοδήποτε άλλο θέμα για το οποίο δεν επιτρέπεται από το νόμο να αποκλείσουμε ή να περιορίσουμε την ευθύνη μας.",
    "lbl_ourLiability_12.2": "Δεδομένου ότι αυτοί οι Όροι Χρήσης διέπουν την πρόσβασή σας και τη χρήση του ιστότοπού μας, εξαιρούνται όλες οι εγγυήσεις, προϋποθέσεις και άλλοι όροι που σχετίζονται με τον ιστότοπό μας ή/και το περιεχόμενό του που διαφορετικά θα υπονοούνται από το νόμο (είτε από το νόμο, το κοινό δίκαιο είτε με άλλο τρόπο).",
    "lbl_ourLiability_12.3": "Δεν θα είμαστε υπεύθυνοι απέναντί ​​σας για οποιαδήποτε απώλεια ή ζημία που προκύπτει από ή σε σχέση με τη χρήση (ή την αδυναμία χρήσης) του ιστότοπού μας ή τη χρήση ή την εξάρτησή σας από οποιοδήποτε περιεχόμενο στον ιστότοπό μας, είτε πρόκειται για σύμβαση, είτε για αδικοπραξία (συμπεριλαμβανομένης χωρίς περιοριστική αμέλεια), ψευδή δήλωση, αποκατάσταση, βάσει νόμου ή με άλλο τρόπο.",
    "lbl_ourLiability_13": "Εφαρμόσιμος νόμος",
    "lbl_ourLiability_13.1": "Εάν είστε καταναλωτής, αυτοί οι Όροι Χρήσης διέπονται από το δίκαιο της Αγγλίας και της Ουαλίας και τα δικαστήρια της Αγγλίας και της Ουαλίας έχουν μη αποκλειστική δικαιοδοσία να επιλύουν οποιαδήποτε διαφορά προκύψει από ή σε σχέση με αυτούς. Εάν διαμένετε στη Σκωτία, μπορείτε επίσης να ασκήσετε αγωγή στη Σκωτία και εάν διαμένετε στη Βόρεια Ιρλανδία, μπορείτε επίσης να ασκήσετε αγωγή στη Βόρεια Ιρλανδία. Οι κάτοικοι άλλων χωρών στις οποίες διατίθεται η ιστοσελίδα υπόκεινται στη νομοθεσία της Αγγλίας και της Ουαλίας",
    "lbl_ourLiability_13.2": "Εάν είστε επαγγελματίας χρήστης, αυτοί οι Όροι Χρήσης (και τυχόν μη συμβατικές υποχρεώσεις που προκύπτουν από ή σε σχέση με αυτούς) διέπονται από το δίκαιο της Αγγλίας και της Ουαλίας και τα δικαστήρια της Αγγλίας και της Ουαλίας έχουν αποκλειστική δικαιοδοσία να επιλύουν οποιαδήποτε διαφορά προκύψει εκτός ή σε σχέση με αυτές (συμπεριλαμβανομένων χωρίς περιορισμό σε σχέση με τυχόν εξωσυμβατικές υποχρεώσεις). Οι κάτοικοι άλλων χωρών στις οποίες διατίθεται η ιστοσελίδα υπόκεινται στη νομοθεσία της Αγγλίας και της Ουαλίας",
    "lbl_privPolicy": "Πολιτική Απορρήτου",
    "lbl_privPolicy_text": "Ο ιστότοπος Eaton SecureConnect είναι ένας ιστότοπος και μια πύλη που διαχειρίζεται η Eaton Electrical Products Limited, μια εταιρεία εγγεγραμμένη στο Companies House με αριθμό εγγραφής 01342230, της οποίας η εγγεγραμμένη διεύθυνση είναι Jephson Court Tancred Close, Queensway, Royal Leamington Spa, Warwickshire, CV31 3RZ.",
    "lbl_privPolicy_text1": "Eaton Electrical Products Limited",
    "lbl_privPolicy_textWe": "εμείς",
    "lbl_privPolicy_textUs": "μας",
    "lbl_privPolicy_textOur": "μας",
    "lbl_privPolicy_text2": "είναι ο υπεύθυνος επεξεργασίας δεδομένων σχετικά με τις προσωπικές πληροφορίες που υποβάλατε σε εμάς μέσω αυτού του ιστότοπου.",
    "lbl_privPolicy_text3": "Αυτή η ειδοποίηση (μαζί με τους όρους χρήσης μας:",
    "lbl_privPolicy_text4": "και κάθε άλλο έγγραφο που αναφέρεται σε αυτό) καθορίζει τη βάση πάνω στην οποία τυχόν προσωπικά δεδομένα που συλλέγουμε από εσάς ή που μας παρέχετε μέσω αυτού του ιστότοπου θα υποβληθούν σε επεξεργασία από εμάς. Διαβάστε προσεκτικά τα παρακάτω για να κατανοήσετε τις πρακτικές μας σχετικά με τα προσωπικά σας δεδομένα και πώς θα τα χειριστούμε.",
    "lbl_privPolicy_text5": "Εάν έχετε οποιεσδήποτε ερωτήσεις σχετικά με αυτήν την ειδοποίηση ή δεν συμφωνείτε με αυτήν, επικοινωνήστε μαζί μας στο dataprotection@eaton.com πριν χρησιμοποιήσετε αυτόν τον ιστότοπο.",
    "lbl_privPolicy_text6": "Ενδέχεται να αλλάζουμε αυτήν την ειδοποίηση κατά καιρούς ενημερώνοντας αυτήν τη σελίδα. Θα πρέπει να ελέγχετε αυτή τη σελίδα από καιρό σε καιρό για να βεβαιωθείτε ότι είστε ικανοποιημένοι με τυχόν αλλαγές. Η παρούσα ανακοίνωση ισχύει από τις 20 Δεκεμβρίου 2019.",
    "lbl_infoWeCollect": "Τι πληροφορίες συλλέγουμε;",
    "lbl_infoWeCollect_text": "Θα μπορείτε να έχετε πρόσβαση στον ιστότοπο χωρίς να μας δώσετε καμία πληροφορία. Ωστόσο, οι περισσότερες από τις υπηρεσίες που παρέχονται μέσω του ιστότοπου (όπως αυτές που διατίθενται μέσω της πύλης) θα είναι διαθέσιμες μόνο εάν επεξεργαστούμε ορισμένες πληροφορίες σχετικά με εσάς.",
    "lbl_infoWeCollect_text1": "Ενδέχεται να συλλέξουμε τις ακόλουθες πληροφορίες:",
    "lbl_infoWeCollect_text2": "το όνομά σας και τα στοιχεία επικοινωνίας σας (συμπεριλαμβανομένης της διεύθυνσης, της διεύθυνσης email και των αριθμών τηλεφώνου) και τα στοιχεία της εταιρείας.",
    "lbl_infoWeCollect_text3": "τα στοιχεία εγγραφής του λογαριασμού σας εάν γίνετε εγγεγραμμένος χρήστης του ιστότοπου (συμπεριλαμβανομένων των στοιχείων σύνδεσης χρήστη και του κωδικού πρόσβασης)·",
    "lbl_infoWeCollect_text4": "τη χώρα από την οποία αποκτάτε πρόσβαση στον ιστότοπο·",
    "lbl_infoWeCollect_text5": "πληροφορίες σχετικά με τα προσωπικά ή επαγγελματικά σας ενδιαφέροντα, δημογραφικά στοιχεία, εμπειρίες με τα προϊόντα μας και προτιμήσεις επικοινωνίας προκειμένου να σας παρέχουμε περαιτέρω αλληλογραφία σχετικά με τα προϊόντα και τις υπηρεσίες μας.",
    "lbl_infoWeCollect_text6": "πληροφορίες σχετικά με τους πελάτες σας (συμπεριλαμβανομένων των ονομάτων, των διευθύνσεών τους, των προϊόντων που έχουν αγοράσει και των συστημάτων που έχουν εγκατασταθεί στις εγκαταστάσεις τους, πληροφορίες σχετικά με την κατάσταση συναγερμού τους (όπως εάν είναι ενεργοποιημένος ή/και λειτουργεί σωστά), εικόνες CCTV και εάν είναι χρήστης της εφαρμογής για κινητά Eaton SecureConnect).",
    "lbl_infoWeCollect_text7": "αρχεία οποιασδήποτε επαφής μεταξύ εσάς και εμάς· και",
    "lbl_infoWeCollect_text8": "οποιεσδήποτε πληροφορίες οικειοθελώς μας παρέχετε ή εισάγετε μέσω του ιστότοπου ή/και της γραμμής βοήθειας.",
    "lbl_whatWeDowithInfo": "Τι κάνουμε με τις πληροφορίες που συλλέγουμε;",
    "lbl_whatWeDowithInfo_text": "Χρησιμοποιούμε αυτές τις πληροφορίες για να σας παρέχουμε τα προϊόντα και τις υπηρεσίες μας, και ειδικότερα για τους ακόλουθους λόγους",
    "lbl_whatWeDowithInfo_text1": "για να μας επιτρέψετε να σας παρέχουμε υποστήριξη και τεχνική βοήθεια σε σχέση με τα προϊόντα που έχετε αγοράσει από εμάς·",
    "lbl_whatWeDowithInfo_text2": "να σας ενημερώσουμε για περιόδους διακοπής συντήρησης σε σχέση με την πύλη και άλλες υπηρεσίες που σας παρέχονται·",
    "lbl_whatWeDowithInfo_text3": "εσωτερική τήρηση αρχείων· και",
    "lbl_whatWeDowithInfo_text4": "για να βελτιώσουμε τα προϊόντα και τις υπηρεσίες μας.",
    "lbl_whatWeDowithInfo_text5": "Ενδέχεται να επικοινωνήσουμε μαζί σας μέσω e-mail, τηλεφώνου ή ταχυδρομείου",
    "lbl_whatWeDowithInfo_text6": "Επεξεργαζόμαστε αυτά τα προσωπικά δεδομένα για τους σκοπούς της εκπλήρωσης των υποχρεώσεών μας και της άσκησης των δικαιωμάτων μας σε σχέση με τις συμβάσεις μας μαζί σας και για τα έννομα συμφέροντά μας, τα οποία περιλαμβάνουν την παροχή προϊόντων, υπηρεσιών και λύσεων για τους πελάτες μας και τους εγκαταστάτες διαπιστευμένους από την Eaton, σύμφωνα με τις νομικές, συμβατικές και ηθικές μας υποχρεώσεις, αυξάνοντας την αποτελεσματικότητα των εργασιών μας και προστατεύοντας την επιχείρησή μας",
    "lbl_infoShareWith": "Με ποιον μοιραζόμαστε αυτές τις πληροφορίες;",
    "lbl_infoShareWith_text": "Εκτός από όσα ορίζονται παρακάτω, δεν θα μεταφέρουμε, αποκαλύψουμε, πουλήσουμε, διανείμουμε ή μισθώσουμε τα προσωπικά σας στοιχεία σε τρίτους εκτός από τις θυγατρικές μας και/ή τη μητρική εταιρεία, εκτός εάν έχουμε την άδειά σας ή απαιτείται από το νόμο.",
    "lbl_infoShareWith_text1": "Μερικές φορές (και με την έγκρισή σας όπου απαιτείται), θα κοινοποιήσουμε τα προσωπικά σας δεδομένα με προσεκτικά επιλεγμένα τρίτα μέρη εκτός του εταιρικού μας ομίλου. Μπορούμε να το κάνουμε αυτό για τους εξής λόγους:",
    "lbl_infoShareWith_text2": "να επιτρέψουμε σε αυτά τα τρίτα μέρη να παρέχουν υπηρεσίες για εμάς, συμπεριλαμβανομένου του προσεκτικά επιλεγμένου παρόχου φιλοξενίας ιστοτόπων μας·",
    "lbl_infoShareWith_text3": "για να επιτρέψετε στον εργοδότη, τον εντολέα ή/και τον ανάδοχό σας να προβάλει τις λεπτομέρειες που περιέχονται στην πύλη που σχετίζονται με την επιχείρηση εγκατάστασης ή/και τα προϊόντα που έχει αγοράσει·",
    "lbl_infoShareWith_text4": "ως απάντηση σε νόμιμα αιτήματα των δημόσιων αρχών, συμπεριλαμβανομένης της εκπλήρωσης των απαιτήσεων εθνικής ασφάλειας ή επιβολής του νόμου·",
    "lbl_infoShareWith_text5": "όταν πιστεύουμε ότι είναι απαραίτητο να συμμορφωθούμε με το νόμο ή να προστατεύσουμε τα δικαιώματα, την περιουσία ή την ασφάλειά μας ή άλλου ατόμου· και/ή",
    "lbl_infoShareWith_text6": "εάν υπάρξει (ή πρόκειται να υπάρξει) οποιαδήποτε αλλαγή στην ιδιοκτησία της επιχείρησης ή των περιουσιακών στοιχείων μας, τότε ίσως θελήσουμε να κοινοποιήσουμε τις πληροφορίες σας, ώστε οι νέοι (υποψήφιοι) ιδιοκτήτες να συνεχίσουν να λειτουργούν αποτελεσματικά την επιχείρησή μας και να συνεχίσουν να παρέχουν υπηρεσίες σε πελάτες. Αυτό μπορεί να περιλαμβάνει νέους μετόχους ή οποιονδήποτε οργανισμό που ενδέχεται να αναλάβει εκχώρηση ή μεταβίβαση τυχόν συμφωνιών που έχουμε συνάψει με τους πελάτες μας.",
    "lbl_infoShareWith_text7": "Αυτή η διαδικασία μπορεί να περιλαμβάνει την αποστολή προσωπικών δεδομένων σε άλλες χώρες εντός του Ευρωπαϊκού Οικονομικού Χώρου. Εάν, για οποιονδήποτε λόγο, μεταφέρουμε τα προσωπικά σας δεδομένα εκτός του Ευρωπαϊκού Οικονομικού Χώρου, θα εφαρμόσουμε διασφαλίσεις για να διασφαλίσουμε τα κατάλληλα επίπεδα προστασίας για όλα αυτά τα προσωπικά δεδομένα και θα διαθέσουμε ή θα σας παράσχουμε ένα μέσο για να αποκτήσετε αντίγραφο του τέτοιες διασφαλίσεις.",
    "lbl_howLongStoreData": "Για πόσο καιρό θα αποθηκεύουμε τα προσωπικά σας δεδομένα;",
    "lbl_howLongStoreData_text": "Το πόσο καιρό διατηρούμε τις πληροφορίες σας εξαρτάται από τη βάση στην οποία παρασχέθηκαν, αλλά γενικά:",
    "lbl_howLongStoreData_text1": "θα διατηρήσουμε τις πληροφορίες που είναι απαραίτητες για να μπορέσουμε να σας παρέχουμε μια υπηρεσία που έχετε ζητήσει μέσω ή σε σχέση με αυτόν τον ιστότοπο για όσο διάστημα απαιτείται για την παροχή αυτής της υπηρεσίας.",
    "lbl_howLongStoreData_text2": "γενικά θα κρατάμε αρχεία για τυχόν συναλλαγές που πραγματοποιείτε μαζί μας για τουλάχιστον έξι χρόνια από το τέλος της σχέσης. Αυτό γίνεται για να μπορέσουμε να επιλύσουμε ζητήματα και να ανταποκριθούμε σε τυχόν παράπονα ή διαφωνίες που προκύπτουν σε αυτήν την περίοδο. Διαφορετικά, θα διατηρήσουμε τις πληροφορίες όπου χρειάζεται για τις νόμιμες επιχειρηματικές ανάγκες.",
    "lbl_howLongStoreData_text3": "θα κρατήσουμε άλλες πληροφορίες σχετικά με εσάς εάν είναι απαραίτητο να το πράξουμε για να συμμορφωθούμε με το νόμο ή όπου χρειάζεται για νόμιμες επιχειρηματικές ανάγκες.",
    "lbl_sensativeInfo": "Ευαίσθητες πληροφορίες",
    "lbl_sensativeInfo_text": "Πληροφορίες για εσάς που θεωρούνται ευαίσθητες ή ειδική κατηγορία προσωπικών δεδομένων σύμφωνα με τους νόμους περί προστασίας δεδομένων μπορεί να περιλαμβάνουν πληροφορίες σχετικά με τις ιατρικές ή υγειονομικές σας καταστάσεις, τη φυλετική ή εθνική καταγωγή, τις πολιτικές απόψεις, τη συμμετοχή σε συνδικάτα, τις θρησκευτικές ή φιλοσοφικές πεποιθήσεις, γενετικά δεδομένα, βιομετρικά δεδομένα , σεξουαλική ζωή και σεξουαλικό προσανατολισμό και ύποπτη ή αποδεδειγμένη εγκληματική δραστηριότητα και συναφείς διαδικασίες. Εάν χρειαστεί να επεξεργαστούμε ευαίσθητα προσωπικά δεδομένα, θα ειδοποιηθείτε για αυτήν την επεξεργασία και θα σας ζητηθεί να συμφωνήσετε συγκεκριμένα με τη χρήση τέτοιων πληροφοριών όπως αρμόζει.",
    "lbl_security": "Ασφάλεια",
    "lbl_security_text": "Δεσμευόμαστε να διασφαλίσουμε ότι οι πληροφορίες σας είναι ασφαλείς. Προκειμένου να αποφευχθεί η μη εξουσιοδοτημένη πρόσβαση ή αποκάλυψη, έχουμε θέσει σε εφαρμογή φυσικές, ηλεκτρονικές και διαχειριστικές διαδικασίες για την προστασία και την ασφάλεια των πληροφοριών που συλλέγουμε στο διαδίκτυο.",
    "lbl_security_text1": "Προσπαθούμε να λάβουμε όλα τα εύλογα μέτρα για την προστασία των προσωπικών σας δεδομένων. Ωστόσο, λάβετε υπόψη ότι υπάρχουν εγγενείς κίνδυνοι ασφάλειας από την παροχή πληροφοριών και τις συναλλαγές στο διαδίκτυο μέσω του Διαδικτύου και επομένως δεν μπορούμε να εγγυηθούμε την ασφάλεια οποιωνδήποτε προσωπικών δεδομένων που αποκαλύπτονται στο διαδίκτυο. Ζητάμε να μην μας παρέχετε ευαίσθητα προσωπικά δεδομένα στο διαδίκτυο (ανατρέξτε στην ενότητα «Ευαίσθητες πληροφορίες» παραπάνω) εκτός εάν το ζητήσουμε ρητά.",
    "lbl_cookies": "Μπισκότα",
    "lbl_cookies_text": "Όπως πολλές εταιρείες, χρησιμοποιούμε «cookies» και παρόμοια εργαλεία στον ιστότοπό μας για να βελτιώσουμε την απόδοσή του και να βελτιώσουμε την εμπειρία χρήστη σας.",
    "lbl_cookies_text1": "Τι είναι τα cookies;",
    "lbl_cookies_text2": "Ένα cookie είναι ένα μικρό αρχείο κειμένου που τοποθετείται στη συσκευή που χρησιμοποιείτε για να αποκτήσετε πρόσβαση στον ιστότοπο (το δικό σας",
    "lbl_cookies_text3": "Τα cookies βοηθούν στην ανάλυση της επισκεψιμότητας στον ιστό και επιτρέπουν στις εφαρμογές Ιστού να ανταποκρίνονται σε εσάς ως άτομο. Η εφαρμογή Ιστού μπορεί να προσαρμόσει τις λειτουργίες της στις ανάγκες, τις προτιμήσεις και τις αντιπάθειές σας, συλλέγοντας και απομνημονεύοντας πληροφορίες σχετικά με τις προτιμήσεις σας. Ορισμένα cookies μπορεί να περιέχουν προσωπικά δεδομένα – για παράδειγμα, εάν κάνετε κλικ στο «να με θυμάσαι» όταν συνδέεστε, ένα cookie μπορεί να αποθηκεύσει το όνομα χρήστη σας. Τα περισσότερα cookies δεν θα συλλέγουν προσωπικά δεδομένα που σας ταυτοποιούν και, αντίθετα, θα συλλέγουν πιο γενικές πληροφορίες, όπως το πώς φτάνουν οι χρήστες και χρησιμοποιούν τον ιστότοπό μας ή τη γενική τοποθεσία ενός χρήστη.",
    "lbl_cookies_text4": "Σε τι χρησιμοποιούμε τα cookies;",
    "lbl_cookies_text5": "Μπορούμε να τοποθετήσουμε cookies ή παρόμοια αρχεία στη Συσκευή σας για λόγους ασφαλείας, για να μας πείτε εάν έχετε επισκεφτεί τον ιστότοπο στο παρελθόν, για να θυμόμαστε τις γλωσσικές σας προτιμήσεις, εάν είστε νέος επισκέπτης ή για να διευκολύνουμε με άλλο τρόπο την πλοήγηση στον ιστότοπο και για να εξατομικεύσουμε την εμπειρία σας κατά την επίσκεψή σας τις ιστοσελίδες μας. Τα cookies μάς επιτρέπουν να συλλέγουμε τεχνικές και πληροφορίες πλοήγησης, όπως ο τύπος του προγράμματος περιήγησης, ο χρόνος που αφιερώνουμε στους ιστότοπούς μας και τις σελίδες που επισκεπτόμαστε. Τα cookies μπορεί να βελτιώσουν την online εμπειρία σας αποθηκεύοντας τις προτιμήσεις σας ενώ επισκέπτεστε έναν συγκεκριμένο ιστότοπο. Συνολικά, τα cookies μας βοηθούν να σας παρέχουμε έναν καλύτερο ιστότοπο, επιτρέποντάς μας να παρακολουθούμε ποιες σελίδες θεωρείτε χρήσιμες και ποιες όχι. Ένα cookie σε καμία περίπτωση δεν μας δίνει πρόσβαση στη Συσκευή σας ή σε οποιαδήποτε πληροφορία σχετικά με εσάς, εκτός από τα δεδομένα που επιλέγετε να μοιραστείτε μαζί μας.",
    "lbl_cookies_text6": "Τι είδους cookies χρησιμοποιούμε;",
    "lbl_cookies_text7": "Γενικά, τα cookies που χρησιμοποιούνται στον ιστότοπο μπορούν να αναλυθούν στις ακόλουθες κατηγορίες:",
    "lbl_cookies_text8": "Cookies συνεδρίας:",
    "lbl_cookies_text9": "Αυτά τα cookies χρησιμοποιούνται «εν τη συνεδρία» κάθε φορά που επισκέπτεστε και στη συνέχεια λήγουν όταν αποχωρείτε από έναν ιστότοπο ή λίγο αργότερα: δεν αποθηκεύονται μόνιμα στη Συσκευή σας, δεν περιέχουν προσωπικά δεδομένα και βοηθούν ελαχιστοποιώντας την ανάγκη μεταφοράς προσωπικών δεδομένων σε όλο το διαδίκτυο. Αυτά τα cookies μπορούν να διαγραφούν ή μπορείτε να αρνηθείτε να επιτρέψετε τη χρήση τους, αλλά αυτό μπορεί να παρεμποδίσει την απόδοση και την εμπειρία σας στη χρήση των ιστότοπων. Αυτά τα cookie λαμβάνουν επίσης χρονικές σημάνσεις που καταγράφουν όταν αποκτάτε πρόσβαση σε έναν ιστότοπο και όταν αποχωρείτε από έναν ιστότοπο.",
    "lbl_cookies_text10": "Cookies παρακολούθησης:",
    "lbl_cookies_text11": "Αυτά τα cookies μας δίνουν τη δυνατότητα να αναγνωρίζουμε επαναλαμβανόμενους επισκέπτες στους ιστότοπούς μας. Αντιστοιχίζοντας ένα ανώνυμο, τυχαία δημιουργημένο αναγνωριστικό, ένα cookie παρακολούθησης παρακολουθεί από πού προήλθε ένας χρήστης των ιστότοπών μας, ποια μηχανή αναζήτησης μπορεί να χρησιμοποιούσε, σε ποιο σύνδεσμο έκαναν κλικ, ποια λέξη-κλειδί χρησιμοποίησε και πού βρισκόταν στον κόσμο πότε μπήκαν σε έναν ιστότοπο. Παρακολουθώντας αυτά τα δεδομένα, μπορούμε να κάνουμε βελτιώσεις στους ιστότοπούς μας.",
    "lbl_cookies_text12": "Μόνιμα cookies:",
    "lbl_cookies_text13": "Αυτός ο τύπος cookie αποθηκεύεται στη Συσκευή σας για καθορισμένη περίοδο (μερικές φορές για λίγες ώρες, μερικές φορές για ένα έτος ή περισσότερο) και δεν διαγράφεται όταν το πρόγραμμα περιήγησης είναι κλειστό. Τα μόνιμα cookies χρησιμοποιούνται όπου πρέπει να θυμόμαστε ποιος είστε για περισσότερες από μία συνεδρίες περιήγησης. Για παράδειγμα, αυτός ο τύπος cookie μπορεί να χρησιμοποιηθεί για την αποθήκευση των προτιμήσεών σας, έτσι ώστε να απομνημονεύονται για την επόμενη επίσκεψη σε έναν ιστότοπο.",
    "lbl_cookies_text14": "Cookies απόδοσης ή αναλυτικών:",
    "lbl_cookies_text15": "Τα cookies απόδοσης χρησιμοποιούνται για την ανάλυση του τρόπου με τον οποίο χρησιμοποιούνται οι ιστότοποι και για την παρακολούθηση της απόδοσής τους, γεγονός που μας επιτρέπει να βελτιώσουμε την εμπειρία σας στη χρήση των ιστότοπων. Αυτά τα cookies μας βοηθούν να προσαρμόσουμε το περιεχόμενο του",
    "lbl_cookies_text16": "ιστοσελίδες",
    "lbl_cookies_text17": "να αντικατοπτρίζει αυτό που οι χρήστες του ιστότοπου βρίσκουν πιο ενδιαφέρον και να εντοπίζει πότε προκύπτουν τεχνικά προβλήματα με τους ιστότοπους. Ενδέχεται επίσης να χρησιμοποιήσουμε αυτά τα δεδομένα για να συντάξουμε αναφορές που θα μας βοηθήσουν να αναλύσουμε πώς χρησιμοποιούνται οι ιστότοποι, ποια είναι τα πιο συνηθισμένα προβλήματα και πώς μπορούμε να βελτιώσουμε τους ιστότοπους.",
    "lbl_cookies_text18": "Μπορούν να αποκλειστούν τα cookies;",
    "lbl_cookies_text19": "Μπορείτε να επιλέξετε να αποδεχτείτε ή να απορρίψετε τα cookies. Τα περισσότερα προγράμματα περιήγησης ιστού δέχονται αυτόματα cookies, αλλά συνήθως μπορείτε να τροποποιήσετε τη ρύθμιση του προγράμματος περιήγησής σας για να απορρίψετε τα cookies, αν προτιμάτε. Εάν προτιμάτε να μην αποδέχεστε cookies, τα περισσότερα προγράμματα περιήγησης θα σας επιτρέπουν: (i) να αλλάξετε τις ρυθμίσεις του προγράμματος περιήγησής σας για να σας ειδοποιεί όταν λαμβάνετε ένα cookie, το οποίο σας επιτρέπει να επιλέξετε εάν θα το αποδεχτείτε ή όχι. (ii) να απενεργοποιήσετε τα υπάρχοντα cookies. ή (iii) για να ρυθμίσετε το πρόγραμμα περιήγησής σας ώστε να απορρίπτει αυτόματα τυχόν cookies. Ωστόσο, έχετε υπόψη σας ότι εάν απενεργοποιήσετε ή απορρίψετε τα cookies, ορισμένες λειτουργίες και υπηρεσίες στους ιστότοπούς μας ενδέχεται να μην λειτουργούν σωστά, επειδή ενδέχεται να μην μπορούμε να σας αναγνωρίσουμε και να σας συσχετίσουμε με τους λογαριασμούς σας. Επιπλέον, οι προσφορές που παρέχουμε όταν μας επισκέπτεστε μπορεί να μην είναι τόσο σχετικές με εσάς ή προσαρμοσμένες στα ενδιαφέροντά σας.",
    "lbl_cookies_text20": "Οδηγίες για τον αποκλεισμό ή την αποδοχή των cookies σε δημοφιλές λογισμικό περιήγησης στο Web Internet Explorer 7 και 8 Firefox Google Chrome Apple Safari AboutCookies.org",
    "lbl_linking": "Σύνδεση",
    "lbl_linking_text": "Ενδέχεται να συνδεθούμε με άλλους ιστότοπους που δεν βρίσκονται υπό τον έλεγχό μας. Αφού αποχωρήσετε από τον ιστότοπό μας, δεν μπορούμε να είμαστε υπεύθυνοι για την προστασία και το απόρρητο οποιασδήποτε πληροφορίας παρέχετε. Θα πρέπει να είστε προσεκτικοί και να δείτε τη δήλωση απορρήτου που ισχύει για τον εν λόγω ιστότοπο.",
    "lbl_yourRights": "Τα δικαιώματά σας",
    "lbl_yourRights_text": "Μπορείτε να ζητήσετε αντίγραφο οποιωνδήποτε προσωπικών πληροφοριών που διατηρούμε για εσάς. Μπορεί να καταβληθεί μια μικρή χρέωση. Μπορείτε να το κάνετε αυτό γράφοντάς μας στη διεύθυνση dataprotection@eaton.com",
    "lbl_yourRights_text1": "Σε ορισμένες περιπτώσεις, μπορεί να δικαιούστε να λαμβάνετε τα προσωπικά σας δεδομένα σε δομημένη, ευρέως χρησιμοποιούμενη και μηχανικά αναγνώσιμη μορφή.",
    "lbl_yourRights_text2": "Εάν πιστεύετε ότι οποιαδήποτε πληροφορία έχουμε για εσάς είναι εσφαλμένη ή ελλιπής, γράψτε μας ή στείλτε μας e-mail το συντομότερο δυνατό. Θα διορθώσουμε ή θα ενημερώσουμε οποιαδήποτε πληροφορία, όπως ενδείκνυται, το συντομότερο δυνατό.",
    "lbl_yourRights_text3": "Έχετε το δικαίωμα να μας ζητήσετε να περιορίσουμε την επεξεργασία των προσωπικών σας δεδομένων σε ορισμένες περιπτώσεις όπου:",
    "lbl_yourRights_text4": "αμφισβητείτε την ακρίβεια των προσωπικών δεδομένων που επεξεργαζόμαστε για εσάς·",
    "lbl_yourRights_text5": "η επεξεργασία των δεδομένων σας από εμάς είναι παράνομη·",
    "lbl_yourRights_text6": "δεν χρειαζόμαστε πλέον τα προσωπικά δεδομένα για τους σκοπούς για τους οποίους τα επεξεργαζόμαστε, αλλά χρειάζεστε τα προσωπικά δεδομένα για τη θεμελίωση, άσκηση ή υπεράσπιση νομικών αξιώσεων.",
    "lbl_yourRights_text7": "αμφισβητείτε εάν οι νόμιμοι λόγοι του υπευθύνου επεξεργασίας υπερισχύουν εκείνων του υποκειμένου των δεδομένων.",
    "lbl_yourRights_text8": "Εάν έχετε οποιεσδήποτε ερωτήσεις, σχόλια ή προτάσεις σχετικά με τη χρήση των προσωπικών σας δεδομένων από εμάς, γράψτε μας στο dataprotection@eaton.com",
    "lbl_yourRights_text9": "Έχετε επίσης το δικαίωμα να υποβάλετε καταγγελία στο Γραφείο του Επιτρόπου Πληροφοριών εάν δεν είστε ικανοποιημένοι με την επεξεργασία των προσωπικών σας δεδομένων από εμάς.",
    "lbl_noticeToCalfrniaResidents": "Notice to California ResidentsΕιδοποίηση για τους κατοίκους της Καλιφόρνια",
    "lbl_noticeToCalfrniaResidents_text": "Αυτή η ενότητα ισχύει για κατοίκους της Καλιφόρνια.",
    "lbl_noticeToCalfrniaResidents_text1": "Όπως περιγράφεται στην ενότητα `Ποιες πληροφορίες συλλέγουμε;` ενδέχεται να συλλέξουμε προσωπικές πληροφορίες, συμπεριλαμβανομένων αναγνωριστικών, εμπορικών πληροφοριών, πληροφοριών δραστηριότητας στο διαδίκτυο ή άλλων δικτύων, πληροφορίες γεωγραφικής τοποθεσίας, επαγγελματικές πληροφορίες ή πληροφορίες που σχετίζονται με την απασχόληση και συμπεράσματα που προέρχονται από οποιαδήποτε από τις πληροφορίες που προσδιορίζονται παραπάνω δημιουργήστε ένα προφίλ για έναν καταναλωτή.",
    "lbl_noticeToCalfrniaResidents_text2": "Όπως περιγράφεται στην ενότητα «Με ποιον μοιραζόμαστε τις πληροφορίες σας;», οι προσωπικές πληροφορίες που συλλέγουμε από τους καταναλωτές ενδέχεται να κοινοποιηθούν για επιχειρηματικούς σκοπούς με τρίτα μέρη. Ενδέχεται να έχουμε αποκαλύψει όλες τις κατηγορίες προσωπικών πληροφοριών που αναφέρονται παραπάνω, με βάση την περίπτωση χρήσης, για επιχειρηματικό σκοπό τους τελευταίους 12 μήνες.",
    "lbl_noticeToCalfrniaResidents_text3": "Δεν πουλάμε τα προσωπικά σας στοιχεία.",
    "lbl_noticeToCalfrniaResidents_text4": "Ως κάτοικος Καλιφόρνια, έχετε ορισμένα δικαιώματα, με την επιφύλαξη νομικών περιορισμών και ισχυουσών εξαιρέσεων, σχετικά με τη συλλογή, τη χρήση και την κοινοποίηση των προσωπικών σας πληροφοριών. Τα δικαιώματα αυτά είναι ειδικότερα:",
    "lbl_noticeToCalfrniaResidents_text5": "Το δικαίωμα στη γνώση",
    "lbl_noticeToCalfrniaResidents_text6": "Έχετε το δικαίωμα να ζητήσετε πληροφορίες σχετικά με τις κατηγορίες προσωπικών πληροφοριών που έχουμε συλλέξει για εσάς, τις κατηγορίες πηγών από τις οποίες συλλέξαμε τις προσωπικές πληροφορίες, τους σκοπούς συλλογής των προσωπικών πληροφοριών, τις κατηγορίες τρίτων με τα οποία έχουμε μοιραστεί προσωπικές πληροφορίες και τον σκοπό για τον οποίο κοινοποιήσαμε τα προσωπικά σας στοιχεία («Αναφορά Κατηγοριών»). Μπορείτε επίσης να ζητήσετε πληροφορίες σχετικά με συγκεκριμένα κομμάτια προσωπικών πληροφοριών που έχουμε συλλέξει για εσάς («Αναφορά Συγκεκριμένων Κομμάτων»). Προσδιορίστε ποιο αίτημα θέλετε να εκτελέσετε.",
    "lbl_noticeToCalfrniaResidents_text7": "Το δικαίωμα διαγραφής",
    "lbl_noticeToCalfrniaResidents_text8": "Έχετε το δικαίωμα να ζητήσετε να διαγράψουμε τις προσωπικές πληροφορίες που έχουμε συλλέξει από εσάς.",
    "lbl_noticeToCalfrniaResidents_text9": "Το δικαίωμα εξαίρεσης",
    "lbl_noticeToCalfrniaResidents_text10": "Έχετε το δικαίωμα να εξαιρεθείτε από την πώληση των προσωπικών σας στοιχείων.",
    "lbl_noticeToCalfrniaResidents_text11": "Σύμφωνα με την ισχύουσα νομοθεσία, δεν θα κάνουμε διακρίσεις εναντίον σας για την άσκηση αυτών των δικαιωμάτων. Μπορείτε να χρησιμοποιήσετε έναν εξουσιοδοτημένο αντιπρόσωπο για να ασκήσετε τα δικαιώματά σας για λογαριασμό σας. Εάν υποβάλλετε οποιοδήποτε από τα παραπάνω αιτήματα μέσω εξουσιοδοτημένου αντιπροσώπου, θα ζητήσουμε γραπτή εξουσιοδότηση από εσάς και θα επιδιώξουμε να επαληθεύσουμε όπως περιγράφεται παραπάνω ή θα δεχτούμε ένα νόμιμο πληρεξούσιο βάσει του Κώδικα Διαθήκης της Καλιφόρνια στον εξουσιοδοτημένο αντιπρόσωπο.",
    "lbl_noticeToCalfrniaResidents_text12": "Μπορείτε να εκτελέσετε το δικαίωμά σας ανά πάσα στιγμή επικοινωνώντας μαζί μας στέλνοντας ένα email στο",
    "lbl_noticeToCalfrniaResidents_text13": "καλώντας +1-800-386-1911 ή χρησιμοποιώντας αυτό",
    "lbl_noticeToCalfrniaResidents_text14": "ηλεκτρονική φόρμα",
    "lbl_noticeToCalfrniaResidents_text15": "Για να ασκήσετε τα δικαιώματά σας, θα χρειαστεί να λάβουμε πληροφορίες για να σας εντοπίσουμε στα αρχεία μας ή να επαληθεύσουμε την ταυτότητά σας ανάλογα με τη φύση του αιτήματος. Εάν υποβάλλετε ένα αίτημα εκ μέρους ενός νοικοκυριού, θα πρέπει να επαληθεύσουμε κάθε μέλος του νοικοκυριού με τον τρόπο που ορίζεται σε αυτήν την ενότητα. Επομένως, βάσει του αιτήματος ενδέχεται να επικοινωνήσουμε μαζί σας για περισσότερες πληροφορίες.",
    "lbl_noticeToCalfrniaResidents_text16": "Θα απαντήσουμε σε ένα αίτημα εξαίρεσης εντός 15 ημερών. Θα απαντήσουμε σε αιτήματα για διαγραφή και αιτήματα για γνώση εντός 45 ημερών, εκτός εάν χρειαστούμε περισσότερο χρόνο, οπότε θα σας ειδοποιήσουμε και ενδέχεται να χρειαστούν έως και 90 ημέρες συνολικά για να απαντήσουμε στο αίτημά σας.",
    "lbl_viewSubEstate": "Προβολή υπο-περιουσίας",
    "lbl_installers": "Εγκαταστάτες",
    "lbl_managedPanels": "Μη διαχειριζόμενα πάνελ",
    "lbl_days": "Ημέρες",
    "lbl_email": "ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ",
    "lbl_company": "Εταιρία",
    "lbl_removeInstaller": "Κατάργηση προγράμματος εγκατάστασης",
    "lbl_confirm": "Επιβεβαιώνω",
    "msg_deleteAdminConfirmation": "Είστε βέβαιοι ότι θέλετε να καταργήσετε",
    "lbl_editDetails": "Επεξεργασία λεπτομερειών",
    "msg_adminAdded": "Ο διαχειριστής επεξεργάστηκε με επιτυχία",
    "msg_adminDeleted": "Ο διαχειριστής διαγράφηκε με επιτυχία",
    "msg_errorDeleteAdmin": "Σφάλμα κατά τη διαγραφή του διαχειριστή",
    "lbl_addAdmin": "Προσθήκη διαχειριστή",
    "lbl_info": "Πληροφορίες",
    "lbl_noOfPanels": "Αριθμός πάνελ",
    "lbl_dateRegistered": "Ημερομηνία εγγραφής",
    "lbl_cmpnyAddress": "Διεύθυνση εταιρίας",
    "lbl_addressDetails": "Λεπτομέρειες διεύθυνσης",
    "txt_addressDetails": "Για να εγγραφείτε για έναν εταιρικό λογαριασμό, δώστε τα στοιχεία της διεύθυνσης της εταιρείας σας.",
    "lbl_contactDetails": "Στοιχεία επικοινωνίας",
    "txt_contactDetails": "Για να εγγραφείτε για έναν εταιρικό λογαριασμό, δώστε τα στοιχεία επικοινωνίας της εταιρείας σας.",
    "lbl_phoneType": "Τύπος τηλεφώνου",
    "msg_alreadyAccnt": "Υπάρχει ήδη λογαριασμός για αυτό το email.",
    "txt_invalidEmail": "άκυρη διεύθυνση ηλεκτρονικού ταχυδρομείου",
    "lbl_createAccnt": "Δημιουργία λογαριασμού",
    "lbl_createAccntDetails": "Για να εγγραφείτε για έναν εταιρικό λογαριασμό, εισαγάγετε τις απαιτούμενες πληροφορίες παρακάτω.",
    "msg_pwdValidation": "Τουλάχιστον 8 χαρακτήρες, τουλάχιστον 1 κεφαλαίο γράμμα, 1 πεζό γράμμα, 1 αριθμός και 1 ειδικός χαρακτήρας",
    "lbl_createPwd": "Δημιούργησε έναν κωδικό",
    "txt_pwdLength": "Ο κωδικός πρόσβασης πρέπει να αποτελείται από τουλάχιστον 8 χαρακτήρες, να περιέχει τουλάχιστον έναν κεφαλαίο χαρακτήρα, μία φορά τον αριθμό και μία φορά τον ειδικό χαρακτήρα.",
    "lbl_licenseAgreememnt": "Συμφωνία άδειαςs",
    "lbl_licenseAgreememntDetails": "Για να εγγραφείτε για έναν εταιρικό λογαριασμό, θα πρέπει να διαβάσετε και να συμφωνήσετε με τους Όρους και Προϋποθέσεις.",
    "lbl_accntActivation": "Ενεργοποίηση Λογαριασμού",
    "msg_accntActivation": "Συγχαρητήρια, έχετε εγγραφεί με τη σύνδεση του",
    "lbl_accntActivationEmailSent": "Σας έχουμε στείλει ένα email ενεργοποίησης. Κάντε κλικ στο σύνδεσμο σε αυτό το μήνυμα για να ενεργοποιήσετε τον λογαριασμό σας.",
    "lbl_notReceive": "Δεν λάβατε email;",
    "lbl_sendAgain": "Στείλε ξανά",
    "lbl_cnfrmPwd": "Επιβεβαίωση Κωδικού",
    "msg_accntExists": "Υπάρχει ήδη λογαριασμός για αυτό το email.",
    "lbl_forgotPwd": "Εάν υπάρχει το User ID, τότε θα σταλεί ένας σύνδεσμος για την επαναφορά του κωδικού πρόσβασής σας",
    "lbl_RmtSvsMsg": "Δεν είναι δυνατή η συνέχεια, ο πίνακας βρίσκεται σε λειτουργία εγκατάστασης",
    "lbl_planned": "ΣΧΕΔΙΑΣΜΕΝΟΣ",
    "lbl_noPlannedService": "Δεν υπάρχουν αναφορές προγραμματισμένης υπηρεσίας",
    "lbl_rmtServInitiationMsg": "Έχει ζητηθεί μη αυτόματη απομακρυσμένη υπηρεσία, περιμένετε 5-10 λεπτά για να ολοκληρωθεί η αναφορά.",
    "lbl_okay": "εντάξει",
    "lbl_pwdReset": "ΕΠΑΝΑΦΟΡΑ ΚΩΔΙΚΟΥ",
    "lbl_setPwd": "Ορίστε κωδικό",
    "lbl_pwdResetMsg": "Για να επαναφέρετε τον κωδικό πρόσβασής σας, εισαγάγετε έναν νέο κωδικό πρόσβασης παρακάτω.",
    "lbl_pwdResetSuccess": "Επιτυχία επαναφοράς κωδικού πρόσβασης",
    "lbl_pwdResetSuccessMsg": "Ο κωδικός πρόσβασής σας επαναφέρθηκε με επιτυχία. Συνδεθείτε στην εφαρμογή χρησιμοποιώντας τον ενημερωμένο κωδικό πρόσβασής σας.",
    "lbl_pwdResetErrMsg": "Ζητήθηκε ήδη επαναφορά κωδικού πρόσβασης τα τελευταία 15 λεπτά.",
    "btn_download_notifications": "Λήψη ιστορικού πίνακα",
    "btn_download_logs": "Λήψη ειδοποιήσεων",
    "lbl_userAccDelete": "Διαγραφή λογαριασμού χρήστη",
    "lbl_userAccDeleteConfirm": "Θέλετε να επιβεβαιώσετε τη διαγραφή λογαριασμού;",
    "lbl_userAccDeleteMsg": "Για να ζητήσετε την κατάργηση ενός λογαριασμού χρήστη, εισαγάγετε τη διεύθυνση email του λογαριασμού παρακάτω και, στη συνέχεια, το κουμπί υποβολής.",
    "lbl_userAccDeleteSubmitMsg": "Το αίτημά σας για διαγραφή λογαριασμού χρήστη έχει υποβληθεί. Εάν υπάρχει το User ID, τότε θα σταλεί ένας σύνδεσμος για την επαναφορά του κωδικού πρόσβασής σας",
    "btn_rmvLogo": "Αφαιρέστε το λογότυπο",
    "btn_cgnLogo": "Αλλαγή λογότυπου",
    "lbl_endUserMsg": "Οι πληροφορίες που παρέχονται εδώ θα εμφανίζονται τόσο στην εφαρμογή για κινητά όσο και στις αναφορές Απομακρυσμένης εξυπηρέτησης",
    "lbl_dwnPanelLog": "Λήψη αρχείων καταγραφής πίνακα",
    "lbl_estatelessPanels": "Πίνακες που δεν είναι μέσα σε ένα Κτήμα",
    "lbl_siteComments": "Oχόλια",
    "mainHeadingforsystemConfig": "Επί κεφαλής",
    "lbl_EatonGCMorFCMurl": "Eaton GCM ή FCM url",
    "lbl_EatonapiKey": "Κλειδί API Eaton",
    "lbl_eatonApplepassword": "Κωδικός πρόσβασης Eaton Apple",
    "lbl_applePushNotification": "Η Apple χρησιμοποιεί ειδοποίηση ώθησης παραγωγής",
    "headingaccountSettings": "Ρυθμίσεις λογαριασμού",
    "lbl_account_default_state": "Προεπιλεγμένη κατάσταση λογαριασμού",
    "lbl_choose_state_for_new_account": "Επιλέξτε κατάσταση για νέο πρόγραμμα εγκατάστασης λογαριασμού",
    "lbl_account_disable_after": "Απενεργοποίηση λογαριασμού μετά",
    "lbl_the_top_limit_in_days": "Το ανώτατο όριο σε ημέρες, μετά το οποίο ένας λογαριασμός θα απενεργοποιηθεί",
    "lbl_remove_unpaired_account_after": "Αφαίρεση μη συζευγμένου λογαριασμού μετά",
    "lbl_helper_text": "Βοηθητικό κείμενο",
    "lbl_cloud_password": "Κωδικός πρόσβασης στο cloud",
    "lbl_user_registration_disable_time": "Χρόνος απενεργοποίησης εγγραφής χρήστη",
    "lbl_invalid_login_attempt": "Μη έγκυρη διάρκεια κλειδώματος προσπάθειας σύνδεσης",
    "lbl_maximum_sign_in_attempt": "Μέγιστες προσπάθειες σύνδεσης",
    "lbl_seconds": "δευτερόλεπτα",
    "lbl_times": "φορές",
    "headingCertificates": "Πιστοποιητικά",
    "lbl_eaton_Apple_certificaates": "Πιστοποιητικό Eaton Apple",
    "lbl_no_file_selected": "Κανένα επιλεγμένο αρχείο",
    "lbl_upload_file": "Ανέβασμα αρχείου",
    "lbl_heartbeat_interval": "Μεσοδιάστημα καρδιακών παλμών",
    "lbl_miliseconds": "χιλιοστά του δευτερολέπτου",
    "lbl_WS_session_drop_after": "Πτώση συνεδρίας WS μετά",
    "lbl_jwt_time_to_live": "JWT ώρα για ζωή",
    "lbl_communication_log_size": "Μέγεθος αρχείου καταγραφής επικοινωνίας",
    "lbl_panel_log_size": "Panel log download Size",
    "lbl_nesting_group_level": "Επίπεδο ομάδας φωλιάσματος",
    "lbl_ws_sessions_close_qty": "Οι συνεδρίες WS κλείνουν κατά αριθμό",
    "lbl_async_context_timeout": "Χρονικό όριο ασυγχρονισμού περιβάλλοντος",
    "lbl_refer_header": "Κεφαλίδα παραπομπής",
    "heading_Liceense_Agrrement": "Συμφωνητικό άδειας χρήσης",
    "heading_proxy": "Πληρεξούσιο",
    "lbl_proxy_host": "Διακομιστής μεσολάβησης",
    "lbl_proxy_port": "Θύρα διακομιστή μεσολάβησης",
    "lbl_use_proxy": "Χρήση διακομιστή μεσολάβησης",
    "lbl_fichet_gcmorfcm": "Διεύθυνση URL Fichet GCM ή FCM",
    "lbl_fichetApiKey": "Αρχείο κλειδιού API",
    "lbl_fichet_applePsword": "Fichet κωδικό πρόσβασης Apple",
    "lbl_fichet_applepassword": "Πιστοποιητικό Fichet Apple",
    "lbl_discard": "Απορρίπτω",
    "heading_CSL": "Ρυθμίσεις CSL",
    "lbl_csl_login": "Είσοδος CSL",
    "lbl_csl_password": "Κωδικός πρόσβασης CSL",
    "lbl_csl_primary_ip": "Πρωτεύον IP διακομιστή CSL",
    "lbl_csl_primary_port": "Θύρα πρωτεύοντος διακομιστή CSL",
    "lbl_csl_secondary_IP": "IP δευτερεύοντος διακομιστή CSL",
    "lbl_csl_secondary_port": "Θύρα δευτερεύοντος διακομιστή CSL",
    "lbl_csl_primary_protocol": "Πρωτόκολλο πρωτεύοντος διακομιστή CSL",
    "lbl_csl_secondary_portocol": "Πρωτόκολλο δευτερεύοντος διακομιστή CSL",
    "lbl_poll_fails": "Η δημοσκόπηση CSL αποτυγχάνει μετά",
    "lbl_minutes": "λεπτά",
    "button_CSL_ARC": "Κωδικοί CSL ARC",
    "button__csl_countries": "Χώρες CSL",
    "lbl_dual_com_login": "Είσοδος CSL DualCom",
    "lbl_dual_com_password": "Κωδικός πρόσβασης CSL DualCom",
    "lbl_poll_failer_helper": "Αποστολή μηνύματος δημοσκόπησης fail sia στο CSL εάν ο πίνακας αποσυνδεθεί περισσότερο από αυτήν την τιμή σε λίγα λεπτά",
    "lbl_add_email": "Προσθήκη ηλεκτρονικού ταχυδρομείου",
    "lbl_csl_support_team_e-mail_address": "Διευθύνσεις ηλεκτρονικού ταχυδρομείου της ομάδας υποστήριξης CSL",
    "lbl_Support_email_list_of_CSL_arc_provider": "Υποστήριξη λίστας email του παρόχου CSL arc",
    "lbl_support_team_email_address": "Διευθύνσεις email της ομάδας υποστήριξης",
    "lbl_email_address_of_support_team": "Διευθύνσεις email της ομάδας υποστήριξης",
    "heading_Permissions": "Άδειες",
    "lbl_Enable_auth": "Ενεργοποίηση auth",
    "lbl_Mail_transport_protocol_authorization": "Εξουσιοδότηση πρωτοκόλλου μεταφοράς αλληλογραφίας",
    "lbl_EnableTLS": "Ενεργοποίηση TLS",
    "lbl_Mail_transport_protocol_enable": "Ενεργοποίηση πρωτοκόλλου μεταφοράς αλληλογραφίας",
    "heading_Info_SMTP": "Πληροφορίες SMTP",
    "lbl_SMTP_host": "πλήθος SMTP",
    "lbl_Mail_trasport_protocol_host": "Κεντρικός υπολογιστής πρωτοκόλλου μεταφοράς αλληλογραφίας",
    "lbl_SMTP_port": "Θύρα SMTP",
    "lbl_Mail_trasport_protocol_post": "Ανάρτηση πρωτοκόλλου μεταφοράς αλληλογραφίας",
    "lbl_SMTP_user": "Χρήστης SMTP",
    "lbl_Mail_transport_protocol_user": "Χρήστης πρωτοκόλλου μεταφοράς αλληλογραφίας",
    "lbl_SMTP_password": "Κωδικός πρόσβασης SMTP",
    "lbl_Mail_transport_protocol_password": "Κωδικός πρόσβασης πρωτοκόλλου μεταφοράς αλληλογραφίας",
    "lbl_Protocol": "Πρωτόκολλο",
    "lbl_Mail_transport_protocol": "Πρωτόκολλο μεταφοράς αλληλογραφίας",
    "lbl_Form": "Μορφή",
    "lbl_Mail_transport_protocol_source": "Πηγή πρωτοκόλλου μεταφοράς αλληλογραφίας",
    "heading_Email_Settings": "Ρυθμίσεις email",
    "lbl_Email_Text_Language": "Γλώσσα κειμένου email",
    "lbl_User_registration_e-mail": "E-mail εγγραφής χρήστη",
    "lbl_helper_text_for_user_register_mail": "Αυτό το κείμενο θα σταλεί στο email του χρήστη. Το ${user.login} θα αντικατασταθεί κατά τη σύνδεση του χρήστη, ο οποίος θα λάβει email ${user.name} - όνομα χρήστη, ${user-cloudId} - αναγνωριστικό cloud χρήστη , ${confirm.link} - σύνδεσμος επιβεβαίωσης ηλεκτρονικού ταχυδρομείου",
    "lbl_State_change_e-mail": "E-mail αλλαγής κατάστασης",
    "lbl_helpertext_for_state_email_change": "Αυτό το κείμενο θα σταλεί στο email του χρήστη. Το ${user.login} θα αντικατασταθεί κατά τη σύνδεση του χρήστη, ο οποίος θα λάβει email ${user.name} - όνομα χρήστη, ${user.state} κατάσταση χρήστη, ${user-cloudId} - αναγνωριστικό cloud χρήστη",
    "lbl_Forgot_login_e-mail_subject": "Ξέχασα το θέμα του e-mail σύνδεσης",
    "lbl_Email_subject_for_login_reminder": "Θέμα email για υπενθύμιση σύνδεσης",
    "lbl_Forgotlogine-mail": "Ξέχασα το e-mail σύνδεσης",
    "lbl_helpertext_for_forget_login_mail": "Όταν αλλάζετε περιγραφή, εισάγετε τις μεταβλητές ${user.name} και ${user.login} όταν θέλετε να δείτε το όνομα χρήστη και τη σύνδεση χρήστη αντίστοιχα",
    "lbl_App_user_creation_e-mail": "Email δημιουργίας χρηστών εφαρμογής",
    "lbl_helper_text_for_app_user_creation": "Αυτό το κείμενο θα σταλεί στο email του χρήστη. Το ${user.login} θα αντικατασταθεί κατά τη σύνδεση του χρήστη, ο οποίος θα λάβει email ${user.name} - όνομα χρήστη ${confirm.link} - σύνδεσμος επιβεβαίωσης ηλεκτρονικού ταχυδρομείου",
    "lbl_Forgot_passworde-mail": "Ξέχασα τον κωδικό πρόσβασης e-mail",
    "lbl_helper_text_for_forgetpassworde-mail": "Αυτό το κείμενο θα σταλεί στο email του χρήστη. ${user.name} - όνομα χρήστη ${secretLink} - URL για αλλαγή κωδικού πρόσβασης",
    "lbl_Maintenance_notification_subject": "Θέμα ειδοποίησης συντήρησης",
    "lbl_Subject_for_all_user_maintenancenotification": "Θέμα για όλες τις ειδοποιήσεις συντήρησης χρήστη",
    "lbl_Maintenance_notificationtext": "Κείμενο ειδοποίησης συντήρησης",
    "lbl_helper_text_for_maintain_notigication": "Αυτό το κείμενο θα σταλεί σε όλους τους χρήστες όταν είναι απαραίτητο να ειδοποιηθούν για κάποιο συμβάν στον διακομιστή cloud",
    "lbl_Raised_alert_event_e-mail_text": "Κείμενο e-mail συμβάντος ειδοποίησης",
    "lbl_helper_text_for_raised_alert_event": "Αυτό θα σταλεί στην ομάδα υποστήριξης του πίνακα. ${site.name} - θα αντικατασταθεί στο όνομα αναφοράς του cloud panel ${panel.id} - θα αντικατασταθεί στο αναγνωριστικό του πίνακα cloud ${sia.code} - συμβάν ειδοποίησης Κωδικός SIA ${description} - συμβάν προειδοποίησης Περιγραφή κωδικού SIA ${time.stamp} - χρονική σήμανση κατά την υποβολή της ειδοποίησης ${panel.link} - Υπερσύνδεσμος στην οθόνη του πίνακα cloud",
    "lbl_Self_test_failure_text": "Κείμενο αποτυχίας αυτοδιαγνωστικού ελέγχου",
    "lbl_helper_text_for_test_failure_Text": "Αυτό το κείμενο θα σταλεί στην ομάδα υποστήριξης.${event.ipAdd} - διεύθυνση IP του αποτυχημένου κόμβου ${event.type} - τύπος συμβάντος ${event.message} - μήνυμα ${event.connected} - κατάσταση σύνδεσης ${event.date} - χρονική σήμανση συμβάντος",
    "lbl_SIA_message_failure_e-mail_subject": "Θέμα e-mail αποτυχίας μηνύματος SIA",
    "lbl_Subject_to_message_about_fails_in_sending_sia_events": "Υπόκειται σε μήνυμα σχετικά με αποτυχίες στην αποστολή συμβάντων sia",
    "lbl_SIA_message_failuree-mail_text": "Κείμενο ηλεκτρονικού ταχυδρομείου αποτυχίας μηνύματος SIA",
    "lbl_Email_text_about_siaevent_sendingproblems": "Κείμενο email σχετικά με προβλήματα αποστολής συμβάντων sia",
    "lbl_time_in_seconds_for_locking_account": "Χρόνος σε δευτερόλεπτα για το κλείδωμα του λογαριασμού όταν συνέβησαν πάρα πολλές αποτυχημένες προσπάθειες σύνδεσης",
    "lbl_max_failed_login": "Μέγιστος αριθμός αποτυχημένων προσπαθειών σύνδεσης πριν το κλείδωμα του λογαριασμού",
    "lbl_seconds_for_temporarily_disable_new_user_registration": "Χρόνος σε δευτερόλεπτα για την προσωρινή απενεργοποίηση της εγγραφής νέου χρήστη",
    "lbl_credentials_for_the_cloud_user_into_the_panel": "Καταχωρίστε τα διαπιστευτήρια για τον χρήστη του cloud στον πίνακα",
    "lbl_Days_to_remove_unpaired_user_accounts": "Ημέρες για την κατάργηση μη συζευγμένων λογαριασμών χρηστών",
    "lbl_google_firebase_push_notification": "URL διακομιστή ειδοποιήσεων push Google ή Firebase",
    "lbl_api_key": "Api Key",
    "lbl_Apple_push_notification_server_password": "Κωδικός πρόσβασης διακομιστή ειδοποιήσεων push της Apple",
    "lbl_determines_apple_server": "Καθορίζει ποιοι διακομιστές Apple πρέπει να χρησιμοποιούνται: παραγωγή ή sandbox",
    "lbl_upload_certificate_for_eaton": "Ανεβάστε ένα πιστοποιητικό για το Eaton Apple Push Notification",
    "lbl_Heartbeat_intervalinmilliseconds": "Διάστημα καρδιακών παλμών σε χιλιοστά του δευτερολέπτου",
    "lbl_Atime_delay_between_closing_session": "Χρονική καθυστέρηση μεταξύ του κλεισίματος της συνεδρίας",
    "lbl_json_web_token": "JSON web token χρόνος για να ζήσετε",
    "lbl_hazelcast_max_comminication_log_size": "Μέγιστο μέγεθος αρχείου καταγραφής επικοινωνίας Hazelcast",
    "lbl_Max_size_of_log_entries": "Μέγιστο μέγεθος καταχωρήσεων ημερολογίου για λήψη από τον πίνακα",
    "lbl_max_nesting_group_level": "Μέγιστο επίπεδο ομάδας ένθεσης",
    "lbl_count_of_Web_token_which_will_be_closed": "Ένα πλήθος περιόδων σύνδεσης web socket, οι οποίες θα κλείσουν",
    "lbl_timeout_for_external_async_request": "Χρονικό όριο για εξωτερικό αίτημα ασυγχρονισμού",
    "lbl_pattern_for_refer_header": "Μοτίβο για την κεφαλίδα αναφοράς",
    "heading_Support_actions": "Υποστηρικτικές δράσεις",
    "lbl_User_e-mail": "Ηλεκτρονικό ταχυδρομείο χρήστη",
    "lbl_Action": "Δράση",
    "btn_Startaction": " Ξεκινήστε τη δράση",
    "heading_Download_Reports": "Λήψη Αναφορών",
    "lbl_Installer_report": "Αναφορά εγκαταστάτη",
    "lbl_Download": "Κατεβάστε",
    "lbl_App_userReport": "Αναφορά χρήστη εφαρμογής",
    "lbl_Managed_panelsreport": "Αναφορά διαχειριζόμενων πλαισίων",
    "lbl_Unmanaged_panels_report": "Αναφορά μη διαχειριζόμενων πλαισίων",
    "lbl_ARC_report": "Έκθεση ARC",
    "heading_confirmation": "Επιβεβαίωση",
    "msg_for_Pairing": "Αυτό θα αφαιρέσει όλες τις πληροφορίες χρήστη (σύζευξη, κινητές συσκευές) και δεν θα μπορεί πλέον να έχει πρόσβαση σε αυτές",
    "msg_for_remove_pariring": "Είστε βέβαιοι ότι θέλετε να καταργήσετε τη σύζευξη μεταξύ",
    "msg_for_disable_registration": "Είστε βέβαιοι ότι θέλετε να απενεργοποιήσετε προσωρινά τις εγγραφές από το cloud και την εφαρμογή",
    "lbl_User_Info": "Πληροφορίες χρήστη",
    "error_pwd_info_capital": "Χρησιμοποιήστε κεφαλαία γράμματα (X,Y,Z...),",
    "error_pwd_small": "πεζά γράμματα (x,y,z...),",
    "error_pwd_number": "αριθμοί (1,2,3,4...),",
    "error_pwd_special": "και ειδικούς χαρακτήρες (!@#$%^&*) στον κωδικό πρόσβασης στον κωδικό πρόσβασης",
    "error_pwd_length": "Το μήκος του κωδικού πρόσβασης δεν πρέπει να είναι μικρότερο από 6 χαρακτήρες.",
    "lbl_installer_Details": "Στοιχεία εγκαταστάτη",
    "lbl_error_for_Date_Range": "Δεν υπάρχει αποτέλεσμα που να ταιριάζει για το επιλεγμένο εύρος ημερομηνιών",
    "lbl_panel_and_esate": "Πάνελ και κτήματα",
    "lbl_new_subEsates": "Νέο Υπο-Κτήμα",
    "btn_rmv_estate": "Κατάργηση περιουσίας",
    "heading_est_details": "Στοιχεία ακινήτου",
    "lbl_Searchforpanels": "Αναζήτηση για πάνελ",
    "lbl_placeholder_for_Search": "Πίνακες αναζήτησης...",
    "heading_new_subEstates_Details": "Στοιχεία υπο-περιουσίας",
    "lbl_edit_estate": "Επεξεργασία περιουσίας",
    "msg_edit_estatesDetails": "Τα στοιχεία της περιουσίας επεξεργάστηκαν με επιτυχία",
    "msg_NoEstatesorPanelsassigned": "Δεν έχουν εκχωρηθεί κτήματα ή πάνελ",
    "lbl_DeleteUser": "Διαγραφή χρήστη",
    "lbl_confirm_delete": "Είστε βέβαιοι ότι θέλετε να διαγράψετε",
    "msg_user_deleted": "Ο χρήστης διαγράφηκε με επιτυχία",
    "msg_Installer_details_edit": "Τα στοιχεία του προγράμματος εγκατάστασης επεξεργάστηκαν με επιτυχία",
    "btn_Deactivate_Installer": "Απενεργοποιήστε το πρόγραμμα εγκατάστασης",
    "btn_Activate_Installer": "Ενεργοποιήστε το πρόγραμμα εγκατάστασης",
    "lbl_Unassigned_Panels":"Μη εκχωρημένα πάνελ",
    "lbl_to":"να...",
    "lbl_plz_select":"Επιλέξτε",
    "error_msg_24_character":"Το μήκος εισαγωγής δεν πρέπει να υπερβαίνει τους 24 χαρακτήρες",
    "error_msg_48_character":"Το μήκος εισαγωγής δεν πρέπει να υπερβαίνει τους 48 χαρακτήρες",
    "error_msg_255_character":"Το μήκος εισαγωγής δεν πρέπει να υπερβαίνει τους 255 χαρακτήρες",
    "error_msg_20_character":"Το μήκος εισαγωγής δεν πρέπει να υπερβαίνει τους 20 χαρακτήρες",
    "error_msg_100_character":"Το μήκος εισαγωγής δεν πρέπει να υπερβαίνει τους 100 χαρακτήρες",
    "error_msg_200_character":"Το μήκος εισαγωγής δεν πρέπει να υπερβαίνει τους 200 χαρακτήρες"
};
export default resources;