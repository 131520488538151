/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import { SiaEmailOptionsProps } from './../../components/UserEmailAlertsModal/UserEmailAlertsTypes';
/* eslint-disable no-empty */
import { PanelLogsArrayProps } from './../../components/PanelLogs/PanelLogsTypes';
/* eslint-disable @typescript-eslint/await-thenable */

import { ArcCountryCodesProps, CslCountries, ReportGroupsPropTypes } from './../../components/ARCAccessModal/ARCAccessProps';
import { MobileAppUsersPropTypes, MobileAppUserByLoginPropTypes } from './../../components/MobileApplicationUsers/MobileApplicationUsersTypes';
import {
    PanelARCSettingsProps,
} from './../../components/SpecificPanelDetails/SpecificPanelEstateTypes';
import { PanelARCReportingTypeProps } from './../../components/PanelARCReporting/PanelARCReportingTypes';
import { PanelNotificationTypeProps } from './../../components/PanelNotifications/PanelNotificationTypes';
import { ConnectionStatsTypeProps } from '../../components/SpecificPanelDetails/SpecificPanelEstateTypes';
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { EstateProps, PanelProps } from './../../components/ListAndGridView/panelEstateTypes';
/* eslint-disable arrow-body-style */
import { SpecificPanelActionTypes } from '../constants/specificPanelActionTypes';
import { DispatchTypesProps } from '../dispatchActionTypes/dispatchAndThunkActionTypes';
import { Dispatch } from 'react';
import { getPerformRemoteServicingStatus } from './remoteServicingActions';
import { ZoneMonitoringArrayProps } from '../../components/RemoteServicingActivityMonitoring/ZonesMoniteringListTypes';
import { REACT_APP_BASE_URL, REACT_ARD_BASE_URL } from '../../entities/Endpoints';


const setSpecificPanelDetails = (specificPanelDetails: PanelProps): any => {
    return {
        type: SpecificPanelActionTypes.SET_SPECIFIC_PANEL,
        payload: specificPanelDetails,
    };
};

const setSpecificPanelEstateDetails = (specificPanelEstateDetails: EstateProps): any => {
    return {
        type: SpecificPanelActionTypes.SET_SPECIFIC_PANEL_ESTATE,
        payload: specificPanelEstateDetails,
    };
};

const setPanelConnectionStats = (connectionStats: ConnectionStatsTypeProps): any => {
    return {
        type: SpecificPanelActionTypes.SET_SPECIFIC_PANEL_CONNECTION_STATS,
        payload: connectionStats,
    };
};

const setPanelNotifications = (panelNotifications: PanelNotificationTypeProps): any => {
    return {
        type: SpecificPanelActionTypes.SET_SPECIFIC_PANEL_NOTIFICATIONS,
        payload: panelNotifications,
    };
};
const setPanelNotificationsLength = (panelNotificationsLen: number): any => {
    return {
        type: SpecificPanelActionTypes.SET_SPECIFIC_PANEL_NOTIFICATIONS_LENGTH,
        payload: panelNotificationsLen,
    };
};

const setData = (data: any): any => {
    return {
        type: SpecificPanelActionTypes.SET_SPECIFIC_PANEL_NOTIFICATIONS_DATA,
        payload: data,
    };
};

const setPanelLogs = (panelLogs: PanelLogsArrayProps): any => {
    return {
        type: SpecificPanelActionTypes.SET_SPECIFIC_PANEL_LOGS,
        payload: panelLogs,
    };
}
const setPanelLogData = (panelLogsData: PanelLogsArrayProps): any => {
    return {
        type: SpecificPanelActionTypes.SET_SPECIFIC_PANEL_LOGS_DATA,
        payload: panelLogsData,
    };
}
const setPanelLogsLength = (panelLogsLength: number): any => {
    return {
        type: SpecificPanelActionTypes.SET_SPECIFIC_PANEL_LOGS_LENGTH,
        payload: panelLogsLength,
    };
}

const setPanelARCReporting = (panelARCReporting: PanelARCReportingTypeProps): any => {
    return {
        type: SpecificPanelActionTypes.SET_SPECIFIC_PANEL_ARC_REPORTING,
        payload: panelARCReporting,
    };
};
const setARCData = (panelARCData: any): any => {
    return {
        type: SpecificPanelActionTypes.SET_SPECIFIC_PANEL_ARC_REPORTING_DATA,
        payload: panelARCData,
    };
};

const setMobileAppUsers = (mobileAppUsers: MobileAppUsersPropTypes): any => {
    return {
        type: SpecificPanelActionTypes.SET_SPECIFIC_PANEL_MOBILE_APP_USERS,
        payload: mobileAppUsers,
    }
}

const setDeleteMobAppUser = (statusCode: number): any => {
    return {
        type: SpecificPanelActionTypes.SET_DELETE_MOBILE_APP_USER_STATUS,
        payload: statusCode,
    }
}

const setMobileAppUserByLogin = (mobileAppUserByLogin: MobileAppUserByLoginPropTypes): any => {
    return {
        type: SpecificPanelActionTypes.SET_MOBILE_APP_USER_BY_LOGIN,
        payload: mobileAppUserByLogin,
    }
}

const setMobileAppUserByLoginStatus = (mobileAppUserByLoginStatus: any): any => {
    return {
        type: SpecificPanelActionTypes.SET_MOBILE_APP_USER_BY_LOGIN_STATUS,
        payload: mobileAppUserByLoginStatus,
    }
}

const setPanelARCSettings = (panelARCSettings: PanelARCSettingsProps): any => {
    return {
        type: SpecificPanelActionTypes.SET_SPECIFIC_PANEL_ARC_SETTINGS,
        payload: panelARCSettings,
    };
};

const setReportGroups = (reportGroups: ReportGroupsPropTypes): any => {
    return {
        type: SpecificPanelActionTypes.SET_REPORT_GROUPS,
        payload: reportGroups,
    };
};
const setCslCountries = (cslCountries: CslCountries): any => {
    return {
        type: SpecificPanelActionTypes.SET_CSL_COUNTRIES,
        payload: cslCountries,
    };
};

const setUserAccess = (status: number): any => {
    return {
        type: SpecificPanelActionTypes.SET_SPECIFIC_PANEL_USER_ACCESS,
        payload: status,
    };
};
const setUserEmailAlerts = (userEmailAlerts: SiaEmailOptionsProps): any => {
    return {
        type: SpecificPanelActionTypes.SET_USER_EMAIL_ALERTS,
        payload: userEmailAlerts,
    };
};

const setInstallerEstateID = (installerEstateID: number): any => {
    return {
        type: SpecificPanelActionTypes.SET_INSTALLER_ESTATE_ID,
        payload: installerEstateID,
    };
};

const setInstallerEstateList = (estateList: EstateProps): any => {
    return {
        type: SpecificPanelActionTypes.SET_INSTALLER_ESTATE_LIST,
        payload: estateList,
    }
}

const setARCCountryCodes = (panelARCCountryCodes: ArcCountryCodesProps): any => {
    return {
        type: SpecificPanelActionTypes.SET_SPECIFIC_PANEL_ARC_COUNTRY_CODES,
        payload: panelARCCountryCodes,
    };
};

const deletePanel = (data: number): any => {
    return {
        type: SpecificPanelActionTypes.DELETE_SPECIFIC_PANEL,
        payload: data,
    };
};
const deleteEstateStatus = (status: number): any => {
    return {
        type: SpecificPanelActionTypes.DELETE_SPECIFIC_ESTATE,
        payload: status,
    };
};


const setView = (viewName: string): any => {
    return {
        type: SpecificPanelActionTypes.SET_PANEL_VIEW,
        payload: viewName,
    };
};

const setEstatesView = (viewName: string): any => {
    return {
        type: SpecificPanelActionTypes.SET_ESTATES_PANEL_VIEW,
        payload: viewName,
    };
};

const setEditSiteDetails = (status: number): any => {
    return {
        type: SpecificPanelActionTypes.SET_SPECIFIC_PANEL_SITE_DETAILS,
        payload: status,
    };
};

const setInstallerEmailAlerts = (status: number): any => {
    return {
        type: SpecificPanelActionTypes.SET_SPECIFIC_PANEL_INSTALLER_EMAIL_ALERTS,
        payload: status,
    };
};

const setArcDisableStatus = (arcDisableStatus: number): any => {
    return {
        type: SpecificPanelActionTypes.SET_ARC_DISABLE_STATUS,
        payload: arcDisableStatus,
    }
}

const setInstallerEmailAlertsStatus = (status: boolean): any => {
    return {
        type: SpecificPanelActionTypes.SET_INSTALLER_EMAIL_ALERTS_STATUS,
        payload: status,
    }
}

const setInstallerEmailAlertsArray = (alerts: string[]): any => {
    return {
        type: SpecificPanelActionTypes.SET_INSTALLER_EMAIL_ALERTS,
        payload: alerts,
    }
}

const setInstallerEmailAlertsDisableStatus = (installerEmailAlertStatus: number): any => {
    return {
        type: SpecificPanelActionTypes.SET_INSTALLER_EMAIL_ALERTS_DISABLE_STATUS,
        payload: installerEmailAlertStatus,
    }
}
const setArcEnableStatus = (arcEnableStatus: number): any => {
    return {
        type: SpecificPanelActionTypes.SET_ARC_ENABLE_STATUS,
        payload: arcEnableStatus,
    }
}
const setArcConnectivityStatus = (arcConnectivityStatus: number): any => {
    return {
        type: SpecificPanelActionTypes.SET_ARC_CONNECTIVITY_STATUS,
        payload: arcConnectivityStatus,
    }
}
const setAddUserEmailAlertsStatus = (addUserEmailAlertsStatus: number): any => {
    return {
        type: SpecificPanelActionTypes.SET_ADD_USER_EMAIL_ALERTS_STATUS,
        payload: addUserEmailAlertsStatus,
    }
}
const setConnectLoginHtmlCodeStatus = (htmlCodeStatus: number): any => {
    return {
        type: SpecificPanelActionTypes.SET_CONNECT_LOGIN_HTML_CODE,
        payload: htmlCodeStatus,
    }
}

export const setDrawerState = (drawerState: string): any => {
    return {
        type: SpecificPanelActionTypes.SET_DRAWER_STATE,
        payload: drawerState,
    };
}

export const setZoneMonitorConfigList = (zonesList:ZoneMonitoringArrayProps):any => {
    return {
        type: SpecificPanelActionTypes.SET_ZONES_MONITORING_LIST,
        payload: zonesList,
    };
}

export const setZoneMonitorConfigListModifiedStatus = (zoneListModifiedStatus:number):any => {
    return {
        type: SpecificPanelActionTypes.SET_ZONES_MONITORING_LIST_MODIFIED_STATUS,
        payload: zoneListModifiedStatus,
    };
}


export function fetchSpecificPanelAndEstate(id: number | undefined) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/device/${id}`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((data) => dispatch(setSpecificPanelDetails(data)));
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/device/group/${id}`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((data) => dispatch(setSpecificPanelEstateDetails(data[0])));
        await fetch(`${REACT_APP_BASE_URL}/m2m-eaton-web/rest/reports/panel/statistic/${id}`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((data) => dispatch(setPanelConnectionStats(data)));
    };
}

export function fetchSpecificPanelDetails(id: number | undefined) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/device/${id}`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((data) => dispatch(setSpecificPanelDetails(data)));
    };
}

export function fetchSpecificPanelAndEstateAllDetails(id: number | undefined) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/device/${id}`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then(async (data) => {
                dispatch(setSpecificPanelDetails(data));
                await fetch(`${REACT_APP_BASE_URL}/m2m-eaton-web/rest/alertevents/${data.panelId}`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }).then((res) => {
                    if (res.status !== 204) {
                        dispatch(setInstallerEmailAlertsStatus(true));
                    } else {
                        dispatch(setInstallerEmailAlertsStatus(false));
                    }
                });


                await fetch(
                    `${REACT_ARD_BASE_URL}/m2m-eaton-web/ardconfig/enabled/${data.panelId}`, {
                    method: 'GET',
                    credentials: 'include',
                }
                )
                    // .then((res) => res.json().then(async(data) => {
                    //     if(res.status === 200)
                    //     await dispatch(getPerformRemoteServicingStatus({status:res.status,enabled:data.enabled}))
                    //     else
                    //     await dispatch(getPerformRemoteServicingStatus({status:res.status,enabled:false}))
                    // }))
                    .then(async(res) => {
                        if(res.status === 200 && res !== undefined){
                           const data :any = await res.json()
                           console.log(data)
                           if(data.state === 'installer'){
                            await dispatch(getPerformRemoteServicingStatus({status:res.status,enabled:data.state}))
                           }
                           else
                            await dispatch(getPerformRemoteServicingStatus({status:res.status,enabled:data.enabled}))
                        }
                        else {
                            await dispatch(getPerformRemoteServicingStatus({ status: res.status, enabled: false }))
                        }
                    })

                await fetch(`${REACT_APP_BASE_URL}/m2m-eaton-web/rest/arcsettings/${data.panelId}`, {
                    method: 'GET',
                    credentials: 'include',
                })
                    .then((res) => res.json())
                    .then(async (arcSetting) => {
                        dispatch(setPanelARCSettings(arcSetting));
                        await fetch(
                            `${REACT_APP_BASE_URL}/m2m-eaton-web/rest/arcsettings/csl/arcCodes?country=${encodeURIComponent(
                                arcSetting.country
                            )}`,
                            {
                                method: 'GET',
                                credentials: 'include',
                            }
                        )
                            .then((res) => res.json())
                            .then((countryCodes) => dispatch(setARCCountryCodes(countryCodes)));
                    });

                await fetch(
                    `${REACT_APP_BASE_URL}/m2m-eaton-web/rest/config/siaemailoption/${data.panelId}`,
                    {
                        method: 'GET',
                        credentials: 'include',
                    }
                )
                    .then((res) => res.json())
                    .then((data) => dispatch(setUserEmailAlerts(data)));

                await fetch(`${REACT_APP_BASE_URL}/m2m-eaton-web/rest/alertevents/${data.panelId}`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((res) => res.json())
                    .then((data) => dispatch(setInstallerEmailAlertsArray(data.availableGroups)));
            });
    };
}


export function deleteMobileAppUser(panelId: string, mobileUser: any) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-eaton-web/rest/pair/remove/${panelId}/${mobileUser}`, {
            method: 'DELETE',
            credentials: 'include',
        }).then((res) => {
            dispatch(setDeleteMobAppUser(res.status));
        });
    };
}

export function fetchMobileAppUserByLogin(panelId: string, login: string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-eaton-web/rest/mobileApplication/byMobileUserLogin/${login}/${panelId}`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((data) => dispatch(setMobileAppUserByLogin(data)))
    };
}
export function fetchMobileAppUserByLoginStatus(panelId: string, login: string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-eaton-web/rest/mobileApplication/byMobileUserLogin/${login}/${panelId}`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => dispatch(setMobileAppUserByLoginStatus(res.status)))
    };
}


export function deleteSpecificPanel(id: number | undefined) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/device/${id}`, {
            method: 'DELETE',
            credentials: 'include',
        }).then((res) => {
            dispatch(deletePanel(res.status));
        });
    };
}
export function deleteSpecificEstate(id: number | undefined) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/group/${id}`, {
            method: 'DELETE',
            credentials: 'include',
        }).then((res) => {
            dispatch(deleteEstateStatus(res.status));
        });
    };
}

export function fetchPanelNotifications(id: number | undefined, start: number, rowsPerPage: number) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(
            `${REACT_APP_BASE_URL}/m2m-eaton-web/rest/push/byDevice/${id}?start=${start}&limit=${rowsPerPage}`,
            {
                method: 'GET',
                credentials: 'include',
            }
        )
            .then((res) => res.json())
            .then((data) => {
                const capitalizeAlarmType = (alarmType: string): string => {
                    const str = alarmType;
                    const str2 = str.charAt(0).toUpperCase() + str.slice(1);

                    return str2
                }

                dispatch(setPanelNotifications(data))
                            // dispatch(setData(
                            //     data?.map((not: any) => ({
                            //         timeStamp: new Date(not?.timeStamp).toUTCString(),
                            //         alarmType: not?.alarmType
                            //             ? capitalizeAlarmType(not?.alarmType)
                            //             : 'Information',
                            //         humanReadableMessage: JSON.parse(not?.text).text,
                            //         verbose: JSON.parse(not?.text).verbose,
                            //         deliveryStatus: not?.deliveryStatus === 'DELIVERED' ? 'DELIVERED' : 'FAILED',
                            //     }))
                            // ))
            });
    };
}
export function fetchPanelNotificationsLength(id: number | undefined, start: number, rowsPerPage: number) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(
            `${REACT_APP_BASE_URL}/m2m-eaton-web/rest/push/byDevice/${id}?start=${start}&limit=${rowsPerPage}`,
            {
                method: 'GET',
                credentials: 'include',
            }
        )
            .then((res) => res.json())
            .then((data) => {
                const capitalizeAlarmType = (alarmType:string):string => {
                    const str = alarmType;
                    const str2 = str.charAt(0).toUpperCase() + str.slice(1);
                
                    return str2
                }
             
                dispatch(setPanelNotificationsLength(data.length))
                            dispatch(setData(
                                data?.map((not: any) => ({
                                    timeStamp: new Date(not?.timeStamp).toUTCString(),
                                    alarmType: not?.alarmType
                                        ? capitalizeAlarmType(not?.alarmType)
                                        : 'Information',
                                    humanReadableMessage: JSON.parse(not?.text).text,
                                    verbose: JSON.parse(not?.text).verbose,
                                    deliveryStatus: not?.deliveryStatus === 'DELIVERED' ? 'DELIVERED' : 'FAILED',
                                }))
                            ))
            });
    };
}
export function fetchPanelLogs(id: number | undefined, panelId: string | undefined) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(
            `${REACT_APP_BASE_URL}/m2m-eaton-web/panelResources/panelid-${panelId}/logs/?$top=10&$filter=id%20le%20${id}`,
            {
                method: 'GET',
                credentials: 'include',
            }
        )
            .then((res) => res.json())
            .then((data) => {
                dispatch(setPanelLogs(data))
            });
    };
}
export function fetchPanelLogsInitial(panelId: string | undefined) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(
            `${REACT_APP_BASE_URL}/m2m-eaton-web/panelResources/panelid-${panelId}/logs/?$top=500`,
            {
                method: 'GET',
                credentials: 'include',
            }
        )
        .then(async(res) =>{
            if(res.status === 200){
            const logs = await res.json()
            await dispatch(setPanelLogs(logs))
            }
            else{
                dispatch(setPanelLogs([])) 
            }
            })
    };
}
export function fetchPanelLogsInitialLength(panelId: string | undefined) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(
            `${REACT_APP_BASE_URL}/m2m-eaton-web/panelResources/panelid-${panelId}/logs/?$top=500`,
            {
                method: 'GET',
                credentials: 'include',
            }
        )
            .then((res) => res.json())
            .then((data) => {
                dispatch(setPanelLogsLength(data.length))
                dispatch(setPanelLogData(
                    data?.map((log: any) => ({
                        id: `${log.id}\t`,
                        type: log.type,
                        desc: log.desc,
                        verbose: log.verbose ? log.verbose : '',
                        timeStamp: new Date(Number(log?.events[0].timestamp) * 1000).toUTCString(),
                        user: log?.events[0]?.user ? log?.events[0]?.user : '-',
                        userName: log?.events[0]?.username ? log?.events[0]?.username : '-'
                    }))
                ))
            });
    };
}

export function postUserAccess(id: string | undefined, access: boolean) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(
            `${REACT_APP_BASE_URL}/m2m-web-admin/rest/device/${id}/userAccess?access=${access}`,
            {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )
            .then((res) => {
                if (res.status === 204 && access === true) {
                    dispatch(setUserAccess(10))
                } else if (res.status === 204 && access === false) {
                    dispatch(setUserAccess(100))
                }

            })
    };
}

export function fetchPanelARCReporting(id: number | undefined) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/device/${id}`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then(
                async (data) =>
                    await fetch(
                        `${REACT_APP_BASE_URL}/m2m-eaton-web/rest/push/byPanel/${data.panelId}`,
                        {
                            method: 'GET',
                            credentials: 'include',
                        }
                    )
                        .then((res) => res.json())
                        .then((arcData) => {
                            dispatch(setPanelARCReporting(arcData))
                            dispatch(setARCData(
                                arcData?.map((not: any) => ({
                                    raiseTimestamp: new Date(not?.raiseTimestamp).toUTCString(),
                                    arcProvider: not?.arcProvider,
                                    value: not?.value,
                                    ascii: not?.ascii,
                                    state: not?.state === 'NACK_NO_RESPONSE_FROM_ARC'
                                    ? 'no response'
                                    : not?.state ===
                                      'SUSPENDED_ON_CLOUD'
                                    ? 'Rejected'
                                    : not?.state ===
                                      'QUEUED'
                                    ? 'Ready for delivering'
                                    : 'sent',
                                }))
                            ))
                        })
            );
    };
}

export function fetchMobileAppUsers(id: number | undefined) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/device/${id}`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then(
                async (data) =>
                    await fetch(
                        `${REACT_APP_BASE_URL}/m2m-eaton-web/rest/mobileUser/byPanelId/${data.panelId}`,
                        {
                            method: 'GET',
                            credentials: 'include',
                        }
                    )
                        .then((res) => res.json())
                        .then((mobUsers) => dispatch(setMobileAppUsers(mobUsers)))
            );
    };
}

export function fetchReportGroups() {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-eaton-web/rest/alertevents/reportGroups`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((data) => dispatch(setReportGroups(data)));
    };
}
export function fetchCslCountries() {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-eaton-web/rest/arcsettings/csl/countries`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((data) => dispatch(setCslCountries(data)));
    };
}
export function fetchArcCountryCodes(country: string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(
            `${REACT_APP_BASE_URL}/m2m-eaton-web/rest/arcsettings/csl/arcCodes?country=${encodeURIComponent(country)}`,
            {
                method: 'GET',
                credentials: 'include',
            }
        )
            .then((res) => res.json())
            .then((countryCodes) => dispatch(setARCCountryCodes(countryCodes)));
    };
}

export function fetchPanelARCSettings(id: number | undefined) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/device/${id}`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then(
                async (data) =>
                    await fetch(
                        `${REACT_APP_BASE_URL}/m2m-eaton-web/rest/arcsettings/${data.panelId}`,
                        {
                            method: 'GET',
                            credentials: 'include',
                        }
                    )
                        .then((res) => res.json())
                        .then(async (arcSetting) => {
                            dispatch(setPanelARCSettings(arcSetting));
                            await fetch(
                                `${REACT_APP_BASE_URL}/m2m-eaton-web/rest/arcsettings/csl/arcCodes?country=${encodeURIComponent(
                                    arcSetting.country
                                )}`,
                                {
                                    method: 'GET',
                                    credentials: 'include',
                                }
                            )
                                .then((res) => res.json())
                                .then((countryCodes) => dispatch(setARCCountryCodes(countryCodes)));
                        })
            );
    };
}



export function setPanelView(viewName: string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await dispatch(setView(viewName));
    };
}

export function setEstatesPanelView(viewName: string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await dispatch(setEstatesView(viewName));
    };
}


export function panelArcAccessConnectivityCheckAndEnable(panelId: string, body: any) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(body.arcProvider === 'SIA_IP' ?
            `${REACT_APP_BASE_URL}/m2m-eaton-web/rest/arcsettings/siaip/test/${panelId}?accountNumber=${body.accountNumber}&accountPrefix=&aesKey=&primaryIp=${body.primaryIp}&secondaryIp=${body.secondaryIp}&primaryPort=${body.primaryPort}&secondaryPort=${body.secondaryPort}&transmitterId=&receiverNumber=&encryption=${body.encryption}&siaVersion=${body.siaVersion}&emailReport=&arcCode=&reportEmail=${body.reportEmail}`
            :`${REACT_APP_BASE_URL}/m2m-eaton-web/rest/arcsettings/csl/test/${panelId}?arcCode=${body.arcCode}&chipNo=${body.accountNumber}`,
            {
                method: 'GET',
                credentials: 'include',
            }
        ).then(async (res) => {
            dispatch(setArcConnectivityStatus(res.status))
            if (res.status === 204) {
                await fetch(body.arcProvider === 'SIA_IP'?
                    `${REACT_APP_BASE_URL}/m2m-eaton-web/rest/arcsettings/siaip/${panelId}`
                    :`${REACT_APP_BASE_URL}/m2m-eaton-web/rest/arcsettings/csl/${panelId}`,
                    {
                        method: 'POST',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(body),
                    }
                ).then((res) => {
                    dispatch(setArcEnableStatus(res.status));
                });
            }
        });
    };
}
export function panelArcAccessEnabled(panelId: string, body: any) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-eaton-web/rest/arcsettings/siaip/${panelId}`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
            .then((res) => {
                dispatch(setArcEnableStatus(res.status))
            })
    };
}


export function panelArcAccessDisable(panelId: string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-eaton-web/rest/arcsettings/${panelId}`, {
            method: 'DELETE',
            credentials: 'include',
        }).then((res) => {
            dispatch(setArcDisableStatus(res.status))
        })
    };
}

export function panelInstallerEmailAlertsDisable(panelId: string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-eaton-web/rest/alertevents/${panelId}`, {
            method: 'DELETE',
            credentials: 'include',
        }).then((res) => {
            dispatch(setInstallerEmailAlertsDisableStatus(res.status))
        })
    };
}

export function putInstallerEmailAlerts(panelId: string, body: any) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-eaton-web/rest/alertevents/${panelId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
            .then((res) => {
                dispatch((setInstallerEmailAlerts(res.status)))
                dispatch(setInstallerEmailAlertsDisableStatus(0))
            })
    }
}

export function fetchInstallerEmailAlerts(panelId: string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        try {
            await fetch(`${REACT_APP_BASE_URL}/m2m-eaton-web/rest/alertevents/${panelId}`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((res) => {
                    if (res.status !== 204) {
                        dispatch(setInstallerEmailAlertsStatus(true))
                    } else {
                        dispatch((setInstallerEmailAlertsStatus(false)))
                    }
                })
        } catch (error) {

        }
    }
}

export function fetchInstallerEmailAlertsArray(panelId: string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        try {
            await fetch(`${REACT_APP_BASE_URL}/m2m-eaton-web/rest/alertevents/${panelId}`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((res) => res.json())
                .then((data) => dispatch(setInstallerEmailAlertsArray(data.availableGroups)))
        } catch (error) {

        }
    }

};

export function putSiteDetails(siteId: number, body: any, selectedEstateId: number, panelIdArray: number[]) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/device/${siteId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
            .then(async (res) => {
                if (selectedEstateId !== 0) {
                    await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/device/assign/${selectedEstateId}`, {
                        method: 'PUT',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(panelIdArray)
                    }).then(async (res) => {
                        await dispatch((setEditSiteDetails(res.status)))
                    })
                } else {
                    await dispatch((setEditSiteDetails(res.status)))
                }
            })
    }
}

export function fetchUserEmailAlerts(panelId: string | undefined) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(
            `${REACT_APP_BASE_URL}/m2m-eaton-web/rest/config/siaemailoption/${panelId}`,
            {
                method: 'GET',
                credentials: 'include',
            }
        )
            .then((res) => res.json())
            .then((data) => dispatch(setUserEmailAlerts(data)));
    };
}

export function fetchEstateIDForInstaller(installerUserId: string | undefined) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(
            `${REACT_APP_BASE_URL}/m2m-web-admin/rest/group/list/${installerUserId}`,
            {
                method: 'GET',
                credentials: 'include',
            }
        ).then((res) => res.json())
            .then((data) => {
                const installerEstate = data.filter((estate: any) => estate.type === 'INSTALLER')
                const installerEstateList = data.filter((estate: any) => estate.type === 'ESTATE')
                dispatch(setInstallerEstateID(installerEstate[0].ID))
                dispatch(setInstallerEstateList(installerEstateList))
            });
    };
}

export function addUserEmailAlerts(body: any) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(
            `${REACT_APP_BASE_URL}/m2m-eaton-web/rest/config/siaemailoption/setConfiguration`,
            {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            }
        )
            .then((res) => dispatch(setAddUserEmailAlertsStatus(res.status)))
    };
}

export function fetchConnectLoginHtmlCodeStatus(panelId: string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(
            `${REACT_APP_BASE_URL}/m2m-eaton-web/iframe/panelid-${panelId}/expart/expart/index.htm`,
            {
                method: 'GET',
                credentials: 'include',
            }
        )
            .then((res) => dispatch(setConnectLoginHtmlCodeStatus(res.status)))
    };
}
export function fetchZoneMonitoringList(panelId:string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(
            `${REACT_ARD_BASE_URL}/m2m-eaton-web/ardconfig/zoneMonitorConfig/${panelId}`,
            {
                method: 'GET',
                credentials:'include',
            }
            )
            .then(async(res) => {
                if(res.status === 200){
                    const data = await res.json()
                    await dispatch(setZoneMonitorConfigList(data))
                }
                else{
                    await dispatch(setZoneMonitorConfigList([])) 
                }
            })     
    };
}
export function fetchZoneMonitoringListAfterRefresh(panelId:string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(
            `${REACT_ARD_BASE_URL}/m2m-eaton-web/ardconfig/zoneMonitorConfig/${panelId}?${encodeURI('forceRefresh')}=true`,
            {
                method: 'GET',
                credentials:'include',
            }
            )
            .then(async(res) => {
                if(res.status === 200){
                    const data = await res.json()
                    await dispatch(setZoneMonitorConfigList(data))
                }
                else{
                    await dispatch(setZoneMonitorConfigList([])) 
                }
            })     
    };
}

export function putZonesExcludingFromMonitoring(panelId:string,body: any) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(
            `${REACT_ARD_BASE_URL}/m2m-eaton-web/ardconfig/zoneMonitorConfig/${panelId}`,
            {
                method: 'PUT',
                credentials:'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            }
        )
            .then((res) => dispatch(setZoneMonitorConfigListModifiedStatus(res.status)))
    };
}

