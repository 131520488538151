/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/restrict-plus-operands */

import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {
    Button,
    Divider,
    Grid,
    IconButton,
    MobileStepper,
    Paper,
    TextField,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CompanyInformationEditModalProps } from '../SettingsPropTypes';
import CompanyInformationEditForm from './CompanyInformationEditForm';
import { useSelector } from 'react-redux';
import { editCompanyInfoSettings } from '../../../redux/actions/settingsActions';
import { useTypedDispatch } from '../../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { useTranslation, Trans } from 'react-i18next';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    backgroundColor: 'background.paper',
    boxShadow: 20,
    p: 0,
};

export default function CompanyInformationEditModal({ open, setOpen, count, setCount, sectionName, setSectionName }: CompanyInformationEditModalProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const { currentUserSettings, settingsCountries } = useSelector((state: any) => state.settingsReducer);
    const dispatch = useTypedDispatch()
    const [activeStep, setActiveStep] = React.useState(0);
    const [firstName, setFirstName] = React.useState<string>(currentUserSettings?.firstName);
    const [lastName, setLastName] = React.useState<string>(currentUserSettings?.lastName);
    const [email, setEmail] = React.useState<string>(currentUserSettings?.login);
    const [companyName, setCompanyName] = React.useState<string>(currentUserSettings?.installerInfo?.installerCompanyName);
    const [firstPhoneNumber, setFirstPhoneNumber] = React.useState<string>(currentUserSettings?.installerInfo?.firstPhoneNumber?.number);
    const [secondPhoneNumber, setSecondPhoneNumber] = React.useState<string>(currentUserSettings?.installerInfo?.secondPhoneNumber?.number);
    const [thirdPhoneNumber, setThirdPhoneNumber] = React.useState<string>(currentUserSettings?.installerInfo?.thirdPhoneNumber?.number);
    const [firstPhoneType, setFirstPhoneType] = React.useState<string>(currentUserSettings?.installerInfo?.firstPhoneNumber?.type);
    const [secondPhoneType, setSecondPhoneType] = React.useState<string>(currentUserSettings?.installerInfo?.secondPhoneNumber?.type);
    const [thirdPhoneType, setThirdPhoneType] = React.useState<string>(currentUserSettings?.installerInfo?.thirdPhoneNumber?.type);
    const [address, setAddress] = React.useState<string>(currentUserSettings?.installerInfo?.address?.address);
    const [country, setCountry] = React.useState<string>(currentUserSettings?.installerInfo?.address?.country);
    const [postCode, setPostCode] = React.useState<string>(currentUserSettings?.installerInfo?.address?.postCode);
    const [companyErrorText,setCompanyErrorText]=React.useState<string>("")
    const [addressErrorText,setAddressErrorText]=React.useState<string>("")
    const [postCodeErrorText,setPostCodeErrorText]=React.useState<string>("")
    const [firstPhoneNumberError,setFirstPhoneNumberError]=React.useState<string>("")
    const [secoundPhoneNumberError,setSecondPhoneNumberError]=React.useState<string>("")
    const [thirdPhoneNumberError,setThirdPhoneNumberError]=React.useState<string>("")
    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        setSectionName('Company information')
        setCompanyName(currentUserSettings?.installerInfo?.installerCompanyName);
        setFirstPhoneNumber(currentUserSettings?.installerInfo?.firstPhoneNumber.number)
        setSecondPhoneNumber(currentUserSettings?.installerInfo?.secondPhoneNumber.number)
        setThirdPhoneNumber(currentUserSettings?.installerInfo?.thirdPhoneNumber.number)
        setFirstPhoneType(currentUserSettings?.installerInfo?.firstPhoneNumber.type)
        setSecondPhoneType(currentUserSettings?.installerInfo?.secondPhoneNumber.type)
        setThirdPhoneType(currentUserSettings?.installerInfo?.thirdPhoneNumber.type)
        setAddress(currentUserSettings?.installerInfo?.address?.address)
        setPostCode(currentUserSettings?.installerInfo?.address?.postCode)
        setCountry(currentUserSettings?.installerInfo?.address?.country)
    }, []);

    const handleClose = () => {
        setOpen(false);
        setActiveStep(0);
    };

    const handleSave = async () => {
        const body = {
            firstName: firstName,
            lastName: lastName,
            login: email,
            groupId: currentUserSettings?.groupId,
            installerInfo: {
                ID: currentUserSettings?.installerInfo?.ID,
                cloudId: currentUserSettings?.installerInfo?.cloudId,
                installerCompanyName: companyName,
                address: {
                    address: address,
                    postCode: postCode,
                    country: country,
                },
                firstPhoneNumber: {
                    number: firstPhoneNumber,
                    type: firstPhoneType,
                },
                secondPhoneNumber: {
                    number: secondPhoneNumber,
                    type: secondPhoneType,
                },
                thirdPhoneNumber: {
                    number: thirdPhoneNumber,
                    type: thirdPhoneType,
                },
                installerPrimaryContact: "firstPhoneNumber",
                installerState: "ACTIVE",
                email: email
            },
            userType: "INSTALLER",
            locale: currentUserSettings?.locale
        };
        await dispatch(editCompanyInfoSettings(body, currentUserSettings.ID))
        setCount(count + 1)
        setOpen(false);
    };

    return (
        <div>
            {md ? (
                <>
                    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box
                            sx={{
                                position: 'absolute' as 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'background.paper',
                                boxShadow: 20,
                                p: 0,
                            }}
                        >
                            <Paper elevation={0} sx={{ pb: 0 }}>
                                <Paper
                                    elevation={4}
                                    sx={{
                                        paddingTop: 2,
                                        paddingBottom: 1,
                                        paddingLeft: 2,
                                        paddingRight: 2,
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <IconButton onClick={handleClose}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                    &ensp;&ensp;
                                    <Typography sx={{ pt: 0.5 }} variant="h6">
                                    {t('lbl_cmpnyInfo')}
                                    </Typography>
                                </Paper>
                                <Divider />
                                <CompanyInformationEditForm firstName={firstName} setFirstName={setFirstName} lastName={lastName} setLastName={setLastName} companyName={companyName} setCompanyName={setCompanyName} address={address} setAddress={setAddress} country={country} setCountry={setCountry} postCode={postCode} setpostCode={setPostCode} firstPhoneNumber={firstPhoneNumber} setfirstPhoneNumber={setFirstPhoneNumber}
                                    secondPhoneNumber={secondPhoneNumber} setSecondPhoneNumber={setSecondPhoneNumber} thirdPhoneNumber={thirdPhoneNumber} setThirdPhoneNumber={setThirdPhoneNumber} countryList={settingsCountries}
                                    firstPhoneType={firstPhoneType} setfirstPhoneType={setFirstPhoneType} secondPhoneType={secondPhoneType} setSecondPhoneType={setSecondPhoneType} thirdPhoneType={thirdPhoneType} setThirdPhoneType={setThirdPhoneType}
                                    companyErrorText={companyErrorText} setCompanyErrorText={setCompanyErrorText} addressErrorText={addressErrorText} setAddressErrorText={setAddressErrorText} postCodeErrorText={postCodeErrorText} setPostCodeErrorText={setPostCodeErrorText} 
                                    firstPhoneNumberError={firstPhoneNumberError} setFirstPhoneNumberError={setFirstPhoneNumberError} secoundPhoneNumberError={secoundPhoneNumberError} setSecondPhoneNumberError={setSecondPhoneNumberError} thirdPhoneNumberError={thirdPhoneNumberError} setThirdPhoneNumberError={setThirdPhoneNumberError}
                                    />
                            </Paper>
                            <Paper
                                sx={{
                                    position: 'absolute' as 'absolute',
                                    top: '100vh',
                                    transform: 'translateY(-100%)',
                                    width: '100%',
                                    zIndex: 1000
                                }}
                                elevation={1}
                            >
                                <Divider />
                                <Box sx={{ flexGrow: 1, p: 2 }}>
                                    <Grid container spacing={2} >
                                        <Grid item xs={12}>
                                            <Button fullWidth size="medium" variant="contained" onClick={handleSave}
                                            disabled={
                                                companyErrorText.length>0||
                                                addressErrorText.length>0 ||
                                                postCodeErrorText.length>0||
                                                firstPhoneNumberError.length>0 ||
                                                secoundPhoneNumberError.length>0 ||
                                                thirdPhoneNumberError.length>0
                                            }>
                                            {t('btn_save')}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button fullWidth size="small" variant="outlined" onClick={handleClose}>
                                            {t('btn_cancel')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Box>
                    </Modal>
                </>
            ) : (
                <>
                    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box sx={style}>
                            <Paper
                                elevation={0}
                                sx={{ paddingTop: 2, paddingBottom: 3, paddingLeft: 3, paddingRight: 2 }}
                            >
                                <Typography variant="h6">{t('lbl_cmpnyInfo')}</Typography>
                            </Paper>
                            <Divider />
                            <Paper elevation={0} sx={{}}>
                                <CompanyInformationEditForm firstName={firstName} setFirstName={setFirstName} lastName={lastName} setLastName={setLastName} companyName={companyName} setCompanyName={setCompanyName} address={address} setAddress={setAddress} country={country} setCountry={setCountry} postCode={postCode} setpostCode={setPostCode} firstPhoneNumber={firstPhoneNumber} setfirstPhoneNumber={setFirstPhoneNumber}
                                    secondPhoneNumber={secondPhoneNumber} setSecondPhoneNumber={setSecondPhoneNumber} thirdPhoneNumber={thirdPhoneNumber} setThirdPhoneNumber={setThirdPhoneNumber} countryList={settingsCountries}
                                    firstPhoneType={firstPhoneType} setfirstPhoneType={setFirstPhoneType} secondPhoneType={secondPhoneType} setSecondPhoneType={setSecondPhoneType} thirdPhoneType={thirdPhoneType} setThirdPhoneType={setThirdPhoneType}
                                    companyErrorText={companyErrorText} setCompanyErrorText={setCompanyErrorText} addressErrorText={addressErrorText} setAddressErrorText={setAddressErrorText} postCodeErrorText={postCodeErrorText} setPostCodeErrorText={setPostCodeErrorText} 
                                    firstPhoneNumberError={firstPhoneNumberError} setFirstPhoneNumberError={setFirstPhoneNumberError} secoundPhoneNumberError={secoundPhoneNumberError} setSecondPhoneNumberError={setSecondPhoneNumberError} thirdPhoneNumberError={thirdPhoneNumberError} setThirdPhoneNumberError={setThirdPhoneNumberError}
                                    />
                            </Paper>
                            <Divider />
                            <MobileStepper
                                variant="dots"
                                steps={0}
                                position="static"
                                activeStep={activeStep}
                                sx={{
                                    maxWidth: 500,
                                    flexGrow: 1,
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    paddingLeft: 3,
                                    paddingRight: 3,
                                    backgroundColor: 'inherit',
                                }}
                                nextButton={
                                    <Button size="medium" variant="contained" onClick={handleSave} 
                                    disabled={
                                        companyErrorText.length>0||
                                        addressErrorText.length>0 ||
                                        postCodeErrorText.length>0||
                                        firstPhoneNumberError.length>0 ||
                                        secoundPhoneNumberError.length>0 ||
                                        thirdPhoneNumberError.length>0
                                    }>
                                        {t('btn_save')}
                                    </Button>
                                }
                                backButton={
                                    <Button size="small" variant="outlined" onClick={handleClose}>
                                        {t('btn_cancel')}
                                    </Button>
                                }
                            />
                        </Box>
                    </Modal>
                </>
            )}
        </div>
    );
}
