/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { PanelMenuListProps } from './PanelMenuListTypes';
import DeletePanelModal from '../DeletePanelModal/DeletePanelModal';
import ManagePanelsModal from '../ManagePanelsAndEstatesModal/ManagePanelsModal';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { fetchEstatesAndPanels, fetchPanelsInEstate } from '../../redux/actions/managePanelsAndEstatesActions';
import { EstateProps } from '../ListAndGridView/panelEstateTypes';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ManagePanelsModalForEstate from '../ManagePanelsAndEstatesModal/ManagePanelsModalForEstate';
import secureLocalStorage  from  "react-secure-storage";
import { fetchEstateIDForInstaller } from '../../redux/actions/specificPanelActions';

export default function PanelMenuList({ path,screen,subEstates, estatePage, anchorEl, open, setAnchorEl, panelId, panelName }: PanelMenuListProps): JSX.Element {
  const dispatch = useTypedDispatch()
  const role = secureLocalStorage.getItem('role')
  //const currentUser = JSON.parse(localStorage.getItem('currentUser') || '')
  const { currentUser, estates, unassignedPanels } = useSelector((state: any) => state.userReducer);
  const { installerDetails } = useSelector((state: any) => state.installerReducer);
  const specificEstate: any = secureLocalStorage.getItem('estate') || {};
  const { panelsInEstate } = useSelector((state: any) => state.managePanelsAndEstatesReducer);
  const [panelsInEstates, setPanelsInEstates] = React.useState([unassignedPanels].concat(panelsInEstate));
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
  const [managePanelsOpen, setManagePanelsOpen] = React.useState(false);
  const [count, setCount] = React.useState(0)
  const [movePanel, setMovePanel] = React.useState(false)
  const { t } = useTranslation();
  const handleClose = (): any => {
    setAnchorEl(null);
  };

  const handleMovePanel = async() => {   
    if(role==='ADMIN'){
      if(path==='installerPanelTab' || estatePage){
        await dispatch(fetchEstateIDForInstaller(installerDetails?.ID))
      }
    }else{
      if(path==='panels' || estatePage){
        await dispatch(fetchEstateIDForInstaller(currentUser?.ID))
      }
    }    
    setMovePanel(true)
    setManagePanelsOpen(true)
    setAnchorEl(null);
  };

  const handleDeletePanel = (): any => {
    setAnchorEl(null);
    setOpenDeleteModal(true)
  };

  return (
    <div>
      <Menu
        elevation={2}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "center", horizontal: "center" }}
      >
        
          {(role !== 'ADMIN' || screen === 'estate' || path === 'installerPanelTab')&&
            <MenuItem  onClick={handleMovePanel}>
        {t('lbl_move_panel')}
        </MenuItem>}
       
        <MenuItem onClick={handleDeletePanel}>
        {t('lbl_delete_panel')}
        </MenuItem>
      </Menu>
      {screen === 'estate' ? 
      <ManagePanelsModalForEstate estatePage={estatePage} movePanel={movePanel} setCount={setCount} count={count} panelsInEstates={panelsInEstates} setPanelsInEstates={setPanelsInEstates} open={managePanelsOpen} setOpen={setManagePanelsOpen} panelId={panelId} panelName={panelName}/>
      :
        <ManagePanelsModal path={path} estatePage={estatePage} movePanel={movePanel} setCount={setCount} count={count} panelsInEstates={panelsInEstates} setPanelsInEstates={setPanelsInEstates} open={managePanelsOpen} setOpen={setManagePanelsOpen} panelId={panelId} panelName={panelName}/>}
      <DeletePanelModal estatePage={estatePage} open={openDeleteModal} setOpen={setOpenDeleteModal} selectedPanelId={panelId} selectedPanelName={panelName} />
    </div>
  );
}
