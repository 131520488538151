/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */

import { Box, Button, Divider, MobileStepper, Modal, Paper, Snackbar, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { fetchInstallerUsers, getDeleteStatus } from '../../../redux/actions/installersActions';
import { useTypedDispatch } from '../../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#fff',
    border: '0px solid #000',
    boxShadow: 20,
    p: 0,
};

type props = {
    open: boolean
    setOpen: (open: boolean) => void
    deleteUserId: number
    deleteUserName: string
    deleteCounter: number
    setDeleteCounter: (counter: number) => void
}
function DeleteUserModal({ open, setOpen, deleteUserId, deleteUserName, deleteCounter, setDeleteCounter }: props): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation();
    const dispatch = useTypedDispatch();
    const [activeStep, setActiveStep] = React.useState(0);
    const { userDeleteStatus } = useSelector((state: any) => state.installerReducer)
    const [count, setCount] = React.useState(0);

    const handleDelete = async () => {
        await dispatch(getDeleteStatus(deleteUserId))
        setCount((prev) => prev + 1)
        setOpen(false);
    }

    const handleClose = () => {
        setOpen(false);
        setActiveStep(0);
    }

    React.useEffect(() => {
        if (count !== 0) {
            setDeleteCounter(deleteCounter + 1)
        }
    }, [count])

    return (
        <>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: md ? 300 : 400,
                    bgcolor: 'background.paper',
                    border: '0px solid #000',
                    boxShadow: 20,
                    p: 0,
                }} >
                    <Paper elevation={0} sx={{ pb: 1 }}>
                        <Typography sx={{ pt: 2, pl: 2, pb: 2 }} variant='h6'>{t('lbl_DeleteUser')}</Typography>
                        <Typography sx={{ pl: 2, pb: 4, pt: 0 }} >{t('lbl_confirm_delete')} {deleteUserName} ?</Typography>
                    </Paper>
                    <Divider />
                    <Paper >
                        <MobileStepper
                            variant="dots"
                            steps={0}
                            position="static"
                            activeStep={activeStep}
                            sx={{ maxWidth: 400, flexGrow: 1, paddingTop: 2, paddingBottom: 2, paddingLeft: 3, paddingRight: 3, backgroundColor: 'inherit' }}
                            nextButton={
                                <Button size="medium" disableElevation sx={{ backgroundColor: '#CA3C3D' }} variant='contained' onClick={handleDelete}>
                                    {t('lbl_delete')}
                                </Button>
                            }
                            backButton={
                                <Button size="small" variant='outlined' onClick={handleClose}  >
                                    {t('btn_cancel')}
                                </Button>
                            }
                        />
                    </Paper>
                </Box>
            </Modal>
        </>
    )
}

export default DeleteUserModal
